import { popoverAnatomy } from '@chakra-ui/anatomy';
import {
  cssVar,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

type Parts = typeof popoverAnatomy;

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');
const $popperBg = cssVar('popper-bg');

const baseStylePopper: SystemStyleObject = {
  zIndex: 10,
};

const baseStyleContent: SystemStyleObject = {
  [$popperBg.variable]: 'colors.white',
  _focus: {
    boxShadow: 'outline',
    outline: 0,
  },
  [$arrowBg.variable]: $popperBg.reference,
  [$arrowShadowColor.variable]: `colors.gray.200`,
  background: $popperBg.reference,
  border: '1px solid',
  borderColor: 'inherit',
  borderRadius: 'radius-6',
  boxShadow: 'shadow-small-1',
  width: 'xs',
  zIndex: 'inherit',
};

const baseStyleHeader: SystemStyleObject = {
  borderBottomWidth: '1px',
  paddingX: 'space-12',
  paddingY: 'space-8',
};

const baseStyleBody: SystemStyleObject = {
  paddingX: 'space-12',
  paddingY: 'space-8',
};

const baseStyleFooter: SystemStyleObject = {
  borderTopWidth: '1px',
  paddingX: 'space-12',
  paddingY: 'space-8',
};

const baseStyleCloseButton: SystemStyleObject = {
  borderRadius: 'md',
  insetEnd: 'space-8',
  padding: 'space-8',
  position: 'absolute',
  top: 'space-4',
};

const baseStyle: PartsStyleObject<Parts> = {
  arrow: {},
  body: baseStyleBody,
  closeButton: baseStyleCloseButton,
  content: baseStyleContent,
  footer: baseStyleFooter,
  header: baseStyleHeader,
  popper: baseStylePopper,
};

const PopoverTheme = {
  baseStyle,
  parts: popoverAnatomy.keys,
};

export default PopoverTheme;
