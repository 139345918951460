/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8558%3A35124&t=zJqO6V1l7kOcMeyZ-1
 */

import ReactSelect, { components, createFilter, Props } from 'react-select';
import { Center, Spinner } from '@chakra-ui/react';
import { useToken } from '@chakra-ui/system';
import {
  components as SunshineComponents,
  getColorValue,
  getTokenValue,
} from '@shinetools/sunshine';

import Button from 'components/_core/Button';
import IconButton from 'components/_core/IconButton';
import SunshineIcon from 'components/_core/SunshineIcon';
import SunshineTag from 'components/_core/SunshineTag';
import Text from 'components/_core/Text';

import LanguageOption from './components/LanguageOption';
import LanguageSingleValue from './components/LanguageSingleValue';
import locales from './locales';
import { addOption } from './utils';

const { select: selectTokens } = SunshineComponents;

const defaultFilter = createFilter(undefined);

export interface OptionTypeBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const filterOption = (
  candidate: {
    label: string;
    value: string;
    data: { isAddOption?: boolean } | undefined;
  },
  input: string,
) => {
  if (input) {
    // always display the "add option" option when available
    if (candidate.data?.isAddOption) {
      return true;
    }

    // if not, fallback on the default filter method
    return defaultFilter(candidate, input);
  }

  return true;
};

type DefaultOptionTypeBase = { label: string; value: string };

export interface SunshineSelectProps<
  OptionType extends OptionTypeBase = DefaultOptionTypeBase,
  isMulti extends boolean = false,
> extends Props<OptionType, isMulti> {
  isInvalid?: boolean;
  variant?: 'default' | 'ghost';
  allowMenuAutoWidth?: boolean;
}

function SunshineSelect<
  OptionType extends OptionTypeBase = DefaultOptionTypeBase,
  isMulti extends boolean = false,
>({
  allowMenuAutoWidth = false,
  components: componentsProp = {},
  isInvalid,
  isSearchable = false,
  variant = 'default',
  ...props
}: SunshineSelectProps<OptionType, isMulti>) {
  const [radius6] = useToken('radii', ['radius-6']);
  const modalIndex = useToken('zIndices', 'modal');
  const primaryTextColor = getTokenValue('text-primary');
  const [space4, space8, space10, space12] = useToken('space', [
    'space-4',
    'space-8',
    'space-10',
    'space-12',
  ]);

  const portalTarget = document.getElementById('react-select-portal');

  return (
    <ReactSelect
      components={{
        DropdownIndicator: (dropdownIndicatorProps) =>
          props.isLoading ? null : (
            <components.DropdownIndicator {...dropdownIndicatorProps}>
              <SunshineIcon
                color="grey.600"
                name="chevron-down"
                size="icon-24"
              />
            </components.DropdownIndicator>
          ),
        IndicatorSeparator: () => null,
        LoadingIndicator: () => (
          <Center boxSize={18} marginX="space-12">
            <Spinner height="100%" width="100%" />
          </Center>
        ),
        MultiValueContainer: (multiValueProps) => (
          <SunshineTag paddingRight="space-4" size="sm">
            {multiValueProps.children}
          </SunshineTag>
        ),
        MultiValueLabel: ({ children }) => <>{children}</>,
        MultiValueRemove: ({ innerProps: { className, ...innerProps } }) => (
          // @ts-expect-error we're spreading props meant for a HTMLDivElement on a HTMLButtonElement.
          // Should work okay, but typings break with the React 18 update.
          <IconButton
            {...innerProps}
            aria-label=""
            bg="transparent"
            boxSize="24px"
            color="inherit"
            icon="cancel"
            minWidth="inherit"
            variant="inline-primary"
          />
        ),
        NoOptionsMessage: (noOptionsMessageProps) => (
          <components.NoOptionsMessage {...noOptionsMessageProps}>
            <Text variant="light">{locales.noOptionsMessage}</Text>
          </components.NoOptionsMessage>
        ),
        Option: (optionProps) => {
          const { data } = optionProps;
          const { buttonProps = {} } = data;

          if (data.isAddOption) {
            return (
              <Center
                paddingY="space-16"
                {...optionProps.innerProps}
                onClick={() => {}}
                tabIndex={0}
              >
                <Button variant="secondary" {...buttonProps}>
                  {data.label}
                </Button>
              </Center>
            );
          }

          return (
            <components.Option {...optionProps}>
              <Text variant="primary">{optionProps.children}</Text>
            </components.Option>
          );
        },
        ...componentsProp,
      }}
      filterOption={filterOption}
      menuPortalTarget={portalTarget}
      placeholder={props?.placeholder ?? locales.defaultPlaceholder}
      styles={{
        control: (base, { hasValue, isDisabled, isFocused, menuIsOpen }) => ({
          ...base,
          ':hover': {
            backgroundColor: (() => {
              if (variant === 'ghost') {
                return getColorValue('grey.300');
              }

              return selectTokens['select-input-backgroundColor-hover'];
            })(),
            borderColor: (() => {
              if (variant === 'ghost') {
                return 'transparent';
              }

              if (isInvalid) {
                return selectTokens['select-input-borderColor-invalid'];
              }

              if (isDisabled) {
                return selectTokens['select-input-borderColor-disabled'];
              }

              if (menuIsOpen) {
                return selectTokens['select-input-borderColor-active'];
              }

              return selectTokens['select-input-borderColor-hover'];
            })(),
          },
          backgroundColor: (() => {
            if (variant === 'ghost' && isFocused) {
              return getColorValue('grey.200');
            }

            if (isDisabled) {
              return selectTokens['select-input-backgroundColor-disabled'];
            }

            if (isInvalid) {
              return selectTokens['select-input-backgroundColor-invalid'];
            }

            if (isFocused || menuIsOpen) {
              return selectTokens['select-input-backgroundColor-active'];
            }

            return selectTokens['select-input-backgroundColor'];
          })(),
          borderColor: (() => {
            if (variant === 'ghost') {
              return 'transparent';
            }

            if (isInvalid) {
              return selectTokens['select-input-borderColor-invalid'];
            }

            if (isDisabled) {
              return selectTokens['select-input-borderColor-disabled'];
            }

            if (isFocused || menuIsOpen) {
              return selectTokens['select-input-borderColor-active'];
            }

            return selectTokens['select-input-borderColor'];
          })(),
          borderRadius: radius6,
          boxShadow: 'none',
          fontWeight: hasValue ? 500 : 400,
          height: '2.5rem', // 40px
          transition: 'all 0.2s ease-out',
        }),
        menu: ({ width, ...base }) => ({
          ...base,
          ...(allowMenuAutoWidth ? {} : { width }),
          marginTop: space4,
        }),
        menuList: (base) => ({
          ...base,
          borderRadius: radius6,
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: modalIndex + 1,
        }),
        noOptionsMessage: (base) => ({
          ...base,
          padding: space10,
        }),
        option: (base, { isDisabled, isFocused, isSelected }) => ({
          ...base,
          '&:last-of-type': {
            borderBottom: '0',
          },
          ':hover': {
            backgroundColor: isDisabled
              ? selectTokens['select-option-backgroundColor']
              : selectTokens['select-option-backgroundColor-hover'],
          },
          backgroundColor:
            (isSelected || isFocused) && !isDisabled
              ? selectTokens['select-option-backgroundColor-hover']
              : selectTokens['select-option-backgroundColor'],
          borderBottom: '1px solid',
          borderBottomColor: getTokenValue('border'),
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          padding: `${space8} ${space10}`,
        }),
        singleValue: (base) => ({
          ...base,
          color: primaryTextColor,
          /**
           * In some edge cases (country phone codes), the label can be too large and is then truncated.
           * That line allows it to overflow on the 12px right padding of `valueContainer`,
           * which partially alleviates the problem.
           */
          overflow: 'visible',
        }),
        valueContainer: (base) => ({
          ...base,
          gap: space8,
          height: '100%',
          padding: variant === 'ghost' ? `0 0 0 ${space8}` : `0 ${space12}`,
        }),
      }}
      {...props}
      /**
       * the following prop is not supported at the moment
       * feel free to open the subject on Slack if you need them
       */
      isClearable={false}
      isDisabled={props.isDisabled || props.isLoading}
      isLoading={props.isLoading}
      isSearchable={isSearchable}
    />
  );
}

SunshineSelect.addOption = addOption;

SunshineSelect.components = {
  LanguageOption,
  LanguageSingleValue,
};

export default SunshineSelect;
