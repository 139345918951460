import { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import { AuthenticationDeviceRequestStatus } from '__generated__/GQL';

import AuthenticationDeviceRequest from './components/AuthenticationDeviceRequest';
import { AuthenticationDeviceRequestsDocument } from './hooks/queries/authenticationDeviceRequests.gql';
import { NewAuthenticationDeviceRequestDocument } from './hooks/subscriptions/newAuthenticationDeviceRequest.gql';

const AuthenticationDeviceRequests: FC = () => {
  const { data: queryData, loading } = useQuery(
    AuthenticationDeviceRequestsDocument,
    {
      variables: {
        status: AuthenticationDeviceRequestStatus.Pending,
      },
    },
  );

  const phone = queryData?.viewer.profile.phone || '';

  const { data: subscriptionData } = useSubscription(
    NewAuthenticationDeviceRequestDocument,
    {
      skip: loading || !phone,
      variables: {
        phone,
      },
    },
  );

  const requestFromSubscription =
    subscriptionData?.newAuthenticationDeviceRequest;
  const requests =
    queryData?.viewer.authenticationDeviceRequests.edges.map(
      (edge) => edge.node,
    ) || [];

  // We want to consider the request from the subscriptions like others requests
  // Though we don't want to show it twice (duplication)
  if (
    requestFromSubscription &&
    !requests.some(
      (req) =>
        req.authenticationDeviceRequestId ===
        requestFromSubscription.authenticationDeviceRequestId,
    )
  ) {
    requests.push(requestFromSubscription);
  }

  return (
    <>
      {requests.map((request) => (
        <AuthenticationDeviceRequest
          key={request.authenticationDeviceRequestId}
          request={request}
        />
      ))}
    </>
  );
};

export default AuthenticationDeviceRequests;
