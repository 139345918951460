import { FC } from 'react';
import { chakra, StackProps, VStack } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import Icon from 'components/_core/Icon';
import { PdfViewerProps } from 'components/PdfViewer/PdfViewer';

import locales from '../../locales';

type PdfFallbackProps = PdfViewerProps & StackProps;

const PdfFallback: FC<PdfFallbackProps> = ({ src, ...rest }) => (
  <VStack
    as={chakra.a}
    backgroundColor="grey.2"
    borderRadius="radius-16"
    color="grey.-1"
    href={src}
    justify="center"
    marginX="auto"
    padding="space-24"
    target="_blank"
    textAlign="center"
    {...rest}
  >
    <Heading>{locales.download}</Heading>

    <Icon name="download" />
  </VStack>
);

export default PdfFallback;
