import { Center } from '@chakra-ui/react';

import {
  TransactionCategory,
  TransactionPaymentMethod,
  TransactionStatus,
} from '__generated__/GQL';
import { formatAmount } from 'common/formatAmount';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import mapTransactionToRebrandedCategoryIconProps from 'features/Bank/pages/Overview/mapTransactionToRebrandedCategoryIconProps';
import { formatDate, FORMATS } from 'helpers/date';

import { Request } from '../types';
import RequestDetailsRow from './RequestDetailsRow';

interface Props {
  cardPaymentStrongAuthenticationRequest: Request;
}

const TransactionInfo = ({ cardPaymentStrongAuthenticationRequest }: Props) => {
  const transactionAmount = `- ${formatAmount({
    currency: cardPaymentStrongAuthenticationRequest.transactionCurrency,
    forcePlusSign: false,
    value: cardPaymentStrongAuthenticationRequest.transactionAmount,
  })}`;

  const { color, name } = mapTransactionToRebrandedCategoryIconProps({
    transaction: {
      category:
        cardPaymentStrongAuthenticationRequest.category as TransactionCategory,
      paymentMethod: TransactionPaymentMethod.Card,
      status: TransactionStatus.Pending,
      type: 'payout',
    },
  });

  const transactionDate = formatDate(
    new Date(cardPaymentStrongAuthenticationRequest.authenticationRequestedAt),
    FORMATS.p,
  );

  return (
    <RequestDetailsRow
      description={transactionDate}
      label={cardPaymentStrongAuthenticationRequest.merchantName}
      leftComponent={
        <Center boxSize={40}>
          <SquircleIcon colorScheme={color} name={name} size="md" />
        </Center>
      }
      rightText={transactionAmount}
    />
  );
};

export default TransactionInfo;
