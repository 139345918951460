import { Gender } from 'common/types';
import { FRANCE_COUNTRY_CODE } from 'features/Bento/libs/constants';

import { AdminIdentityModuleDataQuery } from '../graphql/queries/adminIdentityModuleData.gql';
import { IdentitySchema } from './identitySchema';

/**
 * This function converts a userProfile from graphql into fields usable in the identity form.
 * Used to prefill the identity form fields.
 */
const getInitialFormValues = ({
  invitation,
  profile,
}: {
  profile: AdminIdentityModuleDataQuery['viewer']['profile'];
  invitation: AdminIdentityModuleDataQuery['viewer']['companyUser']['invitation'];
}): IdentitySchema => ({
  birthCity: profile.birthCity || '',
  birthCountry: profile.birthCountry || '',
  birthdate: profile.birthdate || '',
  birthDepartment: profile.birthDepartment || '',
  firstName: invitation?.inviteeFirstName || profile.firstName,
  gender: profile.gender === 'male' ? Gender.MALE : Gender.FEMALE,
  lastName: invitation?.inviteeLastName || profile.lastName,
  nationality: profile.nationality || FRANCE_COUNTRY_CODE,
});

export default getInitialFormValues;
