// The following import loads the remote-config part of the firebase
// library into the firebase namespace.
import { FirebaseError } from 'firebase/app';
import {
  fetchAndActivate,
  getAll,
  getBoolean,
  getNumber,
  getRemoteConfig,
  getString,
  getValue,
  LogLevel,
  RemoteConfig,
  setLogLevel,
} from 'firebase/remote-config';

import config from 'config';
import logger from 'helpers/logger';

import { GetConfigGettersOnceLoadedFn, RemoteConfigGetters } from './types';

const ONE_MINUTE_MS = 60 * 1000;
const RESPONSE_DUE_TO_TIMEOUT = 'timeout-response';

let remoteConfig: RemoteConfig;

let hasConfigLoaded = false;
let fetchAndActivatePromise: Promise<unknown>;

const FIREBASE_ERROR_TO_SILENCE = [
  // https://github.com/firebase/firebase-js-sdk/blob/firebase%409.9.0/packages/remote-config/src/errors.ts
  'remoteconfig/fetch-client-network', // Fetch client failed to connect to a network. Check Internet connection.
  'remoteconfig/fetch-timeout', // The config fetch request timed out
  'remoteconfig/fetch-client-parse', // Fetch client could not parse response
  'remoteconfig/fetch-status', // Fetch server returned an HTTP error status. HTTP status
  'remoteconfig/indexed-db-unavailable', // Indexed DB is not supported by current browser
  'remoteconfig/storage-open', // Error thrown when opening storage.
  'remoteconfig/storage-get', // Error thrown when reading from storage
  // https://github.com/firebase/firebase-js-sdk/blob/firebase%409.9.0/packages/installations/src/util/errors.ts
  'installations/not-registered', // Firebase Installation is not registered.
  'installations/app-offline', // Could not process request. Application offline.
  // https://github.com/firebase/firebase-js-sdk/blob/firebase%409.0.0/packages/auth/src/core/errors.ts
  'auth/network-request-failed', // A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred
  'auth/internal-error', // An internal AuthError has occurred
];

/**
 * Call the native fetchAndActivate firebase method and silence client-related errors.
 */
export const fetchAndActivateRemoteConfig = (conf: RemoteConfig) => {
  return fetchAndActivate(conf).catch((error) => {
    if (error instanceof FirebaseError) {
      if (FIREBASE_ERROR_TO_SILENCE.includes(error.code)) {
        logger.warn(error);
        return;
      }
    }
    throw error;
  });
};

const initialize = () => {
  remoteConfig = getRemoteConfig();
  remoteConfig.settings = {
    fetchTimeoutMillis: ONE_MINUTE_MS,
    // The minimum time between updates of the remote config. This value
    // should not be set too low in production in order to avoid a throttling
    // error.
    // See https://firebase.google.com/docs/remote-config/use-config-web#throttling
    minimumFetchIntervalMillis: config.remoteConfigMinFetchIntervalMs,
  };

  setLogLevel(remoteConfig, config.remoteConfigLogLevel as LogLevel);

  remoteConfig.defaultConfig = config.remoteConfigDefaults;
};

/**
 * Fetches the latest remote config and immediately sets it as the currently
 * active state.
 *
 * Currently, this should only be called when the user first loads the app -
 * potentially before they've logged in. This gives time for the latest remote
 * config to load before we're likely to show any branched views. Currently,
 * the remote config values should generally be loaded via the getConfigGettersOnceLoaded
 * function however as this means users aren't shown one A:B test before the
 * remote config switches them to being under another.
 * This corresponds to "Strategy 2: Activate behind loading screen" in the docs:
 * https://firebase.google.com/docs/remote-config/loading#strategy_2_activate_behind_loading_screen
 */
export const initializeFetchAndActivate = async () => {
  initialize();
  if (remoteConfig === undefined) {
    throw new Error('The remoteConfig property should be initialized');
  }

  fetchAndActivatePromise = fetchAndActivateRemoteConfig(remoteConfig);
  await fetchAndActivatePromise;

  hasConfigLoaded = true;
};

const produceRemoteConfigGetters = (): RemoteConfigGetters => {
  if (remoteConfig === undefined) {
    throw new Error('The remoteConfig property should be initialized');
  }

  // As remoteConfig is a class instance, just returning { getX, getY, ...} directly
  // from remoteConfig causes errors as they are separated from their instance.
  return {
    getAll: () => getAll(remoteConfig),
    getBoolean: (key: string) => getBoolean(remoteConfig, key),
    getNumber: (key: string) => getNumber(remoteConfig, key),
    getString: (key: string) => getString(remoteConfig, key),
    getValue: (key: string) => getValue(remoteConfig, key),
  };
};

// Note that any users of this module will need to handle this
// promise possibly resolving after an unmount.
const waitForTimeout = (timeoutMs: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(RESPONSE_DUE_TO_TIMEOUT), timeoutMs);
  });

/**
 * Only returns the current remote config once the initial startup
 * fetch has been completed.
 */
export const getConfigGettersOnceLoaded: GetConfigGettersOnceLoadedFn = async (
  maxTimeoutMs = undefined,
) => {
  // TODO(matt): Consider going for a different strategy which allows using the
  // default or currently stored config with fresh values reloaded for next time.
  // TODO(matt): Change the logic in initializeFetchAndActivate so that if the timeout
  // is triggered here before the config has loaded, do not activate the config on
  // this page load so that the user maintains a consistent view for this visit.
  if (hasConfigLoaded) {
    return {
      ...produceRemoteConfigGetters(),
      isFromCacheDueToTimeout: false,
    };
  }
  let isFromCacheDueToTimeout = false;
  if (maxTimeoutMs === undefined) {
    await fetchAndActivatePromise;
  } else {
    const response = await Promise.race([
      fetchAndActivatePromise,
      waitForTimeout(maxTimeoutMs),
    ]);
    if (response === RESPONSE_DUE_TO_TIMEOUT) {
      isFromCacheDueToTimeout = true;
    }
  }
  return {
    ...produceRemoteConfigGetters(),
    isFromCacheDueToTimeout,
  };
};
