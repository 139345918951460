/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import LOCALE from 'common/locale';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

import { getTextSize, getVariant } from './helpers';
import locales from './locales';

export interface PriceProps {
  boxProps?: BoxProps;
  canceled?: boolean;
  className?: string;
  monthly?: boolean;
  size?: number;
  value: number;
  withoutCents?: boolean;
}

const Price: FC<PriceProps> = ({
  boxProps = {},
  canceled,
  className,
  monthly = false,
  size = 4,
  value,
  withoutCents = false,
}) => {
  const formatter = new Intl.NumberFormat(LOCALE, {
    currency: 'EUR',
    minimumFractionDigits: withoutCents ? 0 : undefined,
    style: 'currency',
  });

  const color = canceled ? 'grey.500' : undefined;
  const textDecoration = canceled ? 'line-through' : 'default';

  const parts = formatter.formatToParts(value);

  const TextComponent = size === undefined || size >= 2 ? Heading : Text;
  const textSize = getTextSize(size);
  const variant = getVariant(size);

  const price = parts.map((part, index) => {
    return (
      <TextComponent
        as="span"
        color={color}
        fontSize={textSize}
        fontWeight="weight-500"
        key={index}
        variant={variant as any}
      >
        {part.value}
      </TextComponent>
    );
  });

  return (
    <Box
      {...boxProps}
      className={className}
      color={color}
      position="relative"
      textDecoration={textDecoration}
    >
      <span>
        {price}
        {monthly ? (
          <TextComponent as="span" color={color}>
            {locales.monthly}
          </TextComponent>
        ) : null}
      </span>
    </Box>
  );
};

export default Price;
