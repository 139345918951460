import { FC } from 'react';
import { Link, ListItem, UnorderedList } from '@chakra-ui/react';

import Callout from 'components/_core/Callout';
import Text from 'components/_core/Text';

import locales from './locales';

const multiAccountFAQLink =
  'https://help.shine.fr/fr/articles/1175607-est-il-possible-d-ouvrir-plusieurs-comptes-shine';

interface AlreadyUsedAlertProps {
  type: 'phone' | 'email';
}

/**
 * A message alerting the user the email or the phone number they entered
 * is already used, with a link to FAQ to use multi-account
 */
const AlreadyUsedAlert: FC<AlreadyUsedAlertProps> = ({ type }) => (
  <Callout marginTop="space-24">
    <Callout.Title>{locales[type].title}</Callout.Title>

    <UnorderedList marginLeft="space-24">
      <ListItem>
        <Text as="span">{`${locales[type].firstItem} `}</Text>

        <Link href={multiAccountFAQLink} target="_blank">
          {locales[type].link}
        </Link>
      </ListItem>
    </UnorderedList>
  </Callout>
);

export default AlreadyUsedAlert;
