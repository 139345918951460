import { FC } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';

/**
 * An illustration designed for the head of the OBCard.
 */
const Illustration: FC<ImageProps & { src: string }> = ({ src, ...props }) => (
  <Image
    boxSize="180px"
    marginTop={{ base: 'space-20', md: 'space-40' }}
    marginX="auto"
    src={src}
    {...props}
  />
);

export default Illustration;
