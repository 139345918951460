import {
  CheckboxGroup,
  CheckboxGroupProps,
  forwardRef,
  Stack,
  StackProps,
  useCheckboxGroup,
} from '@chakra-ui/react';

import { CheckboxPositions } from '../..';
import { CheckboxGroupProvider } from '../../hooks/useCheckboxGroup';

export type GroupProps = CheckboxGroupProps &
  Pick<StackProps, 'direction' | 'width'> & {
    checkboxPosition?: CheckboxPositions;
  };

const Group = forwardRef<GroupProps, 'div'>(
  (
    {
      checkboxPosition = 'left',
      children,
      direction = 'column',
      width,
      ...props
    },
    ref,
  ) => {
    const group = useCheckboxGroup(props);

    return (
      <CheckboxGroupProvider value={{ ...group, checkboxPosition }}>
        <CheckboxGroup data-testid="Checkbox-group" {...props}>
          <Stack
            direction={direction}
            display="inline-flex"
            ref={ref}
            spacing="space-12"
            width={width}
          >
            {children}
          </Stack>
        </CheckboxGroup>
      </CheckboxGroupProvider>
    );
  },
);

export default Group;
