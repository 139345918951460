import { FC, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import HandleMobileInvitation from 'Main/SmallResScreen/HandleMobileInvitation';

import { CompanyInvitationRole } from '__generated__/GQL';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import { InvitationInfo } from 'types/invitation';

import { SubmitPhoneHookResult } from '../LoginForm/types';
import PhoneNumber from '../PhoneNumber';
import locales from './locales';

interface RedeemInvitePhoneNumberProps extends SubmitPhoneHookResult {
  invitationInfo: InvitationInfo | null;
  onStartRegistering: () => void;
}

const RedeemInvitePhoneNumber: FC<RedeemInvitePhoneNumberProps> = ({
  invitationInfo,
  onStartRegistering,
  ...phoneSubmitProps
}) => {
  if (invitationInfo === null) {
    throw new Error(
      "RedeemInvitePhoneNumber shouldn't be called with null invitationInfo",
    );
  }

  const [hasAlreadyAnAccount, setHasAlreadyAnAccount] = useState(false);

  if (hasAlreadyAnAccount) {
    return (
      <div>
        <Heading>{locales.welcomeMessage}</Heading>
        <Box marginTop="-90px">
          <PhoneNumber withNoBackButton {...phoneSubmitProps} />
        </Box>
      </div>
    );
  }

  const { companyName, inviteeFirstName, inviterName, role } = invitationInfo;

  const isOnboardingResponsive =
    role === CompanyInvitationRole.LegalBeneficiary ||
    role === CompanyInvitationRole.AccountHolder ||
    role === CompanyInvitationRole.Admin;

  return (
    <>
      {/* Mobile blocker for UM invitees */}
      {/* Originally made in `SmallResScreen`, but BE onboarding is now responsive */}
      {/* so we have to filter here, after having parsed the invitation */}
      {isOnboardingResponsive ? null : (
        <Box
          backgroundColor="white"
          display={{ base: 'block', lg: 'none' }}
          inset={0}
          position="absolute"
          zIndex="modal"
        >
          <HandleMobileInvitation />
        </Box>
      )}

      <Heading>
        {
          locales.formatString(locales.titleByRole[role], {
            inviteeFirstName,
          }) as string
        }
      </Heading>
      <Text marginBottom="space-40" marginTop="space-8">
        {
          locales.formatString(locales.subtitleByRole[role], {
            companyName,
            inviterName,
            trailingDot: companyName.slice(-1) === '.' ? '' : '.',
          }) as string
        }
      </Text>
      <VStack align="start" spacing="space-24">
        <Button
          icon="edit-file"
          iconPosition="left"
          onClick={onStartRegistering}
        >
          {locales.accountCreationCta}
        </Button>
        <Button
          onClick={() => {
            setHasAlreadyAnAccount(true);
          }}
          variant="inline-secondary"
        >
          {locales.haveAccount}
        </Button>
      </VStack>
    </>
  );
};

export default RedeemInvitePhoneNumber;
