import { components, SingleValueProps } from 'react-select';
import { Flex, Image, Text } from '@chakra-ui/react';

import { OptionTypeBase } from '../..';

const LanguageSingleValue = <TOptionType extends OptionTypeBase>(
  singleValueProps: SingleValueProps<TOptionType>,
) => (
  <components.SingleValue {...singleValueProps}>
    <Flex alignItems="center" gap="space-8">
      <Image
        borderRadius="radius-4"
        src={singleValueProps.data.flag}
        width="20px"
      />
      <Text variant="primary">{singleValueProps.data.label}</Text>
    </Flex>
  </components.SingleValue>
);

export default LanguageSingleValue;
