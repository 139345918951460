import { createModel } from 'xstate/lib/model';

import { OutstandingAgreementType } from '__generated__/GQL';
import baseEvents from 'common/bento/config/baseEvents';

import { GetAgreementDataQuery } from '../graphql/queries/getAgreementData.gql';

export type Context = {
  getModuleData: () => GetAgreementDataQuery & {
    agreementType: OutstandingAgreementType;
  };
};

const initialContext: Context = {
  getModuleData: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
};

const creators = {
  events: {
    ...baseEvents,
  },
};

const model = createModel(initialContext, creators);

export type Model = typeof model;

export default model;
