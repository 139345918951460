import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
    collapse: 'View the document',
    download: 'Download the document',
    exit: 'Close',
    expand: 'Continue',
  },
  fr: {
    cancel: 'Annuler',
    collapse: 'Voir le document',
    download: 'Télécharger le document',
    exit: 'Fermer',
    expand: 'Continuer',
  },
});

export default locales;
