/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8468%3A42058&t=BMXlpcFDV83xIao0-1
 */

import { anatomy, PartsStyleObject } from '@chakra-ui/theme-tools';

const parts = anatomy('Callout').parts(
  'container',
  'title',
  'content',
  'button',
  'iconContainer',
  'icon',
);

type Parts = typeof parts;

const baseStyle: PartsStyleObject<Parts> = {
  button: {
    _after: {
      backgroundColor: 'currentColor',
    },
    _hover: {
      _after: { backgroundColor: 'currentColor' },
      color: 'currentColor',
      opacity: 0.8,
    },
    color: 'currentColor',
  },
  container: {
    alignItems: 'flex-start',
    borderRadius: 'radius-8',
    display: 'flex',
    flexDirection: 'column',
    gap: 'space-8',
    padding: 'space-16',
    position: 'relative',
    width: 'full',
  },
  content: {
    color: 'currentColor',
    textAlign: 'left',
  },
  icon: {
    color: 'currentColor',
  },
  iconContainer: {
    alignItems: 'center',
    borderRadius: 'radius-full',
    boxShadow: `0 0 0 4px white`,
    boxSize: '24px',
    display: 'flex',
    justifyContent: 'center',
    padding: 'space-4',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(50%, -50%)',
  },
  title: {
    color: 'currentColor',
    fontWeight: 'weight-500',
  },
};

export type Variant = 'info' | 'warning' | 'error';

const variants: Record<Variant, PartsStyleObject<Parts>> = {
  error: {
    container: {
      backgroundColor: 'red.200',
      color: 'red.700',
    },
    iconContainer: {
      backgroundColor: 'red.200',
    },
  },
  info: {
    container: {
      backgroundColor: 'grey.200',
      color: 'grey.700',
    },
    iconContainer: {
      backgroundColor: 'grey.200',
    },
  },
  warning: {
    container: {
      backgroundColor: 'orange.200',
      color: 'orange.700',
    },
    iconContainer: {
      backgroundColor: 'orange.200',
    },
  },
};

const CalloutTheme = {
  baseStyle,
  variants,
};

export default CalloutTheme;
