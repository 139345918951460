import { FC, ReactNode, useCallback, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import useResize from 'common/hooks/useResize';

import DefaultMobilePage from './DefaultMobilePage';
import useForceMobileAccess from './DefaultMobilePage/hook';

const getIsMobile = (): boolean => window.innerWidth < 1024;

interface SmallResScreenContainerProps {
  children: ReactNode;
}

// Responsive routes should be added here
// On a mobile device, routes not in that list will render a page
// inviting the user to use the mobile app
const RESPONSIVE_ROUTES = [
  '/oauth2',
  '/register',
  '/tax-residency-update',
  '/onboarding',
  // Preventing UM mobile onboarding is now done in `Login/RedeemInvitePhoneNumber`
  '/i/',
  '/signup',
  '/sign-in',
  '/logout',
];

// These routes have to match exactly to be considered responsive
// This is to prevent routes that contain parts to pass the check
const EXACT_RESPONSIVE_ROUTES = ['/d'];

const SmallResScreenContainer: FC<SmallResScreenContainerProps> = ({
  children,
}) => {
  const { forceMobileAccess, isMobileAccessForced } = useForceMobileAccess();

  const location = useLocation();

  const isRouteResponsive =
    RESPONSIVE_ROUTES.some((route) => location.pathname.includes(route)) ||
    EXACT_RESPONSIVE_ROUTES.some((route) => location.pathname === route) ||
    location.pathname === '/';

  const [isMobile, setIsMobile] = useState(getIsMobile());

  /* useCallback is needed so resizeHandler is not re-created on render
    which would trigger useResize's useEffect update */
  const resizeHandler = useCallback((): void => {
    setIsMobile(getIsMobile());
  }, []);

  useResize(resizeHandler);

  return isMobile && !isMobileAccessForced && !isRouteResponsive ? (
    <Switch>
      <Route>
        <DefaultMobilePage onForceAccess={() => forceMobileAccess()} />
      </Route>
    </Switch>
  ) : (
    <>{children}</>
  );
};

export default SmallResScreenContainer;
