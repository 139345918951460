import { FC } from 'react';
import { HStack } from '@chakra-ui/react';

import * as Form from 'components/_core/form';
import CountryCallingCodePicker from 'components/CountryCallingCodePicker';

import { PhoneInputProps } from './types';

const PhoneInput: FC<PhoneInputProps> = ({
  countryCode,
  onUpdateCountryCode,
  onUpdatePhone,
  phone,
}) => {
  const localOnUpdateCountryCode = (countryCallingCode: string): void => {
    onUpdateCountryCode(countryCallingCode);
  };

  const localOnUpdatePhone = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onUpdatePhone(event.target.value);
  };

  const inputProps = {
    autoFocus: true,
    'data-testid': 'phone-input',
    inputMode: 'tel',
    name: 'phone',
    onChange: localOnUpdatePhone,
    type: 'text',
    value: phone,
  } as const;

  return (
    <HStack spacing="space-16" width="100%">
      <Form.Field flexShrink={0} width="128px">
        <CountryCallingCodePicker
          onChange={localOnUpdateCountryCode}
          value={countryCode}
        />
      </Form.Field>

      <Form.Field flex={1}>
        <Form.Input {...inputProps} />
      </Form.Field>
    </HStack>
  );
};

export default PhoneInput;
