import config from 'config';
import { get } from 'helpers/fetch';

const { shineApiHost } = config;

type VerifyReferralResponse = {
  data: {
    firstName: string;
    referralCode: string;
  };
  ok: true;
};

/**
 * A function querying the Shine API endpoint to verify if a referral is valid and retrieve its data.
 */
export const verifyReferral = (code: string) =>
  get<VerifyReferralResponse>(
    `${shineApiHost}/referrals/codes/verify?referralCode=${code}`,
  );
