import { MachineServices } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';

import { CreateTeamMemberReviewDocument } from '../../../graphql/createTeamMemberReview.gql';
import { UpdateUserPersonalInfoDocument } from '../../../graphql/updatePersonalInfo.gql';
import { Model } from './model';

export enum Service {
  CreateReview = 'createReview',
  UpdatePersonalInfos = 'updatePersonalInfos',
}

const services: MachineServices<Model, Service> = {
  createReview: ({ getModuleData }) => {
    const { companyUserId } = getModuleData();

    return apolloClient.mutate({
      awaitRefetchQueries: true,
      mutation: CreateTeamMemberReviewDocument,
      variables: { input: { companyUserId } },
    });
  },

  updatePersonalInfos: (_, event) => {
    if (event.type !== 'SUBMIT_PERSONAL_INFORMATION') {
      throw new Error(
        `event.type is "${event.type}", but should be "SUBMIT_PERSONAL_INFORMATION"`,
      );
    }

    return apolloClient.mutate({
      awaitRefetchQueries: true,
      mutation: UpdateUserPersonalInfoDocument,
      variables: { input: { ...event.data } },
    });
  },
};

export default services;
export type Services = typeof services;
