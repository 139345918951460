import { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Center, Divider, HStack, Image, Link } from '@chakra-ui/react';

import okHandImg from 'assets/brand/ok-hand@2x.png';
import { FCWithChildren } from 'common/types';
import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import CountryPicker from 'components/CountryPicker';
import logger from 'helpers/logger';

import { UpdateTaxResidencyDocument } from './hooks/useUpdateTaxResidencyMutation/updateTaxResidencyMutation.gql';
import locales from './locales';

const COUNTRY_FR = 'FR';
const COUNTRIES_FRANCE = [COUNTRY_FR];

const ButtonsContainer: FCWithChildren = (props) => (
  <HStack paddingTop="space-12" {...props} />
);

const TaxResidencyUpdate: FC = () => {
  const [hasActivityAbroad, setHasActivityAbroad] = useState<boolean | null>(
    null,
  );
  const [isLivingAbroad, setIsLivingAbroad] = useState<boolean | null>(null);
  const [hasTaxResidenceAbroad, setHasTaxResidenceAbroad] = useState<
    boolean | null
  >(null);
  const [country, setCountry] = useState('');
  const [fiscalId, setFiscalId] = useState('');
  const [updateTaxResidency, { error, loading }] = useMutation(
    UpdateTaxResidencyDocument,
  );
  const [isDone, setIsDone] = useState(false);

  const canSubmit =
    (hasActivityAbroad === false && isLivingAbroad === false) ||
    ((hasActivityAbroad || isLivingAbroad) &&
      (hasTaxResidenceAbroad === false || (!!country && !!fiscalId)));

  const onSubmit = async () => {
    try {
      const reallyHasTaxResidenceAbroad =
        (hasActivityAbroad || isLivingAbroad) && hasTaxResidenceAbroad;

      await updateTaxResidency({
        variables: {
          input: {
            country: reallyHasTaxResidenceAbroad ? country : COUNTRY_FR,
            fiscalId: reallyHasTaxResidenceAbroad ? fiscalId : '',
          },
        },
      });
      setIsDone(true);
    } catch (err) {
      logger.error(err);
    }
  };
  const hasError = !!error;

  if (isDone) {
    return (
      <Center margin="space-16">
        <SunshineCard maxWidth="600px" padding="space-24">
          <Center flexDirection="column">
            <Image
              alt=""
              boxSize="90px"
              marginBottom="space-12"
              src={okHandImg}
            />
            <Text variant="primary">{locales.done}</Text>
          </Center>
        </SunshineCard>
      </Center>
    );
  }

  return (
    <Center margin="space-16">
      <SunshineCard maxWidth="600px" padding="space-0">
        <SunshineCard.Content
          alignItems="stretch"
          padding="space-16"
          spacing="space-24"
        >
          <div>
            <SunshineCard.Title>{locales.hasActivityAbroad}</SunshineCard.Title>
            <SunshineCard.Text>
              {locales.hasActivityAbroadSubtitle}
            </SunshineCard.Text>

            <ButtonsContainer>
              <Button
                onClick={() => setHasActivityAbroad(true)}
                variant={hasActivityAbroad === true ? 'primary' : 'secondary'}
              >
                {locales.buttonLabelYes}
              </Button>
              <Button
                onClick={() => setHasActivityAbroad(false)}
                variant={hasActivityAbroad === false ? 'primary' : 'secondary'}
              >
                {locales.buttonLabelNo}
              </Button>
            </ButtonsContainer>
          </div>

          {hasActivityAbroad === false ? (
            <div>
              <SunshineCard.Title>{locales.isLivingAbroad}</SunshineCard.Title>
              <ButtonsContainer>
                <Button
                  onClick={() => setIsLivingAbroad(true)}
                  variant={isLivingAbroad === true ? 'primary' : 'secondary'}
                >
                  {locales.buttonLabelYes}
                </Button>
                <Button
                  onClick={() => setIsLivingAbroad(false)}
                  variant={isLivingAbroad === false ? 'primary' : 'secondary'}
                >
                  {locales.buttonLabelNo}
                </Button>
              </ButtonsContainer>
            </div>
          ) : null}

          {hasActivityAbroad || isLivingAbroad ? (
            <>
              <div>
                <Text>{locales.mayHaveTaxResidenceAbroad}</Text>

                <Link
                  href="https://help.shine.fr/fr/articles/2912143-pourquoi-shine-me-demande-si-j-ai-une-residence-fiscale-a-l-etranger"
                  isExternal
                >
                  {locales.linkTaxResidence}
                </Link>
              </div>

              <div>
                <SunshineCard.Title>
                  {locales.doYouHaveTaxResidenceAbroad}
                </SunshineCard.Title>

                <ButtonsContainer>
                  <Button
                    onClick={() => setHasTaxResidenceAbroad(true)}
                    variant={
                      hasTaxResidenceAbroad === true ? 'primary' : 'secondary'
                    }
                  >
                    {locales.buttonLabelYes}
                  </Button>

                  <Button
                    onClick={() => setHasTaxResidenceAbroad(false)}
                    variant={
                      hasTaxResidenceAbroad === false ? 'primary' : 'secondary'
                    }
                  >
                    {locales.buttonLabelNo}
                  </Button>
                </ButtonsContainer>
              </div>
            </>
          ) : null}

          {(hasActivityAbroad || isLivingAbroad) && hasTaxResidenceAbroad ? (
            <div>
              <SunshineCard.Title>{locales.country}</SunshineCard.Title>

              <CountryPicker
                countriesExcluded={COUNTRIES_FRANCE}
                label={locales.countryPlaceholder}
                onSelect={setCountry}
                selected={country}
              />
            </div>
          ) : null}

          {(hasActivityAbroad || isLivingAbroad) &&
          hasTaxResidenceAbroad &&
          !!country ? (
            <div>
              <SunshineCard.Title>{locales.fiscalId}</SunshineCard.Title>
              <Form.Input
                onChange={(e) => setFiscalId(e.target.value)}
                value={fiscalId}
              />
            </div>
          ) : null}
        </SunshineCard.Content>

        <Divider />

        <SunshineCard.Content padding="space-16">
          {hasError ? (
            <Text textAlign="center" variant="light">
              {locales.hasError}
            </Text>
          ) : null}

          <Button
            isDisabled={!canSubmit || loading}
            isExpanded
            isLoading={loading}
            marginX="auto"
            onClick={onSubmit}
          >
            {locales.submit}
          </Button>
        </SunshineCard.Content>
      </SunshineCard>
    </Center>
  );
};

export default TaxResidencyUpdate;
