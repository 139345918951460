import { FC } from 'react';

import articlesImg from 'assets/brand/articles@2x.png';

import Container from '../../../../layouts/FeaturesLayout/components/Container';
import Description from '../../../../layouts/FeaturesLayout/components/Description';
import Illustration from '../../../../layouts/FeaturesLayout/components/Illustration';
import Title from '../../../../layouts/FeaturesLayout/components/Title';
import locales from './locales';

const Expenses: FC = () => (
  <Container>
    <Illustration src={articlesImg} />
    <Title>{locales.title}</Title>
    <Description>{locales.description}</Description>
  </Container>
);

export default Expenses;
