import { AccountType } from '../machine/model';

const mapOnboardingAccountTypeToAccountType: Record<AccountType, string> = {
  'capital-deposit': 'capital_deposit',
  // TODO(LED-3264): review if we should send sasu-creation
  'sasu-creation': 'capital_deposit',
  'shine-start': 'shine_start_micro',
  standard: 'regular_account_opening',
};

export default mapOnboardingAccountTypeToAccountType;
