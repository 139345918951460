import { createContext, useContext } from 'react';

import { CompanyUserFeature } from '__generated__/GQL';

export interface Features {
  features?: CompanyUserFeature[];
}

export const FeaturesContext = createContext<Features>({
  features: undefined,
});

const useFeaturesContext = () => useContext(FeaturesContext);

export default useFeaturesContext;
