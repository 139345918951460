import { AnyState } from '../types/Abstract';

/**
 * Given a State, this function will return a string that is
 * - deterministic,
 * - human readable,
 * - unique (one given string cannot be returned for different states of the same machine).
 *
 * @info This is NOT compatible with parallel states because it returns a string based on the last leaf state node.
 */
const stringifyState = <TState extends string>(state: AnyState) => {
  const result = state.toStrings();

  return result[result.length - 1] as TState;
};

export default stringifyState;
