import { useCallback } from 'react';
import { KYC_DOCUMENTS_TYPES } from '@shinetools/kyc-validation';

import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import SuspenseQuery from 'components/SuspenseQuery';

import useIdentityModuleData from './hooks/useIdentityModuleData';
import machineConfig, { model, State } from './machine';
import { Model } from './machine/model';
import IdCheck from './pages/IdCheck';

const Identity = withStateMachine<Model>(
  ({ machine }) => {
    const [state, send] = machine;
    const stringifiedState = stringifyState<State>(state);

    const onPrev = useCallback(() => send(model.events.PREV()), [send]);

    switch (stringifiedState) {
      case State.DOCUMENTS:
      case State.DOCUMENTS_SEND:
      case State.DONE:
      case State.PREV:
        return (
          <IdCheck
            isSubmitting={state.matches(State.DOCUMENTS_SEND)}
            onPrev={onPrev}
            onSuccess={(documents) => send(model.events.SUCCESS(documents))}
          />
        );
      default:
        return assertNever(stringifiedState);
    }
  },
  {
    devTools: true,
    machineConfig,
  },
);

interface IdentityProps {
  companyProfileId: string;
  companyUserId: string;
}

/**
 * This module is responsible to verify the identity of the user.
 *
 * @input A user whose identity data is not registered, and whose identity is not certified.
 * @output A user whose identity data is registered and with an Onfido-certified ID check.
 */
const IdentityModule = asBentoModule<IdentityProps>(
  ({ companyProfileId, onDone }) => {
    const query = useIdentityModuleData({
      companyProfileId,
      kycDocumentTypes: KYC_DOCUMENTS_TYPES,
    });

    return (
      <SuspenseQuery query={query}>
        {(moduleData) => <Identity moduleData={moduleData} onDone={onDone} />}
      </SuspenseQuery>
    );
  },
);

export default IdentityModule;
