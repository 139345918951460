/* eslint-disable @typescript-eslint/naming-convention */
import logger from 'helpers/logger';

import getCookies from './getCookies';

/**
 * Get the UTM parameters from the cookies storage
 * https://en.wikipedia.org/wiki/UTM_parameters
 * These are stored from shine.fr’s "HTML - Cookie - UTMz" GTM script
 */
const getUTMParametersFromStorage = (
  cookies?: Partial<Record<string, string>>,
) => {
  const { utm_zlc } = cookies ?? getCookies();

  // UTMs from the last non-organic click
  if (!utm_zlc) {
    return {};
  }

  try {
    // Stored as `utm_source_value|utm_medium_value|utm_campaign_value`
    const [utm_source, utm_medium, utm_campaign] = atob(utm_zlc).split('|');

    return {
      utm_campaign,
      utm_medium,
      utm_source,
    };
  } catch (error) {
    logger.error('Couldn’t decode utm_zlc cookie', { context: { error } });
    return {};
  }
};

export default getUTMParametersFromStorage;
