import {
  RefObject,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  forwardRef,
  InputGroup,
  InputRightElement,
  Link,
  Slide,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import useOnClickOutside from 'common/hooks/useOnClickOutside';
import * as Form from 'components/_core/form';
import IconButton from 'components/_core/IconButton';
import SunshineIcon from 'components/_core/SunshineIcon';
import { useAddressSearch } from 'features/GoogleMaps';
import { Prediction } from 'features/GoogleMaps/hooks/useAddressSearch/types';

import Header from '../Header';
import SelectOnlyFrance from '../SelectOnlyFrance';
import StickyButton from '../StickyButton/StickyButton';
import addressSchema from './addressSchema';
import Predictions from './components/Predictions';
import locales from './locales';
import { Address, AddressInputMode } from './types';

type AddressPayload = z.input<typeof addressSchema>;

const emptyAddress: Address = {
  city: '',
  street: '',
  streetAddition: '',
  zip: '',
};

interface AddressSearchInputProps extends Form.InputProps {
  allowManualMode: boolean;
  isDesktop: boolean;
  isOverlayOpen: boolean;
  label: string;
  overlayContainerRef: RefObject<HTMLElement>;
  searchPlaceholder?: string;
  onOverlayClose: () => void;
  onOverlayOpen: () => void;
  onSelectAddress: (address: Address) => void;
  onSelectedManualMode?: () => void;
}

/**
 * An address input component with autocompletion based on GoogleMaps API.
 * On desktop, the predictions will be displayed as a dropdown below the input.
 * On mobile, focusing the input will open an overlay containing an address search bar.
 */
const AddressSearchInput = forwardRef<AddressSearchInputProps, 'input'>(
  (
    {
      allowManualMode,
      isDesktop,
      isOverlayOpen,
      label,
      onOverlayClose,
      onOverlayOpen,
      onSelectAddress,
      onSelectedManualMode,
      overlayContainerRef,
      searchPlaceholder,
      ...inputProps
    },
    ref,
  ) => {
    const {
      formState,
      getValues,
      handleSubmit,
      register,
      reset: resetForm,
      setFocus,
      setValue,
    } = useForm<AddressPayload>({
      defaultValues: emptyAddress,
      mode: 'onSubmit',
      resolver: zodResolver(addressSchema),
    });

    const [isLoading, setIsLoading] = useState(false);
    const [hasAddressComplement, setHasAddressComplement] = useState(false);

    const [inputMode, setInputMode] = useState<AddressInputMode>('search');

    const {
      clear: clearPredictions,
      isLoading: isLoadingPredictions,
      predictions,
      search,
    } = useAddressSearch({
      language: locales.getLanguage(),
    });

    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const onPredictionClick = async (prediction: Prediction) => {
      setIsLoading(true);
      const address = await prediction.getAddress();
      setIsLoading(false);

      onSelectAddress(address);
      clearPredictions();
      onOverlayClose();
    };

    const onSelectManualMode = () => {
      resetForm();
      clearPredictions();
      setHasAddressComplement(false);
      setInputMode('manual');
      onSelectedManualMode?.();
    };

    const onSubmit = (values: AddressPayload) => {
      onSelectAddress(values);
      onOverlayClose();
    };

    useOnClickOutside(containerRef, () => {
      if (isDesktop) {
        clearPredictions();
      }
    });

    useEffect(() => {
      if (isOverlayOpen) {
        setInputMode('search');
        clearPredictions();

        if (searchInputRef.current) {
          searchInputRef.current.value = '';

          searchInputRef.current.focus({
            preventScroll: true,
          });

          // Sarafi on iOS add a blank space under the whole page (under html element)
          // This manual scroll is here to avoid having this blank space visible when
          // opening the overlay, it causes issues with the animation.
          window.scrollTo({
            behavior: 'instant',
            left: 0,
            top: 0,
          });
        }
      }
    }, [isOverlayOpen, clearPredictions]);

    useLayoutEffect(() => {
      // Focus the streetAddition field when you just clicked on the add button.
      // We can't do the focus in the callback of the button because the input is not displayed yet.
      if (hasAddressComplement && !getValues('streetAddition')) {
        setFocus('streetAddition');
      }
    }, [hasAddressComplement, getValues, setFocus]);

    const renderSearchInputRightElement = (
      parentInputRef: RefObject<HTMLInputElement | null>,
    ) => (
      <InputRightElement>
        <IconButton
          aria-label="search-icon"
          fontSize="font-18"
          icon={parentInputRef.current?.value ? 'cross' : 'search'}
          isLoading={isLoadingPredictions}
          onClick={() => {
            clearPredictions();
            if (parentInputRef.current) {
              parentInputRef.current.focus();
              parentInputRef.current.value = '';
            }
          }}
          variant="inline-secondary"
        />
      </InputRightElement>
    );

    const renderPredictions = () =>
      predictions ? (
        <Predictions
          allowManualMode={allowManualMode}
          isDesktop={isDesktop}
          onPredictionClick={onPredictionClick}
          onSelectManualMode={onSelectManualMode}
          predictions={predictions}
        />
      ) : null;

    const renderOverlay = () =>
      overlayContainerRef.current
        ? createPortal(
            <Slide
              direction="bottom"
              in={isOverlayOpen}
              style={{
                background: 'white',
                height: 'calc(100% - 80px)',
              }}
              unmountOnExit
            >
              {inputMode === 'manual' ? (
                <VStack
                  alignItems="stretch"
                  as="form"
                  noValidate
                  onSubmit={(e) => {
                    // We have to manually stop the event propagation because the event will bubble
                    // in the React tree and not the DOM tree (it ignores portals).
                    // If we don't stop the propagation, the event will arrive to the parent form.
                    handleSubmit(onSubmit)(e);
                    e.stopPropagation();
                  }}
                  padding="space-16"
                  spacing="space-16"
                >
                  <Header
                    description={locales.manualEntrySubTitle}
                    title={locales.manualEntryTitle}
                  />
                  <Form.Field
                    error={formState.errors.street?.message}
                    label={locales.inputs?.street.label}
                  >
                    <Form.Input
                      {...register('street')}
                      data-testid="street-input"
                      required
                    />
                  </Form.Field>
                  <Form.Field
                    display={hasAddressComplement ? undefined : 'none'}
                    error={formState.errors.streetAddition?.message}
                    label={locales.streetAdditionLabel}
                  >
                    <Flex alignItems="center" gap="space-8">
                      <Form.Input
                        {...register('streetAddition')}
                        data-testid="streetAddition-input"
                        placeholder={locales.streetAdditionPlaceholder}
                      />
                      <IconButton
                        aria-label={locales.clear}
                        icon="trash"
                        onClick={() => {
                          setValue('streetAddition', '');
                          setHasAddressComplement(false);
                        }}
                        variant="inline-secondary"
                      />
                    </Flex>
                  </Form.Field>
                  <Link
                    alignItems="center"
                    as="button"
                    display={hasAddressComplement ? 'none' : undefined}
                    flex="row"
                    marginY="space-18"
                    onClick={() => setHasAddressComplement(true)}
                  >
                    <SunshineIcon
                      marginRight="space-12"
                      name="plus"
                      size="icon-13"
                    />
                    {locales.addAddressComplement}
                  </Link>
                  <Flex gap="space-16">
                    <Form.Field
                      error={formState.errors.zip?.message}
                      label={locales.zipLabel}
                    >
                      <Form.Input
                        {...register('zip')}
                        data-testid="zip-input"
                        inputMode="numeric"
                        maxLength={5}
                        required
                      />
                    </Form.Field>
                    <Form.Field
                      error={formState.errors.city?.message}
                      label={locales.cityLabel}
                    >
                      <Form.Input
                        {...register('city')}
                        data-testid="city-input"
                        required
                      />
                    </Form.Field>
                  </Flex>
                  <SelectOnlyFrance />
                  <StickyButton type="submit">{locales.save}</StickyButton>
                </VStack>
              ) : (
                <VStack
                  alignItems="stretch"
                  padding="space-16"
                  spacing="space-4"
                >
                  <Form.Field label={label}>
                    <InputGroup>
                      <Form.Input
                        onChange={(event) => {
                          search(event.target.value);
                        }}
                        placeholder={searchPlaceholder}
                        ref={searchInputRef}
                        type="text"
                      />
                      {renderSearchInputRightElement(searchInputRef)}
                    </InputGroup>
                  </Form.Field>
                  {renderPredictions()}
                </VStack>
              )}
            </Slide>,
            overlayContainerRef.current,
          )
        : null;

    return (
      <Box position="relative" ref={containerRef}>
        <InputGroup>
          <Form.Input
            {...inputProps}
            {...(isDesktop
              ? {
                  isReadOnly: inputProps.isReadOnly ?? isLoading,
                  onChange: (e) => {
                    inputProps.onChange?.(e);
                    search(e.target.value);
                  },
                  /**
                   * Open the dropdown if it was closed,
                   * and the user tries to re-open it by clicking the input
                   */
                  onFocus: (e) => {
                    inputProps.onFocus?.(e);
                    if (e.target.value) {
                      search(e.target.value);
                    }
                  },
                }
              : {
                  isReadOnly: inputProps.isReadOnly ?? true,
                  onClick: (e) => {
                    inputProps.onClick?.(e);
                    onOverlayOpen();
                  },
                })}
            ref={inputRef}
          />
          {inputProps.placeholder || isLoadingPredictions
            ? renderSearchInputRightElement(inputRef)
            : null}
        </InputGroup>

        {isDesktop ? renderPredictions() : renderOverlay()}
      </Box>
    );
  },
);

export default AddressSearchInput;
