import * as Sentry from '@sentry/react';

import webView from 'common/webView';

import { EXCLUDED_DOMAINS_OR_IP_FROM_SENTRY_BREADCRUMBS } from './constants';
import {
  DestinationPage,
  webAppOpeningTransaction,
} from './webAppOpeningTransaction';

/**
 * Initializes Sentry SDK to track errors and performance monitoring
 */
const initSentry = () => {
  /**
   * @important This is required even if Sentry isn't initialized
   * because we start Sentry transactions in `webAppOpeningTransaction` even when we don't actually send them.
   */
  Sentry.addTracingExtensions();

  if (
    import.meta.env.MODE === 'development' &&
    !import.meta.env.VITE_DEV_ENABLE_SENTRY
  ) {
    return;
  }

  Sentry.init({
    beforeBreadcrumb: (breadcrumb) => {
      // Filter useless breadcrumbs out to decrease noise

      // Network calls breadcrumbs
      if (breadcrumb.type === 'http') {
        const breadcrumbUrl: string | undefined = breadcrumb.data?.url;

        if (!breadcrumbUrl) {
          return breadcrumb;
        }

        const isCallToExcludedDomainOrIp =
          EXCLUDED_DOMAINS_OR_IP_FROM_SENTRY_BREADCRUMBS.some(
            (excludedDomainOrIp) => breadcrumbUrl.includes(excludedDomainOrIp),
          );

        // We will manually create breadcrumbs for GraphQL calls, including the
        // query name for better readability.
        const isCallToGraphql = breadcrumbUrl.includes('/v2/graphql');

        if (isCallToExcludedDomainOrIp || isCallToGraphql) {
          return null;
        }
      }

      // ui.click & ui.input breadcrumbs
      if (
        breadcrumb.category === 'ui.click' ||
        breadcrumb.category === 'ui.input'
      ) {
        const breadcrumbMessage = breadcrumb.message;
        if (!breadcrumbMessage) {
          return breadcrumb;
        }

        // Click on not identified elements
        const identifiedElementSubstrings = ['id=', '#'];
        if (
          !identifiedElementSubstrings.some((identifier) =>
            breadcrumbMessage.includes(identifier),
          )
        ) {
          return null;
        }
      }

      return breadcrumb;
    },

    beforeSend(event) {
      const isApolloError =
        !event.message &&
        event.exception?.values?.some((v) =>
          v.value?.includes('Response not successful: Received status code'),
        );

      if (isApolloError) {
        // Remove errors sent by Apollo as we manually report them in clients.ts
        return null;
      }

      return event;
    },

    beforeSendTransaction(event) {
      /**
       * These are outliers and mostly related to SDK clock drift or some kind of browser tab suspension.
       * We didn't find a better way to handle them.
       */
      if (
        event.timestamp &&
        event.start_timestamp &&
        event.timestamp - event.start_timestamp > 120 // Setting the outlier value to 2 minutes.
      ) {
        return null;
      }

      return event;
    },

    /**
     * Don't forward errors coming from third-party scripts.
     * They are not user-impacting and are coming from GTM scripts in tagmanager.google.com.
     */
    denyUrls: [/www\.googletagmanager\.com/i],
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_API_ENV,

    /*
      A list of strings or regex patterns that match error messages that shouldn't be sent to Sentry. 
      Messages that match these strings or regular expressions will be filtered out before they're sent to Sentry. 
      When using strings, partial matches will be filtered out, so if you need to filter by exact match, use regex patterns instead.
    */
    ignoreErrors: [
      // Network errors
      /.*NetworkError when attempting to fetch resource/,
      'UnknownError: Connection is closing.',
      /AbortError.*/,
      'ApolloError: Failed to fetch',
      'ApolloError: Load failed',
      'ApolloError: Internal server error',
      'TypeError: Load failed',
      'TypeError: Failed to fetch',
      'TypeError: annulé',
      'Unexpected end of script',
      'The operation was aborted.',
      'Unexpected end of JSON input',

      // Firebase errors (not wrapped in FirebaseError)
      /UnknownError: Connection to Indexed Database server lost.*/,
      'Database deleted by request of the user',

      // Shine errors
      'ApolloError: Unknown error', // when the original error is hidden from the customer

      // Errors from third party scripts
      'Failed to load Stripe.js',
      "undefined is not an object (evaluating 'window.webkit.messageHandlers')", // sentry performance monitoring
      "undefined is not an object (evaluating 'this._perf.domInteractive')", // sentry
      'i.end is not a function', // sentry
      "undefined is not an object (evaluating 'pe.challenges[re].type')", // onfido
      "undefined is not an object (evaluating 'S.props.challenges[SA].type')", // onfido
      "undefined is not an object (evaluating 't[0].pixelCode')", // tiktok pixel
      "Cannot read properties of undefined (reading 'pixelCode')", // tiktok pixel
      '__AutoFillPopupClose__', // instagram webview
      '_AutofillCallbackHandler', // instagram webview
      'NETWORK_BLOCKED', // fourthline
      'NETWORK_OFFLINE', // fourthline
      'window.UET is not a constructor', // bing
      "Unexpected token '<'", // snapchat

      // React related errors
      'ResizeObserver loop completed with undelivered notifications.', // React + some extensions cause this error
      'ResizeObserver loop limit exceeded', // React + some extensions cause this error
    ],
    release: import.meta.env.VITE_GIT_SHA,
    tracesSampler(samplingContext) {
      if (
        samplingContext.transactionContext.tags?.destination ===
        ('login' satisfies DestinationPage)
      ) {
        return 0.01;
      }

      if (
        samplingContext.transactionContext.name ===
        webAppOpeningTransaction.postLoginTransactionName
      ) {
        return 0.01;
      }

      const customSampleRate =
        samplingContext.transactionContext.attributes?.['sentry.sample_rate'];

      if (typeof customSampleRate === 'string') {
        return parseFloat(customSampleRate);
      }

      return 0.1;
    },
  });

  Sentry.setTag('isInWebView', webView.isInWebView);
};

export default initSentry;
