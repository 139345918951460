import { useQuery } from '@apollo/client';

import {
  TeamMemberIdentityModuleDataDocument,
  TeamMemberIdentityModuleDataQueryVariables,
} from './useIdentityModuleData.gql';

const useIdentityModuleData = ({
  companyProfileId,
  kycDocumentTypes,
}: TeamMemberIdentityModuleDataQueryVariables) => {
  const { data, ...hook } = useQuery(TeamMemberIdentityModuleDataDocument, {
    variables: { companyProfileId, kycDocumentTypes },
  });

  const viewer = data?.viewer;

  if (!viewer) {
    return { ...hook, data: undefined };
  }

  return {
    ...hook,
    data: {
      companyProfileId: viewer.companyUser.companyProfileId,
      companyUserId: viewer.companyUser.companyUserId,
      kycReviewDocuments: viewer.companyUser.kycReviewDocuments,
      reviewStatus: viewer.companyUser.reviewStatus,
    },
  };
};

export type UseIdentityModuleData = ReturnType<
  typeof useIdentityModuleData
>['data'];

export default useIdentityModuleData;
