import { FC } from 'react';
import { Flex, HStack, VStack } from '@chakra-ui/react';

import Text from 'components/_core/Text';

interface RequestDetailsRowProps {
  description?: string;
  label: string;
  rightText?: string;
  leftComponent: React.ReactNode;
}

const RequestDetailsRow: FC<RequestDetailsRowProps> = ({
  description,
  label,
  leftComponent,
  rightText,
}) => (
  <HStack padding="space-16" spacing="space-16" width="100%">
    {leftComponent}
    <VStack alignItems="start" justifyContent="center" spacing={0} width="full">
      <Flex justifyContent="space-between" width="full">
        <Text variant="primary">{label}</Text>
        {rightText ? (
          <Text fontSize="font-15" fontWeight="weight-500" variant="primary">
            {rightText}
          </Text>
        ) : null}
      </Flex>
      {description ? (
        <Text size="sm" variant="light">
          {description}
        </Text>
      ) : null}
    </VStack>
  </HStack>
);

export default RequestDetailsRow;
