import { keyframes } from '@chakra-ui/system';
import { getColor, SystemStyleFunction } from '@chakra-ui/theme-tools';

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { background: startColor, borderColor: startColor },
    to: { background: endColor, borderColor: endColor },
  });

const baseStyle: SystemStyleFunction = ({
  endColor = 'gray.400',
  speed,
  startColor = 'gray.100',
  theme,
}) => {
  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    animation: `${speed}s linear infinite alternate ${fade(start, end)}`,
    background: end,
    borderColor: start,
    borderRadius: '2px',
    opacity: 0.7,
  };
};

const SkeletonTheme = {
  baseStyle,
};

export default SkeletonTheme;
