import { AbstractState } from 'features/Bento/types/Abstract';
import logger from 'helpers/logger';

import { State } from '../../../machine';

/**
 * Returns the correct signupSituation for the current CompanyCreationLandbot page’s state
 */
const getSignupSituationFromState = (state: AbstractState) => {
  if (state.matches(State.COMPANY_CREATION)) {
    return 'shine-start-company';
  }

  if (state.matches(State.CAP_DEP)) {
    return 'partner-capital-deposit';
  }

  logger.error(
    `Couldn’t get signupSituation from state for CompanyCreationLandbot page`,
    { context: { state: state.value } },
  );

  return undefined;
};

export default getSignupSituationFromState;
