import { Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { Scope } from '@sentry/react';

import config from 'config';

import { TRACE_ID_HEADER_NAME } from './constants';

/**
 * Builds a full trace ID, including the GCP project identifier, to be able
 * to easily correlate with logs.
 */
export const buildFullTraceId = (traceId: string): string => {
  return `projects/${config.firebase.projectId}/traces/${traceId}`;
};

/**
 * Extracts a partial (without GCP project identifier) trace ID from a response
 * object featuring headers.
 */
export const extractPartialTraceId = (response: Response) => {
  const partialTraceId = response.headers.get(TRACE_ID_HEADER_NAME);

  if (!partialTraceId) {
    return null;
  }

  // Most of the time, the trace ID looks like "e9df29dc0f853175a655e735bda258e9/17742769148163309397"
  // Sometimes the trace ID looks like "e9df29dc0f853175a655e735bda258e9/17742769148163309397;o=1"
  // In both cases we need to keep only the first part of the "full" trace ID,
  // which is everything before the slash, because that's what we store in
  // our logs.
  return partialTraceId.split('/')[0];
};

/**
 * Extracts a trace ID from a GraphQL error and sets it as "traceId" tag the
 * given Sentry scope.
 */
export const setTraceIdInSentryTagsIfPresent = (
  scope: Scope,
  graphQLOperation: Operation,
) => {
  const operationContext = graphQLOperation.getContext();

  if (operationContext.response?.headers?.map) {
    const partialTraceId = extractPartialTraceId(
      operationContext.response.headers.map,
    );
    if (partialTraceId) {
      scope.setTag('traceId', buildFullTraceId(partialTraceId));
    }
  }
};

/**
 * Returns the type of a GraphQL operation: "query", "mutation" or "subscription"
 */
export const getOperationType = (
  operation: Operation,
): 'query' | 'mutation' | 'subscription' => {
  const definition = getMainDefinition(operation.query);

  if (definition.kind === 'OperationDefinition') {
    return definition.operation;
  }

  return 'query';
};
