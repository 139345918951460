import { FC } from 'react';

import GuidanceBaseAdvertising from './Base/GuidanceBaseAdvertising';
import MobileAppRatings from './components/MobileAppRatings';
import locales from './locales';

const GuidanceHome: FC = () => (
  <GuidanceBaseAdvertising title={locales.guidanceHome.title}>
    <MobileAppRatings />
  </GuidanceBaseAdvertising>
);

export default GuidanceHome;
