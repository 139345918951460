import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    body: {
      capitalDeposit: 'Now let’s talk about your capital deposit',
      inHouseSasuCreation:
        'You will now:\n\nAnswer a few questions about your SASU project',
      shineStart: 'Now let’s talk about the creation of your micro-entreprise',
      standard: 'We will now ask you for information about your company',
    },
    cta: 'Continue',
    guidanceTitle: 'Welcome {firstName} {lastName}!',
    title: 'Welcome\n{firstName} {lastName}!',
  },

  fr: {
    body: {
      capitalDeposit: 'Parlons maintenant de votre dépot de capital',
      inHouseSasuCreation:
        'Vous allez maintenant:\n\nRépondre à quelques questions autour de votre projet de SASU',
      shineStart: 'Parlons maintenant de votre création de micro-entreprise',
      standard:
        'Nous allons maintenant vous demander des informations sur votre entreprise',
    },
    cta: 'Continuer',
    guidanceTitle: 'Bienvenue {firstName} {lastName}\u00a0!',
    title: 'Bienvenue\n{firstName} {lastName}\u00a0!',
  },
} as const);

export default locales;
