import LocalizedStrings from 'localized-strings';

/**
 * @todo validate referral locales with Charly.
 */
const locales = new LocalizedStrings({
  en: {
    back: 'Back',
    invalidReferral:
      'The referral code {referral} is not valid.\nIt won’t be taken into account during your subscription.',
    validReferral:
      'Your referral code\n{referral} has been applied successfully.',
  },
  fr: {
    back: 'Retour',
    invalidReferral:
      'Le code parrain {referral} n’est plus valide.\nIl ne sera pas pris en compte lors de votre inscription.',
    validReferral: 'Votre code de parrainage\n{referral} a bien été appliqué.',
  },
});

export default locales;
