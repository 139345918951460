import { FC } from 'react';
import { chakra } from '@chakra-ui/react';

import Button from 'components/_core/Button';

import locales from './locales';

const HELP_URL = 'https://help.shine.fr/';

/**
 * The help link
 */
const HelpLink: FC = () => {
  return (
    <Button
      as={chakra.a}
      href={HELP_URL}
      icon="help"
      iconPosition="left"
      size="sm"
      target="_blank"
      variant="discreet"
    >
      {locales.help}
    </Button>
  );
};

export default HelpLink;
