import {
  anatomy,
  PartsStyleFunction,
  PartsStyleObject,
} from '@chakra-ui/theme-tools';

const parts = anatomy('tag').parts('container', 'label', 'icon', 'iconRight');
type Parts = typeof parts;

const baseStyle: PartsStyleFunction<Parts> = ({ colorScheme }) => ({
  container: {
    alignItems: 'center',
    backgroundColor: `${colorScheme}.200`,
    borderRadius: 'radius-full',
    display: 'inline-flex',
    gap: 'space-4',
  },
  icon: {
    color: `${colorScheme}.700`,
  },
  iconRight: {
    color: `${colorScheme}.700`,
  },
  label: {
    alignItems: 'center',
    color: `${colorScheme}.700`,
    display: 'flex',
    fontWeight: 'weight-500',
  },
});

const sizes: Record<string, PartsStyleObject<Parts>> = {
  md: {
    container: {
      height: '2rem',
      paddingX: 'space-12', // 32px
    },
  },
  sm: {
    container: {
      height: '1.5rem',
      paddingX: 'space-8', // 24px
    },
  },
};

const defaultProps = {
  colorScheme: 'grey',
  size: 'md',
};

const TagTheme = {
  baseStyle,
  defaultProps,
  parts: parts.keys,
  sizes,
};

export default TagTheme;
