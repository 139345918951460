import { isValidBIC } from '@shinetools/iban-bban-bic';
import { isValid as isValidIBAN } from 'iban';
import { z } from 'zod';

import zodLocales from 'common/zod/locales';
import { isValidZip } from 'components/LegacyInput/utils';

export const nonEmptyStringSchemaWithCustomMessage = (message: string) =>
  z.string().trim().min(1, { message });

export const nonEmptyStringSchema = nonEmptyStringSchemaWithCustomMessage(
  zodLocales.errors.required,
);

export const ibanSchema = nonEmptyStringSchema.refine(isValidIBAN, {
  message: zodLocales.errors.invalidIban,
});

export const swiftBicSchema = nonEmptyStringSchema.refine(isValidBIC, {
  message: zodLocales.errors.invalidSwiftBic,
});

export const fileUploadSchema = z.object({
  bucketName: z.string(),
  fileRef: z.string(),
  url: z.string(),
});

export const zipCodeSchema = nonEmptyStringSchema.refine(isValidZip, {
  message: zodLocales.errors.invalidZipCode,
});
