import { useMutation } from '@apollo/client';
import { Heading } from '@chakra-ui/react';
import ModalBody from 'Main/SecuredRoutes/CardPaymentStrongAuthenticationRequests/components/ModalBody';

import useAuthentifiedAction from 'common/hooks/useAuthenticatedAction';
import { Scope } from 'common/hooks/useAuthenticatedAction/types';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import Text from 'components/_core/Text';
import PasscodeInput from 'components/PasscodeInput';
import useToast from 'components/ToastProvider/useToast';
import localizedErrors from 'features/Bento/libs/errors/localizedErrors';
import { getDeviceInformation } from 'helpers/device';

import { AcceptCardPaymentStrongAuthenticationRequestDocument } from '../../../graphql/mutations/acceptCardPaymentStrongAuthenticationRequest.gql';
import { Request } from '../../../types';
import locales from './locales';

interface Props {
  cardPaymentStrongAuthenticationRequest: Request;
  goBack: () => void;
}

const CardPaymentStrongAuthenticationRequestCode = ({
  cardPaymentStrongAuthenticationRequest,
  goBack,
}: Props) => {
  const { showToast } = useToast();
  const [mutation, { loading: mutationLoading }] = useMutation(
    AcceptCardPaymentStrongAuthenticationRequestDocument,
  );

  const acceptCardPaymentStrongAuthenticationRequest = async (
    accessToken: string,
  ) => {
    try {
      await mutation({
        context: {
          headers: {
            'short-lived-token': accessToken,
          },
        },
        variables: {
          input: {
            cardPaymentStrongAuthenticationRequestId:
              cardPaymentStrongAuthenticationRequest.cardPaymentStrongAuthenticationRequestId,
            deviceToken: getDeviceInformation().token,
          },
        },
      });
    } catch {
      showToast({ message: localizedErrors.UNRECOGNIZED, type: 'error' });
    }
  };

  const { onPasscodeSubmit } = useAuthentifiedAction({
    onPasscodeSuccess: acceptCardPaymentStrongAuthenticationRequest,
    scope: Scope.CARD_PAYMENT_STRONG_AUTHENTICATION_REQUESTS_ACCEPT,
  });

  return (
    <>
      <ModalBody>
        <Heading marginBottom="space-12">{locales.title}</Heading>
        <Text marginBottom="space-32">{locales.description}</Text>
        <PasscodeInput hidden onSubmit={onPasscodeSubmit} />
      </ModalBody>
      <Modal.Footer justifyContent="space-between">
        <Button onClick={goBack} variant="inline-primary">
          {locales.goBack}
        </Button>
        <Button
          icon="arrow-right"
          isDisabled={true}
          isLoading={mutationLoading}
        >
          {locales.confirm}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CardPaymentStrongAuthenticationRequestCode;
