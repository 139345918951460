import { MachineServices } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';
import { OBError } from 'features/Bento/libs/errors/OBError';

import { AcceptAgreementDocument } from '../graphql/mutations/acceptAgreement.gql';
import { AssignAgreementDocument } from '../graphql/mutations/assignAgreement.gql';
import { Model } from './model';

export enum Service {
  SendAgreement = 'sendAgreement',
  SendAssignment = 'sendAssignment',
}

const services: MachineServices<Model, Service> = {
  /**
   * Agree to the outstanding agreement, effectively signing it.
   */
  sendAgreement: ({ getModuleData }) => {
    const [agreement] = getModuleData().viewer.outstandingAgreements;

    if (!agreement) {
      throw new OBError('No agreement available', {
        metadata: getModuleData(),
      });
    }

    return apolloClient.mutate({
      mutation: AcceptAgreementDocument,
      variables: {
        input: {
          agreementId: agreement.agreementId,
        },
      },
    });
  },

  /**
   * Assign the most recent PDF version of the agreement type to sign.
   */
  sendAssignment: ({ getModuleData }) => {
    const [agreement] = getModuleData().viewer.outstandingAgreements;

    if (!agreement) {
      throw new OBError('No agreement available', {
        metadata: getModuleData(),
      });
    }

    return apolloClient.mutate({
      mutation: AssignAgreementDocument,
      variables: {
        input: {
          agreementId: agreement.agreementId,
        },
      },
    });
  },
};

export default services;
