import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    back: 'Back',
  },
  fr: {
    back: 'Précédent',
  },
});

export default locales;
