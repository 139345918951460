/* eslint-disable jsx-a11y/no-autofocus */

import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { differenceInSeconds } from 'date-fns';
import useInterval from 'use-interval';

import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineIcon from 'components/_core/SunshineIcon';
import { PinInput } from 'features/Bento/atoms';
import InputErrorMessage from 'features/Bento/atoms/InputErrorMessage/InputErrorMessage';
import RichText from 'features/Bento/atoms/RichText';
import { PageLayout } from 'features/Bento/components';
import { SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import { OBErrorCode } from 'features/Bento/libs/errors/OBErrorCode';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import { GuidancePhone } from '../../../../components/Guidance';
import { model } from '../../machine';
import { Intro, State } from '../../machine/machine';
import locales from './locales';

const DEFAULT_TIMER_IN_SECONDS = 180;
const VERIFICATION_CODE_LENGTH = 6;

/**
 * A page where we ask the user to enter the verification code
 */
const VerificationCode: SignupPage<Intro> = ({ send, state }) => {
  const { phoneNumber, verificationCodeTimestamp } = state.context;

  if (phoneNumber === null || verificationCodeTimestamp === null) {
    throw Error('phoneNumber and verificationCodeTimestamp should be defined');
  }

  const Layout = useContext(OBLayoutContext);
  const [timer, setTimer] = useState(DEFAULT_TIMER_IN_SECONDS);
  const [verificationCode, setVerificationCode] = useState('');
  const [pinInputError, setPinInputError] = useState('');

  const timerHasExpired = timer <= 0;

  const updateTimer = useCallback(() => {
    const timeInSecondsSinceVerificationCodeSent = differenceInSeconds(
      new Date(),
      new Date(verificationCodeTimestamp),
    );

    setTimer(DEFAULT_TIMER_IN_SECONDS - timeInSecondsSinceVerificationCodeSent);
  }, [verificationCodeTimestamp]);

  // @todo focus input when verificationCodeTimestamp changes ?
  useEffect(() => updateTimer(), [verificationCodeTimestamp, updateTimer]);

  useInterval(() => updateTimer(), timerHasExpired ? null : 1000);

  useEffect(() => {
    if (
      state.matches(State.VERIFICATION_CODE) &&
      state.context.verificationCodeError === OBErrorCode.CODE_NOT_MATCH
    ) {
      setPinInputError(locales.didNotMatchError);
      // Void the input after the user submitted a wrong code
      setVerificationCode('');
    }
  }, [state]);

  useEffect(() => {
    if (timerHasExpired) {
      setVerificationCode('');
      setPinInputError('');
    }
  }, [timerHasExpired]);

  const handleSubmit = (
    event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();

    if (!timerHasExpired) {
      send(model.events.SUBMIT_VERIFICATION_CODE(verificationCode));
    }
  };

  // eslint-disable-next-line require-jsdoc
  const getFooter = () =>
    timerHasExpired ? (
      <Button
        isLoading={state.matches(State.VERIFICATION_CODE_RESEND)}
        marginTop="space-24"
        onClick={() => send(model.events.ASK_NEW_VERIFICATION_CODE())}
        variant="inline-primary"
      >
        {locales.sendANewCode}
      </Button>
    ) : (
      <Button
        isDisabled={
          verificationCode.length !== VERIFICATION_CODE_LENGTH ||
          Boolean(pinInputError)
        }
        isLoading={
          state.matches(State.CREATE_ACCOUNT_AND_LOGIN) ||
          state.matches(State.CHECK_VERIFICATION_CODE)
        }
        marginTop="space-24"
        type="submit"
        width="100%"
      >
        {locales.cta}
      </Button>
    );

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Layout
        actions={
          <PageLayout.Actions onBack={() => send(model.events.PREV())} />
        }
        data-testid="verification-phone"
        footer={getFooter()}
        guidance={<GuidancePhone />}
      >
        <Heading marginBottom="space-24" size="lg">
          {locales.title}
        </Heading>

        <PinInput
          autoFocus
          digits={VERIFICATION_CODE_LENGTH}
          isDisabled={timerHasExpired}
          isInvalid={Boolean(pinInputError)}
          onChange={(value) => {
            setVerificationCode(value as string);
            setPinInputError('');
          }}
          value={verificationCode}
        />

        {pinInputError ? (
          <InputErrorMessage>{pinInputError}</InputErrorMessage>
        ) : null}

        <RichText marginBottom="space-4" marginTop="space-24">
          {
            locales.formatString(locales.sentSmsTo, {
              phoneNumber,
            }) as string
          }
        </RichText>

        <HStack>
          <Text sx={{ fontVariantNumeric: 'tabular-nums' }} variant="light">
            <SunshineIcon marginRight="space-8" name="timer" />

            {timerHasExpired
              ? locales.codeExpired
              : locales.formatString(
                  timer > 1
                    ? locales.codeTimerPlural
                    : locales.codeTimerSingular,
                  {
                    timer,
                  },
                )}
          </Text>
        </HStack>
      </Layout>
    </Box>
  );
};

export default VerificationCode;
