import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

const Container: FC<FlexProps> = (props) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    textAlign="center"
    {...props}
  />
);
export default Container;
