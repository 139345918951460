/* eslint-disable jsx-a11y/no-autofocus */

import {
  ChakraComponent,
  HStack,
  PinInput as ChakraPinInput,
  PinInputField,
  PinInputProps as ChakraPinInputProps,
  StackProps,
} from '@chakra-ui/react';

type PinInputProps = StackProps & {
  digits: number;
} & Pick<
    ChakraPinInputProps,
    | 'onComplete'
    | 'mask'
    | 'autoFocus'
    | 'value'
    | 'onChange'
    | 'isDisabled'
    | 'isInvalid'
  >;

/**
 * An uncontrolled component that allow the user to enter a PIN code
 * and fire an onComplete event when the PIN code is fully entered.
 */
const PinInput: ChakraComponent<'div', PinInputProps> = ({
  autoFocus,
  digits,
  isDisabled,
  isInvalid,
  mask,
  onChange,
  onComplete,
  value,
  ...props
}) => (
  <HStack data-testid="pin-input" spacing="space-20" {...props}>
    <ChakraPinInput
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      mask={mask}
      onChange={onChange}
      onComplete={(input) => {
        if (onComplete && input.length === digits) {
          return onComplete(input);
        }

        return null;
      }}
      otp
      placeholder=""
      value={value}
    >
      {Array(digits)
        .fill(undefined)
        .map((_, i) => (
          <PinInputField
            // eslint-disable-next-line react/no-array-index-key
            key={`pin-${i}`}
          />
        ))}
    </ChakraPinInput>
  </HStack>
);

export default PinInput;
