import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    /** @todo confirm EN locale */
    title: 'Tell us more about your company creation project',
  },

  fr: {
    title: 'Dites-nous en plus sur votre projet de création d’entreprise',
  },
});

export default locales;
