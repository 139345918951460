import { FC } from 'react';
import { Box, Image, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import Callout from 'components/_core/Callout';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import { Prediction } from 'features/GoogleMaps/hooks/useAddressSearch/types';

import googleLogo from '../../../../../../../assets/images/googleLogo.png';
import locales from './locales';

interface PredictionsProps {
  allowManualMode: boolean;
  isDesktop: boolean;
  predictions: Prediction[];
  onPredictionClick: (prediction: Prediction) => void;
  onSelectManualMode: () => void;
}

const Predictions: FC<PredictionsProps> = ({
  allowManualMode,
  isDesktop,
  onPredictionClick,
  onSelectManualMode,
  predictions,
}) => {
  const renderManualModeCta = () => (
    <>
      <Image
        alignSelf="end"
        alt="Google Logo"
        marginBottom="space-12"
        src={googleLogo}
      />
      <Callout hideIcon variant="info">
        <Callout.Content>
          <Text marginBottom="space-12">{locales.manualEntry.prompt}</Text>
          <Link
            data-testid="address-manual-entry"
            onClick={onSelectManualMode}
            textAlign="left"
          >
            {locales.manualEntry.cta}
          </Link>
        </Callout.Content>
      </Callout>
    </>
  );

  return (
    <Box
      {...(isDesktop
        ? {
            marginTop: 'space-4',
            position: 'absolute',
            width: 'full',
            zIndex: 1,
          }
        : {})}
    >
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        data-testid="results-list"
        initial={{ opacity: 0, y: 10 }}
      >
        {predictions.length > 0 || (isDesktop && allowManualMode) ? (
          <SunshineCard.Group>
            {predictions.map((prediction) => (
              <SunshineCard
                key={prediction.placeId}
                onClick={() => onPredictionClick(prediction)}
                title={prediction.mainText}
              >
                <SunshineCard.Content>
                  <SunshineCard.Text>
                    {prediction.secondaryText}
                  </SunshineCard.Text>
                </SunshineCard.Content>
                <SunshineCard.Slot name="action">
                  <SunshineCard.SunshineIcon name="chevron-right" />
                </SunshineCard.Slot>
              </SunshineCard>
            ))}
            {isDesktop && allowManualMode ? (
              <SunshineCard key="manual-mode-cta" title="">
                <SunshineCard.Content>
                  {renderManualModeCta()}
                </SunshineCard.Content>
              </SunshineCard>
            ) : null}
          </SunshineCard.Group>
        ) : null}
      </motion.div>
      {!isDesktop && allowManualMode ? (
        <Box marginY="space-16">{renderManualModeCta()}</Box>
      ) : null}
    </Box>
  );
};

export default Predictions;
