import { FC } from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

interface ContentProps extends StackProps {
  title?: string;
  content?: string;
}

const Content: FC<ContentProps> = ({ content, title, ...props }) => (
  <Stack spacing="space-16" {...props}>
    <Heading size="lg">{title}</Heading>
    {content ? <Text>{content}</Text> : null}
  </Stack>
);

export default Content;
