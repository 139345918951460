import { logEvent, TrackedEvent } from 'features/Analytics/analytics';
import logger from 'helpers/logger';

import { SharedAction } from '.';

/**
 * Given a TrackedEvent,
 * this function returns an XState action that calls logEvent on the corresponding data.
 *
 * Use it this way:
 * @example <caption>
 * on: {
 *   EXAMPLE: {
 *     actions: shared.actions.track({ name: 'My Event' })
 *   }
 * }
 * </caption>
 */
const track: SharedAction<TrackedEvent> = (data) => ({
  exec: () => {
    logEvent({ ...data, usePrefix: data.usePrefix ?? false });

    logger.info('ℹ️ TRACKED EVENT: ', data);
  },
  type: 'track',
});

export default track;
