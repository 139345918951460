import { useContext } from 'react';

import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import GuidanceCompanyProfile from '../../components/Guidance/GuidanceCompanyProfile';
import { model } from '../../machine';
import { Signup } from '../../machine/machine';
import { SignupPage } from '../../types/SignupPage';
import locales from './locales';

/**
 * A question about the type of company
 */
const StandardSelectCompanyProfile: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      guidance={<GuidanceCompanyProfile />}
    >
      <Heading marginBottom="space-32" size="lg">
        {locales.title}
      </Heading>

      <SunshineCard.Group isBoxed={false}>
        <SunshineCard
          alignItems="center"
          onClick={() => send(model.events.SUBMIT_COMPANY_PROFILE_TYPE('solo'))}
          title={locales.options.solo}
        >
          <SunshineCard.Slot name="aside">
            <SquircleIcon name="profile" />
          </SunshineCard.Slot>

          <SunshineCard.Content>
            <SunshineCard.Text>
              {locales.options.soloDescription}
            </SunshineCard.Text>
          </SunshineCard.Content>

          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          alignItems="center"
          onClick={() =>
            send(
              model.events.SUBMIT_COMPANY_PROFILE_TYPE(
                'fewer-than-ten-employees',
              ),
            )
          }
          title={locales.options.fewerThanTen}
        >
          <SunshineCard.Slot name="aside">
            <SquircleIcon name="suitcase" />
          </SunshineCard.Slot>

          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          alignItems="center"
          onClick={() =>
            send(
              model.events.SUBMIT_COMPANY_PROFILE_TYPE(
                'more-than-ten-employees',
              ),
            )
          }
          title={locales.options.moreThanTen}
        >
          <SunshineCard.Slot name="aside">
            <SquircleIcon name="building" />
          </SunshineCard.Slot>

          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>
      </SunshineCard.Group>
    </Layout>
  );
};

export default StandardSelectCompanyProfile;
