import { Box } from '@chakra-ui/react';
import { PublicStepConfig, PublicStepTypes } from 'onfido-sdk-ui';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import LoaderPage from 'common/bento/pages/LoaderPage';
import Heading from 'components/_core/Heading';
import { KycDocument } from 'components/OnfidoDocumentVerifier/types';
import { PageLayout } from 'features/Bento/components';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import IdCheckProvider from './components/IdCheckProvider';
import locales from './locales';

export const PROVIDER_CONTAINER_ID = 'shine-onboarding-onfido-container';

const STEPS: Array<PublicStepTypes | PublicStepConfig> = [
  {
    options: {
      descriptions: [
        locales.onfidoWelcomeDescription,
        locales.onfidoWelcomeDescriptionTwo,
      ],
      nextButton: locales.onfidoNext,
      title: locales.onfidoWelcomeTitle,
    },
    type: 'welcome',
  },
  {
    options: {
      documentTypes: {
        driving_licence: false,
        national_identity_card: true,
        passport: true,
        residence_permit: true,
      },
      forceCrossDevice: false,
      uploadFallback: true,
    },
    type: 'document',
  },
  // 'complete',
];

interface IdCheckProps {
  isSubmitting: boolean;
  onSuccess: (documents: KycDocument[]) => void;
}

/**
 * A page that verifies the user’s identity with Onfido.
 */
const IdCheck = asBentoPage<IdCheckProps>(
  ({ isSubmitting, Layout, onSuccess }) => {
    const isMobile = !useLayoutSizeQuery(BentoLayoutSize.sm);

    if (isSubmitting) {
      return <LoaderPage onPrev={() => null} />;
    }

    return isMobile ? (
      <Box height="100vh" width="100vw">
        <IdCheckProvider onSuccess={onSuccess} steps={STEPS} />
      </Box>
    ) : (
      <Layout nav={<Navigation />}>
        <PageLayout.Header textAlign="center">
          <Heading marginBottom="space-16" size="lg">
            {locales.title}
          </Heading>
        </PageLayout.Header>

        <IdCheckProvider fitContainer onSuccess={onSuccess} steps={STEPS} />
      </Layout>
    );
  },
);

export default IdCheck;
