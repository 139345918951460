import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    'bank:transfers:create': {
      title: 'This transfer has been authorized!',
    },
    description: 'You can now go back to {clientName} to initiate it.',
    dismissButton: 'Done',
    title: 'This operation has been authorized!',
  },
  fr: {
    'bank:transfers:create': {
      title: 'Ce virement a été autorisé\u00A0!',
    },
    description:
      'Vous pouvez maintenant retourner dans l’application {clientName} pour l’initier.',
    dismissButton: 'Compris',
    title: 'Cette opération a été autorisée\u00A0!',
  },
});

export default locales;
