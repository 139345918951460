import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import Heading from 'components/_core/Heading';
import { createToast } from 'features/Bento/atoms';
import RichText from 'features/Bento/atoms/RichText';
import { PageLayout } from 'features/Bento/components';
import { SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import localizedErrors from 'features/Bento/libs/errors/localizedErrors';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';
import AlreadyUsedAlert from 'features/Onboarding/components/AlreadyUsedAlert/AlreadyUsedAlert';
import { GENDER_OPTIONS } from 'features/Onboarding/modules/Identity/libs/constants';
import { checkEmail } from 'helpers/auth/service';

import { GuidancePersonalInfo } from '../../../../components/Guidance';
import formSchema, { FormInputSchema, FormSchema } from '../../libs/formSchema';
import { model } from '../../machine';
import { Intro } from '../../machine/machine';
import locales from './locales';

/**
 * Action CTAs for the account creation form page
 */
const FormPageActions: SignupPage<Intro> = ({ send, state }) => (
  <PageLayout.Actions onBack={() => send(model.events.PREV())}>
    {state.context.referral ? (
      <RichText marginBottom="space-16">
        {
          locales.formatString(locales.page.hasReferral, {
            firstName: state.context.referral.referrerName ?? '',
          }) as string
        }
      </RichText>
    ) : (
      <Button
        icon="gift"
        onClick={() => send(model.events.GO_REFERRAL_GUIDE())}
        variant="inline-primary"
      >
        {locales.page.shouldHaveReferral}
      </Button>
    )}
  </PageLayout.Actions>
);

/**
 * A form prompting the user to enter personal info and accept our CGU.
 */
const AccountCreationForm: SignupPage<Intro> = (props) => {
  const { send, state } = props;
  const { context } = state;

  const Layout = useContext(OBLayoutContext);

  const form = useForm<FormInputSchema, undefined, FormSchema>({
    defaultValues: context.form,
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
  });

  return (
    <form
      data-testid="account-creation-form"
      onSubmit={form.handleSubmit(async (values) =>
        checkEmail(values.email)
          .then(({ available }) => {
            if (available) {
              send(model.events.SUBMIT_FORM_DATA(values));
            } else {
              form.setError('email', {
                type: 'already-used',
              });
            }
          })
          .catch(() => {
            createToast({
              status: 'error',
              title: localizedErrors.UNRECOGNIZED,
            });
          }),
      )}
    >
      <Layout
        actions={<FormPageActions {...props} />}
        footer={
          <Button
            isLoading={form.formState.isSubmitting}
            marginTop="space-24"
            type="submit"
            width="100%"
          >
            {locales.page.cta}
          </Button>
        }
        guidance={<GuidancePersonalInfo />}
      >
        <Heading marginBottom="space-16" size="lg">
          {locales.page.title}
        </Heading>

        <VStack align="stretch" marginY="space-16" spacing="space-24">
          <Form.Field
            error={form.formState.errors.gender?.message}
            label={locales.form.labelLegalTitle}
          >
            <Controller
              control={form.control}
              name="gender"
              render={({ field: { onChange, value } }) => (
                <Form.Radio.Group
                  alignSelf="flex-start"
                  direction="row"
                  marginY="space-8"
                  name="radio-group"
                  onChange={onChange}
                  value={value ?? undefined}
                  variant="outline"
                >
                  {GENDER_OPTIONS.map((option) => (
                    <Form.Radio key={option.value} value={option.value}>
                      <Form.Radio.Title>
                        {option.abbreviatedLabel}
                      </Form.Radio.Title>
                    </Form.Radio>
                  ))}
                </Form.Radio.Group>
              )}
            />
          </Form.Field>

          <Form.Field
            error={form.formState.errors.firstName?.message}
            label={locales.form.labelFirstName}
          >
            <Form.Input
              data-testid="first-name-input"
              {...form.register('firstName')}
            />
          </Form.Field>

          <Form.Field
            error={form.formState.errors.lastName?.message}
            label={locales.form.labelLastName}
          >
            <Form.Input
              data-testid="last-name-input"
              {...form.register('lastName')}
            />
          </Form.Field>

          <Form.Field
            error={form.formState.errors.email?.message}
            label={locales.form.labelEmail}
          >
            <Form.Input
              data-testid="email-address-input"
              enterKeyHint="send"
              type="email"
              {...form.register('email')}
            />
          </Form.Field>

          {form.formState.errors.email?.type === 'already-used' ? (
            <AlreadyUsedAlert type="email" />
          ) : null}

          <Form.Field
            error={form.formState.errors.hasAgreedToTermsOfService?.message}
          >
            <Controller
              control={form.control}
              name="hasAgreedToTermsOfService"
              render={({ field }) => (
                <Form.Checkbox
                  data-testid="cgu-checkbox"
                  isChecked={field.value}
                  onChange={(val) => field.onChange(val)}
                >
                  <Form.Checkbox.Label>
                    <Box>
                      <Text as="span">
                        {`${locales.form.labelCgu} `}

                        <Link
                          href="https://www.shine.fr/lp/cgu-ep-en-clair/"
                          target="_blank"
                        >
                          {locales.form.labelCguLink}
                        </Link>
                      </Text>
                    </Box>
                  </Form.Checkbox.Label>
                </Form.Checkbox>
              )}
            />
          </Form.Field>
        </VStack>
      </Layout>
    </form>
  );
};

export default AccountCreationForm;
