import { FC, FormEvent, useState } from 'react';
import { Box } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import Heading from 'components/_core/Heading';
import { PageLayout } from 'features/Bento/components';
import { CompanyCreationLegalForm } from 'features/Bento/flows/signup/machine/model';

import locales from './locales';

type CompanyCreationLegalFormWithoutCapDep = Exclude<
  CompanyCreationLegalForm,
  CompanyCreationLegalForm.CAP_DEP
>;

const legalForm: Record<
  CompanyCreationLegalFormWithoutCapDep,
  CompanyCreationLegalFormWithoutCapDep
> = {
  [CompanyCreationLegalForm.EURL]: CompanyCreationLegalForm.EURL,
  [CompanyCreationLegalForm.SARL]: CompanyCreationLegalForm.SARL,
  [CompanyCreationLegalForm.SAS]: CompanyCreationLegalForm.SAS,
  [CompanyCreationLegalForm.SASU]: CompanyCreationLegalForm.SASU,
  [CompanyCreationLegalForm.SCI]: CompanyCreationLegalForm.SCI,
  [CompanyCreationLegalForm.UNKNOWN]: CompanyCreationLegalForm.UNKNOWN,
};

interface CompanyCreationLegalFormFormProps {
  onSubmit: (legalForm: CompanyCreationLegalForm) => void;
}

/**
 * A page asking the user what legal form they want to create
 */
const CompanyCreationLegalFormForm: FC<CompanyCreationLegalFormFormProps> = ({
  onSubmit,
}) => {
  const [option, setOption] = useState<CompanyCreationLegalForm | undefined>(
    undefined,
  );

  const handleSubmit = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (option) {
      onSubmit(option);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <PageLayout.Header marginBottom="space-24">
        <Heading size="lg">{locales.title}</Heading>
      </PageLayout.Header>

      <Form.Radio.Group
        marginBottom="space-24"
        onChange={(nextValue) => {
          setOption(nextValue as CompanyCreationLegalForm);
        }}
      >
        {Object.values(legalForm).map((form) => (
          <Form.Radio key={form} value={form} variant="outline">
            <Form.Radio.Title margin="space-8" variant="primary">
              {locales.legalForm[form]}
            </Form.Radio.Title>
          </Form.Radio>
        ))}
      </Form.Radio.Group>

      <Button isDisabled={option === undefined} isExpanded type="submit">
        {locales.submit}
      </Button>
    </Box>
  );
};

export default CompanyCreationLegalFormForm;
