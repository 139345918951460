import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = ({ colorScheme }) => ({
  alignItems: 'center',
  background: `${colorScheme}.4`,
  borderRadius: 'full',
  color: `${colorScheme}.-2`,
  display: 'inline-flex',
  fontWeight: 'weight-500',
  lineHeight: 'short',
});

const sizes = {
  md: {
    fontSize: 'font-15',
    paddingX: 'space-12',
    paddingY: 'space-6',
  },
  sm: {
    fontSize: 'font-12',
    paddingX: 'space-8',
    paddingY: 'space-4',
  },
};

const defaultProps = {
  colorScheme: 'grey',
  size: 'md',
};

const BadgeTheme = {
  baseStyle,
  defaultProps,
  sizes,
};

export default BadgeTheme;
