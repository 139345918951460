import { Divider, Heading, VStack } from '@chakra-ui/react';

import fadedFlower from 'assets/brand/faded-flower@2x.png';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import Text from 'components/_core/Text';

import CardInfo from '../../components/CardInfo';
import ModalBody from '../../components/ModalBody';
import TransactionInfo from '../../components/TransactionInfo';
import { Request } from '../../types';
import locales from './locales';

interface Props {
  onClose: () => void;
  cardPaymentStrongAuthenticationRequest: Request;
}

const CardPaymentStrongAuthenticationRequestExpired = ({
  cardPaymentStrongAuthenticationRequest,
  onClose,
}: Props) => {
  const { creditCard } = cardPaymentStrongAuthenticationRequest;

  return (
    <>
      <ModalBody>
        <Modal.Image src={fadedFlower} width="144px" />
        <Heading marginBottom="space-12">{locales.title}</Heading>
        <Text marginBottom="space-32">{locales.description}</Text>
        <VStack
          alignItems="start"
          borderColor="border"
          borderRadius="radius-8"
          borderWidth="thin"
          divider={<Divider borderColor="border" />}
          marginBottom="space-32"
          spacing={0}
        >
          <TransactionInfo
            cardPaymentStrongAuthenticationRequest={
              cardPaymentStrongAuthenticationRequest
            }
          />
          <CardInfo creditCard={creditCard} />
        </VStack>
      </ModalBody>
      <Modal.Footer justifyContent="flex-end">
        <Button onClick={onClose}>{locales.closeButton}</Button>
      </Modal.Footer>
    </>
  );
};

export default CardPaymentStrongAuthenticationRequestExpired;
