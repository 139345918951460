import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Divider, Flex, Heading, VStack } from '@chakra-ui/react';

import { CardPaymentStrongAuthenticationRequestStatus } from '__generated__/GQL';
import { formatAmount } from 'common/formatAmount';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import Text from 'components/_core/Text';
import useToast from 'components/ToastProvider/useToast';
import localizedErrors from 'features/Bento/libs/errors/localizedErrors';
import { getDeviceInformation } from 'helpers/device';

import CardInfo from '../../components/CardInfo';
import ModalBody from '../../components/ModalBody';
import TransactionInfo from '../../components/TransactionInfo';
import { RefuseCardPaymentStrongAuthenticationRequestDocument } from '../../graphql/mutations/refuseCardPaymentStrongAuthenticationRequest.gql';
import { GetPendingCardPaymentStrongAuthenticationRequestsDocument } from '../../graphql/queries/getPendingCardPaymentStrongAuthenticationRequests.gql';
import { Request } from '../../types';
import CardPaymentStrongAuthenticationRequestCode from './CardPaymentStrongAuthenticationRequestCode';
import Countdown from './Countdown';
import locales from './locales';

const MIN_AMOUNT_FOR_ADDITIONAL_FRAUD_HINTS = 10;

interface Props {
  cardPaymentStrongAuthenticationRequest: Request;
}

const CardPaymentStrongAuthenticationRequestPending = ({
  cardPaymentStrongAuthenticationRequest,
}: Props) => {
  const [askPasscode, setAskPasscode] = useState(false);

  const { showToast } = useToast();

  const [mutation, { client, loading }] = useMutation(
    RefuseCardPaymentStrongAuthenticationRequestDocument,
    {
      variables: {
        input: {
          cardPaymentStrongAuthenticationRequestId:
            cardPaymentStrongAuthenticationRequest.cardPaymentStrongAuthenticationRequestId,
          deviceToken: getDeviceInformation().token,
        },
      },
    },
  );

  const refuseCardPaymentStrongAuthenticationRequest = async () => {
    try {
      await mutation();
    } catch {
      showToast({ message: localizedErrors.UNRECOGNIZED, type: 'error' });
    }
  };

  const setToExpired = () => {
    client.cache.writeQuery({
      data: {
        __typename: 'Query',
        cardPaymentStrongAuthenticationRequests: [
          {
            ...cardPaymentStrongAuthenticationRequest,
            status: CardPaymentStrongAuthenticationRequestStatus.Expired,
          },
        ],
      },
      query: GetPendingCardPaymentStrongAuthenticationRequestsDocument,
    });
  };

  const { creditCard, transactionAmount, transactionCurrency } =
    cardPaymentStrongAuthenticationRequest;
  const shouldShowAdditionalFraudHints =
    transactionAmount > MIN_AMOUNT_FOR_ADDITIONAL_FRAUD_HINTS;

  return askPasscode ? (
    <CardPaymentStrongAuthenticationRequestCode
      cardPaymentStrongAuthenticationRequest={
        cardPaymentStrongAuthenticationRequest
      }
      goBack={() => setAskPasscode(false)}
    />
  ) : (
    <>
      <ModalBody>
        <Countdown
          cardPaymentStrongAuthenticationRequest={
            cardPaymentStrongAuthenticationRequest
          }
          onEnd={setToExpired}
        />
        <Heading marginBottom="space-12">{locales.title}</Heading>
        <Text marginBottom="space-32">
          {shouldShowAdditionalFraudHints
            ? locales.descriptionFraudVariant
            : locales.description}
        </Text>
        <VStack
          alignItems="start"
          borderColor="border"
          borderRadius="radius-8"
          borderWidth="thin"
          divider={<Divider borderColor="border" />}
          marginBottom="space-32"
          spacing={0}
        >
          <TransactionInfo
            cardPaymentStrongAuthenticationRequest={
              cardPaymentStrongAuthenticationRequest
            }
          />
          <CardInfo creditCard={creditCard} />
        </VStack>
      </ModalBody>
      <Modal.Footer alignItems="stretch" flexDir="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Button
            isLoading={loading}
            onClick={refuseCardPaymentStrongAuthenticationRequest}
            variant="inline-primary"
          >
            {locales.refuse}
          </Button>
          <Button icon="arrow-right" onClick={() => setAskPasscode(true)}>
            {locales.authorize}
          </Button>
        </Flex>
        {shouldShowAdditionalFraudHints ? (
          <Text
            align="right"
            color="text-secondary"
            mt="space-8"
            px="space-8"
            size="xs"
          >
            {locales.formatString(locales.acceptTransactionHint, {
              formattedAmount: formatAmount({
                currency: transactionCurrency,
                forcePlusSign: false,
                value: transactionAmount,
              }),
            })}
          </Text>
        ) : null}
      </Modal.Footer>
    </>
  );
};

export default CardPaymentStrongAuthenticationRequestPending;
