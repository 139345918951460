/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { ERROR_CODES } from '@shinetools/errors';

import { hasErrorCode } from 'common/hasErrorCode';
import { ClearAllDigitsFn } from 'components/PasscodeInput/types';
import useToast from 'components/ToastProvider/useToast';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import { GenerateShortLivedTokenDocument } from './generateShortLivedToken.gql';
import locales from './locales';
import { Params } from './types';

const useAuthentifiedAction = ({ onPasscodeSuccess, scope }: Params) => {
  const [isLoading, setIsLoading] = useState(false);
  const [generateShortLivedToken] = useMutation(
    GenerateShortLivedTokenDocument,
  );
  const device = useDevice();
  const { hideToast, showToast } = useToast();

  const onPasscodeSubmit = async (
    passcode: string,
    clearAllDigits: ClearAllDigitsFn,
  ): Promise<void> => {
    try {
      hideToast();
      setIsLoading(true);
      const { data, errors } = await generateShortLivedToken({
        variables: {
          input: {
            deviceToken: device.token,
            passcode,
            scopes: [scope],
          },
        },
      });

      if (errors !== undefined) {
        throw new Error('Could not generate short lived token, unknown error');
      }

      if (data === undefined || data === null) {
        throw new Error('Could not generate short lived token, data is empty');
      }

      const token = data.generateShortLivedToken.access_token;

      await onPasscodeSuccess(token);
    } catch (error: unknown) {
      if (
        error instanceof ApolloError &&
        hasErrorCode(error.graphQLErrors, ERROR_CODES.RATE_LIMIT)
      ) {
        showToast({ message: locales.rateLimitError, type: 'error' });
      } else if (
        error instanceof ApolloError &&
        hasErrorCode(
          error.graphQLErrors,
          ERROR_CODES.AUTHENTICATION_PASSCODE_WRONG,
        )
      ) {
        showToast({ message: locales.wrongPasscode, type: 'error' });
      } else {
        logger.error(error);
        showToast({ message: locales.unknownError, type: 'error' });
      }

      clearAllDigits({ andFocusFirstInput: true });
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    onPasscodeSubmit,
  };
};

export default useAuthentifiedAction;
