import { FC, useMemo } from 'react';

import { TranslatedCountry } from 'common/countries/types';
import useCountriesList from 'common/countries/useCountriesList';
import * as Form from 'components/_core/form';

import { CountryPickerProps } from './types';

const CountryPicker: FC<CountryPickerProps> = ({
  countries,
  countriesExcluded,
  label,
  onSelect,
  selected,
}) => {
  const allCountries = useCountriesList();

  const countryOptions = useMemo(() => {
    const availableCountries = allCountries.filter((c) => {
      return (
        (countries === undefined || countries.includes(c.value)) &&
        !countriesExcluded?.includes(c.value)
      );
    });

    return availableCountries.map((country) => ({
      flag: country.flag,
      label: country.label,
      value: country,
    }));
  }, [allCountries, countries, countriesExcluded]);

  const currentlySelectedCountry = countryOptions.find(
    (c) => c.value.value === selected,
  );
  const onCountrySelected = (selectedCountry: TranslatedCountry | null) => {
    if (selectedCountry) {
      onSelect(selectedCountry.value);
    }
  };

  return (
    <Form.Field label={label}>
      <Form.SunshineSelect
        components={{
          Option: Form.SunshineSelect.components.LanguageOption,
          SingleValue: Form.SunshineSelect.components.LanguageSingleValue,
        }}
        isSearchable
        onChange={(option) => onCountrySelected(option?.value ?? null)}
        options={countryOptions}
        value={currentlySelectedCountry}
      />
    </Form.Field>
  );
};

export default CountryPicker;
