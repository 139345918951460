import { KycStatus } from '__generated__/GQL';
import { MachineGuards } from 'common/bento/types/MachineConfig';

import getVerificationSteps from '../lib/steps/getVerificationSteps';
import { VerificationStatus } from '../lib/steps/types';
import { Model } from './model';

export enum Guard {
  ShouldSkipHomeToSubmit = 'shouldSkipHomeToSubmit',
  IsIdentityReviewInvalid = 'isIdentityReviewInvalid',
}

const guards: MachineGuards<Model, Guard> = {
  isIdentityReviewInvalid: (context) => {
    const { companyUser } = context.getModuleData().verificationData.viewer;

    return companyUser.kycIdStatus === KycStatus.Refused;
  },

  shouldSkipHomeToSubmit: ({ getModuleData }) => {
    const steps = getVerificationSteps(getModuleData());

    const numberOfRemainingSteps = steps.filter(
      ({ status }) =>
        status !== VerificationStatus.Completed &&
        status !== VerificationStatus.Validated,
    ).length;

    return numberOfRemainingSteps === 0;
  },
};

export default guards;
