import { CompanyUser } from '__generated__/GQL';
import { kycToStatus } from 'features/Bento/libs/mappings';

interface GetIdentityStatusProps {
  companyUser: Pick<CompanyUser, 'kycIdStatus'>;
}

const getIdentityStatus = ({ companyUser }: GetIdentityStatusProps) =>
  kycToStatus(companyUser.kycIdStatus);

export default getIdentityStatus;
