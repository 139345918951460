import { FC } from 'react';
import { useHistory } from 'react-router';

import { RoleFeaturesProps } from 'features/TeamOnboarding/TeamOnboardingTutorials/utils';

import Layout from '../../layouts/FeaturesLayout';
import AccountingExports from './components/AccountingExports';
import BankStatements from './components/BankStatements';
import Transactions from './components/Transactions';
import locales from './locales';

const AccountantFeatures: FC<RoleFeaturesProps> = ({
  companyName,
  onTutorialsDone,
}) => {
  const history = useHistory();
  const handleOnDone = async () => {
    await onTutorialsDone();
    history.push('/accounting/exports');
  };

  return (
    <Layout
      carouselHeight="27.5rem" // 440px
      getCtaText={({ isLast }) => (isLast ? locales.lastFeatureCta : null)}
      onDone={handleOnDone}
    >
      <Transactions companyName={companyName} key="transactions" />
      <BankStatements companyName={companyName} key="bank-statements" />
      <AccountingExports key="accounting-exports" />
    </Layout>
  );
};

export default AccountantFeatures;
