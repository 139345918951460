import { ERROR_CODES } from '@shinetools/errors';
import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    errors: {
      [ERROR_CODES.ACTION_REQUEST_ALREADY_CONFIRMED]:
        'This operation has already been confirmed.',
      [ERROR_CODES.ACTION_REQUEST_ALREADY_REJECTED]:
        'This operation has already been rejected.',
      [ERROR_CODES.ACTION_REQUEST_EXPIRED]: 'This operation has expired.',
    },
  },
  fr: {
    errors: {
      [ERROR_CODES.ACTION_REQUEST_ALREADY_CONFIRMED]:
        'Cette opération a déjà été confirmée.',
      [ERROR_CODES.ACTION_REQUEST_ALREADY_REJECTED]:
        'Cette opération a déjà été refusée.',
      [ERROR_CODES.ACTION_REQUEST_EXPIRED]: 'Cette opération a expiré.',
    },
  },
});

export default locales;
