import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Flex } from '@chakra-ui/react';

import { CardPaymentStrongAuthenticationRequestStatus } from '__generated__/GQL';
import ShineLogo from 'assets/shine-logo.svg';
import Modal from 'components/_core/Modal';
import DecorativeImage from 'components/DecorativeImage';

import MastercardLogo from './assets/mastercard-logo.svg';
import useCardPaymentStrongAuthenticationRequest from './hooks/useCardPaymentStrongAuthenticationRequest';
import CardPaymentStrongAuthenticationRequestAccepted from './steps/CardPaymentStrongAuthenticationRequestAccepted';
import CardPaymentStrongAuthenticationRequestExpired from './steps/CardPaymentStrongAuthenticationRequestExpired';
import CardPaymentStrongAuthenticationRequestPending from './steps/CardPaymentStrongAuthenticationRequestPending';
import CardPaymentStrongAuthenticationRequestRefused from './steps/CardPaymentStrongAuthenticationRequestRefused';

// The modal can only be dismissed if the request has been accepted or if it has
// expired.
const DISMISSIBLE_STEPS = [
  CardPaymentStrongAuthenticationRequestStatus.Accepted,
  CardPaymentStrongAuthenticationRequestStatus.Expired,
  CardPaymentStrongAuthenticationRequestStatus.Refused,
];

const CardPaymentStrongAuthenticationRequests: FC = () => {
  const { loading, refetch, request } =
    useCardPaymentStrongAuthenticationRequest();

  const history = useHistory();

  const goToCards = () => {
    refetch();
    history.push('/cards');
  };

  if (!request || loading) {
    return null;
  }

  const { status } = request;
  const canBeDismissed = DISMISSIBLE_STEPS.includes(status);

  const stepForStatus: Record<
    CardPaymentStrongAuthenticationRequestStatus,
    ReactNode
  > = {
    [CardPaymentStrongAuthenticationRequestStatus.Accepted]: (
      <CardPaymentStrongAuthenticationRequestAccepted
        cardPaymentStrongAuthenticationRequest={request}
        onFinish={refetch}
      />
    ),
    [CardPaymentStrongAuthenticationRequestStatus.Refused]: (
      <CardPaymentStrongAuthenticationRequestRefused
        cardPaymentStrongAuthenticationRequest={request}
        navigateToCards={goToCards}
        onFinish={refetch}
      />
    ),
    [CardPaymentStrongAuthenticationRequestStatus.Expired]: (
      <CardPaymentStrongAuthenticationRequestExpired
        cardPaymentStrongAuthenticationRequest={request}
        onClose={refetch}
      />
    ),
    [CardPaymentStrongAuthenticationRequestStatus.Pending]: (
      <CardPaymentStrongAuthenticationRequestPending
        cardPaymentStrongAuthenticationRequest={request}
      />
    ),
  };

  return (
    <Modal isOpen={!!request} onClose={canBeDismissed ? refetch : undefined}>
      {canBeDismissed ? (
        <Modal.Header />
      ) : (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px="space-16"
          py="space-24"
        >
          <DecorativeImage heightPx={24} src={ShineLogo} />
          <DecorativeImage heightPx={24} src={MastercardLogo} />
        </Flex>
      )}

      {stepForStatus[status]}
    </Modal>
  );
};

export default CardPaymentStrongAuthenticationRequests;
