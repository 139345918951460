import { FC } from 'react';
import {
  Box,
  Circle,
  HStack,
  Image,
  SimpleGrid,
  SimpleGridProps,
  VStack,
} from '@chakra-ui/react';
import { IconName } from '@shinetools/sunshine-icons';

import Heading from 'components/_core/Heading';
import SunshineSquircleIcon from 'components/_core/SunshineSquircleIcon';
import Text from 'components/_core/Text';

import Illustration from './assets/person-with-computer.webp';
import SogeLogo from './assets/soge-logo.svg';
import locales from './locales';

const STEPS = [
  {
    icon: 'profile',
    label: locales.steps.identity,
  },
  {
    icon: 'edit',
    label: locales.steps.questions,
  },
  {
    icon: 'clock',
    label: locales.steps.verification,
  },
] satisfies Array<{
  icon: IconName;
  label: string;
}>;

const LegalBeneficiaryPresentation: FC<SimpleGridProps> = (props) => (
  <SimpleGrid
    columns={{
      base: 1,
      xl: 2,
    }}
    display={{
      base: 'none',
      md: 'grid',
    }}
    gap={{ base: 'space-16', xl: 'space-24' }}
    gridTemplateAreas={{
      base: `
        "title   "
        "steps   "
        "picture "
        "soge    "
      `,
      xl: `
        "title   steps   "
        "picture picture "
        "soge    soge    "
      `,
    }}
    gridTemplateRows={{
      base: 'auto auto 1fr auto',
      xl: '1fr 1fr auto',
    }}
    maxWidth="container.xl"
    padding={{ base: 'space-16', xl: 'space-24' }}
    paddingLeft="0"
    {...props}
  >
    <VStack
      alignItems="start"
      backgroundColor="grey.300"
      borderRadius="radius-16"
      gridArea="title"
      justifyContent="center"
      padding={{ base: 'space-16', lg: 'space-40' }}
    >
      <Circle
        backgroundColor="grey.800"
        color="white"
        fontSize="font-31"
        padding="space-8"
        paddingTop="space-10"
        size={50}
      >
        {STEPS.length}
      </Circle>
      <Heading size="lg" textAlign="start">
        {locales.title}
      </Heading>
    </VStack>

    <VStack
      align="stretch"
      backgroundColor="grey.300"
      borderRadius="radius-16"
      gridArea="steps"
      justify="center"
      padding={{ base: 'space-16', lg: 'space-40' }}
      spacing="space-16"
    >
      {STEPS.map((step) => (
        <HStack
          background="white"
          borderRadius="radius-16"
          key={step.label}
          padding="space-8"
          spacing="space-16"
        >
          <SunshineSquircleIcon name={step.icon} />

          <Text textAlign="start" variant="primary">
            {step.label}
          </Text>
        </HStack>
      ))}
    </VStack>

    <Box
      backgroundImage={`url(${Illustration})`}
      // 85% at the bottom allows to focus on the Shine cards
      backgroundPosition="center 85%"
      backgroundSize="cover"
      borderRadius="radius-16"
      gridArea="picture"
    />
    <HStack alignItems="center" gridArea="soge" spacing="space-8">
      <Image height={24} src={SogeLogo} width={24} />
      <Text fontWeight="weight-500" size="sm" variant="light">
        {locales.sogeInformation}
      </Text>
    </HStack>
  </SimpleGrid>
);

export default LegalBeneficiaryPresentation;
