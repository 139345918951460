import { IntercomMethod } from 'react-use-intercom';

const IntercomAPI = (method: IntercomMethod, ...args: []) => {
  if (!window.Intercom) {
    throw new Error('Intercom instance is not initialized yet');
  }

  return window.Intercom.apply(null, [method, ...args]);
};

export default IntercomAPI;
