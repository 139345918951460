import { AuthenticationDeviceRequestsQuery } from '../../hooks/queries/authenticationDeviceRequests.gql';

export type AuthenticationDeviceRequest =
  AuthenticationDeviceRequestsQuery['viewer']['authenticationDeviceRequests']['edges'][number]['node'];

export enum ModalStep {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Refused = 'Refused',
}

export interface Props {
  request: AuthenticationDeviceRequest;
}
