import { Flex, Image } from '@chakra-ui/react';

import { OutstandingAgreementType } from '__generated__/GQL';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineIcon from 'components/_core/SunshineIcon';
import Text from 'components/_core/Text';
import OBNavigation from 'features/Onboarding/components/OBNavigation/OBNavigation';

import Notepad from './assets/Notepad@2x.png';
import locales from './locales';

interface ReviewPageProps {
  agreementType: OutstandingAgreementType.Cgu;
  onNext: () => void;
}

/**
 * A page where the user can see they already accepted the agreement
 */
const ReviewPage = asBentoPage<ReviewPageProps>(
  ({ agreementType, Layout, onNext, onPrev }) => (
    <Layout nav={<OBNavigation onPrev={onPrev} />}>
      <Image
        alt="empty-drawer"
        boxSize="148px"
        marginBottom="space-24"
        src={Notepad}
      />

      <Heading marginBottom="space-32" size="lg">
        {locales.title[agreementType]}
      </Heading>

      <Flex alignItems="center" gap="space-16" marginBottom="space-40">
        <SunshineIcon color="grey.800" name="tick" />

        <Text fontWeight="weight-500">{locales.check[agreementType]}</Text>
      </Flex>

      <Button isExpanded onClick={onNext}>
        {locales.cta}
      </Button>
    </Layout>
  ),
);

export default ReviewPage;
