import { createContext, FC, ReactElement, useContext } from 'react';
import {
  CheckboxProps,
  useCheckbox as useChakraCheckbox,
  UseCheckboxProps,
  UseCheckboxReturn,
} from '@chakra-ui/react';

const CheckboxPropsContext = createContext<UseCheckboxReturn | null>(null);

interface CheckboxProviderProps {
  children: ReactElement;
  value: UseCheckboxProps & CheckboxProps;
}

export const CheckboxProvider: FC<CheckboxProviderProps> = ({
  children,
  value,
}) => {
  const checkbox = useChakraCheckbox(value);

  return (
    <CheckboxPropsContext.Provider value={checkbox}>
      {children}
    </CheckboxPropsContext.Provider>
  );
};

export function useCheckbox() {
  const context = useContext(CheckboxPropsContext);

  if (!context) {
    throw new Error(
      'useCheckbox can only be used inside of a CheckboxProvider',
    );
  }

  return context;
}
