import { createMachine } from 'xstate';

import shared from 'common/bento/shared';
import { AnyMachineEvent } from 'common/bento/types/Abstract';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';

import model, { Context } from './model';
import services, { Service } from './services';

export enum State {
  DOCUMENTS = 'documents',
  DOCUMENTS_SEND = 'documents-send',

  DONE = 'done',
  PREV = 'prev',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'identity',
    initial: State.DOCUMENTS,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.DOCUMENTS]: {
        entry: shared.actions.trackView(),

        on: {
          PREV: State.PREV,
          SUCCESS: {
            target: State.DOCUMENTS_SEND,
          },
        },
      },

      [State.DOCUMENTS_SEND]: {
        invoke: {
          id: 'createKycDocumentsFromProvider',
          onDone: {
            actions: shared.actions.track({ name: 'ID_Taken_With_Onfido' }),
            target: State.DONE,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.DOCUMENTS,
          },
          src: Service.CreateKycDocumentsFromProvider,
        },
      },

      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },

      [State.PREV]: {
        data: {
          status: BentoModuleDoneStatus.ABORT,
        },
        type: 'final',
      },
    },
  },
  {
    services,
  },
);

export default machine;
