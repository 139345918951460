import countriesList from './countriesList';
import { GetCountryByCode } from './types';

const getCountryByCode: GetCountryByCode = ({
  countryCode,
  translationLanguage,
}) => {
  const country = countriesList[countryCode];
  if (country) {
    const { callingCode, emoji, flag, name } = country;
    return {
      callingCode,
      emoji,
      flag,
      label: name[translationLanguage],
      value: countryCode,
    };
  }
  return null;
};

export default getCountryByCode;
