/* eslint-disable react/no-unused-prop-types, @typescript-eslint/no-explicit-any */

import { FC, useCallback } from 'react';
import { ERROR_CODES } from '@shinetools/errors';

import errorHandlerLocales from 'common/hooks/useErrorHandledMutation/locales';
import { OnPasscodeSubmit } from 'components/PasscodeInput/types';
import useToast from 'components/ToastProvider/useToast';
import { useUser } from 'helpers/auth';
import { confirmActionRequest } from 'helpers/auth/service';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import locales from '../locales';
import { ActionRequestType } from '../types';
import ActionRequestPasscode from './component';

interface ActionRequestPasscodeContainerProps {
  actionRequest: ActionRequestType;
  setStep: (step: string) => void;
  close: () => void;
}

const ActionRequestPasscodeContainer: FC<
  ActionRequestPasscodeContainerProps
> = ({ actionRequest, setStep }) => {
  const user = useUser();
  const device = useDevice();
  const { hideToast, showToast } = useToast();
  const onPasscodeSubmit = useCallback<OnPasscodeSubmit>(
    async (passcode, clearAllDigits) => {
      try {
        hideToast();
        await confirmActionRequest({
          accessToken: user.token,
          authenticationActionRequestId:
            actionRequest.authenticationActionRequestId,
          deviceToken: device.token,
          passcode,
          phone: user.phone,
        });
        setStep('confirmation');
      } catch (error: any) {
        const errorLocale = locales.errors[error.code];
        if (errorLocale) {
          showToast({ message: errorLocale, type: 'error' });
        } else if (error.status === 429) {
          showToast({
            message: errorHandlerLocales.rateLimitError,
            type: 'error',
          });
        } else if (error.code === ERROR_CODES.AUTHENTICATION_PASSCODE_WRONG) {
          showToast({
            message: errorHandlerLocales.wrongPasscode,
            type: 'error',
          });
        } else {
          logger.error(error);
          showToast({
            message: errorHandlerLocales.unrecognizedErrorMessage,
            type: 'error',
          });
        }

        clearAllDigits({ andFocusFirstInput: true });
      }
    },
    [actionRequest, device, hideToast, showToast, user, setStep],
  );
  const goBack = useCallback(() => {
    setStep('');
  }, [setStep]);
  return (
    <ActionRequestPasscode
      actionRequest={actionRequest}
      goBack={goBack}
      onPasscodeSubmit={onPasscodeSubmit}
    />
  );
};

export default ActionRequestPasscodeContainer;
