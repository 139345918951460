import { ChakraProvider as BaseChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import IconsTTF from '@shinetools/suntheme/src/assets/fonts/Icons.ttf';

import MabryProMediumWoff from 'assets/fonts/MabryPro-Medium.woff';
import MabryProMediumWoff2 from 'assets/fonts/MabryPro-Medium.woff2';
import MabryProRegularWoff from 'assets/fonts/MabryPro-Regular.woff';
import MabryProRegularWoff2 from 'assets/fonts/MabryPro-Regular.woff2';
import ValueSerifProMediumWoff from 'assets/fonts/ValueSerifPro-Medium.woff';
import ValueSerifProMediumWoff2 from 'assets/fonts/ValueSerifPro-Medium.woff2';
import ValueSerifProRegularWoff from 'assets/fonts/ValueSerifPro-Regular.woff';
import ValueSerifProRegularWoff2 from 'assets/fonts/ValueSerifPro-Regular.woff2';
import { FCWithChildren } from 'common/types';
import theme from 'theme';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Icons';
        src: url('${IconsTTF}') format('truetype') /* Safari, Android, iOS */
      }

      @font-face {
        font-family: 'Mabry Pro';
        src: url('${MabryProMediumWoff2}') format('woff2'),
          url('${MabryProMediumWoff}') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mabry Pro';
        src: url('${MabryProRegularWoff2}') format('woff2'),
          url(${MabryProRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Value Serif Pro';
        src: url('${ValueSerifProMediumWoff2}') format('woff2'),
          url('${ValueSerifProMediumWoff}') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Value Serif Pro';
        src: url('${ValueSerifProRegularWoff2}') format('woff2'),
          url('${ValueSerifProRegularWoff}') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
);

const ChakraProvider: FCWithChildren = ({ children }) => (
  <BaseChakraProvider theme={theme}>
    <Fonts />
    {children}
  </BaseChakraProvider>
);

export default ChakraProvider;
