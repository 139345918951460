import { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import Illustration from 'features/TeamOnboarding/components/OnboardingContainer/components/Illustration';
import Content from 'features/TeamOnboarding/Onboarding/components/Content';

interface OnboardingContainerContentProps {
  content?: string;
  title?: string;
  illustration: string;
}

const OnboardingContainerContent: FC<OnboardingContainerContentProps> = ({
  content,
  illustration,
  title,
}) => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      flex={1}
      height="auto"
      marginBottom="space-40"
      textAlign="center"
    >
      <Illustration src={illustration} />
      <Content content={content} title={title} />
    </Flex>
  );
};

export default OnboardingContainerContent;
