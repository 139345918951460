import { ReactNode } from 'react';
import { Center, Image, Stack } from '@chakra-ui/react';

import { FCWithChildren } from 'common/types';
import Card from 'components/_core/Card';
import Heading from 'components/_core/Heading';

interface CardContainerProps {
  cta: ReactNode;
  headerImg: string;
  title: string;
}

const CardContainer: FCWithChildren<CardContainerProps> = ({
  children,
  cta,
  headerImg,
  title,
}) => (
  <Card marginTop="7.5rem" maxWidth="25rem" size="lg">
    <Stack alignItems="center" spacing="space-32">
      <Center boxSize="11.25rem">
        <Image alt="" role="presentation" src={headerImg} />
      </Center>

      <Stack spacing="space-16" textAlign="center">
        <Heading size="lg">{title}</Heading>
        {children}
      </Stack>

      {cta}
    </Stack>
  </Card>
);

export default CardContainer;
