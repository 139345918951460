import { createMachine } from 'xstate';

import shared from 'common/bento/shared';
import { AnyMachineEvent } from 'features/Bento/types/Abstract';

import locales from '../../../locales';
import guards, { Guard } from './guards';
import model, { Context } from './model';
import services, { Service } from './services';

export enum State {
  INIT = 'init',
  WELCOME = 'intro',
  VERIFICATION = 'verification',
  AGREEMENT = 'agreement',
  SEND_APPLICATION = 'send-application',
  UNDER_REVIEW = 'under-review',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'beneficiary',

    initial: State.INIT,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.INIT]: {
        always: [
          {
            cond: Guard.IsApplicationSent,
            target: State.UNDER_REVIEW,
          },
          {
            cond: Guard.HasStartedApplication,
            target: State.VERIFICATION,
          },
          { target: State.WELCOME },
        ],
      },

      [State.WELCOME]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.VERIFICATION,
          PREV: undefined,
        },
      },

      [State.VERIFICATION]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.AGREEMENT,
          PREV: State.WELCOME,
        },
      },

      [State.AGREEMENT]: {
        always: {
          cond: Guard.HasSignedCguAgreement,
          target: State.SEND_APPLICATION,
        },

        on: {
          NEXT: State.SEND_APPLICATION,
          PREV: State.VERIFICATION,
        },
      },

      [State.SEND_APPLICATION]: {
        invoke: {
          id: 'submitApplication',
          onDone: {
            actions: [
              shared.actions.track({
                name: 'submitApplication',
                properties: {
                  isCapitalDeposit: false,
                  isPrimaryApplicant: false,
                  isShineStart: false,
                  proofOfAddressSentByEmail: false,
                },
              }),
            ],
            target: State.UNDER_REVIEW,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.VERIFICATION,
          },

          src: Service.CreateReview,
        },
      },

      [State.UNDER_REVIEW]: {
        entry: shared.actions.trackView(),
        on: {
          PREV: {
            actions: shared.actions.toast({
              status: 'error',
              title: locales.toast.definitiveStep,
            }),
          },
        },
      },
    },
  },

  {
    actions: {},
    guards,
    services,
  },
);

export default machine;
