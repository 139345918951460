import { FC } from 'react';

import Text from 'components/_core/Text';

import GuidanceBaseHelp from './Base/GuidanceBaseHelp';
import locales from './locales';

/**
 * Help-like guidance to explain what a micro-entreprise is
 */
const GuidanceShineStartMicro: FC = () => {
  const loc = locales.shineStartMicro;

  return (
    <GuidanceBaseHelp title={loc.title}>
      <Text color="inherit" fontSize="inherit">
        {loc.content}
      </Text>
    </GuidanceBaseHelp>
  );
};

export default GuidanceShineStartMicro;
