import { FC, useState } from 'react';
import { Image } from '@chakra-ui/react';

import fadedFlowerImg from 'assets/brand/faded-flower@2x.png';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import logger from 'helpers/logger';

import locales from './locales';

interface Props {
  retry?: () => void;
}

const ErrorCard: FC<Props> = ({ retry }) => {
  const [isRefetching, setIsRefetching] = useState(false);

  const refetch = async () => {
    try {
      setIsRefetching(true);
      retry?.();
    } catch (err) {
      logger.error(err);
    } finally {
      setIsRefetching(false);
    }
  };

  return (
    <SunshineCard.Group maxWidth="31.25rem">
      <SunshineCard alignItems="center" textAlign="center">
        <Image
          alt={locales.title}
          marginBottom="space-16"
          marginX="auto"
          src={fadedFlowerImg}
          width="180px"
        />

        <Heading marginBottom="space-16" size="lg">
          {locales.title}
        </Heading>

        <Text paddingX="10%">{locales.description}</Text>
      </SunshineCard>

      {retry ? (
        <SunshineCard>
          <SunshineCard.Content alignItems="center">
            <Button isExpanded isLoading={isRefetching} onClick={refetch}>
              {locales.retryButtonText}
            </Button>
          </SunshineCard.Content>
        </SunshineCard>
      ) : null}
    </SunshineCard.Group>
  );
};

export default ErrorCard;
