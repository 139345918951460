import { Children, FC } from 'react';
import { ModalFooter, ModalFooterProps } from '@chakra-ui/react';

const Footer: FC<ModalFooterProps> = ({ children, ...props }) => {
  const childrenCount = Children.count(children);

  return (
    <ModalFooter
      // if there's a single button, align it to the right
      // otherwise fallback to "space-between" as done in the theme
      {...(childrenCount === 1 && {
        justifyContent: 'flex-end',
      })}
      {...props}
    >
      {children}
    </ModalFooter>
  );
};

export default Footer;
