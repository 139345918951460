import { ComponentProps } from 'react';
import { ChakraComponent } from '@chakra-ui/react';

import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import Card from 'components/_core/Card';

import * as Subcomponents from './subcomponents';

type OBCardProps = ComponentProps<typeof Card>;

/**
 * A card with an optional illustration, a body and a footer.
 */
const OBCard: typeof Subcomponents & ChakraComponent<'div', OBCardProps> = ({
  children,
  ...props
}) => {
  const isLarge = useLayoutSizeQuery(BentoLayoutSize.md);

  return (
    <Card
      {...(!isLarge && {
        borderWidth: 0,
      })}
      padding="space-0"
      {...props}
    >
      {children}
    </Card>
  );
};

OBCard.Illustration = Subcomponents.Illustration;
OBCard.Body = Subcomponents.Body;
OBCard.Footer = Subcomponents.Footer;
OBCard.Carousel = Subcomponents.Carousel;

export default OBCard;
