import { validate as uuidValidate } from 'uuid';

import config from 'config';
import { get } from 'helpers/fetch';

import { OAuthClient } from './types';

const { shineApiHost } = config;

class InvalidUuidError extends Error {
  constructor(message = 'Invalid UUID') {
    super(message);
  }
}

const getHeader = (accessToken: string): string => `Bearer ${accessToken}`;

export const getClient = async (
  clientId: string,
  accessToken: string,
): Promise<OAuthClient> => {
  if (!uuidValidate(clientId)) {
    throw new InvalidUuidError();
  }

  return get<OAuthClient>(
    `${shineApiHost}/authentication/oauth2/client/${clientId}`,
    undefined,
    {
      headers: {
        Authorization: getHeader(accessToken),
      },
    },
  );
};

// OAuth2 grant
interface GrantResponse {
  redirectTo: string;
}
interface GrantInput {
  clientId: string;
  scope: string;
  redirectUri: string;
  state?: string;
  accessToken: string;
  companyProfileId: string;
}
export const grant = async ({
  accessToken,
  clientId,
  companyProfileId,
  redirectUri,
  scope,
  state,
}: GrantInput): Promise<void> => {
  return get<GrantResponse>(
    `${shineApiHost}/authentication/oauth2/grant`,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: clientId,

      companyProfileId,

      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: redirectUri,
      scope,
      state,
    },
    {
      headers: {
        Authorization: getHeader(accessToken),
      },
    },
  ).then(({ redirectTo }): void => {
    window.location.href = redirectTo;
  });
};
