import { FC } from 'react';
import { chakra, HTMLChakraProps } from '@chakra-ui/react';

import LottieAnimation from 'components/LottieAnimation';

interface SunshineLoaderProps extends HTMLChakraProps<'div'> {
  width?: number;
}

const SunshineLoader: FC<SunshineLoaderProps> = ({ width = 100, ...props }) => (
  <chakra.div {...props} data-testid="shine-loader">
    <LottieAnimation animation="loader" width={width} />
  </chakra.div>
);

export default SunshineLoader;
