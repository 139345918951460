import {
  AccordionButton,
  AccordionButtonProps,
  chakra,
  ComponentWithAs,
} from '@chakra-ui/react';

import SunshineIcon from 'components/_core/SunshineIcon';

import { useStyles } from '../../../../context';

export interface ButtonProps extends AccordionButtonProps {
  isExpanded?: boolean;
}

const Button: ComponentWithAs<'button', ButtonProps> = ({
  children,
  isExpanded = false,
  ...props
}) => {
  const styles = useStyles();

  return (
    <AccordionButton {...props}>
      <chakra.svg
        __css={styles.iconLeft}
        as={SunshineIcon}
        name="quotation-mark"
        size="icon-16"
      />
      <span>{children}</span>
      <chakra.svg
        __css={styles.iconRight}
        as={SunshineIcon}
        name="chevron-down"
        size="icon-24"
        transform={`rotate(${isExpanded ? -0.5 : 0}turn)`}
      />
    </AccordionButton>
  );
};

export default Button;
