import { useContext, useState } from 'react';

import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import {
  GuidanceStatutesDefinition,
  GuidanceStatutesHowTo,
} from '../../components/Guidance';
import GuidanceHome from '../../components/Guidance/GuidanceHome';
import { model } from '../../machine';
import { Signup } from '../../machine/machine';
import { SignupPage } from '../../types/SignupPage';
import locales from './locales';

type Option = 'yes' | 'no';

/**
 * A page asking the user if they have already redacted their statutes
 */
const CapitalDepositSelectStatutes: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);
  const [hasStatutes, setHasStatutes] = useState<Option | undefined>(undefined);

  const renderGuidance = () => {
    if (hasStatutes === undefined) {
      return <GuidanceStatutesDefinition />;
    }

    return {
      no: <GuidanceStatutesHowTo />,
      yes: <GuidanceHome />,
    }[hasStatutes];
  };

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      footer={
        <Button
          isDisabled={hasStatutes === undefined}
          onClick={() => {
            if (hasStatutes !== undefined) {
              send(model.events.SUBMIT_HAS_STATUTES(hasStatutes === 'yes'));
            }
          }}
          type="submit"
          width="100%"
        >
          {locales.validate}
        </Button>
      }
      guidance={renderGuidance()}
    >
      <PageLayout.Header marginBottom="space-24">
        <Heading size="lg">{locales.title}</Heading>
      </PageLayout.Header>

      <SunshineCard.RadioGroup
        marginBottom="space-24"
        onChange={(nextValue) => setHasStatutes(nextValue as Option)}
        value={hasStatutes}
      >
        <SunshineCard.Group>
          <SunshineCard title={locales.yes} value="yes" variant="radio" />

          <SunshineCard title={locales.no} value="no" variant="radio" />
        </SunshineCard.Group>
      </SunshineCard.RadioGroup>
    </Layout>
  );
};

export default CapitalDepositSelectStatutes;
