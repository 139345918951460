import { Link } from 'react-router-dom';
import { Color, COLORS } from '@shinetools/suntheme';
import styled from 'styled-components';

const isThemeColor = (color: string): color is Color =>
  (COLORS as string[]).includes(color);

export const CardImageContainer = styled.div<{
  brandColor: string;
  $rebrandedColor?: string;
}>`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${({ $rebrandedColor, brandColor, theme }) => {
    if ($rebrandedColor) {
      return $rebrandedColor;
    }
    return isThemeColor(brandColor)
      ? theme.colors[brandColor](4)
      : 'transparent';
  }};
  height: 146px;
  overflow: hidden;
`;

export const UpgradeLink = styled(Link)`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacings.abs8}px;
  padding-right: ${({ theme }) => theme.spacings.abs8}px;
  margin-top: 20px;
`;
