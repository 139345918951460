import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

import SunshineIcon from 'components/_core/SunshineIcon';
import Text from 'components/_core/Text';

interface PlanDetailsBulletPointProps extends FlexProps {
  description?: string;
  title: string;
}

const PlanDetailsBulletPoint: FC<PlanDetailsBulletPointProps> = ({
  description,
  title,
  ...props
}) => (
  <Flex align="baseline" marginTop="space-16" {...props}>
    <Flex flex="none" width={32}>
      <SunshineIcon
        color="text-primary"
        name="tick"
        position="relative"
        top="space-2"
      />
    </Flex>

    <Flex align="baseline" direction="column">
      <Text fontWeight="weight-500" variant="primary">
        {title}
      </Text>

      {description ? (
        <Text marginTop="space-4" size="sm" variant="light">
          {description}
        </Text>
      ) : null}
    </Flex>
  </Flex>
);

export default PlanDetailsBulletPoint;
