import { FC, memo, useCallback, useState } from 'react';
import ReactSelect, { components, SingleValueProps } from 'react-select';
import { isNil } from 'ramda';

import Text from 'components/_core/Text';
import logger from 'helpers/logger';
import { useLegacyTheme } from 'theme/legacyTheme';

import getOverriddenStyles from './getOverriddenStyles';
import { OptionType, SelectProps } from './types';

// TODO(alex): Find a way to reduce code duplication from components/Select

const RemovedIndicatorSeparator = () => null;

// eslint-disable-next-line @typescript-eslint/ban-types
const SingleValue = <ValueType extends {}>({
  children,
  isDisabled,
  ...props
}: SingleValueProps<OptionType<ValueType>>) => (
  <components.SingleValue isDisabled={isDisabled} {...props}>
    <Text variant={isDisabled ? 'disabled' : 'primary'}>{children}</Text>
  </components.SingleValue>
);

// eslint-disable-next-line @typescript-eslint/ban-types
const getSelectedOption = <ValueType extends {}>(
  options: Readonly<OptionType<ValueType>[]>,
  value: ValueType | null,
): OptionType<ValueType> | null => {
  if (value === null) {
    return null;
  }
  const foundOption = options.find((option): boolean => option.value === value);
  if (foundOption === undefined) {
    logger.warn(
      'An invalid value was provided for the Select component:',
      value,
    );
    return null;
  }
  return foundOption;
};

const Select: FC<SelectProps<string>> = ({
  isSearchable = false,
  label,
  onChange,
  options,
  placeholder = '',
  value,
}) => {
  // TODO(alex): Find a better solution to fix conflict between select and datepicker
  const [isOpenHack, setIsOpenHack] = useState(false);
  const theme = useLegacyTheme();
  const selectedOption = getSelectedOption(options, value);

  const onChangeHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (newValue: { value: any } | null): void => {
      setIsOpenHack(false);

      if (onChange === undefined) {
        return;
      }

      if (isNil(newValue)) {
        onChange(null);
      } else if ('value' in newValue) {
        // Check that this is a single OptionType (and therefore has a 'value' property)
        onChange(newValue.value);
      } else {
        throw new Error('unexpected value');
      }
    },
    [onChange],
  );

  const hasPlaceholder = placeholder !== '' && !isNil(placeholder);

  return (
    <ReactSelect
      components={{
        IndicatorSeparator: RemovedIndicatorSeparator,
        SingleValue,
      }}
      isSearchable={isSearchable}
      menuIsOpen={isOpenHack}
      onChange={onChangeHandler}
      onMenuOpen={() => {
        setIsOpenHack(true);
      }}
      options={options}
      placeholder={hasPlaceholder ? placeholder : ''}
      styles={getOverriddenStyles(label, theme)}
      value={selectedOption}
    />
  );
};

export default memo(Select);
