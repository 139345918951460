import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    excludingVAT: 'excl. tax',
    includingVAT: 'incl. tax',
    monthly: '/month',
  },
  fr: {
    excludingVAT: 'HT',
    includingVAT: 'TTC',
    monthly: ' / mois',
  },
});
