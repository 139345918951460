/* eslint-disable @typescript-eslint/no-explicit-any */
import { ERROR_CODES } from '@shinetools/errors';

import { Gender } from 'common/types';
import { OBError } from 'features/Bento/libs/errors/OBError';
import { OBErrorCode } from 'features/Bento/libs/errors/OBErrorCode';
import { finishRegister } from 'helpers/auth/service';
import { Tokens } from 'helpers/auth/types';
import { Device } from 'helpers/device';

import { AffiliationContext, CompanyProfileType } from '../../../machine/model';
import { AccountType } from '../machine/model';

interface Input {
  phoneNumber: string;
  passcode: string;
  phoneNumberOwnershipToken: string;
  email: string;
  signupAccountType: AccountType;
  signupCompanyProfileType: CompanyProfileType | null;
  gender: Gender;
  firstName: string;
  lastName: string;
  hasAgreedToTermsOfService: boolean;
  device: Device;
  affiliationContext?: AffiliationContext;
}

/**
 * Same function as finishRegister, throwing Bento errors when it fails
 */
export const enhancedEndRegister = async ({
  affiliationContext,
  device,
  email,
  firstName,
  gender,
  hasAgreedToTermsOfService,
  lastName,
  passcode,
  phoneNumber,
  phoneNumberOwnershipToken,
  signupAccountType,
  signupCompanyProfileType,
}: Input): Promise<Tokens> => {
  try {
    const { token: deviceToken, ...deviceData } = device;
    const tokens = await finishRegister({
      affiliationContext,
      deviceToken,
      email,
      firstName,
      gender,
      hasAgreedToTermsOfService,
      lastName,
      passcode,
      phone: phoneNumber,
      phoneNumberOwnershipToken,
      signupAccountType,
      signupCompanyProfileType: signupCompanyProfileType ?? undefined,
      ...deviceData,
    });

    return tokens;
  } catch (error: any) {
    // @todo display this error in the page instead of a toast (cf Figma) ?
    if (error.code === ERROR_CODES.AUTHENTICATION_PASSWORDLESS_CODE_NOT_MATCH) {
      throw new OBError(OBErrorCode.CODE_NOT_MATCH, { shouldReport: false });
    }

    if (error.code === ERROR_CODES.AUTHENTICATION_PASSWORDLESS_CODE_EXPIRED) {
      throw new OBError(OBErrorCode.CODE_EXPIRED, { shouldReport: false });
    }

    // @todo we can't access status in the error object :/
    if (error.error?.nextValidRequestDate) {
      throw new OBError(OBErrorCode.RATE_LIMIT, { shouldReport: false });
    }

    throw Error(OBErrorCode.NETWORK);
  }
};
