import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CompanyUserInvitationQueryVariables = Types.Exact<{
  companyProfileId: Types.Scalars['ID']['input'];
}>;


export type CompanyUserInvitationQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, companyUser: { __typename: 'CompanyUser', companyUserId: string, firstName: string | null, invitation: { __typename: 'CompanyInvitation', companyInvitationId: string, role: Types.CompanyInvitationRole } | null }, company: { __typename: 'Company', companyProfileId: string, profile: { __typename: 'CompanyProfile', companyProfileId: string, legalName: string } } } };


export const CompanyUserInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"companyUserInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"companyUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyUserId"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"invitation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyInvitationId"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"legalName"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CompanyUserInvitationQuery, CompanyUserInvitationQueryVariables>;