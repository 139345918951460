import { FC } from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Stack,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import Group, { GroupProps } from './components/Group';
import Icon from './components/Icon';
import Label from './components/Label';
import Title from './components/Title';
import { COMPONENT_NAME, StylesProvider } from './context';
import { CheckboxProvider } from './hooks/useCheckbox';
import { useCheckboxGroup } from './hooks/useCheckboxGroup';

export type CheckboxPositions = 'left' | 'right';

interface CheckboxProps extends ChakraCheckboxProps {
  checkboxPosition?: CheckboxPositions;
}
interface CheckboxParts {
  Group: typeof Group;
  Label: typeof Label;
  Title: typeof Title;
}

type CheckboxType = CheckboxParts & FC<CheckboxProps>;

const Checkbox: CheckboxType = ({ checkboxPosition, children, ...props }) => {
  const group = useCheckboxGroup({ checkboxPosition, ...props });
  const checkbox = group.getCheckboxProps({ value: props.value });

  const styles = useMultiStyleConfig(COMPONENT_NAME, {});

  return (
    <StylesProvider value={styles}>
      <CheckboxProvider value={{ ...checkbox, ...props }}>
        <ChakraCheckbox
          data-testid="checkbox"
          flexDirection={
            group.checkboxPosition === 'right' ? 'row-reverse' : 'row'
          }
          icon={<Icon />}
          {...checkbox}
          {...props}
        >
          <Stack spacing="space-4">{children}</Stack>
        </ChakraCheckbox>
      </CheckboxProvider>
    </StylesProvider>
  );
};

Checkbox.Group = Group;
Checkbox.Label = Label;
Checkbox.Title = Title;

export type { GroupProps as CheckboxGroupProps };
export type { CheckboxProps };
export default Checkbox;
