import { FC } from 'react';
import { Box, BoxProps, Image } from '@chakra-ui/react';

/**
 * The header of the Layout.
 */
interface Props {
  illustrationSrc?: string;
}

/**
 * A common header for the Layout, with an optional illustration.
 */
const PageLayoutHeader: FC<BoxProps & Props> = ({
  children,
  illustrationSrc,
  ...props
}) => (
  <Box marginBottom="space-24" {...props}>
    {illustrationSrc ? (
      <Image boxSize="90px" marginBottom="space-24" src={illustrationSrc} />
    ) : null}

    {children}
  </Box>
);

export default PageLayoutHeader;
