import {
  OutstandingAgreement,
  OutstandingAgreementStatus,
  OutstandingAgreementType,
} from '__generated__/GQL';

interface GetIsAgreementCompletedProps {
  outstandingAgreements: Pick<OutstandingAgreement, 'type' | 'status'>[];
  agreementType: OutstandingAgreementType;
}

const getIsAgreementCompleted = ({
  agreementType,
  outstandingAgreements,
}: GetIsAgreementCompletedProps) => {
  const agreement = outstandingAgreements.find(
    ({ type }) => type === agreementType,
  );

  /**
   * The only case where we don't find an agreement is if the user signed it already.
   */
  if (!agreement) {
    return true;
  }

  return agreement.status === OutstandingAgreementStatus.Complete;
};

export default getIsAgreementCompleted;
