/**
 * https://www.figma.com/file/GXtg4B5Uy32yVEBVUJahQF/%F0%9F%90%A5-Design-System?type=design&node-id=9314-51181&mode=design&t=DqY4jHoAfJl8zEb2-0
 */

import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  _focus: {
    boxShadow: 'outline',
  },
  _hover: {
    textDecoration: 'none',
  },
  color: 'inherit',
  cursor: 'pointer',
  display: 'inline-flex',
  fontWeight: 'weight-500',
  outline: 'none',
  textDecoration: 'underline',
  transitionDuration: 'duration-100',
  transitionProperty: 'common',
  transitionTimingFunction: 'ease-out',
};

const sizes = {
  md: {
    fontSize: { base: 'font-16', md: 'font-15' },
  },
  sm: {
    fontSize: { base: 'font-15', md: 'font-13' },
  },
};

const inlineVariant: SystemStyleObject = {
  _hover: {
    color: 'blue.500',
  },
  color: 'blue.600',
};

enum Variant {
  Inline = 'inline',
}

const variants: Record<Variant, SystemStyleObject> = {
  [Variant.Inline]: inlineVariant,
};

const defaultProps = {
  size: 'md',
};

const LinkTheme = {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};

export default LinkTheme;
