import { Box, VStack } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import Loader from 'components/Loader';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';
import isEi from 'features/TeamOnboarding/Onboarding/lib/isEi';

import Header from '../../../../components/Header';
import getVerificationSteps from '../../lib/steps/getVerificationSteps';
import { VerificationStatus } from '../../lib/steps/types';
import { model } from '../../machine';
import { VerificationModuleData } from '../../machine/model';
import locales from './locales';

const getCtaLocale = (numberOfRemainingSteps: number): string => {
  if (numberOfRemainingSteps > 1) {
    return locales.formatString(locales.cta.reamainingSteps, {
      numberOfRemainingSteps,
    }) as string;
  }

  if (numberOfRemainingSteps === 1) {
    return locales.cta.reamainingStepsSingular;
  }

  return locales.cta.send;
};

interface HomeProps {
  moduleData: VerificationModuleData;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  send: Function;
}
/**
 * A non-interactive page welcoming an invited beneficiary.
 */
const Home = asBentoPage<HomeProps>(
  ({ isLoading, Layout, moduleData, send }) => {
    const { legalForm, legalName } =
      moduleData.verificationData.viewer.company.profile;

    const steps = getVerificationSteps(moduleData);

    const hasCurrentUserError = steps.some(
      ({ status }) => status === VerificationStatus.Invalid,
    );

    const numberOfRemainingSteps = steps.filter(
      ({ status }) =>
        status !== VerificationStatus.Completed &&
        status !== VerificationStatus.Validated,
    ).length;

    return (
      <Layout data-testid="verification-home" nav={<Navigation />}>
        <Box paddingBottom="space-64">
          <Header
            description={
              hasCurrentUserError
                ? locales.page.errorSubtitle
                : locales.page.subtitle
            }
            title={
              // The name of an individual company is the user's name, that's why we don't to include it in the title
              !legalName || isEi(legalForm)
                ? locales.page.title
                : locales.formatString(locales.page.titleWithLegalName, {
                    legalName,
                  })
            }
          />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <SunshineCard.Group isBoxed={false} marginBottom="space-40">
                {steps.map(({ errors, id, metadata, status }) => {
                  const isDisabled =
                    status === VerificationStatus.Validated ||
                    metadata.isDisabled;

                  const renderIcon = () => {
                    if (status === VerificationStatus.ToDo) {
                      return (
                        <SquircleIcon
                          colorScheme="grey"
                          name={metadata.iconName}
                        />
                      );
                    }

                    if (status === VerificationStatus.Invalid) {
                      return <SquircleIcon colorScheme="red" name="cross" />;
                    }

                    return <SquircleIcon colorScheme="green" name="tick" />;
                  };

                  return (
                    <SunshineCard
                      alignItems="center"
                      isDisabled={isDisabled}
                      key={id}
                      onClick={() => send(metadata.event)}
                      title={
                        status === VerificationStatus.ToDo
                          ? metadata.label.add
                          : metadata.label.review
                      }
                    >
                      <SunshineCard.Slot name="aside">
                        {renderIcon()}
                      </SunshineCard.Slot>

                      {status === VerificationStatus.Invalid &&
                      errors.length > 0 ? (
                        <SunshineCard.Content>
                          <VStack marginTop="space-12" spacing="space-16">
                            {errors.map((error) => (
                              <Callout key={error} variant="error">
                                <Callout.Content>{error}</Callout.Content>
                              </Callout>
                            ))}
                          </VStack>
                        </SunshineCard.Content>
                      ) : null}

                      {isDisabled ? null : (
                        <SunshineCard.Slot name="action">
                          <SunshineCard.SunshineIcon name="chevron-right" />
                        </SunshineCard.Slot>
                      )}
                    </SunshineCard>
                  );
                })}
              </SunshineCard.Group>

              <Button
                isDisabled={numberOfRemainingSteps > 0}
                isExpanded
                onClick={() => send(model.events.NEXT())}
              >
                {getCtaLocale(numberOfRemainingSteps)}
              </Button>
            </>
          )}
        </Box>
      </Layout>
    );
  },
);

export default Home;
