/* eslint-disable jsx-a11y/no-autofocus */

import { useContext, useState } from 'react';
import { Text } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import { PinInput } from 'features/Bento/atoms';
import { PageLayout } from 'features/Bento/components';
import { SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import { GuidancePasscode } from '../../../../components/Guidance';
import { model } from '../../machine';
import { Intro, State } from '../../machine/machine';
import locales from './locales';

/**
 * A page where we ask the user to confirm their passcode
 * @todo check with Matthew / Charly we still want a passcode confirmation
 */
const PasscodeConfirm: SignupPage<Intro> = ({ send, state }) => {
  const Layout = useContext(OBLayoutContext);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      data-testid="passcode-confirm"
      guidance={<GuidancePasscode />}
    >
      <Heading marginBottom="space-24" size="lg">
        {locales.title}
      </Heading>

      <Text marginBottom="space-8" size="sm">
        {locales.label}
      </Text>

      <PinInput
        autoFocus
        digits={4}
        isDisabled={isLoading}
        marginBottom="space-24"
        mask
        onComplete={(value) => {
          if (state.matches(State.PASSCODE_CONFIRM)) {
            setIsLoading(true);
            send(model.events.SUBMIT_PASSCODE(value));
          }
        }}
      />
    </Layout>
  );
};

export default PasscodeConfirm;
