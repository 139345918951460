import { selectAnatomy } from '@chakra-ui/anatomy';
import { PartsStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

import InputTheme from './Input';

export type Parts = typeof selectAnatomy;

export const baseStyleFieldCommon: SystemStyleObject = {
  '> option, > optgroup': {
    background: 'white',
  },
  appearance: 'none',
  background: 'white',
  lineHeight: 'normal',
  paddingBottom: '1px',
};

const baseStyleIcon: SystemStyleObject = {
  _disabled: {
    opacity: 0.5,
  },
  color: 'currentColor',
  fontSize: 'font-20',
  height: '100%',
  insetEnd: 'space-8',
  position: 'relative',
  width: '1.5rem',
};

const baseStyle: PartsStyleFunction<Parts> = (props) => ({
  field: {
    ...InputTheme.baseStyle(props).field,
    ...baseStyleFieldCommon,
  },
  icon: baseStyleIcon,
});

const SelectTheme = {
  baseStyle,
  parts: selectAnatomy.keys,
};

export default SelectTheme;
