/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8571%3A34657&t=Zo1x1tq5fDFhbo8L-1
 */

import { radioAnatomy } from '@chakra-ui/anatomy';
import { PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools';

import CheckboxTheme from './Checkbox';

export type Parts = typeof radioAnatomy;

export const baseStyleControlCommon: SystemStyleObject = {
  borderRadius: 'radius-full',
  boxSize: '20px',
  // needed to simulate a 24px box
  margin: 'space-2',
};

const baseStyleControl: SystemStyleObject = {
  ...CheckboxTheme.baseStyle.control,
  ...baseStyleControlCommon,

  _checked: {
    _disabled: {
      backgroundColor: 'radio-icon-disabled',
    },
    backgroundColor: 'radio-icon-active',
    borderColor: 'radio-border-active',
    boxShadow: `inset 0 0 0px 2px #fff`,
  },
};

const baseStyle: PartsStyleObject<Parts> = {
  container: CheckboxTheme.baseStyle.container,
  control: baseStyleControl,
  label: CheckboxTheme.baseStyle.label,
};

const RadioTheme = {
  baseStyle,
  parts: radioAnatomy.keys,
  variants: CheckboxTheme.variants,
};

export default RadioTheme;
