import { OBLayout } from 'features/Bento/types/OBLayout';

import LayoutLarge from './LayoutLarge';
import LayoutMedium from './LayoutMedium';
import LayoutSmall from './LayoutSmall';

const LAYOUTS: { [key: string]: OBLayout } = {
  base: LayoutSmall,
  lg: LayoutLarge,
  md: LayoutMedium,
};

/**
 * Returns one of the allowed layouts
 */
export const getLayout = (bp: string) => LAYOUTS[bp] || LAYOUTS.base;
