import { FC, useCallback, useRef } from 'react';

import {
  InternalFieldValidator,
  InternalFieldValidatorResponse,
} from './_internal/types';
import Component from './component';
import { FormProps } from './types';

type PossiblyAsyncValidatorResponse =
  | InternalFieldValidatorResponse
  | Promise<InternalFieldValidatorResponse>;

const getValidatorResponses = (
  validators: Set<InternalFieldValidator>,
): PossiblyAsyncValidatorResponse[] => {
  return [...validators].map((validator) => validator());
};

const areAllResponsesSynchronous = (
  responses: PossiblyAsyncValidatorResponse[],
): responses is InternalFieldValidatorResponse[] => {
  return responses.every(
    (response) =>
      'isValid' in response && typeof response.isValid === 'boolean',
  );
};

const areAllValidResponses = (
  responses: InternalFieldValidatorResponse[],
): boolean => {
  return responses.every(({ isValid }) => isValid);
};

const focusFirstInvalidResponse = (
  responses: InternalFieldValidatorResponse[],
): void => {
  const firstInvalidFocusableResponse = responses.find(
    ({ focusFieldFn, isValid }): boolean =>
      !isValid && focusFieldFn !== undefined,
  );
  if (firstInvalidFocusableResponse !== undefined) {
    const focusFieldFn =
      firstInvalidFocusableResponse.focusFieldFn as () => void;
    focusFieldFn();
  }
};

const Form: FC<FormProps> = ({ children, onValidSubmit, ...otherProps }) => {
  const fieldValidatorsRef = useRef(new Set<InternalFieldValidator>());
  const onSubmit = useCallback(async () => {
    const validatorResponses = getValidatorResponses(
      fieldValidatorsRef.current,
    );
    const handleResolvedResponses = (
      resolvedResponses: InternalFieldValidatorResponse[],
    ): void => {
      if (areAllValidResponses(resolvedResponses)) {
        onValidSubmit();
      } else {
        focusFirstInvalidResponse(resolvedResponses);
      }
    };
    if (areAllResponsesSynchronous(validatorResponses)) {
      handleResolvedResponses(validatorResponses);
    } else {
      Promise.all(validatorResponses).then((resolvedResponses) =>
        handleResolvedResponses(resolvedResponses),
      );
    }
  }, [onValidSubmit, fieldValidatorsRef]);

  const addFieldValidator = useCallback(
    (validationCallback: InternalFieldValidator) => {
      fieldValidatorsRef.current.add(validationCallback);
    },
    [fieldValidatorsRef],
  );
  const removeFieldValidator = useCallback(
    (validationCallback: InternalFieldValidator) => {
      fieldValidatorsRef.current.delete(validationCallback);
    },
    [fieldValidatorsRef],
  );
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };
  return (
    <Component
      formFieldRegistrationContext={{ addFieldValidator, removeFieldValidator }}
      onSubmitHandler={onSubmitHandler}
      {...otherProps}
    >
      {children}
    </Component>
  );
};

export default Form;
