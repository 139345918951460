import {
  forwardRef,
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';

import SunshineIcon, { IconName, SunshineIconProps } from '../SunshineIcon';

export interface IconButtonProps extends Omit<ChakraIconButtonProps, 'icon'> {
  icon: IconName;
  iconSize?: SunshineIconProps['size'];
}

const IconButton = forwardRef<IconButtonProps, 'button'>(
  ({ icon, iconSize, ...props }, ref) => (
    <ChakraIconButton
      // Remove borders for inline variants
      // TODO(REB-29): add IconButton theme after it's documented in Figma
      _after={{ display: 'none' }}
      icon={<SunshineIcon name={icon} size={iconSize} />}
      ref={ref}
      {...props}
    />
  ),
);

export default IconButton;
