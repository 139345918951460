import { useEffect, useState } from 'react';
import { OperationVariables, QueryOptions } from '@apollo/client';

import { publicApolloClient } from './clients';

export const usePublicQuery = <TData, TVariables = OperationVariables>({
  query,
  variables,
}: Pick<QueryOptions<TVariables, TData>, 'query' | 'variables'>) => {
  const [result, setResult] = useState<{
    isLoading: boolean;
    data?: TData;
    error?: Error;
  }>({
    isLoading: false,
  });

  useEffect(() => {
    setResult((state) => ({
      ...state,
      isLoading: true,
    }));

    publicApolloClient
      .query({
        query,
        ...(variables && { variables }),
      })
      .then((value) => {
        setResult({
          data: value.data,
          error: undefined,
          isLoading: false,
        });
      })
      .catch((error) => {
        setResult({
          data: undefined,
          error,
          isLoading: false,
        });
      });
  }, [query, variables]);

  return result;
};
