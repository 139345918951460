import { FC, useEffect } from 'react';
import { Redirect } from 'react-router';

import { authenticationEmitter, AuthenticationEvents } from 'helpers/auth';

const Logout: FC = () => {
  useEffect((): void => {
    authenticationEmitter.emit(AuthenticationEvents.OnLogout);
  }, []);
  return <Redirect to="/" />;
};

export default Logout;
