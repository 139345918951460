import { FC, ReactNode } from 'react';

import { Container, Wrapper } from './styles';

interface Props {
  children: ReactNode;
  footer: ReactNode;
}

const CenterCardLayout: FC<Props> = ({ children, footer }) => (
  <Wrapper>
    <Container>{children}</Container>
    {footer}
  </Wrapper>
);

export default CenterCardLayout;
