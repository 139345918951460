/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=9702%3A51788&t=PRHBc1JPmL8xkvkm-1
 */

import { SystemStyleObject } from '@chakra-ui/styled-system';
import { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { getColorValue } from '@shinetools/sunshine';

export type Variant = 'default' | 'active';

const disabledStyles: SystemStyleObject = {
  backgroundColor: 'chip-backgroundColor-disabled',
  color: 'chip-color-disabled',
  cursor: 'not-allowed',
};

const baseStyle: SystemStyleFunction = ({ icon, iconRight }) => ({
  _disabled: { ...disabledStyles },
  _focusVisible: {
    boxShadow: `0 0 0 2px white, 0 0 0 4px ${getColorValue('blue.600')}`,
    outline: 0,
  },
  _hover: {
    _disabled: { ...disabledStyles },
    backgroundColor: 'chip-backgroundColor-hover',
    color: 'chip-color-hover',
  },
  alignItems: 'center',
  border: '1px solid',
  borderRadius: 'radius-6',
  color: 'chip-color',
  display: 'inline-flex',
  fontSize: 'font-16',
  gap: 'space-8',
  height: '2.5rem', // 40px
  paddingLeft: icon ? 'space-12' : 'space-16',
  paddingRight: iconRight ? 'space-12' : 'space-16',
  transition: 'background-color 0.2s ease-out',
});

const variants: Record<Variant, SystemStyleObject> = {
  active: {
    backgroundColor: 'chip-backgroundColor-active',
    borderColor: 'chip-border-active',
    color: 'chip-color-active',
    fontWeight: 'weight-500',
  },
  default: {
    backgroundColor: 'chip-backgroundColor',
    borderColor: 'chip-border',
    color: 'chip-color',
  },
};

const ChipTheme = {
  baseStyle,
  variants,
};

export default ChipTheme;
