import { FC } from 'react';
import { Center, CenterProps, Divider } from '@chakra-ui/react';

import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';

/**
 * A footer designed for the bottom of the OBCard.
 */
const Footer: FC<CenterProps> = ({ children, ...props }) => {
  const isLarge = useLayoutSizeQuery(BentoLayoutSize.md);

  return (
    <>
      {isLarge ? <Divider /> : null}

      <Center
        padding={isLarge ? 'space-24' : 'space-8'}
        width="full"
        {...props}
      >
        {children}
      </Center>
    </>
  );
};

export default Footer;
