import { FC, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import SunshineModal from 'components/_core/SunshineModal';
import useToast from 'components/ToastProvider/useToast';

import AcceptedStep from './components/AcceptedStep';
import PendingStep from './components/PendingStep';
import RefusedStep from './components/RefusedStep';
import locales from './locales';
import { ModalStep, Props } from './types';

const AuthenticationDeviceRequest: FC<Props> = ({ request }) => {
  const [step, setStep] = useState<ModalStep>(ModalStep.Pending);
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const { showToast } = useToast();

  const onRequestAlreadyHandled = () => {
    showToast({
      message: locales.authenticationDeviceRequestAlreadyHandled,
      type: 'error',
    });
    onClose();
  };

  const onRequestExpired = () => {
    showToast({
      message: locales.authenticationDeviceRequestExpired,
      type: 'error',
    });
    onClose();
  };

  return (
    <SunshineModal isOpen={isOpen} onClose={onClose}>
      <SunshineModal.Header />

      {step === ModalStep.Pending ? (
        <PendingStep
          onRequestAlreadyHandled={onRequestAlreadyHandled}
          onRequestExpired={onRequestExpired}
          request={request}
          setStep={setStep}
        />
      ) : null}
      {step === ModalStep.Refused ? <RefusedStep onClose={onClose} /> : null}
      {step === ModalStep.Accepted ? (
        <AcceptedStep onClose={onClose} request={request} />
      ) : null}
    </SunshineModal>
  );
};

export default AuthenticationDeviceRequest;
