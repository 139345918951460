import { OutstandingAgreementStatus } from '__generated__/GQL';
import { MachineGuards } from 'common/bento/types/MachineConfig';

import getIsAgreementCompleted from '../libs/getIsAgreementCompleted';
import { Model } from './model';

export enum Guard {
  HasSignedAgreement = 'hasSignedAgreement',
  IsDocumentAssigned = 'isDocumentAssigned',
}

const guards: MachineGuards<Model, Guard> = {
  hasSignedAgreement: ({ getModuleData }) => {
    const { agreementType, viewer } = getModuleData();

    return getIsAgreementCompleted({
      agreementType,
      outstandingAgreements: viewer.outstandingAgreements,
    });
  },
  isDocumentAssigned: ({ getModuleData }) => {
    const [agreement] = getModuleData().viewer.outstandingAgreements;

    if (!agreement) {
      throw new Error('No agreement available');
    }

    return agreement.status !== OutstandingAgreementStatus.NoAssignedDocument;
  },
};

export default guards;
