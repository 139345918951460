import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    accessMyAccount: 'Access my account',
    close: 'Close',
    errorDescription:
      'The request has expired because the time limit has passed.\n\n' +
      'Please retry the operation from the device you want to authorize.',
    errorTitle: 'Request expired',
    new: 'New',
    retry: 'Retry',
    successDescription:
      'You can now access your Shine account from the following device:',
    successTitle: 'Connection accepted',
  },
  fr: {
    accessMyAccount: 'Accéder à mon compte',
    close: 'Fermer',
    errorDescription:
      'La requête a expiré car le temps est écoulé.\n\n' +
      'Veuillez recommencer l’opération depuis l’appareil que vous souhaitez' +
      ' autoriser.',
    errorTitle: 'Requête expirée',
    new: 'Nouveau',
    retry: 'Réessayer',
    successDescription:
      'Vous pouvez à présent accéder à votre compte Shine depuis l’appareil suivant\u00A0:',
    successTitle: 'Connexion acceptée',
  },
});
