import { FC, useRef, useState } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import useInterval from 'use-interval';

import useFeatureFlagsSwitcher from 'common/hooks/useFeatureFlagsSwitcher';

import loadScript from '../libs/loadScript';

const landbotScriptAttributes = {
  SameSite: 'None; Secure',
  src: 'https://static.landbot.io/landbot-3/landbot-3.0.0.js',
  type: 'text/javascript',
};

interface SignupLandbotProps extends BoxProps {
  customData: Record<string, string | boolean | undefined>;
  callback?: (event: LandbotCallbackEvent) => void;
}

export type LandbotCallbackEvent = 'goShineStartMicro' | 'goCapdep';

/**
 * The component that embeds the signup landbot in the page.
 * https://static.landbot.io/landbot-3/storybook-docs/index.html?path=/story/advanced-embedding-in-your-app--page
 */
const SignupLandbot: FC<SignupLandbotProps> = ({
  callback,
  customData,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLandbotLoaded, setIsLandbotLoaded] = useState(false);

  const { featureFlags } = useFeatureFlagsSwitcher();

  const configUrl = featureFlags.enableNewLandbotFlow
    ? 'https://storage.googleapis.com/landbot.pro/v3/H-1658098-R1VOG6CYWCHL7985/index.json'
    : 'https://chats.landbot.io/v3/H-1032762-LD23TX95LFT9U9MP/index.json';

  loadScript(landbotScriptAttributes);

  useInterval(
    () => {
      if (window.Landbot) {
        setIsLandbotLoaded(true);

        new window.Landbot.Container({
          configUrl,
          container: containerRef.current,
          customData,
        });

        if (callback) {
          window.landbotCallback = callback;
        }
      }
    },
    isLandbotLoaded ? null : 10,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      ref={containerRef}
      // This a trick to inject CSS styles to the container and iframe that are created by Landbot library.
      // The main goal is to fulfill the whole space of the container without having to set a fixed height.
      // We don't have access to the iframe's inline style directly, so we have to use the CSS selector to target it.
      sx={{
        '> div': {
          '> iframe': {
            height: 'auto !important',
            width: '100%',
          },
          display: 'flex',
          flex: '1',
          height: 'initial',
          width: '100%',
        },
      }}
      {...props}
    />
  );
};

export default SignupLandbot;
