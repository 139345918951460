import { OBErrorCode } from 'features/Bento/libs/errors/OBErrorCode';

import triggerMarketingZap from './actions/triggerMarketingZap';
import model from './model';

const actions = {
  assignFormData: model.assign<'SUBMIT_FORM_DATA'>({
    form: (context, event) => event.data,
  }),
  assignPasscode: model.assign<'SUBMIT_PASSCODE'>({
    passcode: (context, event) => event.data,
  }),
  assignPhone: model.assign<'SUBMIT_PHONE'>({
    phoneNumber: (context, event) => event.data,
  }),
  assignPhoneNumberOwnershipToken:
    model.assign<'done.invoke.checkVerificationCode'>({
      phoneNumberOwnershipToken: (context, event) =>
        event.data.phoneNumberOwnershipToken,
    }),

  assignVerificationCodeError:
    model.assign<'error.platform.createAccountAndLogin'>({
      verificationCodeError: (context, event) => {
        const { data: error } = event;

        switch (error.message) {
          case OBErrorCode.CODE_EXPIRED:
            return OBErrorCode.CODE_EXPIRED;
          case OBErrorCode.CODE_NOT_MATCH:
            return OBErrorCode.CODE_NOT_MATCH;
          default:
            return null;
        }
      },
    }),
  assignVerificationCodeTimestamp: model.assign<'done.invoke.startRegister'>({
    verificationCodeTimestamp: () => new Date().toISOString(),
  }),
  clearPasscodeAndVerificationCode: model.assign({
    passcode: null,
    phoneNumberOwnershipToken: null,
    verificationCodeError: null,
  }),
  triggerMarketingZap,
};

export default actions;
