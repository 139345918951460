import { ModalHeader, ModalHeaderProps, Spacer } from '@chakra-ui/react';

import { FCWithChildren } from 'common/types';

import CloseButton from './components/CloseButton';
import IconButton from './components/IconButton';

interface HeaderParts {
  IconButton: typeof IconButton;
}

export type SunshineModalHeaderProps = ModalHeaderProps;

const Header: FCWithChildren<SunshineModalHeaderProps> & HeaderParts = ({
  children,
  ...props
}) => (
  <ModalHeader {...props}>
    {children}
    <Spacer />
    <CloseButton />
  </ModalHeader>
);

Header.IconButton = IconButton;

export default Header;
