import LocalizedStrings from 'localized-strings';

import { Section } from './sections';

const locales = new LocalizedStrings({
  en: {
    [Section.SupportingDocuments]: {
      label: 'Supporting documents',
    },
    [Section.Review]: {
      label: 'Validating your application',
    },
  },
  fr: {
    [Section.SupportingDocuments]: {
      label: 'Justificatifs',
    },
    [Section.Review]: {
      label: 'Suivi du dossier',
    },
  },
});

export default locales;
