import {
  anatomy,
  PartsStyleFunction,
  PartsStyleObject,
} from '@chakra-ui/theme-tools';

const parts = anatomy('SquircleIcon').parts('container', 'icon');
type Parts = typeof parts;

export type Size = 'sm' | 'md';

type DefaultProps = {
  size: Size;
};

const baseStyle: PartsStyleFunction<Parts> = ({ colorScheme, iconColor }) => ({
  container: {
    alignItems: 'center',
    backgroundColor: `${colorScheme}.200`,
    borderRadius: 'radius-10',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
  },
  icon: {
    boxSize: 'space-20',
    color: iconColor,
  },
});

const sizes: Record<Size, PartsStyleObject<Parts>> = {
  md: {
    container: {
      boxSize: '2.5rem', // 40px
    },
  },
  sm: {
    container: {
      borderRadius: 'radius-6',
      boxSize: 'space-24',
    },
    icon: {
      boxSize: 'space-16',
    },
  },
};

const defaultProps: DefaultProps = {
  size: 'md',
};

const SunshineSquircleIconTheme = {
  baseStyle,
  defaultProps,
  sizes,
};

export default SunshineSquircleIconTheme;
