import { useCallback, useState } from 'react';
import { parsePhoneNumber } from 'awesome-phonenumber';

import { CompanyInvitationRole } from '__generated__/GQL';
import { InvitationInfo } from 'types/invitation';

import { LoginType } from '../types';
import locales from './locales';
import { LoginStep, SubmitPhoneHookResult } from './types';
import useAutoResettingErrorMessage from './useAutoResettingErrorMessage';

const usePhoneSubmit = (
  currentStep: LoginStep,
  initialPhone: string,
  setCurrentStep: (currentStep: LoginStep) => void,
  setLoginType: (loginType: LoginType) => void,
  loginType: LoginType,
  invitationInfo?: InvitationInfo | null,
): SubmitPhoneHookResult => {
  const [errorMessage, setErrorMessage] =
    useAutoResettingErrorMessage(currentStep);
  const [phone, setPhone] = useState(initialPhone);
  const onSubmit = useCallback(
    async (newPhone: string): Promise<void> => {
      setPhone('');
      setErrorMessage('');
      const parsedPhoneNumber = parsePhoneNumber(newPhone);

      if (!parsedPhoneNumber.valid) {
        setErrorMessage(locales.invalidPhone);
        return;
      }

      if (loginType === 'sign_up') {
        setPhone(newPhone);
        setErrorMessage('');
        if (
          invitationInfo?.role &&
          [
            CompanyInvitationRole.Accountant,
            CompanyInvitationRole.Employee,
            CompanyInvitationRole.Admin,
          ].includes(invitationInfo.role)
        ) {
          setCurrentStep('cguWithHiddenEmail');
        } else {
          setCurrentStep('createEmail');
        }
      } else {
        setPhone(newPhone);
        setErrorMessage('');
        setLoginType('sign_in');
        setCurrentStep('passcode');
      }
    },
    [
      loginType,
      invitationInfo?.role,
      setCurrentStep,
      setErrorMessage,
      setLoginType,
    ],
  );
  return { errorMessage, initialPhone, onSubmit, phone };
};

export default usePhoneSubmit;
