import { FC } from 'react';
import { ListItem, UnorderedList } from '@chakra-ui/react';

import Text from 'components/_core/Text';

import GuidanceBaseHelp from './Base/GuidanceBaseHelp';
import locales from './locales';

/**
 * Help-like guidance to explain
 * why we need names and email
 */
const GuidancePersonalInfo: FC = () => {
  const loc = locales.personalInfo;

  return (
    <GuidanceBaseHelp title={loc.title}>
      <Text color="inherit" fontSize="inherit">
        {loc.content}
      </Text>

      <UnorderedList>
        {loc.list.map((str, i) => (
          <ListItem key={i}>
            <Text color="inherit" fontSize="inherit">
              {str}
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    </GuidanceBaseHelp>
  );
};

export default GuidancePersonalInfo;
