import LocalizedStrings from 'localized-strings';

export const locales = new LocalizedStrings({
  en: {
    completed: 'Done',
    steps: [
      {
        title: 'Register',
      },
      {
        description: 'You’re almost there',
        title: 'Fill in the eligibility information',
      },
      {
        description:
          'Verify your identity and fill in your company information',
        title: 'Complete your file',
      },
      {
        description:
          'We will check your company documents within 48 working hours',
        title: 'Verification of your file',
      },
      {
        title: 'Opening your pro Shine account',
      },
    ],
  },
  fr: {
    completed: 'Terminé',
    steps: [
      {
        title: 'Inscrivez-vous',
      },
      {
        description: 'Vous y êtes presque',
        title: 'Renseignez les informations d’éligibilité',
      },
      {
        description:
          'Vérifiez votre identité et renseignez les informations de votre entreprise',
        title: 'Complétez votre dossier',
      },
      {
        description:
          'Nous vérifions les documents de votre entreprise sous 48h ouvrées.',
        title: 'Vérification de votre dossier',
      },
      {
        title: 'Ouverture de votre compte pro Shine',
      },
    ],
  },
} as const);
