import assertNever from 'common/bento/lib/assertNever';

import SignupLoaderPage from '../../../pages/SignupLoaderPage/SignupLoaderPage';
import { SignupPage } from '../../../types/SignupPage';
import { Intro, State } from '../machine/machine';
import AccountAlreadyExists from './AccountAlreadyExists/AccountAlreadyExists';
import Form from './Form';
import Passcode from './Passcode';
import PasscodeConfirm from './PasscodeConfirm';
import Phone from './Phone';
import ReferralGuide from './ReferralGuide';
import VerificationCode from './VerificationCode';

const EmptyComponent = () => null;

export const getPageFromState = (state: State): SignupPage<Intro> => {
  switch (state) {
    case State.FORM:
      return Form;

    case State.START_REGISTER:
    case State.PHONE:
      return Phone;

    case State.REFERRAL_GUIDE:
      return ReferralGuide;

    case State.PASSCODE:
    case State.PASSCODE_CONFIRM_INVALID:
      return Passcode;

    case State.PASSCODE_CONFIRM:
      return PasscodeConfirm;

    case State.VERIFICATION_CODE:
    case State.VERIFICATION_CODE_RESEND:
    case State.CHECK_VERIFICATION_CODE:
      return VerificationCode;

    case State.CREATE_ACCOUNT_AND_LOGIN:
      return SignupLoaderPage;

    case State.ACCOUNT_ALREADY_EXISTS:
      return AccountAlreadyExists;

    case State.DONE:
      return EmptyComponent;

    case State.ABORT:
      return EmptyComponent;

    default:
      return assertNever(state);
  }
};
