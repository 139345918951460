import { FC } from 'react';

import { CompanyContextProvider } from 'common/hooks/useCompanyContext';
import { HasSeenNewFeatureContextProvider } from 'common/hooks/useHasSeenNewFeature';
import { UserContext } from 'helpers/auth';

import LoginForm from './LoginForm';
import { ComponentProps } from './types';

const Login: FC<ComponentProps> = ({
  children,
  invitationInfo,
  invitationSlug,
  isAuthenticated,
  loading,
  loginType,
  setCurrentUser,
  setLoginType,
  user,
}) => {
  if (loading) {
    return null;
  }

  const isInvalidInvitation = invitationSlug && invitationInfo === null;
  if (isAuthenticated && user && !isInvalidInvitation) {
    return (
      <UserContext.Provider value={user}>
        <CompanyContextProvider>
          <HasSeenNewFeatureContextProvider>
            {children}
          </HasSeenNewFeatureContextProvider>
        </CompanyContextProvider>
      </UserContext.Provider>
    );
  }

  return (
    <LoginForm
      invitationInfo={invitationInfo}
      invitationSlug={invitationSlug}
      loginType={loginType}
      setCurrentUser={setCurrentUser}
      setLoginType={setLoginType}
      user={user}
    />
  );
};

export default Login;
