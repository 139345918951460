/* eslint-disable react/display-name */

import { Box, Flex } from '@chakra-ui/react';

import * as PageLayout from 'features/Bento/components/PageLayout';
import { OBLayout } from 'features/Bento/types/OBLayout';

import SignupSidebar from './SignupSidebar';
import withLayoutFull from './withLayoutFull';

const TEMPLATE_RULES = {
  templateAreas: `
    "sidebar actions guidance"
    "sidebar body  guidance"
  `,
  templateColumns:
    'minmax(240px,320px) minmax(400px, 1fr) minmax(240px,320px);',
  templateRows: '82px auto',
};

/**
 * A grid layout for the onboarding screens.
 */
const SignupLayoutLarge: OBLayout = ({
  actions,
  children,
  'data-testid': testId,
  footer,
  guidance,
  sidebar,
}) => {
  return (
    <PageLayout.Grid {...TEMPLATE_RULES} data-testid={testId}>
      {sidebar === false ? null : (
        <PageLayout.Area gridArea="sidebar">
          {sidebar || <SignupSidebar />}
        </PageLayout.Area>
      )}

      {actions ? (
        <PageLayout.Area gridArea="actions" padding="space-24">
          {actions}
        </PageLayout.Area>
      ) : null}

      <PageLayout.Body
        display="flex"
        flexDirection="column"
        gridArea="body"
        padding="space-16"
      >
        <Flex flexDirection="column" flexGrow={1} margin="0 auto" width="400px">
          {children}

          <Box paddingRight="space-80">{footer}</Box>
        </Flex>
      </PageLayout.Body>

      {guidance ? (
        <PageLayout.Area gridArea="guidance">{guidance}</PageLayout.Area>
      ) : null}
    </PageLayout.Grid>
  );
};

export default withLayoutFull(SignupLayoutLarge);
