import { FC, useRef, useState } from 'react';
import { Grid } from '@chakra-ui/react';

import Input from '../_core/form/Input';
import locales from './locales';

interface MemorableDateInputProps {
  value: string | null;
  onChange: (date: string | null) => void;
}

/**
 * An uncontrolled component to ask for a specific, absolute date.
 *
 * This component is based on a study investigating the most user-friendly way to ask for a date.
 * In case of "memorable dates" that are in the distant past, 3 inputs are used to ask for the day, month and year.
 *
 * It's using a `string` as a value to avoid timezone issues (eg. a birthdate or registration date shouldn't drift to another day because of the timezone)
 *
 * @see https://design-system.service.gov.uk/patterns/dates/#asking-for-memorable-dates
 */
const MemorableDateInput: FC<MemorableDateInputProps> = ({
  onChange,
  value,
}) => {
  /**
   * Initial values for the input.
   * After first mount, it's uncontrolled, meaning it will not react to `value` changes but only forward new values via `onChange`.
   */
  const [defaultYear, defaultMonth, defaultDay] = value?.split('-') ?? [];

  /**
   * Used to keep track of the current state of the inputs, to directly jump to the next input
   * the moment one has been completed.
   */
  const [previousState, setPreviousState] = useState<{
    month?: string;
    day?: string;
    year?: string;
  }>({
    day: defaultDay,
    month: defaultMonth,
    year: defaultYear,
  });

  const refs = {
    day: useRef<HTMLInputElement>(null),
    month: useRef<HTMLInputElement>(null),
    year: useRef<HTMLInputElement>(null),
  };

  const refreshDate = () => {
    const day = refs.day.current?.value;
    const month = refs.month.current?.value;
    const year = refs.year.current?.value;

    setPreviousState({ day, month, year });

    /**
     * The date is not complete (we don't wanna forward an invalid partial date to the parent).
     */
    if (!(day && month && year)) {
      return onChange(null);
    }

    /**
     * Generate a string representation of the date.
     * Same string format as a native date input would return.
     */
    const dateString = `${year.padStart(4, '0')}-${month.padStart(
      2,
      '0',
    )}-${day.padStart(2, '0')}`;

    return onChange(dateString);
  };

  return (
    <Grid gap="space-12" templateColumns="1fr 1fr 2fr">
      <Input
        defaultValue={defaultDay ?? ''}
        inputMode="numeric"
        max="31"
        min="1"
        onChange={(e) => {
          const shouldJumpToNextInput =
            e.target.value.length === 2 &&
            (previousState.day?.length ?? 0) < 2 &&
            !refs.month.current?.value;

          if (shouldJumpToNextInput) {
            refs.month.current?.focus();
          }

          refreshDate();
        }}
        placeholder={locales.placeholder.day}
        ref={refs.day}
        type="number"
      />

      <Input
        defaultValue={defaultMonth ?? ''}
        inputMode="numeric"
        max="12"
        min="1"
        onChange={(e) => {
          const shouldJumpToNextInput =
            e.target.value.length === 2 &&
            (previousState.month?.length ?? 0) < 2 &&
            !refs.year.current?.value;

          if (shouldJumpToNextInput) {
            refs.year.current?.focus();
          }

          refreshDate();
        }}
        placeholder={locales.placeholder.month}
        ref={refs.month}
        type="number"
      />

      <Input
        defaultValue={defaultYear ?? ''}
        inputMode="numeric"
        onChange={refreshDate}
        placeholder={locales.placeholder.year}
        ref={refs.year}
        type="number"
      />
    </Grid>
  );
};

export default MemorableDateInput;
