import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    allGood: 'All good! Continue',
    description:
      'You can now access your Shine account from the following device:',
    title: 'Login accepted',
  },
  fr: {
    allGood: 'C’est tout bon\xA0!',
    description:
      'Vous pouvez à présent accéder à votre compte depuis les appareils suivants\xa0:',
    title: 'Connexion acceptée',
  },
});

export default locales;
