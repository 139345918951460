import { FC } from 'react';
import {
  chakra,
  HTMLChakraProps,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { IconName } from '@shinetools/sunshine-icons';

import SunshineIcon from '../SunshineIcon';

export interface SquircleIconProps
  extends HTMLChakraProps<'div'>,
    ThemingProps<'SunshineSquircleIcon'> {
  name: IconName;
  iconColor?: string;
}

const SquircleIcon: FC<SquircleIconProps> = ({
  colorScheme = 'grey',
  iconColor = 'grey.800',
  name,
  size = 'md',
  ...props
}) => {
  const styles = useMultiStyleConfig('SunshineSquircleIcon', {
    colorScheme,
    iconColor,
    size,
  });

  return (
    <chakra.div __css={styles.container} {...props}>
      <chakra.svg __css={styles.icon} as={SunshineIcon} name={name} />
    </chakra.div>
  );
};

export default SquircleIcon;
