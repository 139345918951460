import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Center, Divider, Flex, Image } from '@chakra-ui/react';
import { convert as titleCase } from 'titlecase-french';

import hornImg from 'assets/brand/horn@2x.png';
import useCompanyContext from 'common/hooks/useCompanyContext';
import { useUserCompanyList } from 'common/hooks/useUserCompanyList';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import Loader from 'components/Loader';
import { INTERCOM_LAUNCHER_CLASS_NAME } from 'features/Intercom';
import { getInvitationInfo } from 'helpers/auth/service';

import locales from './locales';

const ParallelOnboardingRestrictionComponent: FC = () => {
  const [invitationCompanyName, setInvitationCompanyName] = useState('');
  const [loadingCompanyFromInvitation, setLoadingCompanyFromInvitation] =
    useState(true);
  const { companies, loading } = useUserCompanyList();
  const { setCompanyContext } = useCompanyContext();
  const { search } = useLocation();

  const history = useHistory();

  const params = new URLSearchParams(search);
  const invitationSlug = params.get('i') || '';

  const companyInOnboarding = companies.find(
    (company) => company.metadata.accountStatus !== 'VALIDATED',
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { companyName },
        } = await getInvitationInfo(invitationSlug);

        setInvitationCompanyName(titleCase(companyName));
      } catch (error) {
        // Could not fetch the company informations from the invitation slug
      } finally {
        setLoadingCompanyFromInvitation(false);
      }
    }
    fetchData();
  }, [invitationSlug, setInvitationCompanyName]);

  const onboardingCompanyName = titleCase(companyInOnboarding?.legalName);

  const continueOnboarding = useCallback(() => {
    setCompanyContext({
      companyProfileId: companyInOnboarding?.companyProfileId,
      companyUserId: companyInOnboarding?.companyUserId,
    });
    history.push('/');
  }, [history, setCompanyContext, companyInOnboarding]);

  if (loadingCompanyFromInvitation || loading) {
    return <Loader />;
  }

  const isPrimaryApplicant = !!companyInOnboarding?.isPrimaryApplicant;

  const title = isPrimaryApplicant
    ? locales.titleOngoingOnboarding
    : locales.titleOngoingOnboardingAsBeneficiary;

  let description = locales.formatString(
    locales.descriptionOngoingOnboardingAsBeneficiary,
    {
      invitationCompanyName,
      onboardingCompanyName,
    },
  );

  if (isPrimaryApplicant) {
    if (onboardingCompanyName) {
      description = locales.formatString(locales.descriptionOngoingOnboarding, {
        invitationCompanyName,
        onboardingCompanyName,
      });
    } else {
      description = locales.formatString(
        locales.descriptionOngoingOnboardingNoName,
        {
          invitationCompanyName,
        },
      );
    }
  }

  return (
    <Center height="full">
      <SunshineCard maxWidth="600px">
        <Flex alignItems="center" flexDirection="column" textAlign="center">
          <Image
            alt=""
            boxSize="180px"
            marginBottom="space-24"
            role="presentation"
            src={hornImg}
          />

          <Heading marginBottom="space-16" size="lg">
            {title}
          </Heading>

          <Text>{description}</Text>

          <Divider marginBottom="space-24" marginTop="space-40" />

          <Button marginBottom="space-16" onClick={continueOnboarding}>
            {locales.finishOnboardingCta}
          </Button>

          <Button
            className={INTERCOM_LAUNCHER_CLASS_NAME}
            marginBottom="space-16"
            variant="inline-secondary"
          >
            {locales.contactUs}
          </Button>
        </Flex>
      </SunshineCard>
    </Center>
  );
};

export default ParallelOnboardingRestrictionComponent;
