import { FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import redFlag from 'assets/brand/red-flag@2x.png';
import Button from 'components/_core/Button';
import SunshineModal from 'components/_core/SunshineModal';
import Text from 'components/_core/Text';

import locales from './locales';

interface FraudWarningModalProps {
  dismiss: () => void;
}

const FraudWarningModal: FC<FraudWarningModalProps> = ({ dismiss }) => {
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const close = () => {
    dismiss();
    onClose();
  };

  return (
    <SunshineModal isOpen={isOpen} onClose={close}>
      <SunshineModal.Header />

      <SunshineModal.Body>
        <SunshineModal.Body.Image src={redFlag} />

        <SunshineModal.Title>{locales.title}</SunshineModal.Title>

        <Text>{locales.description}</Text>
      </SunshineModal.Body>

      <SunshineModal.Footer>
        <Button
          // Magic id setup on Intercom side, opening Intercom, good luck with that
          id="help-menu-chat"
          variant="inline-secondary"
        >
          {locales.helpButton}
        </Button>

        <Button onClick={close}>{locales.button}</Button>
      </SunshineModal.Footer>
    </SunshineModal>
  );
};

export default FraudWarningModal;
