import {
  forwardRef,
  Switch,
  SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react';

export type SunshineSwitchProps = Omit<
  ChakraSwitchProps,
  'colorScheme' | 'variant'
>;

const SunshineSwitch = forwardRef<SunshineSwitchProps, 'input'>(
  (props, ref) => <Switch {...props} ref={ref} />,
);

export default SunshineSwitch;
