import LocalizedStrings from 'localized-strings';

/**
 * @todo locales
 */
const locales = new LocalizedStrings({
  en: {
    cardDescription: `Vous pouvez continuer à utiliser votre compte Shine comme avant.`,
    cardTitle: `C'est tout bon !`,
    cta: 'Continuer',
  },
  fr: {
    cardDescription: `Vous pouvez continuer à utiliser votre compte Shine comme avant.`,
    cardTitle: `C'est tout bon !`,
    cta: 'Continuer',
  },
});

export default locales;
