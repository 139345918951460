import { FC } from 'react';

import Text, { TextProps } from '../../../Text';
import { useCard } from '../../hooks/useCard';

const CardText: FC<TextProps> = (props) => {
  const { isDisabled } = useCard();

  return (
    <Text size="sm" {...props} {...(isDisabled && { variant: 'disabled' })} />
  );
};

export default CardText;
