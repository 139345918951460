import { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import SunshineLoader from 'components/_core/SunshineLoader';

const Loader: FC = () => (
  <Flex
    alignItems="center"
    flexGrow={1}
    height="100%"
    justifyContent="center"
    width="100%"
  >
    <SunshineLoader />
  </Flex>
);

export default Loader;
