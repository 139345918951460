import { FC } from 'react';
import { gql } from '@apollo/client';
import { Flex, Spacer, VStack } from '@chakra-ui/react';

import { usePublicQuery } from 'common/graphql/usePublicQuery';
import SunshineTag from 'components/_core/SunshineTag';
import HelpLink from 'components/HelpLink';
import HotlineInfo from 'components/HotlineInfo';
import Logo from 'components/Logo';
import { AbstractState } from 'features/Bento/types/Abstract';
import { getUser } from 'helpers/auth';

import SidebarMenu from './components/SidebarMenu';
import SidebarStep from './components/SidebarStep';
import getFlowNameFromState from './libs/getFlowNameFromState';
import locales from './locales';

/**
 * Generate the top-level sidebar of the sign-up flow
 */
const SignupSidebar: FC<{
  state: AbstractState;
  hideSteps?: boolean;
}> = ({ hideSteps, state }) => {
  const user = getUser();
  const flowName = getFlowNameFromState(state);

  const renderFlowTag = () => {
    if (!flowName) {
      return null;
    }

    return (
      <SunshineTag colorScheme="grey" marginBottom="space-8" size="sm">
        {flowName}
      </SunshineTag>
    );
  };

  const { data } = usePublicQuery<{
    onboardingHotline: {
      __typename: 'OnboardingHotline';
      phoneNumber: string;
      availability: string;
      description: string;
      isAvailable: boolean;
    };
  }>({
    query: gql`
      query GetOnboardingHotline {
        onboardingHotline {
          phoneNumber
          availability
          description
          isAvailable
        }
      }
    `,
  });

  const { onboardingHotline } = data ?? {};

  return (
    <Flex align="start" direction="column" height="100%" width="100%">
      {user && user.uid ? <SidebarMenu /> : null}

      {hideSteps ? null : (
        <VStack align="stretch" spacing="space-32">
          <SidebarStep
            isCurrent
            stepName={locales.step1.name}
            substepName={locales.step1.label}
          >
            {renderFlowTag()}
          </SidebarStep>

          <SidebarStep
            stepName={locales.step2.name}
            substepName={locales.step2.label}
          />

          <SidebarStep
            stepName={locales.step3.name}
            substepName={locales.step3.label}
          />
        </VStack>
      )}

      <Spacer />

      {onboardingHotline ? <HotlineInfo hotline={onboardingHotline} /> : null}

      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Logo />

        <HelpLink />
      </Flex>
    </Flex>
  );
};

export default SignupSidebar;
