/* eslint-disable react/no-unused-prop-types */

import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PopInProps {
  closeTo?: string;
  level?: 'modal' | 'alert';
  children: React.ReactNode;
}

// TODO(alex): Fix potential timing issues causing root element to not be existing at `level`'s change

const Modal: FC<PopInProps> = ({ children, level = 'modal' }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setContainer(document.getElementById(level));
  }, [level]);

  return container ? createPortal(children, container) : null;
};

export default Modal;
