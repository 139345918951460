import { FC } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';

import logo from 'assets/shine-logo.svg';

const Logo: FC<ImageProps> = (props) => (
  <Image alt="Shine" src={logo} width="95px" {...props} />
);

export default Logo;
