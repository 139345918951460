import { FC } from 'react';

import easyImg from 'assets/brand/easy@2x.png';

import Container from '../../../../layouts/FeaturesLayout/components/Container';
import Description from '../../../../layouts/FeaturesLayout/components/Description';
import Illustration from '../../../../layouts/FeaturesLayout/components/Illustration';
import Title from '../../../../layouts/FeaturesLayout/components/Title';
import locales from './locales';

const AccountingExports: FC = () => (
  <Container>
    <Illustration src={easyImg} />
    <Title>{locales.title}</Title>
    <Description>{locales.description}</Description>
  </Container>
);

export default AccountingExports;
