import { forwardRef } from '@chakra-ui/react';

import IconButton, { IconButtonProps } from 'components/_core/IconButton';

export type HeaderIconButtonProps = Omit<IconButtonProps, 'aria-label'>;

const HeaderIconButton = forwardRef<HeaderIconButtonProps, 'button'>(
  (props, ref) => (
    <IconButton
      aria-label=""
      iconSize="icon-16"
      ref={ref}
      variant="secondary"
      {...props}
    />
  ),
);

export default HeaderIconButton;
