import { useEffect, useState } from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
} from '@chakra-ui/react';
import { differenceInMilliseconds, intervalToDuration } from 'date-fns';

import { Request } from '../../types';

interface Props {
  cardPaymentStrongAuthenticationRequest: Request;
  onEnd: () => void;
}

const Countdown = ({
  cardPaymentStrongAuthenticationRequest,
  onEnd,
}: Props) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 100);
    return () => clearInterval(interval);
  }, []);

  const expiresAt = new Date(cardPaymentStrongAuthenticationRequest.expiresAt);
  const authenticatedAt = new Date(
    cardPaymentStrongAuthenticationRequest.authenticationRequestedAt,
  );

  const diffInMs = differenceInMilliseconds(expiresAt, now);

  if (diffInMs <= 0) {
    onEnd();
    return null;
  }

  const ttlInMs = differenceInMilliseconds(expiresAt, authenticatedAt);

  const percentage = (diffInMs * 100) / ttlInMs;

  const { minutes, seconds } = intervalToDuration({
    end: expiresAt,
    start: now,
  });

  const label = `${minutes?.toString().padStart(2, '0')} : ${seconds
    ?.toString()
    .padStart(2, '0')}`;

  return (
    <Flex justifyContent="center" marginBottom="space-32">
      <CircularProgress
        alignSelf="center"
        capIsRound
        color="grey.800"
        size={110}
        thickness={4}
        trackColor="grey.300"
        value={percentage}
      >
        <CircularProgressLabel
          css={{
            fontFeatureSettings: 'tnum',
            fontVariantNumeric: 'tabular-nums',
          }}
          fontSize="font-18"
          fontWeight="weight-500"
        >
          {label}
        </CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

export default Countdown;
