import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    complete: 'Complete your company  information',
    finish: 'Finish my account creation',
    newCompany: 'New company',
    onboardingOngoing: 'Creation in progress',
  },
  fr: {
    complete: 'Complétez vos informations pour la société',
    finish: 'Finalisez la création de votre compte',
    newCompany: 'Nouvelle entreprise',
    onboardingOngoing: 'En cours de création',
  },
});
