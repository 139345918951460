import { useState } from 'react';
import { inspect, Inspector } from '@xstate/inspect';

/**
 * This hook will, during development only, open the XState inspector on first mount.
 *
 * Warning : we didn't use a `useEffect` because we need the effect to be instantaneous,
 * because it needs to happen BEFORE any following `useMachine` for the inspector to display it.
 */
const useXStateDevtools = () => {
  const [inspector, setInspector] = useState<Inspector | undefined>(undefined);

  if (
    import.meta.env.VITE_APP_XSTATE_DEVTOOLS !== 'true' ||
    import.meta.env.MODE !== 'development'
  ) {
    return;
  }

  if (!inspector) {
    setInspector(inspect({ iframe: false }));
  }
};

export default useXStateDevtools;
