import styled, { CSSProperties } from 'styled-components';

interface DecorativeImageProps {
  widthPx?: CSSProperties['width'];
  heightPx?: CSSProperties['height'];
  src: string;
}

const DecorativeImage = styled.div<DecorativeImageProps>`
  width: ${({ widthPx }) => widthPx || 90}px;
  height: ${({ heightPx }) => heightPx || 90}px;
  background: no-repeat;
  background-size: contain;
  background-image: url(${({ src }) => src});
`;

export default DecorativeImage;
