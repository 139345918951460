/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8500%3A33155&t=uUz0DrpiHBkIS1kl-1
 */

import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  color: 'text-title',
  fontWeight: 'weight-500',
  lineHeight: 'short',
  whiteSpace: 'pre-line',
};

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const sizes: Record<Size, SystemStyleObject> = {
  '3xl': {
    fontSize: {
      base: 'font-42',
      md: 'font-45',
    },
  },
  '2xl': {
    fontSize: {
      base: 'font-38',
      md: 'font-37',
    },
  },
  xl: {
    fontSize: {
      base: 'font-31',
      md: 'font-31',
    },
  },
  lg: {
    fontSize: {
      base: 'font-25',
      md: 'font-26',
    },
  },
  md: {
    fontSize: {
      base: 'font-21',
      md: 'font-22',
    },
  },
  sm: {
    fontSize: 'font-18',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

type Variant = 'primary' | 'number';

const primaryVariant: SystemStyleObject = {
  fontFamily: 'value',
  letterSpacing: '-0.02em',
};

const numberVariant: SystemStyleObject = {
  fontFamily: 'mabry',
  fontFeatureSettings: `'salt' on, 'ss08' on`, // Modify Mabry Pro font characters (middle dot and question mark)
  fontVariant: 'no-common-ligatures tabular-nums', // Configure Mabry Pro font (no ligature, same size numbers)
  lineHeight: 'shorter',
};

const variants: Record<Variant, SystemStyleObject> = {
  number: numberVariant,
  primary: primaryVariant,
};

const defaultProps = {
  size: 'md',
  variant: 'primary',
};

const HeadingTheme = {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};

export default HeadingTheme;
