import { createModel } from 'xstate/lib/model';

import { UpdateUserPersonalInfoInput } from '__generated__/GQL';
import baseEvents from 'common/bento/config/baseEvents';
import { KycDocument } from 'components/OnfidoDocumentVerifier/types';

import { UseTeamOnboardingFlow } from '../hooks/useTeamOnboardingFlow';
import { OnfidoCheck } from '../types';

export const initialContext = {
  getModuleData: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
  redirectUrl: null,
} as {
  redirectUrl: null | string;
  getModuleData: () => NonNullable<UseTeamOnboardingFlow>;
};

const model = createModel(initialContext, {
  events: {
    ...baseEvents,

    'done.invoke.check-identity': (data: OnfidoCheck | null) => ({ data }),
    'done.invoke.personal-information-send': (
      data: UpdateUserPersonalInfoInput,
    ) => ({ data }),

    ON_ERROR: () => ({}),
    SUBMIT_CHECK: (data: OnfidoCheck) => ({ data }),
    SUBMIT_IDENTITY: (data: KycDocument[]) => ({ data }),
    SUBMIT_PERSONAL_INFORMATION: (data: UpdateUserPersonalInfoInput) => ({
      data,
    }),
    VALIDATION_TIMEOUT: () => ({}),
  },
});

export default model;

export type ModuleData = ReturnType<Context['getModuleData']>;
export type Context = typeof initialContext;
export type Model = typeof model;
