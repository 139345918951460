import { FC, ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import MotionBox from 'components/MotionBox';

import Liberal from '../assets/Liberal@2x.jpg';

const layoutVariants = {
  hidden: {
    opacity: 0,
    x: 5,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 0.12,
    },
    x: 0,
  },
};

type GuidanceLargeProps = {
  variant: 'help' | 'advertising';
  children: ReactNode;
};

/**
 * Container for the Large version for the guidance
 */
const GuidanceLarge: FC<GuidanceLargeProps> = ({ children, variant }) => {
  const content =
    variant === 'advertising' ? (
      // Show an image a the top of the container
      <Flex flexDirection="column" height="full">
        <Box
          backgroundImage={Liberal}
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          borderBottomLeftRadius="2500px 50%"
          borderBottomRightRadius="2500px 50%"
          flexGrow={1}
          marginBottom="space-64"
          maxHeight="520px"
        />

        <MotionBox
          animate="show"
          gridArea="body"
          initial="hidden"
          variants={layoutVariants}
        >
          <Box paddingX="space-40">{children}</Box>
        </MotionBox>
      </Flex>
    ) : (
      <MotionBox
        animate="show"
        gridArea="body"
        initial="hidden"
        variants={layoutVariants}
      >
        {children}
      </MotionBox>
    );

  return (
    <Box
      background="grey.100"
      height="100vh"
      overflow="auto"
      paddingBottom="space-48"
      paddingX={variant === 'advertising' ? '0' : 'space-40'}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none' /* IE and Edge */,
        'scrollbar-width': 'none' /* Firefox */,
      }}
    >
      {content}
    </Box>
  );
};

export default GuidanceLarge;
