import { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';
import Title from './components/Title';

interface SunshineModalParts {
  Body: typeof Body;
  Footer: typeof Footer;
  Header: typeof Header;
  Title: typeof Title;
}

export type SunshineModalProps = ModalProps & {
  contentProps?: ModalContentProps;
};

const SunshineModal: FC<SunshineModalProps> & SunshineModalParts = ({
  children,
  contentProps,
  ...props
}) => (
  <Modal scrollBehavior="inside" {...props}>
    <ModalOverlay />
    <ModalContent {...contentProps}>{children}</ModalContent>
  </Modal>
);

SunshineModal.Body = Body;
SunshineModal.Footer = Footer;
SunshineModal.Header = Header;
SunshineModal.Title = Title;

export default SunshineModal;
