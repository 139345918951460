import type { FC } from 'react';
import { chakra, HStack, VStack } from '@chakra-ui/react';
import { convert as titleCase } from 'titlecase-french';

import { UserCompany } from 'common/hooks/useUserCompanyList';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import Text from 'components/_core/Text';
import locales from 'components/CompanyList/locales';

interface Props {
  company: UserCompany;
  onClick?: () => void;
  isOnboarding?: boolean;
}

export const AccountSelectionItemRebranded: FC<Props> = ({
  company,
  isOnboarding,
  onClick,
}) => {
  const isPrimaryApplicant = !!company?.isPrimaryApplicant;
  const description = isPrimaryApplicant ? locales.finish : locales.complete;

  if (isOnboarding) {
    return (
      <SunshineCard onClick={onClick}>
        <VStack alignItems="flex-start" spacing="space-16">
          <Text lineHeight="none">{description}</Text>
          <HStack alignSelf="stretch" spacing="space-16">
            <SquircleIcon backgroundColor="grey.200" name="profile" />
            <VStack alignItems="flex-start" flex={1} spacing="space-2">
              <SunshineCard.Title>
                {company.legalName
                  ? titleCase(company.legalName)
                  : locales.newCompany}
              </SunshineCard.Title>
              <SunshineCard.Text color="grey.600" lineHeight="none">
                {locales.onboardingOngoing}
              </SunshineCard.Text>
            </VStack>
            <SunshineCard.SunshineIcon name="chevron-right" />
          </HStack>
        </VStack>
      </SunshineCard>
    );
  }

  return (
    <SunshineCard onClick={onClick}>
      <SunshineCard.Slot name="aside">
        <chakra.img
          alt="Company avatar"
          borderRadius="radius-12"
          boxSize={40}
          overflow="hidden"
          src={company.logoSrc}
        />
      </SunshineCard.Slot>

      <SunshineCard.Content paddingTop="space-0">
        <SunshineCard.Title>
          {company.legalName
            ? titleCase(company.legalName)
            : locales.newCompany}
        </SunshineCard.Title>
        <SunshineCard.Text color="grey.600" lineHeight="none">
          {company.legalForm}
        </SunshineCard.Text>
      </SunshineCard.Content>
      <SunshineCard.Slot name="action">
        <SunshineCard.SunshineIcon alignSelf="center" name="chevron-right" />
      </SunshineCard.Slot>
    </SunshineCard>
  );
};
