import { createContext, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';

import { FCWithChildren } from 'common/types';
import SunshineToast from 'components/_core/SunshineToast';

import { ToastManager, ToastOptions } from './types';

const AUTO_CLOSE_DELAY = 6000; // 6s

// Empty function used below as an easier to handle default value
const noop = () => {};

export const ToastContext = createContext<ToastManager>({
  hideToast: noop,
  showToast: noop,
});

const ToastProvider: FCWithChildren = ({ children }) => {
  const toastManagerRef = useRef<null | ToastManager>(null);
  const [isShown, setIsShown] = useState(false);
  const [options, setOptions] = useState<ToastOptions>({
    message: '',
    type: 'error',
  });

  const showToast = (opts: ToastOptions) => {
    setOptions(opts);
    setIsShown(true);
  };

  const hideToast = () => setIsShown(false);

  // Lazily set useRef's value so it is only set once and never changes
  if (toastManagerRef.current === null) {
    toastManagerRef.current = { hideToast, showToast };
  }

  useEffect((): (() => void) => {
    // This hook is only run if isShown or options change
    // because they are passed as arguments to useEffect
    if (isShown) {
      // We only want to start a timeout when the Toast is shown,
      // not when it is hidden
      const timeout = setTimeout(hideToast, AUTO_CLOSE_DELAY);
      return () => clearTimeout(timeout);
    }
    // Returns an empty function to maintain a consistent return type.
    return () => {};
  }, [isShown, options]);

  return (
    // Use a ref to avoid passing a different value every time
    // which could cause consumers to re-render
    <ToastContext.Provider value={toastManagerRef.current}>
      {isShown ? (
        <>
          {/*
            @todo: use chakra useToast hook
            @see https://linear.app/shine/issue/SUN-29/create-new-toast-component
          */}
          <Box
            position="fixed"
            right="space-48"
            top="space-48"
            width="360px"
            zIndex="tooltip"
          >
            <SunshineToast onClose={hideToast} variant={options.type}>
              {options.message}
            </SunshineToast>
          </Box>
        </>
      ) : null}

      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
