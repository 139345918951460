import {
  OperationVariables,
  SubscriptionHookOptions,
  useSubscription as apolloUseSubscription,
} from '@apollo/client';
import {
  DefinitionNode,
  DocumentNode,
  FieldNode,
  FragmentDefinitionNode,
  InlineFragmentNode,
  Kind,
  OperationDefinitionNode,
  SelectionNode,
} from 'graphql';
import { evolve } from 'ramda';

import logger from 'helpers/logger';

type Timestamp = number | string;

// We can receive 3 types of timestamps
// int: 1535025558597
// string: '1535025558597'
// string; '2018-08-23T09:02:39.766Z'
const toDate = (timestamp: Timestamp): Date => {
  // If it is a number, create a Date using the value as timestamp.
  if (typeof timestamp === 'number') {
    return new Date(timestamp);
  }
  // If it is a number stored as a string, use its numeric value as a timestamp just like above.
  const intValue = parseInt(timestamp, 10);
  if (intValue.toString() === timestamp) {
    return new Date(intValue);
  }
  // If it is a string, just let Date parse the value.
  return new Date(timestamp);
};

const isOperationDefinitionNode = (
  definitionNode: DefinitionNode,
): definitionNode is OperationDefinitionNode =>
  definitionNode.kind === 'OperationDefinition';
const isFieldNode = (
  selectionNode: SelectionNode,
): selectionNode is FieldNode => selectionNode.kind === 'Field';
const isFragmentDefinitionNode = (
  definitionNode: DefinitionNode,
): definitionNode is FragmentDefinitionNode =>
  definitionNode.kind === 'FragmentDefinition';
const isInlineFragmentNode = (
  selectionNode: SelectionNode,
): selectionNode is InlineFragmentNode =>
  selectionNode.kind === 'InlineFragment';

/**
 * A wrapper around the Apollo's useSubscription hook
 * Contrary to the native hook, it does not write subscription results
 * directly to the cache, but first checks the updatedAt value.
 * If the value from the subscription is greater, than it's written to the cache.
 * Otherwise, the subscription's result is dismissed.
 * Because of this, it's very important to include the `updatedAt` field
 * in the subscription query payload.
 * @param {string} subscription - The GraphQL string describing the subscription.
 * @param {function?} callback - A callback function which is run after
 * cache updates have been performed.
 */
const useSubscription = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData extends Record<string, any>,
  TVars extends OperationVariables,
>(
  subscription: DocumentNode,
  callback?: SubscriptionHookOptions<TData, TVars>['onData'],
  options?: SubscriptionHookOptions<TData, TVars>,
) =>
  apolloUseSubscription<TData, TVars>(subscription, {
    ...options,
    // Disable automatic cache update
    fetchPolicy: 'no-cache',
    // Handle cache update manually
    // Google's PubSubs are not sent in order
    // Sometimes an older version of an object can be received
    // We want to avoid overriding newer data with old expired data
    onData: ({ client, data: subscriptionData }): void => {
      try {
        // Key under which the subscription data can be accessed
        // Example :
        // subscription onUpdatedUserMetadata {
        //   updatedUserMetadata {
        //     ...UserMetadata
        //   }
        // }
        // subscriptionName = updatedUserMetadata

        const operationDefinition = subscription.definitions.find(
          isOperationDefinitionNode,
        );
        if (operationDefinition === undefined) {
          return;
        }
        const field =
          operationDefinition.selectionSet.selections.find(isFieldNode);
        if (field === undefined) {
          return;
        }
        const subscriptionName = field.name.value;
        const fragmentDefinition = subscription.definitions.find(
          isFragmentDefinitionNode,
        );
        if (fragmentDefinition === undefined) {
          return;
        }
        // Fragment to update
        // Example:
        // subscriptionFragment = UserMetadata
        const subscriptionFragment: DocumentNode = {
          definitions: [fragmentDefinition],
          kind: Kind.DOCUMENT,
        };
        // Fragment to query with only updatedAt to avoid cache misses
        const queryFragment: DocumentNode = {
          definitions: [
            evolve(
              {
                selectionSet: {
                  selections: (items: readonly SelectionNode[]) =>
                    items.filter(
                      (item: SelectionNode): boolean =>
                        !isInlineFragmentNode(item) &&
                        item.name.value === 'updatedAt',
                    ),
                },
              },
              fragmentDefinition,
            ),
          ],
          kind: Kind.DOCUMENT,
        };
        // Updated value received
        if (
          subscriptionData.data === undefined ||
          !(subscriptionName in subscriptionData.data)
        ) {
          return;
        }
        const newObject = subscriptionData.data[subscriptionName];
        try {
          // Check previous value only if updatedAt exists
          if (newObject.updatedAt) {
            // Previous value
            const oldObject = client.cache.readFragment<TData>({
              fragment: queryFragment,
              id: client.cache.identify(newObject),
            });
            // If previous value is newer than the received value
            // Skip cache update
            if (
              oldObject &&
              oldObject.updatedAt &&
              toDate(newObject.updatedAt) <= toDate(oldObject.updatedAt)
            ) {
              return;
            }
          }
        } catch (e) {
          if (!('updatedAt' in newObject)) {
            logger.warn(
              'updatedAt is likely missing from the cache. When using subscriptions on an entity, be sure to include updatedAt every time you query this entity.',
            );
          } else {
            logger.warn(e);
          }
        }
        // Otherwise update the cache with the received value
        client.cache.writeFragment({
          data: newObject,
          fragment: subscriptionFragment,
          id: client.cache.identify(newObject),
        });

        if (callback) {
          callback({ client, data: subscriptionData });
        }
      } catch (error) {
        logger.error(error);
      }
    },
  });

export default useSubscription;
