import { createMachine, forwardTo } from 'xstate';
import { pure } from 'xstate/lib/actions';

import shared from 'common/bento/shared';
// eslint-disable-next-line no-restricted-imports
import features from 'featureFlags';
import { logEvent } from 'features/Analytics/analytics';
import { AnyMachineEvent } from 'features/Bento/types/Abstract';
import { MachineModelFrom } from 'features/Bento/types/Machine';
import { saveApplicationType } from 'features/Onboarding/common/localStorage/applicationType';
import { State as OnboardingState } from 'features/Onboarding/machine';

import signup from '../libs/signup';
import accountCreation from '../modules/AccountCreation';
import actions from './actions';
import guards, { Guard } from './guards';
import model, { CompanyCreationLegalForm, Context } from './model';
import services from './services';

export enum State {
  CHECK_REFERRAL = 'check-referral',
  HOME = 'home',
  STANDARD = 'standard',
  COMPANY_CREATION = 'company-creation',
  CAP_DEP = 'cap-dep',
  BENEFICIARY = 'beneficiary',
  DONE = 'done',
}

export enum Standard {
  INIT_STANDARD_FLOW = 'init-standard-flow',
  SELECT_COMPANY_PROFILE = 'select-company-profile',
  ACCOUNT = 'account',
  WELCOME = 'welcome',
}

export enum CompanyCreation {
  SELECT_TYPE = 'select-type',
  LANDBOT = 'landbot',
  ACCOUNT = 'account',
  WELCOME = 'welcome',
  ELIGIBILITY_CHECK = 'eligibility-check',
  SASU_WELCOME = 'sasu-welcome',
  SELECT_LEGAL_FORM = 'select-legal-form',
}

export enum CapDep {
  SELECT_STATUTES = 'select-statutes',
  COMPANY_CREATION_LANDBOT = 'company-creation-landbot',
  ACCOUNT = 'account',
  WELCOME = 'welcome',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'signup',

    initial: State.CHECK_REFERRAL,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.CHECK_REFERRAL]: {
        entry: {
          exec: () => {
            logEvent({
              name: 'Signup Started',
              usePrefix: false,
            });
          },
          type: 'track',
        },

        invoke: {
          id: 'verifyReferral',
          onDone: {
            actions: ['assignValidReferral', 'toastValidReferral'],
            target: State.HOME,
          },

          onError: {
            actions: [
              'toastInvalidReferral',
              'assignEmptyReferral',
              'removeReferralParam',
            ],
            target: State.HOME,
          },

          src: 'verifyReferral',
        },

        on: {
          PREV: { actions: 'leaveSignup' },
        },
      },

      [State.HOME]: {
        entry: shared.actions.trackView(),
        on: {
          GO_BENEFICIARY: {
            actions: [
              shared.actions.identify({
                signupSituation: 'beneficiary',
              }),
              shared.actions.track({
                name: 'Signup Situation Selected',
                properties: {
                  signupSituation: 'beneficiary',
                },
              }),
              shared.actions.track({
                name: 'Signup Situation Confirmed',
                properties: {
                  signupSituation: 'beneficiary',
                },
              }),
            ],
            target: State.BENEFICIARY,
          },

          GO_CAP_DEP: {
            actions: shared.actions.track({
              name: 'Signup Situation Selected',
              properties: {
                signupSituation: 'capital-deposit',
              },
            }),
            target: State.CAP_DEP,
          },
          GO_COMPANY_CREATION: {
            actions: shared.actions.track({
              name: 'Signup Situation Selected',
              properties: {
                signupSituation: 'shine-start',
              },
            }),
            target: State.COMPANY_CREATION,
          },
          GO_STANDARD: {
            actions: [
              shared.actions.identify({
                signupSituation: 'standard',
              }),
              shared.actions.track({
                name: 'Signup Situation Selected',
                properties: {
                  signupSituation: 'standard',
                },
              }),
              shared.actions.track({
                name: 'Signup Situation Confirmed',
                properties: {
                  signupSituation: 'standard',
                },
              }),
            ],
            target: State.STANDARD,
          },
          PREV: { actions: 'leaveSignup' },
        },
      },

      [State.STANDARD]: {
        initial: Standard.SELECT_COMPANY_PROFILE,

        states: {
          [Standard.SELECT_COMPANY_PROFILE]: {
            entry: shared.actions.trackView(),
            on: {
              PREV: `#signup.${State.HOME}`,
              SUBMIT_COMPANY_PROFILE_TYPE: {
                actions: [
                  'assignCompanyProfileType',
                  pure((context) =>
                    shared.actions.identify({
                      signupCompanyProfileType: context.companyProfileType,
                    }),
                  ),
                  pure((context) =>
                    shared.actions.track({
                      name: 'Signup Profile Type Chosen',
                      properties: {
                        profileType: context.companyProfileType,
                      },
                    }),
                  ),
                ],
                target: Standard.ACCOUNT,
              },
            },
          },

          [Standard.ACCOUNT]: {
            invoke: {
              id: `${State.STANDARD}.${Standard.ACCOUNT}`,

              onDone: [
                {
                  cond: (context, event) => event.data === 'ABORT',
                  target: Standard.SELECT_COMPANY_PROFILE,
                },
                {
                  actions: [
                    shared.actions.identify({
                      onboarding_account_type: 'regular_account_opening',
                    }),
                  ],
                  target: Standard.WELCOME,
                },
              ],

              src: shared.services.spawn(
                accountCreation.machine,
                (context) => ({
                  context: {
                    accountType: 'standard' as const,
                    affiliate: context.affiliate,
                    affiliationContext: context.affiliationContext,
                    companyProfileType: context.companyProfileType,
                    helpers: context.helpers,
                    referral: context.referral,
                  },
                }),
              ),
            },

            on: {
              PREV: {
                actions: forwardTo(`${State.STANDARD}.${Standard.ACCOUNT}`),
              },
            },
          },

          [Standard.WELCOME]: {
            entry: [
              () => saveApplicationType('standard'),
              shared.actions.trackView(),
            ],

            on: {
              NEXT: {
                actions: signup.actions.navigate(
                  `/onboarding/${OnboardingState.STANDARD}`,
                ),
              },

              PREV: {
                actions: signup.actions.navigate('/onboarding'),
              },
            },
          },
        },
      },

      [State.CAP_DEP]: {
        initial: CapDep.SELECT_STATUTES,

        states: {
          [CapDep.SELECT_STATUTES]: {
            /**
             * Skip CapDep question for legaltech partners
             * @see https://linear.app/shine/issue/LED-883/sign-up-affiliation-legaltech-skip-capdep-question
             */
            always: {
              actions: [
                shared.actions.identify({
                  signupSituation: 'capital-deposit',
                }),
                shared.actions.track({
                  name: 'Signup Situation Confirmed',
                  properties: {
                    signupSituation: 'capital-deposit',
                  },
                }),
              ],
              cond: 'isAffiliatedLegaltech',
              target: CapDep.ACCOUNT,
            },

            entry: shared.actions.trackView(),
            on: {
              PREV: `#signup.${State.HOME}`,
              SUBMIT_HAS_STATUTES: [
                {
                  actions: [
                    shared.actions.identify({
                      signupSituation: 'capital-deposit',
                    }),
                    shared.actions.track({
                      name: 'Signup Situation Confirmed',
                      properties: {
                        signupSituation: 'capital-deposit',
                      },
                    }),
                  ],
                  cond: 'hasStatutes',
                  target: CapDep.ACCOUNT,
                },

                {
                  actions: [
                    shared.actions.identify({
                      signupSituation: 'partner-capital-deposit',
                    }),
                    shared.actions.track({
                      name: 'Signup Situation Confirmed',
                      properties: {
                        signupSituation: 'partner-capital-deposit',
                      },
                    }),
                    'assignCompanyLegalFormToCapDep',
                  ],
                  target: CapDep.COMPANY_CREATION_LANDBOT,
                },
              ],
            },
          },

          [CapDep.COMPANY_CREATION_LANDBOT]: {
            entry: shared.actions.trackView(),
            on: {
              GO_CAP_DEP: {
                actions: [
                  shared.actions.identify({
                    signupSituation: 'capital-deposit',
                  }),
                  shared.actions.track({
                    name: 'Signup Situation Selected',
                    properties: {
                      signupSituation: 'capital-deposit',
                    },
                  }),
                ],
                target: `#signup.${State.CAP_DEP}.${CapDep.SELECT_STATUTES}`,
              },
              GO_SHINE_START_MICRO: {
                actions: [
                  shared.actions.identify({
                    signupSituation: 'shine-start',
                  }),
                  shared.actions.track({
                    name: 'Signup Situation Confirmed',
                    properties: {
                      signupSituation: 'shine-start',
                    },
                  }),
                ],
                target: `#signup.${State.COMPANY_CREATION}.${CompanyCreation.ACCOUNT}`,
              },
              PREV: CapDep.SELECT_STATUTES,
            },
          },

          [CapDep.ACCOUNT]: {
            invoke: {
              id: `${State.CAP_DEP}.${CapDep.ACCOUNT}`,

              onDone: [
                {
                  actions: [
                    shared.actions.identify({
                      onboarding_account_type: 'capital_deposit',
                    }),
                  ],
                  cond: (context, event) => event.data !== 'ABORT',
                  target: CapDep.WELCOME,
                },
                /**
                 * The previous screen makes no sense for legaltech partners.
                 */
                {
                  cond: 'isAffiliatedLegaltech',
                  target: `#signup.${State.HOME}`,
                },
                {
                  target: CapDep.SELECT_STATUTES,
                },
              ],

              src: shared.services.spawn(
                accountCreation.machine,
                (context) => ({
                  context: {
                    accountType: 'capital-deposit' as const,
                    affiliate: context.affiliate,
                    affiliationContext: context.affiliationContext,
                    helpers: context.helpers,
                    referral: context.referral,
                  },
                }),
              ),
            },

            on: {
              PREV: {
                actions: forwardTo(`${State.CAP_DEP}.${CapDep.ACCOUNT}`),
              },
            },
          },

          [CapDep.WELCOME]: {
            entry: [
              () => saveApplicationType('capital-deposit'),
              shared.actions.trackView(),
            ],

            on: {
              NEXT: {
                actions: signup.actions.navigate(
                  `/onboarding/${OnboardingState.CAPITAL_DEPOSIT}`,
                ),
              },

              PREV: {
                actions: signup.actions.navigate('/onboarding'),
              },
            },
          },
        },
      },

      [State.COMPANY_CREATION]: {
        initial: CompanyCreation.SELECT_TYPE,

        states: {
          [CompanyCreation.SELECT_TYPE]: {
            entry: shared.actions.trackView(),
            on: {
              GO_CAP_DEP: {
                actions: [
                  shared.actions.identify({
                    signupSituation: 'capital-deposit',
                  }),
                  shared.actions.track({
                    name: 'Signup Situation Selected',
                    properties: {
                      signupSituation: 'capital-deposit',
                    },
                  }),
                ],
                target: `#signup.${State.CAP_DEP}.${CapDep.SELECT_STATUTES}`,
              },
              PREV: `#signup.${State.HOME}`,
              SUBMIT_COMPANY_CREATION_TYPE: [
                {
                  actions: [
                    shared.actions.identify({
                      signupSituation: 'shine-start-company',
                    }),
                    shared.actions.track({
                      name: 'Signup Situation Confirmed',
                      properties: {
                        signupSituation: 'shine-start-company',
                      },
                    }),
                  ],
                  cond: 'isSociety',
                  target: features.enableNewLandbotFlow
                    ? CompanyCreation.SELECT_LEGAL_FORM
                    : CompanyCreation.LANDBOT,
                },
                {
                  actions: [
                    shared.actions.identify({
                      signupSituation: 'shine-start',
                    }),
                    shared.actions.track({
                      name: 'Signup Situation Confirmed',
                      properties: {
                        signupSituation: 'shine-start',
                      },
                    }),
                  ],
                  cond: 'isIndependent',
                  target: CompanyCreation.ACCOUNT,
                },
                {
                  actions: [
                    shared.actions.identify({
                      signupSituation: 'shine-start-company',
                    }),
                    shared.actions.track({
                      name: 'Signup Situation Confirmed',
                      properties: {
                        signupSituation: 'shine-start-company',
                      },
                    }),
                    'assignCompanyLegalFormToUnknown',
                  ],
                  target: CompanyCreation.LANDBOT,
                },
              ],
            },
          },

          [CompanyCreation.LANDBOT]: {
            entry: shared.actions.trackView(),
            on: {
              GO_CAP_DEP: {
                actions: [
                  shared.actions.identify({
                    signupSituation: 'capital-deposit',
                  }),
                  shared.actions.track({
                    name: 'Signup Situation Selected',
                    properties: {
                      signupSituation: 'capital-deposit',
                    },
                  }),
                ],
                target: `#signup.${State.CAP_DEP}.${CapDep.SELECT_STATUTES}`,
              },
              GO_SHINE_START_MICRO: {
                actions: [
                  shared.actions.identify({
                    signupSituation: 'shine-start',
                  }),
                  shared.actions.track({
                    name: 'Signup Situation Confirmed',
                    properties: {
                      signupSituation: 'shine-start',
                    },
                  }),
                ],
                target: CompanyCreation.ACCOUNT,
              },
              PREV: CompanyCreation.SELECT_TYPE,
            },
          },

          [CompanyCreation.SELECT_LEGAL_FORM]: {
            entry: shared.actions.trackView(),
            on: {
              PREV: CompanyCreation.SELECT_TYPE,
              SUBMIT_COMPANY_CREATION_LEGAL_FORM: [
                {
                  actions: 'assignCompanyLegalForm',
                  cond: Guard.IsInHouseSasuCreation,
                  target: CompanyCreation.ELIGIBILITY_CHECK,
                },
                {
                  actions: 'assignCompanyLegalForm',
                  target: CompanyCreation.LANDBOT,
                },
              ],
            },
          },

          [CompanyCreation.ELIGIBILITY_CHECK]: {
            entry: shared.actions.trackView(),
            on: {
              NEXT: CompanyCreation.ACCOUNT,
              PREV: CompanyCreation.SELECT_LEGAL_FORM,
            },
          },

          [CompanyCreation.ACCOUNT]: {
            invoke: {
              id: `${State.COMPANY_CREATION}.${CompanyCreation.ACCOUNT}`,

              onDone: [
                {
                  cond: (context, event) => event.data === 'ABORT',
                  /** @todo we should redirect to select statuses instead if it was the precedent step */
                  target: CompanyCreation.SELECT_TYPE,
                },
                {
                  actions: [
                    () => {
                      shared.actions.identify({
                        onboarding_account_type: 'sasu_in_house',
                      });
                    },
                  ],
                  cond: (context: Context) =>
                    context.companyLegalForm === CompanyCreationLegalForm.SASU,
                  target: CompanyCreation.SASU_WELCOME,
                },
                {
                  actions: [
                    () => {
                      shared.actions.identify({
                        onboarding_account_type: 'shine_start_micro',
                      });
                    },
                  ],
                  target: CompanyCreation.WELCOME,
                },
              ],

              src: shared.services.spawn(
                accountCreation.machine,
                (context) => ({
                  context: {
                    accountType:
                      context.companyLegalForm === CompanyCreationLegalForm.SASU
                        ? ('sasu-creation' as const)
                        : ('shine-start' as const),
                    affiliate: context.affiliate,
                    affiliationContext: context.affiliationContext,
                    helpers: context.helpers,
                    referral: context.referral,
                  },
                }),
              ),
            },

            on: {
              PREV: {
                actions: forwardTo(
                  `${State.COMPANY_CREATION}.${CompanyCreation.ACCOUNT}`,
                ),
              },
            },
          },

          [CompanyCreation.WELCOME]: {
            entry: [
              () => saveApplicationType('shine-start'),
              shared.actions.trackView(),
            ],

            on: {
              NEXT: [
                {
                  actions: signup.actions.navigate(
                    `/onboarding/${OnboardingState.SHINE_START}`,
                  ),
                },
              ],
              PREV: {
                actions: signup.actions.navigate('/onboarding'),
              },
            },
          },

          [CompanyCreation.SASU_WELCOME]: {
            entry: [
              () => saveApplicationType('sasu-creation'),
              shared.actions.trackView(),
            ],

            on: {
              NEXT: [
                {
                  actions: signup.actions.navigate(
                    `/onboarding/${OnboardingState.SASU_CREATION}`,
                  ),
                },
              ],
              PREV: {
                actions: signup.actions.navigate('/onboarding'),
              },
            },
          },
        },
      },

      [State.BENEFICIARY]: {
        entry: shared.actions.trackView(),
        on: {
          PREV: State.HOME,
        },
      },

      [State.DONE]: {
        entry: signup.actions.navigate('/'),
        type: 'final',
      },
    },
  },

  {
    actions,
    guards,
    services,
  },
);

export default machine;

export type Signup = MachineModelFrom<typeof machine, typeof model>;
