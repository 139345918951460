import { Box, chakra, Circle } from '@chakra-ui/react';

import SunshineIcon from 'components/_core/SunshineIcon';
import { getIconName } from 'helpers/auth/devices';

import { Request } from '../types';
import RequestDetailsRow from './RequestDetailsRow';

interface Props {
  authenticationDevice: NonNullable<Request['authenticationDevice']>;
  className?: string;
}

const DeviceInfo = ({ authenticationDevice, className }: Props) => {
  return (
    <Box
      borderColor="grey.2"
      borderRadius="radius-8"
      borderWidth="thin"
      className={className}
    >
      <RequestDetailsRow
        description={authenticationDevice.model ?? ''}
        label={authenticationDevice.name ?? ''}
        leftComponent={
          <Circle bgColor="lightBlue.3" size="space-40">
            <SunshineIcon
              color="ctaBlue.-1"
              name={getIconName(authenticationDevice)}
              size="icon-22"
            />
          </Circle>
        }
      />
    </Box>
  );
};

export default chakra(DeviceInfo);
