import {
  MessageNativeToWeb,
  messageNativeToWebSchema,
} from '../webViewMessages';
import getWebViewAPI from './getWebViewAPI';

const listenNativeMessage = (callback: (msg: MessageNativeToWeb) => void) => {
  if (getWebViewAPI() === null) {
    return () => {};
  }

  const listener = (message: MessageEvent<unknown>) => {
    const parsed = messageNativeToWebSchema.safeParse(message.data);

    if (parsed.success) {
      callback(parsed.data);
    }
  };

  window.addEventListener('message', listener);

  return () => {
    window.removeEventListener('message', listener);
  };
};

export default listenNativeMessage;
