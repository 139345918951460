import { FC } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';

import logo from 'assets/shine-logo.svg';
import Button from 'components/_core/Button';
import SunshineCard from 'components/_core/SunshineCard';
import HelpLink from 'components/HelpLink';

import locales from './locales';
import { Props } from './types';

const NewDeviceLoginLayout: FC<Props> = ({ cancel, children }) => (
  <Box height="100vh" marginX="auto" paddingTop="space-32" width="600px">
    {cancel ? (
      <Button
        icon="cross"
        iconPosition="left"
        marginBottom="space-40"
        onClick={() => cancel()}
        variant="tertiary"
      >
        {locales.cancel}
      </Button>
    ) : null}

    <SunshineCard borderWidth={{ base: 0, md: 1 }}>
      <Flex alignItems="center" flexDirection="column">
        {children}
      </Flex>
    </SunshineCard>

    <Flex
      bottom="space-24"
      justifyContent="space-between"
      left="space-24"
      position="absolute"
      right={0}
    >
      <Image alt="Shine" marginRight="" src={logo} width="82px" />
      <HelpLink />
    </Flex>
  </Box>
);

export default NewDeviceLoginLayout;
