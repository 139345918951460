import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    buttonLabel: 'Continue',
    subtitle:
      'The email & phone number associated to your new account will be:',
    title: 'You will open a Shine account for another company',
  },
  fr: {
    buttonLabel: 'Continuer',
    subtitle:
      'L’e-mail et le numéro de téléphone liés à votre nouveau compte seront\u00A0:',
    title: 'Vous allez ouvrir un compte Shine pour une autre entreprise',
  },
});

export default locales;
