import { createModel } from 'xstate/lib/model';

import baseEvents from 'common/bento/config/baseEvents';

import { TeamOnboardingAdminDataQuery } from '../graphql/queries/teamOnboardingAdminData.gql';

interface AdminModuleData {
  data: TeamOnboardingAdminDataQuery;
}

const context = {
  getModuleData: (() => {
    throw new Error('NOT_IMPLEMENTED');
  }) as () => AdminModuleData,
};

export type Context = typeof context;

const model = createModel(
  context,

  {
    events: {
      ...baseEvents,
    },
  },
);

export type Model = typeof model;

export default model;
