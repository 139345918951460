import { Heading } from '@chakra-ui/react';

import okHand from 'assets/brand/ok-hand@2x.png';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import Text from 'components/_core/Text';
import { getDeviceInformation } from 'helpers/device';

import DeviceInfo from '../../components/DeviceInfo';
import ModalBody from '../../components/ModalBody';
import { Request } from '../../types';
import locales from './locales';

interface Props {
  cardPaymentStrongAuthenticationRequest: Request;
  onFinish: () => void;
}

const CardPaymentStrongAuthenticationRequestAccepted = ({
  cardPaymentStrongAuthenticationRequest,
  onFinish,
}: Props) => {
  const { authenticationDevice } = cardPaymentStrongAuthenticationRequest;
  const hasBeenAcceptedElsewhere =
    authenticationDevice?.token !== getDeviceInformation().token;

  return (
    <>
      <ModalBody>
        <Modal.Image src={okHand} width="144px" />
        <Heading marginBottom="space-12">
          {hasBeenAcceptedElsewhere
            ? locales.titleAlreadyAccepted
            : locales.title}
        </Heading>
        <Text marginBottom="space-32">
          {hasBeenAcceptedElsewhere
            ? locales.descriptionAlreadyAccepted
            : locales.description}
        </Text>
        {hasBeenAcceptedElsewhere && authenticationDevice ? (
          <DeviceInfo
            authenticationDevice={authenticationDevice}
            marginBottom="space-32"
          />
        ) : null}
      </ModalBody>
      <Modal.Footer justifyContent="flex-end">
        <Button onClick={onFinish}>
          {hasBeenAcceptedElsewhere
            ? locales.closeButton
            : locales.finishButton}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CardPaymentStrongAuthenticationRequestAccepted;
