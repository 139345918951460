import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { Image } from '@chakra-ui/react';

import fadedFlowerImg from 'assets/brand/faded-flower@2x.png';
import shineLogo from 'assets/shine-logo.svg';
import { isErrorWithStatusCode } from 'common/errors/utils';
import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import { ErrorPage } from 'components/ErrorPage';
import { ErrorPageType } from 'components/ErrorPage/types';
import HelpAndTermsLinks from 'components/HelpAndTermsLinks';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';
import { useUser } from 'helpers/auth';
import CenterCardLayout from 'layouts/CenterCardLayout';

import AccountSwitcher from './AccountSwitcher';
import locales from './locales';
import { getClient, grant } from './service';
import {
  Actions,
  ClientLogo,
  ClientLogoContainer,
  ClientLogoContainerResponsive,
  Container,
  CustomText,
  Form,
  Item,
  List,
  Logo,
  Spacer,
  Title,
} from './styles';
import { AccessItem, OAuthClient, Queries } from './types';
import { getAccessItems, hasInvalidParams, parseQueryString } from './utils';

const Authorize: FC = () => {
  const user = useUser();
  const queries: Queries = useMemo((): Queries => parseQueryString(), []);
  const [loading, setLoading] = useState(true);
  const [authorizing, setAuthorizing] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const [client, setClient] = useState<OAuthClient | null>(null);
  const { companyProfileId } = useCompanyContext();
  // Parameters below comes from client and should be considered unsafe
  const {
    client_id: clientId,
    redirect_uri: unsafeRedirectUri,
    scope,
    state,
  } = queries;
  const redirectUri = sanitizeUrl(unsafeRedirectUri);

  const accessItems: AccessItem[] = useMemo(
    (): ReturnType<typeof getAccessItems> => getAccessItems(queries.scope),
    [queries.scope],
  );
  useEffect((): void => {
    const fetch = async (): Promise<void> => {
      try {
        const queryClient = await getClient(clientId, user.token);
        setClient(queryClient);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [clientId, user.token]);

  if (loading) {
    return <Loader />;
  }

  if (
    !user ||
    !companyProfileId ||
    !client ||
    hasInvalidParams(queries) ||
    error
  ) {
    const isForbiddenError =
      error && isErrorWithStatusCode(error) && error.status === 403;
    return (
      <ErrorPage type={ErrorPageType.Generic}>
        <SunshineCard.Group maxWidth="31.25rem">
          <SunshineCard alignItems="center" textAlign="center">
            <Image
              alt={locales.errorTitle}
              marginBottom="space-16"
              marginX="auto"
              src={fadedFlowerImg}
              width="180px"
            />

            <Heading marginBottom="space-16" size="lg">
              {locales.errorTitle}
            </Heading>

            <Text paddingX="10%">
              {isForbiddenError
                ? locales.errorDescriptionPermission
                : locales.errorDescription}
            </Text>
          </SunshineCard>
        </SunshineCard.Group>
      </ErrorPage>
    );
  }

  const authorize = async (): Promise<void> => {
    setAuthorizing(true);
    try {
      await grant({
        accessToken: user.token,
        clientId,
        companyProfileId,
        redirectUri,
        scope,
        state,
      });
    } catch (e) {
      setError(e);
    } finally {
      setAuthorizing(false);
    }
  };

  return (
    <CenterCardLayout footer={<HelpAndTermsLinks center />}>
      <Container>
        <ClientLogoContainer>
          <ClientLogo alt="client logo" src={client.logoURL} />
        </ClientLogoContainer>
        <Form>
          <a href="https://shine.fr" key="logo" target="__blank">
            <Logo alt="Shine logo" src={shineLogo} />
          </a>
          <AccountSwitcher />
          <Title>
            {locales.formatString(locales.title, client.displayName)}
            <ClientLogoContainerResponsive>
              <ClientLogo alt="client logo" src={client.logoURL} />
            </ClientLogoContainerResponsive>
          </Title>
          <List>
            {accessItems.map(
              (accessItem): ReactNode => (
                <Item key={accessItem.id}>
                  <CustomText>{accessItem.label}</CustomText>
                  {accessItem.tooltip ? (
                    <Tooltip text={accessItem.tooltip} />
                  ) : null}
                </Item>
              ),
            )}
          </List>
        </Form>
        <Actions>
          <Button
            onClick={(): void => {
              window.location.href = sanitizeUrl(
                `${redirectUri}?error=access_denied&state=${state}`,
              );
            }}
            variant="secondary"
          >
            {locales.cancel}
          </Button>
          <Spacer />
          <Button isLoading={authorizing} onClick={authorize}>
            {locales.authorize}
          </Button>
        </Actions>
      </Container>
    </CenterCardLayout>
  );
};

export default Authorize;
