import { FC, ReactNode } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import Text from 'components/_core/Text';

import GuidanceLarge from './GuidanceLarge';

/**
 * Advertising variant specific title
 */
const GuidanceBaseAdvertisingTitle: FC<{ children: string }> = ({
  children,
}) => (
  <Text
    color="primary"
    fontFamily="value"
    fontSize="font-26"
    fontWeight="weight-400"
    lineHeight="shortest"
    marginBottom="space-32"
    sx={{
      p: {
        margin: 0,
      },
      strong: {
        color: 'primary',
        fontWeight: 'weight-600',
      },
    }}
    textAlign="center"
  >
    {children}
  </Text>
);

interface GuidanceBaseAdvertisingProps {
  title: string;
  children: ReactNode;
}

/**
 * The ad style guidance with possible testimonial
 * - title is mandatory
 * - children is the text content
 */
const GuidanceBaseAdvertising: FC<GuidanceBaseAdvertisingProps> = ({
  children,
  title,
}) => {
  const isSmall = useBreakpointValue({
    base: true,
    lg: false,
  });

  return isSmall ? null : (
    <GuidanceLarge variant="advertising">
      <GuidanceBaseAdvertisingTitle>{title}</GuidanceBaseAdvertisingTitle>

      {children}
    </GuidanceLarge>
  );
};

export default GuidanceBaseAdvertising;
