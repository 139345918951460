import { format, isAfter, isBefore } from 'date-fns';

import {
  CardProductId,
  CreditCard,
  CreditCardInterface,
  CreditCardStatusCode,
  PaymentCardProductType,
  TeamMembersRequest,
  TeamMembersRequestType,
} from '__generated__/GQL';
import basicPhysicalCard from 'assets/images/paymentCards/basicPhysicalCard.png';
import basicPhysicalCardOld from 'assets/images/paymentCards/basicPhysicalCardOld.png';
import premiumPhysicalCard from 'assets/images/paymentCards/premiumPhysicalCard.png';
import premiumPhysicalCardOld from 'assets/images/paymentCards/premiumPhysicalCardOld.png';
import rebrandedBasicPhysicalCard from 'assets/images/paymentCards/rebranded/basic@2x.png';
import rebrandedPremiumPhysicalCard from 'assets/images/paymentCards/rebranded/premium@2x.png';
import rebrandedVirtualCard from 'assets/images/paymentCards/rebranded/virtual@2x.png';
import virtualBudgetCard from 'assets/images/paymentCards/rebranded/virtualBudget.png';
import rebrandedTemporaryVirtualCard from 'assets/images/paymentCards/rebranded/virtualTemp.png';

export const formatPan = (pan: string): string =>
  pan
    .replace(/(.{4})/g, (s) => `${s} `)
    .split('*')
    .join('•');

const CARD_DESIGN_SWITCH_DATE = new Date('2021-05-19T00:00:00');
const CARD_DESIGN_REBRANDED_SWITCH_DATE = new Date('2023-12-13T00:00:00');

export const isOldCardDesign: <
  TCreditCard extends Pick<CreditCardInterface, 'createdAt'>,
>(
  creditCard: TCreditCard,
) => boolean = ({ createdAt }) =>
  isBefore(new Date(createdAt), CARD_DESIGN_SWITCH_DATE);

export const isNewRebrandedCardDesign: <
  TCreditCard extends Pick<CreditCardInterface, 'createdAt'>,
>(
  creditCard: TCreditCard,
) => boolean = ({ createdAt }) =>
  isAfter(new Date(createdAt), CARD_DESIGN_REBRANDED_SWITCH_DATE);

export const sortByStatusCodes =
  (statusCodes: CreditCardStatusCode[]) =>
  <TCreditCard extends Pick<CreditCardInterface, 'status'>>(
    first: TCreditCard,
    second: TCreditCard,
  ) =>
    statusCodes.indexOf(first.status) - statusCodes.indexOf(second.status);

export const getCardAssetSrc = <
  TCreditCard extends Pick<
    CreditCardInterface,
    'isPhysical' | 'isPremium' | 'createdAt' | 'cardProductId'
  > &
    Partial<Pick<CreditCardInterface, 'cardProductType'>> &
    Partial<Pick<CreditCard, 'deactivateAt'>>,
>(
  creditCard: TCreditCard,
) => {
  const { cardProductId, cardProductType, isPhysical, isPremium } = creditCard;

  if (cardProductType === PaymentCardProductType.VirtualBudget) {
    return virtualBudgetCard;
  }

  if (cardProductType === PaymentCardProductType.VirtualTemporary) {
    return rebrandedTemporaryVirtualCard;
  }

  if (cardProductType === PaymentCardProductType.Virtual) {
    return rebrandedVirtualCard;
  }

  if (!isPhysical) {
    if (cardProductId === CardProductId.Budget) {
      return virtualBudgetCard;
    }

    return creditCard.deactivateAt
      ? rebrandedTemporaryVirtualCard
      : rebrandedVirtualCard;
  }

  // is Physical
  if (isNewRebrandedCardDesign(creditCard)) {
    return isPremium
      ? rebrandedPremiumPhysicalCard
      : rebrandedBasicPhysicalCard;
  }

  if (isNewRebrandedCardDesign(creditCard)) {
    return isPremium
      ? rebrandedPremiumPhysicalCard
      : rebrandedBasicPhysicalCard;
  }

  const isOldDesign = isOldCardDesign(creditCard);
  const basicAssetSrc = isOldDesign ? basicPhysicalCardOld : basicPhysicalCard;
  const premiumAssetSrc = isOldDesign
    ? premiumPhysicalCardOld
    : premiumPhysicalCard;

  return isPremium ? premiumAssetSrc : basicAssetSrc;
};

export const getCardRequestAssetSrc = <
  TCardRequest extends Pick<TeamMembersRequest, 'type'>,
>(
  cardRequest: TCardRequest,
) => {
  switch (cardRequest.type) {
    case TeamMembersRequestType.PhysicalPaymentCardOrder:
      return rebrandedPremiumPhysicalCard;
    case TeamMembersRequestType.VirtualPaymentCardOrder:
      return rebrandedVirtualCard;
    case TeamMembersRequestType.VirtualBudgetCardOrder:
      return virtualBudgetCard;
    default:
      return rebrandedVirtualCard;
  }
};

export const formatExpiryDate = (date: number) => format(date, 'MM/yy');
