import {
  AlertStatus,
  createStandaloneToast,
  UseToastOptions,
} from '@chakra-ui/react';

import SunshineToast from 'components/_core/SunshineToast';
import theme from 'theme';

const { toast, ToastContainer } = createStandaloneToast({ theme });

/**
 * A function that displays a Toast outside the current React lifecycle.
 * If you're in a React context, use the hook instead.
 */
const createToast = ({
  status,
  title,
  ...props
}: Omit<UseToastOptions, 'description'> & { status: AlertStatus }) =>
  toast({
    position: 'top-right',
    render: ({ onClose }) => (
      <SunshineToast
        onClose={onClose}
        variant={status === 'success' ? status : 'error'} // chakra variant can be 'loading', 'info' or 'warning' but we consider them as errors
      >
        {title}
      </SunshineToast>
    ),
    ...props,
  });

export default createToast;

export { ToastContainer };
