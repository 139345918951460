import { FC } from 'react';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import Description from './components/Description';
import Header from './components/Header';
import Image from './components/Image';
import Title from './components/Title';
import { COMPONENT_NAME, StylesProvider } from './context';
import { ModalContextProvider } from './hooks/useModalContext';

interface ModalParts {
  Body: typeof ModalBody;
  Description: typeof Description;
  Footer: typeof ModalFooter;
  Header: typeof Header;
  Image: typeof Image;
  Title: typeof Title;
}

export type ModalProps = Omit<ChakraModalProps, 'onClose'> & {
  // make onClose optional
  onClose?: ChakraModalProps['onClose'];
};

type ModalType = ModalParts & FC<ModalProps>;

const defaultCloseFn = () => {
  // Mandatory for ChakraModal even though we don't need one for not closable modals
};

const Modal: ModalType = ({ children, onClose, ...props }) => {
  const isClosable = !!onClose;
  const onCloseFn = onClose || defaultCloseFn;
  const styles = useMultiStyleConfig(COMPONENT_NAME, props);

  return (
    <ModalContextProvider value={{ isClosable }}>
      <StylesProvider value={styles}>
        <ChakraModal
          closeOnOverlayClick={isClosable}
          onClose={onCloseFn}
          {...props}
        >
          <ModalOverlay />
          <ModalContent>{children}</ModalContent>
        </ChakraModal>
      </StylesProvider>
    </ModalContextProvider>
  );
};

Modal.Body = ModalBody;
Modal.Description = Description;
Modal.Footer = ModalFooter;
Modal.Header = Header;
Modal.Image = Image;
Modal.Title = Title;

export default Modal;
