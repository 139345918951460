import { FormEvent, useCallback, useContext, useState } from 'react';
import { Box } from '@chakra-ui/react';
import PhoneNumberParser, { parsePhoneNumber } from 'awesome-phonenumber';

import {
  DEFAULT_COUNTRY_CODE,
  getFormattedPhoneNumber,
  getRegionCodeForCountryCode,
} from 'common/phoneNumber';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import PhoneInput from 'components/Login/PhoneNumber/PhoneInput';
import InputErrorMessage from 'features/Bento/atoms/InputErrorMessage/InputErrorMessage';
import { PageLayout } from 'features/Bento/components';
import { SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';
import logger from 'helpers/logger';

import { GuidancePhone } from '../../../../components/Guidance';
import { model } from '../../machine';
import { Intro } from '../../machine/machine';
import locales from './locales';

/**
 * A function returning the initial country code
 */
const getInitialCountryCode = (initialPhoneNumber: string | null): string => {
  if (!initialPhoneNumber) {
    return PhoneNumberParser.getCountryCodeForRegionCode(
      DEFAULT_COUNTRY_CODE,
    ).toString();
  }

  return getFormattedPhoneNumber({
    countryCode: DEFAULT_COUNTRY_CODE,
    phoneNumber: initialPhoneNumber,
  }).formattedCountryCode;
};

/**
 * A function returning the initial phone
 */
const getInitialPhone = (initialPhoneNumber: string | null): string => {
  if (!initialPhoneNumber) {
    return '';
  }

  return getFormattedPhoneNumber({
    countryCode: DEFAULT_COUNTRY_CODE,
    phoneNumber: initialPhoneNumber,
  }).formattedPhone;
};

/**
 * A page where we ask the user their phone number
 * Most of the code used here comes from one of the following files:
 * - src/components/Login/PhoneNumber/index.tsx
 * - src/components/Login/LoginForm/usePhoneSubmit.ts
 */
const Phone: SignupPage<Intro> = ({ send, state }) => {
  const { phoneNumber } = state.context;
  const Layout = useContext(OBLayoutContext);
  const [countryCode, setCountryCode] = useState<string>(() =>
    getInitialCountryCode(phoneNumber),
  );
  const [phone, setPhone] = useState<string>(() =>
    getInitialPhone(phoneNumber),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Updates phone and countryCode based on the last input to phoneFormatterRef.
  const updatePhoneStateWithNumber = useCallback(
    (updatedCountryCode: string, updatedPhone: string) => {
      const { formattedCountryCode, formattedPhone } = getFormattedPhoneNumber({
        countryCode: updatedCountryCode,
        phoneNumber: updatedPhone,
      });

      setCountryCode(formattedCountryCode);
      setPhone(formattedPhone);
    },
    [],
  );

  const onUpdateCountryCode = useCallback(
    (updatedCountryCode: string) => {
      updatePhoneStateWithNumber(updatedCountryCode, phone);
    },
    [phone, updatePhoneStateWithNumber],
  );

  const onUpdatePhone = useCallback(
    (updatedPhone: string) => {
      updatePhoneStateWithNumber(countryCode, updatedPhone);
    },
    [countryCode, updatePhoneStateWithNumber],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>) => {
      event.preventDefault();

      const parsedPhoneNumber = parsePhoneNumber(phone, {
        regionCode: getRegionCodeForCountryCode(countryCode),
      });

      try {
        setErrorMessage('');
        setIsLoading(true);

        if (!parsedPhoneNumber.valid) {
          setErrorMessage(locales.invalidPhone);
          setIsLoading(false);

          return;
        }

        send(model.events.SUBMIT_PHONE(parsedPhoneNumber.number.e164));
      } catch (e) {
        logger.error(e, { context: { phone } });
        setErrorMessage(locales.blockedByServer);
        setIsLoading(false);
      }
    },
    [phone, countryCode, send],
  );

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Layout
        actions={
          <PageLayout.Actions onBack={() => send(model.events.PREV())} />
        }
        data-testid="phone"
        footer={
          <Button
            isDisabled={phone === '' || countryCode === ''}
            isLoading={isLoading}
            marginTop="space-24"
            type="submit"
            width="100%"
          >
            {locales.cta}
          </Button>
        }
        guidance={<GuidancePhone />}
      >
        <Heading marginBottom="space-20" size="lg">
          {locales.title}
        </Heading>

        <Text size="sm">{locales.help}</Text>

        <PhoneInput
          countryCode={countryCode}
          onUpdateCountryCode={onUpdateCountryCode}
          onUpdatePhone={onUpdatePhone}
          phone={phone}
        />

        {errorMessage ? (
          <InputErrorMessage>{errorMessage}</InputErrorMessage>
        ) : null}
      </Layout>
    </Box>
  );
};

export default Phone;
