/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8468%3A39450&t=GNHsXVlDt2Zpruph-1
 */

import { SystemStyleObject, ThemeTypings } from '@chakra-ui/react';
import { getColor, SystemStyleFunction } from '@chakra-ui/theme-tools';

const EXPANDED_MIN_WIDTH = '19.5rem'; // 312px
const ICON_SELECTOR = '.chakra-button__icon';
const ICON_DELTA_POSITION = '-space-4';

export enum Variant {
  Primary = 'primary',
  Secondary = 'secondary',
  Discreet = 'discreet',
  Tertiary = 'tertiary', // TODO(REB-17): remove this variant (not present in the rebranded design system)
  Danger = 'danger',
  InlinePrimary = 'inline-primary',
  InlineSecondary = 'inline-secondary',
}

export const isExpandableVariant = (variant: Variant) => {
  const EXPANDABLE_VARIANTS = [
    Variant.Primary,
    Variant.Secondary,
    Variant.Discreet,
    Variant.Danger,
  ];

  return EXPANDABLE_VARIANTS.includes(variant);
};

const getIsInlineVariant = (variant: Variant) => variant.startsWith('inline-');

const getBorderBottom = (
  styles: SystemStyleObject,
  { isLoading, variant }: { isLoading: boolean; variant: Variant },
) =>
  getIsInlineVariant(variant)
    ? {
        _after: {
          ...styles,
          bottom: '3px',
          content: '""',
          height: '1.5px',
          left: 0,
          opacity: isLoading ? 0 : 1,
          position: 'absolute',
          right: 0,
        },
      }
    : undefined;

interface GetIconStylesProps {
  isExpanded: boolean;
  iconPosition: 'left' | 'right';
  buttonPadding: ThemeTypings['space'];
  variant: Variant;
}

const getIconStyles = ({
  buttonPadding,
  iconPosition,
  isExpanded,
  variant,
}: GetIconStylesProps) => {
  if (getIsInlineVariant(variant)) {
    return {};
  }

  return isExpanded && isExpandableVariant(variant)
    ? {
        // when expanded, center the text in the button
        // by putting the icon in an absolute position
        position: 'absolute',
        ...(iconPosition === 'left'
          ? { left: buttonPadding }
          : { right: buttonPadding }),
      }
    : {
        // as in the designs, move the icon slightly closer to edge of the button
        ...(iconPosition === 'left'
          ? { marginLeft: ICON_DELTA_POSITION }
          : { marginRight: ICON_DELTA_POSITION }),
      };
};

const baseStyle: SystemStyleObject = {
  _disabled: {
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
  alignItems: 'center',
  borderRadius: 'radius-full',
  cursor: 'pointer',
  display: 'inline-flex',
  fontWeight: 'weight-500',
  justifyContent: 'center',
  position: 'relative',
  transitionDuration: 'duration-200',
  transitionProperty: 'common',

  [`${ICON_SELECTOR}`]: {
    margin: 0,
  },
};

export type Size = 'md' | 'sm';

const getGap = ({ size, variant }: { size: Size; variant: Variant }) => {
  const isInlineVariant = getIsInlineVariant(variant);

  const sizeToGap: Record<Size, string> = {
    md: isInlineVariant ? 'space-8' : 'space-12',
    sm: isInlineVariant ? 'space-6' : 'space-8',
  };

  return sizeToGap[size];
};

const mdSize: SystemStyleFunction = ({ iconPosition, isExpanded, variant }) => {
  const buttonPadding = 'space-24';
  const isInlineVariant = getIsInlineVariant(variant);

  return {
    fontSize: 'font-15',
    gap: getGap({ size: 'md', variant }),
    height: {
      base: isInlineVariant ? '2.5rem' /* 40px */ : '3rem', // 48px
      md: isInlineVariant ? '1.875rem' /* 30px */ : '2.5rem', // 40px
    },
    minWidth:
      isExpanded && isExpandableVariant(variant)
        ? EXPANDED_MIN_WIDTH
        : '2.5rem',
    paddingX: buttonPadding,

    [`${ICON_SELECTOR}`]: {
      fontSize: `font-16`,
      ...getIconStyles({ buttonPadding, iconPosition, isExpanded, variant }),
    },
  };
};

const smSize: SystemStyleFunction = ({ iconPosition, isExpanded, variant }) => {
  const buttonPadding = 'space-16';

  return {
    fontSize: 'font-13',
    gap: getGap({ size: 'sm', variant }),
    height: '2rem', // 32px
    minWidth:
      isExpanded && isExpandableVariant(variant) ? EXPANDED_MIN_WIDTH : '2rem',
    paddingX: buttonPadding,

    [`${ICON_SELECTOR}`]: {
      fontSize: `font-12`,
      ...getIconStyles({ buttonPadding, iconPosition, isExpanded, variant }),
    },
  };
};

const sizes: Record<Size, SystemStyleFunction> = {
  md: mdSize,
  sm: smSize,
};

const getVariant =
  (variant: Variant): SystemStyleFunction =>
  ({ isLoading, theme }) => ({
    ...(getIsInlineVariant(variant) && {
      borderRadius: 'radius-6',
      paddingX: 'space-0',
    }),

    _disabled: {
      ...getBorderBottom(
        { backgroundColor: `button-${variant}-borderBottom-disabled` },
        { isLoading, variant },
      ),
      _hover: {
        ...getBorderBottom(
          { backgroundColor: `button-${variant}-borderBottom-disabled` },
          { isLoading, variant },
        ),
        backgroundColor: `button-${variant}-bg-disabled`,
        borderBottomColor: `button-${variant}-borderBottom-disabled`,
        color: `button-${variant}-text-disabled`,
      },
      backgroundColor: `button-${variant}-bg-disabled`,
      borderBottomColor: `button-${variant}-borderBottom-disabled`,
      color: `button-${variant}-text-disabled`,
    },
    _focusVisible: {
      boxShadow: `0 0 0 2px white, 0 0 0 4px ${getColor(theme, 'blue.600')}`,
      outline: 0,
    },
    _hover: {
      ...getBorderBottom(
        { backgroundColor: `button-${variant}-borderBottom-hover` },
        { isLoading, variant },
      ),
      backgroundColor: `button-${variant}-bg-hover`,
      borderBottomColor: `button-${variant}-borderBottom-hover`,
      color: `button-${variant}-text-hover`,
    },
    ...getBorderBottom(
      { backgroundColor: `button-${variant}-borderBottom` },
      { isLoading, variant },
    ),
    backgroundColor: `button-${variant}-bg`,
    color: `button-${variant}-text`,
    position: 'relative',
    variant,
  });

const variants: Record<Variant, SystemStyleFunction> = {
  danger: getVariant(Variant.Danger),
  discreet: getVariant(Variant.Discreet),
  'inline-primary': getVariant(Variant.InlinePrimary),
  'inline-secondary': getVariant(Variant.InlineSecondary),
  primary: getVariant(Variant.Primary),
  secondary: getVariant(Variant.Secondary),
  tertiary: getVariant(Variant.Discreet),
};

const defaultProps = {
  size: 'md',
  variant: Variant.Primary,
};

const ButtonTheme = {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};

export default ButtonTheme;
