import { FC } from 'react';

import SunshineLoader from 'components/_core/SunshineLoader';
import Column from 'components/Column';

import { LoadingMessage } from './styles';
import { LoaderProps } from './types';

// TODO(matt): Merge this with the top level Loader component
const Loader: FC<LoaderProps> = ({ message, ...otherProps }) => (
  <Column {...otherProps}>
    <SunshineLoader />
    {message === undefined ? null : <LoadingMessage>{message}</LoadingMessage>}
  </Column>
);

export default Loader;
