import { FC, useContext } from 'react';
import { Flex } from '@chakra-ui/react';

import OBSidebarContext from 'features/Bento/libs/OBSidebarContext';

/**
 * SignupLayout sidebar
 */
const SignupSidebar: FC = () => {
  const sidebar = useContext(OBSidebarContext);

  return (
    <Flex
      align="start"
      background="white"
      flexDirection="column"
      height="100vh"
      justify="space-between"
      padding="space-40"
    >
      {sidebar}
    </Flex>
  );
};

export default SignupSidebar;
