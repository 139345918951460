import { Box, Text } from '@chakra-ui/react';

import { FCWithChildren } from 'common/types';

type SidebarStepProps = {
  stepName: string;
  substepName: string;
  isCurrent?: boolean;
};

/**
 * A component displaying a step from the signup sidebar
 * */
const SidebarStep: FCWithChildren<SidebarStepProps> = ({
  children,
  isCurrent,
  stepName,
  substepName,
}) => {
  return (
    <Box>
      <Text
        fontWeight="weight-500"
        marginBottom="space-8"
        variant={isCurrent ? 'primary' : 'disabled'}
      >
        {stepName}
      </Text>

      <Text
        marginBottom="space-8"
        variant={isCurrent ? 'secondary' : 'disabled'}
      >
        {substepName}
      </Text>

      {children}
    </Box>
  );
};

export default SidebarStep;
