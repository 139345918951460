import { createMachine } from 'xstate';
import { pure } from 'xstate/lib/actions';

import shared from 'common/bento/shared';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import { AnyMachineEvent } from 'features/Bento/types/Abstract';

import actions, { Action } from './actions';
import guards, { Guard } from './guards';
import model, { Context } from './model';
import services, { Service } from './services';

export enum State {
  INIT = 'init',

  IDENTITY_REVIEW_ERROR = 'identity-review-error',

  IDENTITY_FORM = 'identity-form',
  IDENTITY_RECAP = 'identity-recap',
  IDENTITY_SEND = 'identity-send',

  GUIDE = 'guide',
  BIOMETRIC_CONSENT = 'biometric-consent',

  START_IDENTITY_VERIFICATION = 'start-identity-verification',

  ONFIDO_NATIVE_IDENTITY_CHECK = 'onfido-native-identity-check',
  ONFIDO_NATIVE_IDENTITY_ERROR = 'onfido-native-identity-error',

  ONFIDO_IDENTITY_CHECK = 'onfido-identity-check',

  FINISH_IDENTITY_VERIFICATION = 'finish-identity-verification',

  SUCCESS = 'success',

  DONE = 'done',
  ABORT = 'abort',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'identity',

    initial: State.INIT,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.INIT]: {
        always: [
          {
            cond: Guard.IsIdentityReviewInvalid,
            target: State.IDENTITY_REVIEW_ERROR,
          },
          {
            cond: Guard.CanEditIdentityInformation,
            target: State.IDENTITY_FORM,
          },
          {
            target: State.IDENTITY_RECAP,
          },
        ],
      },

      [State.IDENTITY_REVIEW_ERROR]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: [
            {
              cond: Guard.CanEditIdentityInformation,
              target: State.IDENTITY_FORM,
            },
            {
              target: State.IDENTITY_RECAP,
            },
          ],
          PREV: State.ABORT,
        },
      },

      [State.IDENTITY_FORM]: {
        entry: shared.actions.trackView(),

        on: {
          PREV: [
            {
              cond: Guard.IsIdentityReviewInvalid,
              target: State.IDENTITY_REVIEW_ERROR,
            },
            {
              target: State.ABORT,
            },
          ],
          SUBMIT: State.IDENTITY_SEND,
        },
      },

      [State.IDENTITY_RECAP]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.GUIDE,
          PREV: [
            {
              cond: Guard.IsIdentityReviewInvalid,
              target: State.IDENTITY_REVIEW_ERROR,
            },
            {
              target: State.ABORT,
            },
          ],
        },
      },

      [State.IDENTITY_SEND]: {
        invoke: {
          onDone: [
            {
              actions: Action.AssignDefaultIdDocumentCase,
              target: State.GUIDE,
            },
          ],
          onError: {
            actions: shared.actions.error(),
            target: State.IDENTITY_FORM,
          },
          src: Service.UpdateIdentity,
        },
      },

      [State.GUIDE]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.BIOMETRIC_CONSENT,
          PREV: [
            {
              cond: Guard.CanEditIdentityInformation,
              target: State.IDENTITY_FORM,
            },
            {
              target: State.IDENTITY_RECAP,
            },
          ],
        },
      },

      [State.BIOMETRIC_CONSENT]: {
        entry: [
          shared.actions.trackView(),
          shared.actions.serverTrack({
            event: 'Onboarding Biometric Consent Asked',
          }),
        ],

        on: {
          NEXT: [
            {
              actions: shared.actions.serverTrack({
                event: 'Onboarding Biometric Consent Accepted',
              }),
              target: State.START_IDENTITY_VERIFICATION,
            },
          ],
          PREV: State.GUIDE,
        },
      },

      [State.START_IDENTITY_VERIFICATION]: {
        invoke: {
          onDone: [
            {
              actions: Action.AssignIdentityCheckContext,
              cond: shared.guards.isInWebView,
              target: State.ONFIDO_NATIVE_IDENTITY_CHECK,
            },
            {
              actions: Action.AssignIdentityCheckContext,
              target: State.ONFIDO_IDENTITY_CHECK,
            },
          ],
          onError: {
            actions: shared.actions.error(),
            target: State.IDENTITY_FORM,
          },
          src: Service.StartIdentityVerification,
        },
      },

      [State.ONFIDO_NATIVE_IDENTITY_CHECK]: {
        // This state shows the native ID check screen, we want to track when it's shown
        entry: shared.actions.trackView(),

        invoke: {
          onDone: [
            {
              cond: Guard.HasNativeIdCheckFailed,
              target: State.ONFIDO_NATIVE_IDENTITY_ERROR,
            },
            {
              cond: Guard.HasNativeIdCheckSucceeded,
              target: State.DONE,
            },
            { target: State.IDENTITY_FORM },
          ],

          onError: {
            target: State.ONFIDO_NATIVE_IDENTITY_ERROR,
          },

          src: Service.CompleteNativeIdCheck,
        },

        on: {
          PREV: State.GUIDE,
        },
      },

      [State.ONFIDO_NATIVE_IDENTITY_ERROR]: {
        on: {
          PREV: State.IDENTITY_FORM,
        },
      },

      [State.ONFIDO_IDENTITY_CHECK]: {
        entry: shared.actions.trackView(),

        on: {
          ONFIDO_SUCCESS: State.FINISH_IDENTITY_VERIFICATION,
          PREV: [
            {
              cond: Guard.CanEditIdentityInformation,
              target: State.IDENTITY_FORM,
            },
            {
              target: State.IDENTITY_RECAP,
            },
          ],
        },
      },

      [State.FINISH_IDENTITY_VERIFICATION]: {
        invoke: {
          onDone: {
            actions: pure((context) =>
              context.idCheckContext
                ? shared.actions.track({
                    name: 'Onboarding ID Completed',
                    properties: {
                      idSupplier: context.idCheckContext.provider.toLowerCase(),
                    },
                  })
                : undefined,
            ),
            target: State.SUCCESS,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.ABORT,
          },
          src: Service.FinishIdentityVerification,
        },
      },

      [State.SUCCESS]: {
        entry: shared.actions.trackView(),

        on: {
          NEXT: State.DONE,
          PREV: State.DONE,
        },
      },

      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },

      [State.ABORT]: {
        data: {
          status: BentoModuleDoneStatus.ABORT,
        },
        type: 'final',
      },
    },
  },

  {
    actions,
    guards,
    services,
  },
);

export default machine;
