import { menuAnatomy } from '@chakra-ui/anatomy';
import { PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools';

type Parts = typeof menuAnatomy;

const baseStyleList: SystemStyleObject = {
  background: '#fff',
  borderRadius: 'radius-6',
  borderWidth: '1px',
  boxShadow: 'sm',
  color: 'inherit',
  minWidth: '14rem',
  paddingY: 'space-8',
  zIndex: 'dropdown',
};

const baseStyleItem: SystemStyleObject = {
  _active: {
    background: 'gray.200',
  },
  _disabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  _expanded: {
    background: 'gray.100',
  },
  _focus: {
    background: 'gray.100',
  },
  paddingX: 'space-12',
  paddingY: 'space-6',
  transitionDuration: 'duration-50',
  transitionProperty: 'background',
  transitionTimingFunction: 'ease-in',
};

const baseStyleGroupTitle: SystemStyleObject = {
  fontSize: 'font-14',
  fontWeight: 'weight-500',
  marginX: 'space-16',
  marginY: 'space-8',
};

const baseStyleCommand: SystemStyleObject = {
  opacity: 0.6,
};

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  marginY: 'space-8',
  opacity: 0.6,
};

const baseStyleButton: SystemStyleObject = {
  textAlign: 'left',
  transitionDuration: 'duration-200',
  transitionProperty: 'common',
};

const baseStyle: PartsStyleObject<Parts> = {
  button: baseStyleButton,
  command: baseStyleCommand,
  divider: baseStyleDivider,
  groupTitle: baseStyleGroupTitle,
  item: baseStyleItem,
  list: baseStyleList,
};

const MenuTheme = {
  baseStyle,
  parts: menuAnatomy.keys,
};

export default MenuTheme;
