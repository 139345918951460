import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    step1: {
      accountOpeningTag: 'Opening your pro account',
      capitalDepositTag: 'Capital deposit',
      companyCreationTag: 'Company creation',
      label: 'Registration',
      name: 'Step 1',
    },
    step2: {
      label: 'Your company',
      name: 'Step 2',
    },
    step3: {
      label: 'Supporting documents',
      name: 'Step 3',
    },
  },

  fr: {
    step1: {
      accountOpeningTag: 'Création de compte pro',
      capitalDepositTag: 'Dépôt de capital',
      companyCreationTag: 'Création d’entreprise',
      label: 'Inscription',
      name: 'Étape 1',
    },
    step2: {
      label: 'Votre entreprise',
      name: 'Étape 2',
    },
    step3: {
      label: 'Justificatifs',
      name: 'Étape 3',
    },
  },
});
