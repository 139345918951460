import { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from '@chakra-ui/react';

import creditCardImg from 'assets/brand/credit-card@2x.png';

import Container from '../../../../layouts/FeaturesLayout/components/Container';
import Description from '../../../../layouts/FeaturesLayout/components/Description';
import Illustration from '../../../../layouts/FeaturesLayout/components/Illustration';
import Title from '../../../../layouts/FeaturesLayout/components/Title';
import locales from './locales';

interface PaymentCardsProps {
  onTutorialsDone: () => void;
}

const PaymentCards: FC<PaymentCardsProps> = ({ onTutorialsDone }) => {
  const history = useHistory();
  const handleLinkClick = async () => {
    await onTutorialsDone();
    history.push('/cards');
  };

  return (
    <Container>
      <Illustration src={creditCardImg} />

      <Title>
        {locales.title}
        <Link fontSize="inherit" onClick={handleLinkClick}>
          {locales.tab}
        </Link>
      </Title>

      <Description>{locales.description}</Description>
    </Container>
  );
};

export default PaymentCards;
