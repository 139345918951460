import { createContext, useContext } from 'react';

import { BentoLayout, BentoLayoutSize } from './types';

export const LayoutContext = createContext<{
  Layout: BentoLayout;
  layoutSize: BentoLayoutSize;
}>({
  Layout: (() => null) as unknown as BentoLayout,
  layoutSize: BentoLayoutSize.base,
});

export const useLayoutContext = () => useContext(LayoutContext);
