import { FC, Fragment } from 'react';
import { Box } from '@chakra-ui/react';

import { CompanyInvitationRole } from '__generated__/GQL';
import Accordion from 'components/_core/Accordion';
import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import Column from 'components/Column';
import Logo from 'components/Logo';
import TrackView from 'features/Analytics/TrackView';

import AccountAlreadyExists from '../AccountAlreadyExists';
import CguWithHiddenEmail from '../CguWithHiddenEmail';
import CreateEmail from '../CreateEmail';
import Passcode from '../Passcode';
import PhoneNumber from '../PhoneNumber';
import RedeemInviteePhoneNumber from '../RedeemInvitePhoneNumber';
import ResetPasscode from '../ResetPasscode';
import * as S from '../styles';
import VerificationCode from '../VerificationCode';
import locales from './locales';
import { SentPhoneNumberWrapper, WrongInvitTitleWrapper } from './styles';
import { ComponentProps, LoginStep } from './types';

const LoginForm: FC<ComponentProps> = ({
  confirmResetPasscodeProps,
  createEmailSubmitProps,
  createPasscodeSubmitProps,
  currentStep,
  invitationInfo,
  isOnSignupPage,
  loginType,
  onBack,
  onLogin,
  onStartRegistering,
  passcodeSubmitProps,
  phoneSubmitProps,
  setCurrentStep,
  verificationCodeSubmitProps,
}) => {
  const showIfCurrentStepIs = (
    expectedStep: LoginStep,
    output: React.ReactNode,
  ): React.ReactNode | null => (expectedStep === currentStep ? output : null);

  let footerContent;

  if (loginType === 'sign_up') {
    if (currentStep === 'createPhone') {
      footerContent = (
        <>
          <Text>
            {
              locales.haveAccount[
                invitationInfo?.role === CompanyInvitationRole.LegalBeneficiary
                  ? 'beneficiary'
                  : 'regular'
              ]
            }
          </Text>
          <Button onClick={onLogin} variant="inline-secondary">
            {locales.login}
          </Button>
        </>
      );
    }
    if (currentStep === 'createPasscode') {
      footerContent = (
        <Callout>
          <Callout.Content>{locales.twoFactor}</Callout.Content>
        </Callout>
      );
    }
  } else if (currentStep === 'redeemInvite') {
    footerContent =
      invitationInfo?.role === CompanyInvitationRole.LegalBeneficiary ? (
        <Accordion width="full">
          <Accordion.Item title={locales.invitedBeneficiary.helpQuestion}>
            <Accordion.Text>
              {locales.invitedBeneficiary.helpAnswer}
            </Accordion.Text>
          </Accordion.Item>
        </Accordion>
      ) : (
        <Callout>
          <Callout.Content>{locales.phoneAuth}</Callout.Content>
        </Callout>
      );
  } else if (currentStep !== 'wrongInviteSlug') {
    footerContent = (
      <>
        <Text>{locales.noAccount}</Text>
        <Button onClick={onStartRegistering} variant="inline-primary">
          {locales.openAccount}
        </Button>
      </>
    );
  }

  return (
    <Fragment>
      <Box marginBottom="space-80">
        <a href="https://shine.fr" rel="noopener noreferrer" target="_blank">
          <Logo />
        </a>
      </Box>

      <S.ContentWrapper>
        {showIfCurrentStepIs(
          'phone',
          <>
            <TrackView pageName="Login" />
            <PhoneNumber
              title={locales.phoneNumberTitle}
              withNoBackButton
              {...phoneSubmitProps}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'passcode',
          <>
            <TrackView pageName="Passcode" />
            <Passcode onBack={onBack} {...passcodeSubmitProps} />
          </>,
        )}
        {showIfCurrentStepIs(
          'confirmResetPasscode',
          <>
            <TrackView pageName="ConfirmResetPasscode" />
            <ResetPasscode onBack={onBack} {...confirmResetPasscodeProps} />
          </>,
        )}
        {showIfCurrentStepIs(
          'verificationCode',
          <>
            <TrackView pageName="VerificationCode" />
            <VerificationCode
              onBack={onBack}
              {...verificationCodeSubmitProps}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'cguWithHiddenEmail',
          <>
            <TrackView pageName="CguWithHiddenEmail" />
            <CguWithHiddenEmail
              invitationInfo={invitationInfo}
              onBack={onBack}
              phone={verificationCodeSubmitProps.phone}
              setCurrentStep={setCurrentStep}
              {...createEmailSubmitProps}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'redeemInvite',
          <>
            <TrackView pageName="RedeemInvite" />
            <RedeemInviteePhoneNumber
              invitationInfo={invitationInfo}
              {...phoneSubmitProps}
              onStartRegistering={onStartRegistering}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'createPhone',
          <>
            <TrackView pageName="Signup" />
            <PhoneNumber
              onBack={onBack}
              subtitle={
                locales.phoneNumberRegisteringSubtitle[
                  invitationInfo?.role ===
                  CompanyInvitationRole.LegalBeneficiary
                    ? 'beneficiary'
                    : 'regular'
                ]
              }
              title={locales.phoneNumberRegisteringTitle}
              withNoBackButton={isOnSignupPage}
              {...phoneSubmitProps}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'createPasscode',
          <>
            <TrackView pageName="PasscodeCreation" />
            <Passcode onBack={onBack} {...createPasscodeSubmitProps} />
          </>,
        )}
        {showIfCurrentStepIs(
          'accountAlreadyExists',
          <>
            <TrackView pageName="AccountAlreadyExists" />
            <AccountAlreadyExists
              onClickLogin={() => {
                // we use location.reload to wipe stale state of different login types
                window.location.reload();
              }}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'createEmail',
          <>
            <TrackView pageName="EmailCreation" />
            <CreateEmail
              onBack={onBack}
              phone={verificationCodeSubmitProps.phone}
              setCurrentStep={setCurrentStep}
              {...createEmailSubmitProps}
            />
          </>,
        )}
        {showIfCurrentStepIs(
          'createVerificationCode',
          <div>
            <TrackView pageName="VerificationCodeCreation" />
            <VerificationCode
              onBack={onBack}
              {...verificationCodeSubmitProps}
            />
            <SentPhoneNumberWrapper>
              <Text variant="light">{locales.sentSmsTo}</Text>
              <Text fontWeight="weight-500">
                {verificationCodeSubmitProps.phone}
              </Text>
            </SentPhoneNumberWrapper>

            <Button
              alignSelf="flex-start"
              isDisabled={verificationCodeSubmitProps.resendSmsLoading}
              marginTop="space-8"
              onClick={() => verificationCodeSubmitProps.resendSms()}
              variant="inline-primary"
            >
              {locales.sendANewCode}
            </Button>
          </div>,
        )}
        {showIfCurrentStepIs(
          'wrongInviteSlug',
          <Column>
            <TrackView pageName="WrongInviteSlug" />
            <WrongInvitTitleWrapper>
              <Heading>{locales.wrongInvitTitle}</Heading>
            </WrongInvitTitleWrapper>
            <Text variant="light">{locales.wrongInvitBody}</Text>
          </Column>,
        )}

        <S.ContentFooter>{footerContent}</S.ContentFooter>
      </S.ContentWrapper>
    </Fragment>
  );
};

export default LoginForm;
