import { useRef } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import AddressForm, {
  AddressFormElement,
} from 'features/TeamOnboarding/Onboarding/components/AddressForm';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import { AddressSchema } from '../../../../components/AddressForm/addressSchema';
import locales from './locales';

interface AddressFormPageProps {
  isSubmitting: boolean;
  initialValues: AddressSchema;
  onSubmit: (values: AddressSchema) => void;
}

/**
 * A page that asks for the user’s address
 */
const AddressFormPage = asBentoPage<AddressFormPageProps>(
  ({ initialValues, isSubmitting, Layout, onPrev, onSubmit }) => {
    const addressOverlay = useDisclosure();

    const addressFormRef = useRef<AddressFormElement>(null);

    const handlePrev = () => {
      if (addressFormRef.current?.isEditing) {
        addressFormRef.current?.reset();
        return;
      }
      onPrev();
    };

    return (
      <Layout
        nav={
          <Navigation
            isCollapsed={addressOverlay.isOpen ? true : undefined}
            onClose={addressOverlay.isOpen ? addressOverlay.onClose : undefined}
            onPrev={handlePrev}
          />
        }
      >
        <Box>
          <AddressForm
            addressOverlayClose={addressOverlay.onClose}
            initialValues={initialValues}
            isOverlayOpen={addressOverlay.isOpen}
            isSending={isSubmitting}
            locales={{
              description: locales.description,
              title: {
                default: locales.title.default,
                fill: locales.title.fill,
                verify: locales.title.verify,
              },
            }}
            onContinue={onSubmit}
            onOverlayClose={addressOverlay.onClose}
            onOverlayOpen={addressOverlay.onOpen}
            ref={addressFormRef}
          />
        </Box>
      </Layout>
    );
  },
);

export default AddressFormPage;
