import { Box, HStack, Square, Stack, Text, VStack } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import SunshineIcon from 'components/_core/SunshineIcon';
import Logo from 'components/Logo';

import { locales } from './locales';

export interface OnboardingGuidanceProps {
  currentStep: number;
}

export const OnboardingGuidance = asBentoPage<OnboardingGuidanceProps>(
  ({ currentStep }) => {
    const steps = [...locales.steps] as {
      title: string;
      description: string;
    }[];

    const squircleText = (stepsLength: number, index: number) => {
      if (index === stepsLength - 1) {
        return <Logo width="30px" />;
      }
      if (index < currentStep) {
        return <SunshineIcon name="tick" size="icon-20" />;
      }

      return (
        <Text
          color={currentStep === index ? 'white' : 'grey.800'}
          fontWeight="weight-500"
        >
          {(index + 1).toString()}
        </Text>
      );
    };

    const squircleBackgroundColor = (index: number) => {
      if (index === currentStep) {
        return 'grey.800';
      }

      if (index < currentStep) {
        return 'green.300';
      }

      return 'grey.200';
    };

    const stepDescription = (
      description: string | undefined,
      index: number,
    ) => {
      if (index < currentStep) {
        return <Text>{locales.completed}</Text>;
      }

      return description ? (
        <Text color={currentStep < index ? 'grey.500' : 'grey.800'}>
          {description}
        </Text>
      ) : null;
    };

    return (
      <Stack gap="space-8">
        {steps.map((step, index) => (
          <>
            <HStack alignItems="start" gap="space-16" key={step.title}>
              <VStack alignSelf="stretch" gap="space-8">
                <Square
                  backgroundColor={squircleBackgroundColor(index)}
                  borderRadius="radius-10"
                  fontSize="13px"
                  size="space-40"
                  title={step.title}
                >
                  {squircleText(steps.length, index)}
                </Square>

                {index < steps.length - 1 ? (
                  <Box
                    alignSelf="center"
                    backgroundColor="grey.200"
                    height="100%"
                    width="1.5px"
                  />
                ) : null}
              </VStack>

              <VStack
                alignItems="start"
                gap="space-2"
                justifyContent="center"
                marginBottom={step.description ? 'space-26' : 'space-32'}
                minHeight="space-40"
              >
                <Text
                  color={currentStep < index ? 'grey.600' : 'primary'}
                  fontWeight="weight-500"
                  size="md"
                >
                  {step.title}
                </Text>

                {stepDescription(step.description, index)}
              </VStack>
            </HStack>
          </>
        ))}
      </Stack>
    );
  },
);

export default OnboardingGuidance;
