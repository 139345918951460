import { createModel } from 'xstate/lib/model';

import baseEvents from 'common/bento/config/baseEvents';
import { KycDocument } from 'components/OnfidoDocumentVerifier/types';

import { UseIdentityModuleData } from '../hooks/useIdentityModuleData';

export const initialContext = {
  documents: [],
  getModuleData: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
} as {
  documents: KycDocument[];
  getModuleData: () => NonNullable<UseIdentityModuleData>;
};

const model = createModel(initialContext, {
  events: {
    ...baseEvents,

    SUCCESS: (data: KycDocument[]) => ({ data }),
  },
});

export default model;

export type Context = typeof initialContext;
export type Model = typeof model;
