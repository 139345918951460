import { IntercomAPI } from 'features/Intercom';
import logger from 'helpers/logger';

import getAffiliateSource from '../../../../libs/getAffiliateSource';
import mapOnboardingAccountTypeToAccountType from '../../libs/mapAccountTypeToOnboardingAccountType';
import { Context } from '../model';

type SignupZapPayload = {
  email: string;
  name: string;
  phone: string | null;
  intercomVisitorID: string | null;
  isMicro: 'True' | 'False' | null;
  referrerCode: string | null;
  aff_href: string | null;
  aff_source: string | null;
  lead_source: 'Signup Flow';
  onboarding_account_type?: string;
  'lp-source': string | null;
  datenow: string;
};

/**
 * This list should mirror the list used in the authentification service.
 * @see 'shinetools/authentication-service/api/helpers/shouldSendSms.js'
 */
const COUNTRY_CALLING_CODE_ALLOWLIST = [
  '+262', // La Réunion & Mayotte
  '+32', // Belgium
  '+33', // France "métropolitaine"
  '+34', // Spain
  '+39', // Italy
  '+41', // Switzerland
  '+43', // Austria
  '+49', // Germany
  '+590', // Guadeloupe & Saint-Barthélemy
  '+594', // Guyane
  '+596', // Martinique
];

const isBlackListed = (num: string) =>
  !COUNTRY_CALLING_CODE_ALLOWLIST.some((countryCode) =>
    num.startsWith(countryCode),
  );

/**
 * This function returns a XState action that, when executed,
 * trigger our big boy Zapier with a big payload with plenty of things inside.
 *
 * @see https://zapier.com/app/editor/110562059?redirect=true
 */
const triggerMarketingZap = (context: Context) => {
  const isMicro = context.accountType === 'shine-start' ? 'True' : 'False';

  const payload: SignupZapPayload = {
    aff_href: context.affiliate,
    aff_source: getAffiliateSource(context.affiliate),
    datenow: new Date().toISOString(),
    email: context.form.email,
    intercomVisitorID: IntercomAPI('getVisitorId') ?? null,
    isMicro,
    lead_source: 'Signup Flow',
    'lp-source': context.affiliate,
    name: `${context.form.firstName} ${context.form.lastName}`,
    phone: context.phoneNumber,
    referrerCode: context.referral?.code ?? null,
    ...(context.accountType && {
      onboarding_account_type:
        mapOnboardingAccountTypeToAccountType[context.accountType],
    }),
    /**
     * Keeping these here for the record.
     * These fields were sent by the shine.fr Landbot to the Zapier
     * and I didn't reproduce them at the moment.
     */
    // isMultiKYC: 'False',
    // utm_medium: '@utm_medium',
    // utm_campaign: '@utm_campaign',
    // hasBusinessPlanInterest: '@want_business',
  };

  if (isBlackListed(payload.phone ?? '')) {
    logger.warn(
      `This phone number's country is blacklisted. TODO: handle blacklisted phones differently`,
      payload,
    );

    return null;
  }

  return fetch('https://hooks.zapier.com/hooks/catch/2912704/o0xltm2/', {
    body: JSON.stringify(payload),
    method: 'POST',
  }).then(() => {
    logger.info('ℹ️ Zapier endpoint called with payload :', payload);
  });
};

export default triggerMarketingZap;
