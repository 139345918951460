import { FC } from 'react';
import { partition } from 'ramda';

import SunshineCard from 'components/_core/SunshineCard';
import { compareConnectionDate } from 'helpers/auth/devices';

import { AuthenticationDevice } from '../../types';
import Device from './components/Device';
import { Props } from './types';

const DeviceList: FC<Props> = ({ devices, request }) => {
  const [[newlyAuthorizedDevice], trustedDevices] = partition(
    (device) =>
      device.authenticationDeviceId === request.authenticationDeviceId,
    devices,
  );

  const sortedDevices = [
    newlyAuthorizedDevice as AuthenticationDevice,
    ...trustedDevices.sort(compareConnectionDate),
  ];

  return (
    <SunshineCard.Group>
      {sortedDevices.map((device) => (
        <Device
          device={device}
          isNew={
            device?.authenticationDeviceId === request?.authenticationDeviceId
          }
          key={device.authenticationDeviceId}
        />
      ))}
    </SunshineCard.Group>
  );
};

export default DeviceList;
