import { FC, ReactNode } from 'react';
import { Box, BoxProps, chakra, Image } from '@chakra-ui/react';
import { IconName } from '@shinetools/sunshine-icons';

import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

export interface HeaderProps extends Omit<BoxProps, 'title'> {
  title: ReactNode;
  description?: ReactNode;
  imageSrc?: string;
  imageWidthPx?: number;
  imageHeightPx?: number;
  linkUrl?: string;
  linkText?: string;
  linkIcon?: IconName;
}

export const DEFAULT_IMAGE_SIZE_PX = 90;

const getDescriptionElement = (description: HeaderProps['description']) => {
  if (description === undefined) {
    return null;
  }

  return (
    <Box marginTop="space-8">
      {typeof description === 'string' ? (
        <Text>{description}</Text>
      ) : (
        description
      )}
    </Box>
  );
};

const getImageElement = ({
  imageHeightPx,
  imageSrc,
  imageWidthPx,
}: Pick<HeaderProps, 'imageSrc' | 'imageHeightPx' | 'imageWidthPx'>) => {
  if (imageSrc === undefined) {
    return null;
  }
  return (
    <Image
      // Leave alt attribute empty for decorative images
      // see https://www.w3.org/WAI/tutorials/images/decorative/
      alt=""
      height={imageHeightPx}
      marginBottom="space-16"
      src={imageSrc}
      width={imageWidthPx}
    />
  );
};

const Header: FC<HeaderProps> = ({
  description,
  imageHeightPx = DEFAULT_IMAGE_SIZE_PX,
  imageSrc,
  imageWidthPx = DEFAULT_IMAGE_SIZE_PX,
  linkIcon,
  linkText,
  linkUrl,
  title,
  ...props
}) => {
  const imageElement = getImageElement({
    imageHeightPx,
    imageSrc,
    imageWidthPx,
  });
  const descriptionElement = getDescriptionElement(description);

  return (
    <Box marginBottom="space-32" {...props}>
      {imageElement}
      <Heading size="lg">{title}</Heading>
      {descriptionElement}
      {linkUrl && linkText ? (
        <Button
          as={chakra.a}
          href={linkUrl}
          marginTop="space-16"
          target="_blank"
          variant="inline-primary"
          {...(linkIcon && { icon: linkIcon })}
        >
          {linkText}
        </Button>
      ) : null}
    </Box>
  );
};

export default Header;
