import { FC } from 'react';
import { useHistory } from 'react-router';

import ButtonBadge from 'components/ButtonBadge';
import TrackView from 'features/Analytics/TrackView';

import NoAccessScreen from '../NoAccessScreen';
import locales from './locales';

const ACCOUNT_PENDING_TEST_ID = 'AccountPendingMessage';

const AccountPendingMessage: FC = () => {
  const history = useHistory();
  const goToHome = (): void => {
    history.replace('/');
  };

  return (
    <>
      <TrackView pageName="AccountPending" />
      <NoAccessScreen
        button={
          <ButtonBadge icon="arrow-left" onClick={goToHome}>
            {locales.link}
          </ButtonBadge>
        }
        locales={locales}
        testId={ACCOUNT_PENDING_TEST_ID}
      />
    </>
  );
};

export default AccountPendingMessage;
