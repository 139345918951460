import { AbstractState } from 'features/Bento/types/Abstract';

import { State } from '../../../machine';
import locales from '../locales';

/**
 * Returns the flow name depending of the given state
 */
const getFlowNameFromState = (state: AbstractState) => {
  if (state.matches(State.STANDARD)) {
    return locales.step1.accountOpeningTag;
  }

  if (state.matches(State.CAP_DEP)) {
    return locales.step1.capitalDepositTag;
  }

  if (state.matches(State.COMPANY_CREATION)) {
    return locales.step1.companyCreationTag;
  }

  return undefined;
};

export default getFlowNameFromState;
