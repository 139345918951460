import { FC, lazy, Suspense, useState } from 'react';
import { Box, Center } from '@chakra-ui/react';

import Loader from 'components/Loader';

import PdfFallback from './components/PdfFallback';
import shouldUseNativePdfViewer from './utils/shouldUseNativePdfViewer';

const CustomPdfViewer = lazy(
  () => import('./components/CustomPdfViewer/CustomPdfViewer'),
);

export interface PdfViewerProps {
  src: string;
}

/**
 * A PDF viewer that will use the native PDF viewer if possible,
 * or dynamically import a custom one when needed.
 */
const PdfViewer: FC<PdfViewerProps> = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  if (shouldUseNativePdfViewer) {
    return (
      <Center height="full" width="full">
        {isLoading ? (
          <Box position="absolute">
            <Loader />
          </Box>
        ) : null}

        <Center
          as="object"
          data={src}
          height="full"
          onLoad={() => {
            setIsLoading(false);
          }}
          type="application/pdf"
          width="full"
        >
          {/* Fallback component for edge cases - allows downloading the file */}
          <PdfFallback src={src} />
        </Center>
      </Center>
    );
  }

  return (
    <Suspense
      fallback={
        <Center height="full" width="full">
          <Loader />
        </Center>
      }
    >
      <CustomPdfViewer src={src} />
    </Suspense>
  );
};

export default PdfViewer;
