import { ContextFrom, EventObject, MachineOptions } from 'xstate';

import { AbstractMachine } from 'features/Bento/types/Abstract';

import { baseContext } from '../../../../features/Bento/libs/machines';

type MachineOverrides<TMachine extends AbstractMachine> = Partial<
  MachineOptions<ContextFrom<TMachine>, EventObject> & {
    context: Partial<ContextFrom<TMachine>>;
  }
>;

/**
 * Given a machine, this service returns an augmented version of the machine,
 * ready to use as a Bento module.
 *
 * Accepts an optional function that will be called with the context of the parent machine,
 * and generate overrides for the config of the child machine.
 */
const spawn =
  <TMachine extends AbstractMachine, TParentContext extends typeof baseContext>(
    machine: TMachine,
    overrides?: (ctx: TParentContext) => MachineOverrides<TMachine>,
  ) =>
  (parentContext: TParentContext) => {
    /**
     * Separate the overriden context and the overriden options.
     */
    const { context, ...options } = overrides?.(parentContext) ?? {};

    /**
     * Augment the child machine config and context.
     */
    return machine.withConfig(
      /**
       * Override MachineOptions, like guards, actions, services...
       */
      options,
      /**
       * Merge the default initial context of the child machine,
       * the `baseContext` helpers coming from the parent machine,
       * and the optional overrides specified by the parent machine.
       */
      {
        ...machine.context,
        getUserContext: parentContext.getUserContext,
        ...context,
      },
    );
  };

export default spawn;
