import { createContext, useContext } from 'react';
import { addMinutes, isAfter } from 'date-fns';

import { companyContextStorage } from 'common/hooks/useCompanyContext/companyContextStorage';
import webView from 'common/webView';
import localStorageObjectSchema from 'common/zod/localStorageObjectSchema';
import { reset } from 'features/Analytics/analytics';
import { COMPANY_INPUT_STORAGE_KEY } from 'features/Onboarding/modules/BusinessInfo/companyProfileStorage';
import logger from 'helpers/logger';

import { revoke } from './service';
import { User, userSchema } from './user.schema';

const USER_STORAGE_KEY = 'user';

const STORAGE_KEYS_TO_CLEAR = [
  USER_STORAGE_KEY,
  COMPANY_INPUT_STORAGE_KEY,
  'onboarding.applicationType',
  'onboarding.company',
  'missingSiret.postponedAt',
  companyContextStorage.storageKey,
];

type UpdateUserProp = Pick<User, 'phone' | 'email' | 'zip'>;

export const UserContext = createContext<User>({ token: '', uid: '' });

export const useUser = (): User => useContext(UserContext);

export const saveUser = (user: User): void => {
  window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
};

export const getUser = (): null | User => {
  const user = localStorageObjectSchema
    .pipe(userSchema.nullable())
    .safeParse(window.localStorage.getItem(USER_STORAGE_KEY));

  if (!user.success) {
    logger.warn(`[Auth] Invalid user in localStorage`, {
      context: { validationError: user.error.message },
      error: user.error,
      labels: { filepath: 'helpers/auth/auth.ts' },
    });
    window.localStorage.removeItem(USER_STORAGE_KEY);
    return null;
  }

  return user.data;
};

export const updateUser = (props: UpdateUserProp): void => {
  const user = getUser();
  if (!user) {
    return;
  }
  saveUser({
    ...user,
    ...props,
  });
};

export const getTokens = (): null | JSONObject => {
  const user = getUser();

  if (!user) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return { access_token: user.token };
};

// Do not use it directly. You should use authenticationEmitter.emit(AuthenticationEvents.OnLogout) instead
export const logout = ({
  deviceToken,
  phone,
  shouldHardReload = true,
}: {
  phone?: string;
  deviceToken: string;
  shouldHardReload?: boolean;
}): void => {
  STORAGE_KEYS_TO_CLEAR.forEach((key) => {
    window.localStorage.removeItem(key);
  });
  reset();
  if (phone) {
    revoke({ deviceToken, phone });
  }

  webView.postMessage({ action: 'LOGOUT_REQUESTED' });

  // Force a clean reload of the page,
  // and redirect to the homepage
  if (shouldHardReload) {
    window.location.href = '/';
  }
};

export const decodeToken = (token: string): AccessToken | null => {
  try {
    return JSON.parse(atob(token.split('.')[1] as string));
  } catch (e) {
    logger.error(e, { tags: { feature: 'login' } });
    return null;
  }
};

export interface AccessToken {
  exp: number;
  sub: string;
}

/**
 * Returns false either if the user's token has already expired or will expire at some point in the next five minutes.
 * This means they won't get failed requests while using the app.
 */
export const hasExpiredOrWillSoon = ({ exp }: AccessToken): boolean => {
  const fiveMinutesFromNow = addMinutes(new Date(), 5);
  const expiryDate = new Date(exp * 1000);
  return isAfter(fiveMinutesFromNow, expiryDate);
};

export enum AuthenticationEvents {
  OnLogout = 'ON_LOGOUT',
  OnSoftLogout = 'ON_SOFT_LOGOUT',
  OnUnauthorized = 'ON_UNAUTHORIZED',
}

type Listener = () => void;

const listeners: { [event in AuthenticationEvents]?: Listener[] } = {};

export const authenticationEmitter = {
  addListener: (event: AuthenticationEvents, listener: Listener): void => {
    listeners[event] = [...(listeners[event] || []), listener];
  },
  emit: (event: AuthenticationEvents): void => {
    (listeners[event] || []).forEach((listener: Listener): void => listener());
  },
  removeListener: (event: AuthenticationEvents, listener: Listener): void => {
    listeners[event] = (listeners[event] || []).filter(
      (item): boolean => item !== listener,
    );
  },
};
