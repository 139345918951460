import { Divider, forwardRef, Stack } from '@chakra-ui/react';

import Card, { CardProps } from '../..';

const Group = forwardRef<CardProps, 'div'>(({ children, ...props }, ref) => (
  <Card alignItems="stretch" padding="space-0" ref={ref} {...props}>
    <Stack
      className="Group"
      divider={<Divider borderColor="grey.2" />}
      spacing="space-0"
      sx={{
        /**
         * when grouped, the border between two Cards will be replaced by the Stack divider
         * so we need to hide the Card's one without causing a layout shift by removing it
         */
        '.Card': {
          borderColor: 'transparent',
        },
      }}
    >
      {children}
    </Stack>
  </Card>
));

export default Group;
