import { MutationResult } from '@apollo/client';
import { createModel } from 'xstate/lib/model';

import { IdDocumentCase, KycProvider } from '__generated__/GQL';
import { ModuleDataWithBootstrap } from 'common/bento/lib/bootstrap/types';
import { KycDocument } from 'components/OnfidoDocumentVerifier/types';
import { baseEvents } from 'features/Bento/libs/machines';

import { VerificationIdUpdateIdentityMutation } from '../graphql/mutations/verificationIdUpdateIdentity.gql';
import { AdminIdentityModuleDataQuery } from '../graphql/queries/adminIdentityModuleData.gql';
import { OnfidoError } from '../libs/getOnfidoError';
import { IdentitySchema } from '../libs/identitySchema';
import { Service } from './services';

interface IdentityModuleData
  extends ModuleDataWithBootstrap,
    AdminIdentityModuleDataQuery {}

interface IdCheckContext {
  token: string;
  provider: KycProvider;
}

const initialContext = {
  getModuleData: (() => {
    throw new Error('NOT_IMPLEMENTED');
  }) as () => IdentityModuleData,
  idCheckContext: null as IdCheckContext | null,
  idDocumentCase: null as IdDocumentCase | null,
};

export type NativeIdCheckOutcome =
  | { status: 'done' }
  | { status: 'canceled' }
  | {
      status: 'error';
      error: OnfidoError | null;
    };

const creators = {
  events: {
    ...baseEvents,
    'done.invoke.completeNativeIdCheck': (data: NativeIdCheckOutcome) => ({
      data,
    }),

    'done.invoke.loadIdentity': (data: IdentitySchema) => ({ data }),

    'done.invoke.updateIdentity': (
      data: MutationResult<VerificationIdUpdateIdentityMutation>,
    ) => ({ data }),

    [`done.invoke.${Service.StartIdentityVerification}` as const]: (
      data: IdCheckContext,
    ) => ({ data }),

    ONFIDO_SUCCESS: (data: KycDocument[]) => ({ data }),
    RESTART_ID_CHECK: () => ({}),
    SUBMIT: (data: IdentitySchema) => ({ data }),

    SUBMIT_HAS_NO_RESIDENCY_DOCUMENT: () => ({}),
    SUBMIT_HAS_RESIDENCY_CARD: (data: { isExpired: boolean }) => ({ data }),
    SUBMIT_HAS_VISA: () => ({}),

    SUCCESS: (data: KycDocument[]) => ({ data }),

    'xstate.init': () => ({}),
  },
};

export type Context = typeof initialContext;

const model = createModel(initialContext, creators);

export default model;

export type Model = typeof model;
