import { AllHTMLAttributes } from 'react';
import { getColorValue } from '@shinetools/sunshine';
import styled from 'styled-components';

export const PasscodeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 85px;

  @media (max-width: 445px) {
    min-height: 75px;
  }
  @media (max-width: 345px) {
    min-height: ${({ theme }) => theme.spacings.abs40}px;
  }
`;

interface PasscodeDigitInputProps {
  hide: boolean;
  length: number;
}

export const PasscodeDigitInput = styled.input.attrs(
  ({ hide }: { hide: boolean }): AllHTMLAttributes<HTMLInputElement> =>
    hide
      ? { type: 'password' }
      : {
          max: 9,
          min: 0,
          type: 'number',
        },
)<PasscodeDigitInputProps>`
  border-radius: 8px;
  outline: none;
  border: 1px solid ${getColorValue('grey.400')};
  font-size: ${({ theme }) => theme.texts.typeScale(4)}px;
  padding: ${({ theme }) => theme.spacings.abs16}px;
  background-color: white;
  margin-right: ${({ theme }) => theme.spacings.abs16}px;
  width: 60px;
  min-height: 85px;
  text-align: center;
  :focus {
    background-color: ${getColorValue('grey.300')};
    border-color: ${getColorValue('grey.800')};
  }
  :last-of-type {
    margin-right: 0;
  }
  ${({ length, theme }) =>
    length === 6
      ? `
    @media (max-width: 445px) {
      width: 50px;
      min-height: 75px;
    }
    @media (max-width: 387px) {
      margin-right: ${theme.spacings.abs8}px;
    }
    @media (max-width: 345px) {
      font-size: ${theme.texts.typeScale(2)}px;
      width: 45px;
      min-height: ${theme.spacings.abs40}px;
    }
    @media (max-width: 315px) {
      margin-right: ${theme.spacings.abs4}px;
    }
  `
      : `
    @media (max-width: 295px) {
      margin-right: ${theme.spacings.abs8}px;
    }
    @media (max-width: 270px) {
      width: 50px;
      min-height: 75px;
    }
  `}
`;
