/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=3593%3A13698&t=GPJAwKvqGR7vNcGD-1
 */

import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  borderColor: 'border',
  borderStyle: 'solid',
  opacity: 1,
};

const mdSize: SystemStyleObject = {
  borderWidth: '1px',
};

const lgSize: SystemStyleObject = {
  borderWidth: '6px',
};

type Size = 'md' | 'lg';

const sizes: Record<Size, SystemStyleObject> = {
  lg: lgSize,
  md: mdSize,
};

const defaultProps = {
  size: 'md',
};

const DividerTheme = {
  baseStyle,
  defaultProps,
  sizes,
};

export default DividerTheme;
