import { MessageWebToNative } from '../webViewMessages';
import getWebViewAPI from './getWebViewAPI';

/**
 * A function that can be called to communicate with Shine's React Native app.
 * It will only do something in the context of our React Native webview.
 */
const postMessage = (message: MessageWebToNative) => {
  const webViewAPI = getWebViewAPI();

  if (webViewAPI) {
    webViewAPI.postMessage(JSON.stringify(message));
  }
};

export default postMessage;
