import { FC } from 'react';
import { BoxProps, Circle, ThemeTypings } from '@chakra-ui/react';

import Icon from 'components/_core/Icon';
import SHINE_COLORS from 'theme/foundations/colors/shineColors';

import { IconProps } from '../Icon/Icon';

const SIZES = {
  md: 32,
  sm: 24,
};

export type CircleIconProps = BoxProps &
  Pick<IconProps, 'name'> & {
    size?: keyof typeof SIZES;
    colorScheme?: keyof typeof SHINE_COLORS;
  };

/**
 * An icon contained in a Circle.
 *
 * Two ways to define the color :
 * - use `colorScheme` with a Shine color for a default nuance pick,
 * - or specify a `background` color and a `color` prop to customize it.
 *
 * @example <caption>
 *  // Both render the same thing.
 *  <CircleIcon colorScheme="turquoise" name="edit" size="sm" />
 *  <CircleIcon background="turquoise.4" color="turquoise.-1" name="edit" size="sm" />
 * </caption>
 */
const CircleIcon: FC<CircleIconProps> = ({
  colorScheme,
  name,
  size = 'md',
  ...props
}) => {
  const colorSchemeProps = colorScheme
    ? { background: `${colorScheme}.3`, color: `${colorScheme}.-1` }
    : undefined;

  return (
    <Circle
      boxSize={`${SIZES[size]}px`}
      display="inline-flex"
      {...colorSchemeProps}
      {...props}
    >
      <Icon
        name={name}
        size={
          `${
            SIZES[size] / 2
          }px` as ThemeTypings['components']['SunshineIcon']['sizes']
        }
      />
    </Circle>
  );
};

export default CircleIcon;
