import { FC } from 'react';
import { Box, BoxProps, Select } from '@chakra-ui/react';

import getCountryByCode from 'common/countries/getCountryByCode';
import useCurrentLanguage from 'common/hooks/useCurrentLanguage';
import * as Form from 'components/_core/form';
import { FRANCE_COUNTRY_CODE } from 'features/Bento/libs/constants';

import locales from './locales';

/**
 * A pseudo select with only France as an option
 */
const SelectOnlyFrance: FC<BoxProps> = (props) => {
  const translationLanguage = useCurrentLanguage();
  const franceCountry = getCountryByCode({
    countryCode: FRANCE_COUNTRY_CODE,
    translationLanguage,
  });

  return (
    <Box {...props}>
      <Form.Field
        hint={locales.onlyAvailableInFrance}
        label={locales.countryLabel}
      >
        <Select cursor="pointer" isDisabled>
          <option value={FRANCE_COUNTRY_CODE}>{franceCountry?.label}</option>
        </Select>
      </Form.Field>
    </Box>
  );
};

export default SelectOnlyFrance;
