import { FC, Fragment } from 'react';

import TrackView from 'features/Analytics/TrackView';

import Component from './component';

const ParallelOnboardingRestriction: FC = () => (
  <Fragment>
    <TrackView pageName="ParallelOnboardingRestriction" />
    <Component />
  </Fragment>
);

export default ParallelOnboardingRestriction;
