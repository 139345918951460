import { OBErrorCode } from 'features/Bento/libs/errors/OBErrorCode';
import { ExtractEventFrom } from 'features/Bento/types/Machine';

import { Context, Model } from './model';

const guards = {
  isPasscodeIdentical: (
    context: Context,
    event: ExtractEventFrom<Model, 'SUBMIT_PASSCODE'>,
  ) => event.data === context.passcode,

  isPasscodeSet: (context: Context) => Boolean(context.passcode),

  isPhoneAvailable: (
    context: Context,
    event: ExtractEventFrom<Model, 'done.invoke.checkVerificationCode'>,
  ) => {
    return event.data.available;
  },

  isVerificationCodeError: (
    context: Context,
    event: ExtractEventFrom<Model, 'error.platform.createAccountAndLogin'>,
  ) => {
    const { data: error } = event;

    return error?.message === OBErrorCode.CODE_NOT_MATCH;
  },
};

export default guards;
