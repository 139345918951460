import { Box, useBreakpointValue } from '@chakra-ui/react';
import { PublicStepConfig, PublicStepTypes } from 'onfido-sdk-ui';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import useGetOnfidoInit from 'common/hooks/onfido/useGetOnfidoInit';
import { useUserPhone } from 'common/hooks/useUserPhone';
import OnfidoDocumentVerifier from 'components/OnfidoDocumentVerifier';
import { KycDocument } from 'components/OnfidoDocumentVerifier/types';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';
import { getOnfidoTheme } from 'helpers/onfido';
import useTheme from 'theme/hooks/useTheme';

import locales from './locales';

const ONFIDO_CONTAINER_ID = 'shine-onboarding-onfido-container';

const getOnfidoConfig = (isAllowedToSkipOnfidoSelfie: boolean) => {
  const steps: Array<PublicStepTypes | PublicStepConfig> = [
    {
      options: {
        descriptions: [
          locales.onfidoWelcomeDescription,
          locales.onfidoWelcomeDescriptionTwo,
        ],
        nextButton: locales.onfidoNext,
        title: locales.onfidoWelcomeTitle,
      },
      type: 'welcome',
    },
    {
      options: {
        documentTypes: {
          driving_licence: false,
          national_identity_card: true,
          passport: true,
          residence_permit: true,
        },
        forceCrossDevice: !isAllowedToSkipOnfidoSelfie,
        uploadFallback: isAllowedToSkipOnfidoSelfie,
      },
      type: 'document',
    },
  ];

  if (!isAllowedToSkipOnfidoSelfie) {
    steps.push({
      options: {
        photoCaptureFallback: true,
        requestedVariant: 'video',
      },
      type: 'face',
    });
  }

  steps.push('complete');

  return steps;
};

interface OnfidoIdCheckProps {
  isAllowedToSkipOnfidoSelfie: boolean;
  onSuccess: (documents: KycDocument[]) => void;
  onfidoToken: string;
}

/**
 * A page that verifies the user’s identity with Onfido.
 */
const OnfidoIdCheck = asBentoPage<OnfidoIdCheckProps>(
  ({ isAllowedToSkipOnfidoSelfie, Layout, onfidoToken, onPrev, onSuccess }) => {
    const { phone } = useUserPhone();
    const theme = useTheme();

    const { onfidoInit } = useGetOnfidoInit();

    // lg breakpoint to include landscape mobile orientation. Otherwise
    // Onfido component will be unmounted on rotation since the layout changes
    // TODO(LED-1710): refactor layouts
    const isMobile = !useBreakpointValue({ lg: true });

    const content = (
      <Box height="full">
        <Box
          height="full"
          sx={{
            '.onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeContainer svg': {
              marginX: 'auto',
            },

            '.onfido-sdk-ui-Modal-inner': isMobile
              ? {}
              : {
                  /** Onfido adds height: 37.5em which is not enough with FR locales */
                  height: '42.5em',

                  /** Onfido adds width: 94w which breaks the layout on desktop */
                  width: 'auto',
                },

            /**
             * Onfido relies on the description being shown even with the `hidden` attribute.
             * But Chakra UI's CSSReset rightfully try to hide it.
             */
            'ul[hidden]': {
              display: 'block !important',
            },
          }}
        >
          {onfidoInit !== null ? (
            <OnfidoDocumentVerifier
              containerId={ONFIDO_CONTAINER_ID}
              customUI={getOnfidoTheme(theme)}
              onComplete={onSuccess}
              onfidoInit={onfidoInit}
              steps={getOnfidoConfig(isAllowedToSkipOnfidoSelfie)}
              token={onfidoToken}
              userSmsNumber={phone || undefined}
            />
          ) : null}
        </Box>
      </Box>
    );

    return isMobile ? (
      <Box height="full" width="100vw">
        {content}
      </Box>
    ) : (
      <Layout nav={<Navigation onPrev={onPrev} />}>{content}</Layout>
    );
  },
);

export default OnfidoIdCheck;
