import { useContext } from 'react';

import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import SasuCreationEligibilityForm from '../../components/SasuCreationEligibility';
import { model } from '../../machine';
import { Signup } from '../../machine/machine';
import { SignupPage } from '../../types/SignupPage';

/**
 * A page asking the user if they're eligible to create a SASU
 */
const SasuCreationEligibility: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
    >
      <SasuCreationEligibilityForm onSubmit={() => send(model.events.NEXT())} />
    </Layout>
  );
};

export default SasuCreationEligibility;
