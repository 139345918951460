import { parsePhoneNumber } from 'awesome-phonenumber';
import { differenceInDays } from 'date-fns';
import gql from 'graphql-tag';

import { UserProfile } from '__generated__/GQL';
import { apolloClient } from 'common/graphql/clients';
import {
  hideSensitiveQueryParams,
  hideSensitiveQueryParamsFromUrl,
} from 'features/Analytics/helpers/url';
import { User } from 'helpers/auth/user.schema';
import { capitalizeFirstLetter } from 'helpers/string';

/**
 * Converts a stringified state to PascalCase.
 * Will consider both '-' and '.' delimiter.
 */
export const stateToPascalCase = (input: string) =>
  input.split(/-|\./).map(capitalizeFirstLetter).join('');

/**
 * Returns the user profile based on its journey
 */
export const getUserProfile = (
  isLogged: boolean,
  hasFinishedProfileSetup: boolean,
) => {
  if (hasFinishedProfileSetup) {
    return 'customer';
  }

  return isLogged ? 'prospect' : 'lead';
};

/**
 * Returns whether the given user has created their account less than X days ago.
 */
export const isNewUser = (createdAt: number) => {
  const now = Date.now();
  const diffDays = differenceInDays(now, createdAt);

  return diffDays <= 30;
};

export const buildOnboardingPageViewedProperties = (
  environment: string,
  user: User | null,
) => {
  const uid = user?.uid;
  const data = uid
    ? apolloClient.cache.readFragment<UserProfile>({
        fragment: gql`
          fragment PartialUserProfile on UserProfile {
            country
            createdAt
            hasFinishedProfileSetup
          }
        `,
        id: `UserProfile:{"uid":"${uid}"}`,
      })
    : null;

  const country = data?.country;
  const createdAt = data?.createdAt;
  const hasFinishedProfileSetup = data?.hasFinishedProfileSetup ?? false;

  const isLogged = Boolean(user?.email && user?.phone);
  const userIdObj = user ? { userId: user.uid } : {};
  const userLoginStatusObj = {
    userLoginStatus: isLogged ? 'logged' : 'anonym',
  };

  const { document, location } = window;
  const { href, search } = location;
  const { referrer, title } = document;
  const referrerObj = referrer
    ? { referrer: hideSensitiveQueryParamsFromUrl(referrer) }
    : {};
  const searchParams = new URLSearchParams(hideSensitiveQueryParams(search));
  const hasSearchParams = [...new Set(searchParams.keys())].length > 0;
  const url = hideSensitiveQueryParamsFromUrl(href);
  const pageVirtPathObj = hasSearchParams ? { pageVirtPath: url } : {};

  const userIsNew = createdAt ? isNewUser(createdAt) : true;
  const userProfile = getUserProfile(isLogged, hasFinishedProfileSetup);

  const parsedPhoneNumber = user?.phone ? parsePhoneNumber(user.phone) : null;

  const regionCode = parsedPhoneNumber?.valid
    ? parsedPhoneNumber.regionCode
    : null;

  const userCountry = country || regionCode;
  const userCountryObj = userCountry ? { userCountry } : {};

  const properties = {
    ...userIdObj,
    ...userCountryObj,
    ...userLoginStatusObj,
    ...pageVirtPathObj,
    ...referrerObj,
    name: title,
    pageEnvironment: environment,
    pageSite: 'app.shine',
    pageTemplate: "Parcours d'inscription",
    url,
    userIsNew,
    userProfile,
  };

  return properties;
};
