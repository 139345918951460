import { Text } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import { GetAgreementCatchupContextQuery } from 'features/AgreementCatchup/graphql/queries/getAgreementCatchupContext.gql';
import { OBCard } from 'features/Bento/components';

import Illustration from './assets/documentValid.svg';
import locales from './locales';

interface SuccessProps {
  moduleData: GetAgreementCatchupContextQuery;
  onNext: () => void;
}

/**
 * A page where the user can see the outstanding agreement and accept it.
 */
const Success = asBentoPage<SuccessProps>(({ Layout, onNext }) => (
  <Layout>
    <OBCard marginTop="space-48" textAlign="center">
      <OBCard.Illustration src={Illustration} />

      <OBCard.Body>
        <Heading size="lg">{locales.cardTitle}</Heading>

        <Text>{locales.cardDescription}</Text>
      </OBCard.Body>

      <OBCard.Footer>
        <Button onClick={onNext} width="100%">
          {locales.cta}
        </Button>
      </OBCard.Footer>
    </OBCard>
  </Layout>
));

export default Success;
