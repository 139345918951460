import { UserProfile } from '__generated__/GQL';

import { VerificationStatus } from '../../Verification/lib/steps/types';

interface GetAddressStatusProps {
  userProfile: Pick<UserProfile, 'city' | 'zip' | 'street'>;
}

const getAddressStatus = ({
  userProfile,
}: GetAddressStatusProps): VerificationStatus => {
  if (userProfile.zip && userProfile.city && userProfile.street) {
    return VerificationStatus.Completed;
  }

  return VerificationStatus.ToDo;
};

export default getAddressStatus;
