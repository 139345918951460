import { PropsWithChildren } from 'react';

import * as Form from '../../../form';

type CheckboxGroupProps<TOption = string> = Omit<
  Form.CheckboxGroupProps,
  'checkboxPosition' | 'onChange'
> & {
  onChange: (options: TOption[]) => void;
  value: TOption[];
};

const CheckboxGroup = <TOption extends string | number>(
  props: PropsWithChildren<CheckboxGroupProps<TOption>>,
) => (
  <Form.Checkbox.Group
    /**
     * the `checkboxPosition` is required here because
     * - `Form.Checkbox` renders a space between its label and the checkbox input
     * - since the label is handled by SunshineCard, `Form.Checkbox` will render an empty div
     * - to avoid having this extra space, we switch the empty label position to the left
     */
    checkboxPosition="right"
    width="full"
    {...props}
  />
);

export default CheckboxGroup;
