import { FC } from 'react';
import { Divider, Image, Stack } from '@chakra-ui/react';

import flowerImg from 'assets/brand/flower@2x.png';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import TrackView from 'features/Analytics/TrackView';

import { FullscreenContainer } from '../../styles';
import locales from './locales';

const MOBILE_APP_DOWNLOAD_LINK = 'https://shine-app.onelink.me/MHIy/website';

interface DefaultMobilePageProps {
  onForceAccess: () => void;
}

const DefaultMobilePage: FC<DefaultMobilePageProps> = ({ onForceAccess }) => (
  <FullscreenContainer>
    <TrackView pageName="SmallResScreen" />
    <SunshineCard marginX="auto" maxWidth="600px">
      <SunshineCard.Content alignItems="center" spacing="space-24">
        <Image alt="" height="180px" role="presentation" src={flowerImg} />
        <Heading>{locales.title}</Heading>
      </SunshineCard.Content>

      <Divider marginBottom="space-24" marginTop="space-40" />

      <Stack alignItems="center" paddingBottom="space-20" spacing="space-16">
        <Button
          as="a"
          href={MOBILE_APP_DOWNLOAD_LINK}
          icon="mobile"
          target="_blank"
        >
          {locales.downloadApp}
        </Button>

        <Button onClick={onForceAccess} variant="inline-secondary">
          {locales.forceAccess}
        </Button>
      </Stack>
    </SunshineCard>
  </FullscreenContainer>
);

export default DefaultMobilePage;
