import { pure } from 'xstate/lib/actions';

import shared from 'common/bento/shared';

import { REFERRAL_PARAM } from '../libs/params';
import locales from '../locales';
import model, { CompanyCreationLegalForm, Context } from './model';

const actions = {
  assignCompanyLegalForm: model.assign<'SUBMIT_COMPANY_CREATION_LEGAL_FORM'>({
    companyLegalForm: (context, event) => event.data,
  }),

  assignCompanyLegalFormToCapDep: model.assign<'SUBMIT_HAS_STATUTES'>({
    companyLegalForm: (context, event) => {
      if (event.data) {
        return null;
      }

      return CompanyCreationLegalForm.CAP_DEP;
    },
  }),

  assignCompanyLegalFormToUnknown: model.assign<'SUBMIT_COMPANY_CREATION_TYPE'>(
    {
      companyLegalForm: (context, event) => {
        if (!event.data) {
          return null;
        }

        if (event.data !== 'unknown') {
          return null;
        }

        return CompanyCreationLegalForm.UNKNOWN;
      },
    },
  ),

  assignCompanyProfileType: model.assign<'SUBMIT_COMPANY_PROFILE_TYPE'>({
    companyProfileType: (context, event) => event.data,
  }),
  assignEmptyReferral: model.assign({
    referral: () => null,
  }),
  assignValidReferral: model.assign<'done.invoke.verifyReferral'>({
    referral: (context, event) =>
      event.data
        ? {
            code: event.data.referralCode,
            referrerName: event.data.firstName,
          }
        : null,
  }),
  /**
   * Calling this action following a `history.pop` event will effectively eject the user from the flow.
   * Because a `history.pop` event will **pop an entry from the flow** (our "buffer" entry, see below).
   *
   * This action will pop the last entry, and the user will be redirected to the actual previous page they visited.
   */
  leaveSignup: (context: Context) => {
    context.helpers.getHistory().goBack();
  },

  /**
   * An action that removes the referral query parameter from the URL.
   * Used to clean invalid referral codes.
   */
  removeReferralParam: (context: Context) => {
    const history = context.helpers.getHistory();
    const params = new URLSearchParams(history.location.search);

    params.delete(REFERRAL_PARAM);

    const hasParams = params.toString().length > 0;

    history.replace(
      hasParams
        ? `${history.location.pathname}?${params.toString()}`
        : history.location.pathname,
    );
  },

  /**
   * @todo find some way to type this better.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toastInvalidReferral: pure((context: any, event: any) =>
    shared.actions.toast({
      duration: 8000,
      status: 'error',
      title: locales.formatString(locales.invalidReferral, {
        referral: event.data,
      }),
    }),
  ),

  toastValidReferral: pure(({ referral }: Context) => {
    if (!referral) {
      return undefined;
    }

    return shared.actions.toast({
      duration: 8000,
      status: 'success',
      title: locales.formatString(locales.validReferral, {
        referral: referral.code,
      }),
    });
  }),
};

export default actions;
