/* eslint-disable prefer-destructuring */

import { FC } from 'react';
import { Skeleton, SkeletonProps } from '@chakra-ui/react';

import theme from 'theme';

const DEFAULT_BORDER_RADIUS = theme.space['space-4'];
const DEFAULT_END_COLOR = theme.colors.blue[4];
const DEFAULT_MIN_HEIGHT = theme.space['space-8'];
const DEFAULT_START_COLOR = theme.colors.blue[5];

const PlaceholderLoader: FC<SkeletonProps> = ({ children, ...rest }) => {
  return (
    <Skeleton
      borderRadius={DEFAULT_BORDER_RADIUS}
      endColor={DEFAULT_END_COLOR}
      minHeight={DEFAULT_MIN_HEIGHT}
      startColor={DEFAULT_START_COLOR}
      {...rest}
    >
      {children}
    </Skeleton>
  );
};

export default PlaceholderLoader;
