import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { CSSReset, useBreakpointValue } from '@chakra-ui/react';

import useXStateDevtools from 'common/bento/lib/useXStateDevtools';
import getPage from 'features/Bento/libs/getPage';
import stringifyState from 'features/Bento/libs/machines/stringifyState';
import withFlowWrapper from 'features/Bento/libs/withFlowWrapper';
import { decodeToken, getUser, hasExpiredOrWillSoon } from 'helpers/auth';

import OBLayoutContext from '../../libs/OBLayoutContext';
import OBSidebarContext from '../../libs/OBSidebarContext';
import { getLayout } from './components/Layout';
import SignupSidebar from './components/Sidebar';
import useIntercomStyle from './libs/useIntercomStyle';
import useSignupMachine from './libs/useSignupMachine';
import { model } from './machine';
import { STATE_TO_PAGE } from './pages';

/**
 * A component encapsulating the whole 2021 Signup flow.
 * When rendered, it will instantiate an XState machine and handle the full flow.
 */
const Signup: FC = () => {
  useXStateDevtools();

  const [state, send] = useSignupMachine();

  const Component = getPage(state, STATE_TO_PAGE) ?? (() => null);

  const history = useHistory();

  const intercom = useIntercom();

  useEffect(() => {
    intercom.boot();

    return () => {
      intercom.shutdown();
    };
  }, [intercom]);

  /**
   * Redirect logged-in users to the root of the web-app.
   * Only do it on original Signup mount,
   * so that users are not ejected from the flow
   * when they finish the account module.
   */
  useEffect(() => {
    const user = getUser();

    if (!user) {
      return;
    }

    const decoded = decodeToken(user.token);
    const isLogged = decoded && !hasExpiredOrWillSoon(decoded);

    if (isLogged) {
      history.push('/');
    }
  }, [history]);

  const bp = useBreakpointValue<string>({
    base: 'base',
    lg: 'lg',
    md: 'md',
  });
  const Layout = getLayout(bp || 'base');

  useIntercomStyle(bp);

  return (
    <>
      <CSSReset />

      <OBSidebarContext.Provider value={<SignupSidebar state={state} />}>
        {/* @ts-expect-error Layout is possibly 'undefined'. */}
        <OBLayoutContext.Provider value={Layout}>
          <Component
            machineRef={state.children[stringifyState(state)]}
            model={model}
            send={send}
            state={state}
            userContext={undefined}
          />
        </OBLayoutContext.Provider>
      </OBSidebarContext.Provider>
    </>
  );
};

export default withFlowWrapper(Signup);
