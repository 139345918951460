import { FC } from 'react';
import { MenuDivider, MenuDividerProps } from '@chakra-ui/react';

import { useStyles } from '../../context';

const Divider: FC<MenuDividerProps> = ({ sx, ...props }) => {
  const styles = useStyles();

  return (
    <MenuDivider
      {...props}
      /**
       * We usually use `__css` here, but it's overriden by Chakra's `MenuDivider` component.
       * So we use `sx` which has more specificity
       * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/src/menu/menu-divider.tsx#L15-L17
       */
      sx={{
        ...styles.divider,
        ...sx,
      }}
    />
  );
};

export default Divider;
