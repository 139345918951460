import { ReactElement } from 'react';
import { QueryResult } from '@apollo/client';
import { Center } from '@chakra-ui/react';

import ErrorView from 'components/ErrorView';
import Loader from 'components/Loader';

/**
 * A component handling a strongly-typed Apollo query.
 *
 * Will return a loading indicator while the query loads.
 * Will call the children with a render function once the data is available.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SuspenseQuery = <QueryResultType extends QueryResult<any, any>>({
  children,
  loader,
  query,
}: {
  query: QueryResultType;
  children: (
    data: Exclude<QueryResultType['data'], undefined>,
  ) => ReactElement | null;
  loader?: ReactElement;
}) => {
  /**
   * A generic error view, for unrecoverable errors.
   */
  if (query.error && !query.loading) {
    return <ErrorView />;
  }

  /**
   * A generic loading state.
   * The second operand is mostly to get typings right and should never actually trigger.
   */
  if (query.loading || !query.data) {
    return (
      loader ?? (
        <Center height="100%">
          <Loader />
        </Center>
      )
    );
  }

  /**
   * At this point, query.data is defined and up-to-date.
   */
  return children(query.data);
};

export default SuspenseQuery;
