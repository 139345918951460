import { Flex } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import Header from 'features/TeamOnboarding/Onboarding/components/Header';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import { OnfidoError } from '../../libs/getOnfidoError';
import locales from './locales';

interface NativeIdCheckErrorProps {
  onfidoErrorCode: OnfidoError | null;
}

const NativeIdCheckError = asBentoPage<NativeIdCheckErrorProps>(
  ({ Layout, onfidoErrorCode, onPrev }) => {
    const errorLocales = locales.errors[onfidoErrorCode ?? 'unknown'];

    return (
      <Layout nav={<Navigation onPrev={onPrev} />}>
        <Flex direction="column">
          <Header title={locales.title} />

          <Callout variant="error">
            <Callout.Title>{errorLocales.message}</Callout.Title>
            <Callout.Content>{errorLocales.guidance}</Callout.Content>
          </Callout>

          <Button
            alignSelf="center"
            isExpanded
            marginTop="space-32"
            onClick={onPrev}
            variant="primary"
          >
            {locales.cta}
          </Button>
        </Flex>
      </Layout>
    );
  },
);

export default NativeIdCheckError;
