import LocalizedStrings from 'localized-strings';

import { CompanyInvitationRole } from '__generated__/GQL';

const locales = new LocalizedStrings({
  en: {
    accountCreationCta: 'Provide my information',
    haveAccount: 'I already have an account',
    label: 'Login with my phone number',
    subtitleByRole: {
      [CompanyInvitationRole.Accountant]:
        'Enter your phone number to start creating your access.',
      [CompanyInvitationRole.Employee]:
        'Enter your phone number to start creating your access.',
      [CompanyInvitationRole.LegalBeneficiary]:
        'You have been invited by **{inviterName}** to fill in your information in order to open a Shine pro account for **{companyName}**{trailingDot}',
      [CompanyInvitationRole.AccountHolder]:
        'In order to finalise the opening of {companyName}’s bank account, we need you to complete the following information.',
      [CompanyInvitationRole.Admin]:
        'You have been invited by **{inviterName}** to create your access to **{companyName}**’s Shine account.',
    },
    titleByRole: {
      [CompanyInvitationRole.Accountant]:
        'Welcome to Shine {inviteeFirstName}!',
      [CompanyInvitationRole.Employee]: 'Welcome to Shine {inviteeFirstName}!',
      [CompanyInvitationRole.LegalBeneficiary]: 'Hello {inviteeFirstName}!',
      [CompanyInvitationRole.AccountHolder]: 'Welcome {inviteeFirstName}!',
      [CompanyInvitationRole.Admin]: 'Hello {inviteeFirstName}!',
    },
    welcomeMessage: 'Happy to see you again!',
  },
  fr: {
    accountCreationCta: 'Compléter mes informations',
    haveAccount: 'J’ai déjà un compte Shine',
    label: 'Me connecter avec mon numéro de téléphone',
    subtitleByRole: {
      [CompanyInvitationRole.Accountant]:
        'Entrez votre numéro de téléphone pour commencer à créer votre accès.',
      [CompanyInvitationRole.Employee]:
        'Entrez votre numéro de téléphone pour commencer à créer votre accès.',
      [CompanyInvitationRole.LegalBeneficiary]:
        'Vous avez été invité·e par **{inviterName}** à remplir vos informations afin d’ouvrir un compte professionnel Shine pour **{companyName}**{trailingDot}',
      [CompanyInvitationRole.AccountHolder]:
        'Afin de finaliser l’ouverture du compte bancaire de {companyName}, nous avons besoin que vous complétiez les informations suivantes.',
      [CompanyInvitationRole.Admin]:
        'Vous avez été invité·e par **{inviterName}** à créer votre accès au compte Shine de **{companyName}**.',
    },
    titleByRole: {
      [CompanyInvitationRole.Accountant]:
        'Bienvenue chez Shine {inviteeFirstName}\u00A0!',
      [CompanyInvitationRole.Employee]:
        'Bienvenue chez Shine {inviteeFirstName}\u00A0!',
      [CompanyInvitationRole.LegalBeneficiary]:
        'Bonjour {inviteeFirstName}\u00A0!',
      [CompanyInvitationRole.AccountHolder]:
        'Bienvenue {inviteeFirstName}\u00A0!',
      [CompanyInvitationRole.Admin]: 'Bonjour {inviteeFirstName}\u00A0!',
    },
    welcomeMessage: 'Heureux de vous revoir\u00A0!',
  },
});

export default locales;
