/* eslint-disable react/no-unused-prop-types */

import { FC } from 'react';

import { ActionRequestType } from '../types';
import ActionRequestConfirmation from './component';

interface ActionRequestConfirmationContainerProps {
  actionRequest: ActionRequestType;
  setStep: (step: string) => void;
  close: () => void;
}

const ActionRequestConfirmationContainer: FC<
  ActionRequestConfirmationContainerProps
> = ({ actionRequest, close }) => {
  return (
    <ActionRequestConfirmation actionRequest={actionRequest} close={close} />
  );
};

export default ActionRequestConfirmationContainer;
