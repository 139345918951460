import LocalizedStrings from 'localized-strings';

import { AvailableLanguages } from './types';

const emptyLocalizedStringsInstance = new LocalizedStrings({ en: {}, fr: {} });

/**
 * On app initialization, set the <html> lang attribute to the appropriate language.
 */
document.documentElement.setAttribute(
  'lang',
  emptyLocalizedStringsInstance.getLanguage(),
);

const useCurrentLanguage = (): AvailableLanguages => {
  const language = emptyLocalizedStringsInstance.getLanguage();
  // getLanguage()'s return type is just 'string' so explicitly return either
  // 'fr' or 'en' to match the AvailableLanguages type.
  if (language === 'fr') {
    return 'fr';
  }
  return 'en';
};

export default useCurrentLanguage;
