import { FC } from 'react';

import * as Form from '../../../form';

type RadioGroupProps = Omit<Form.RadioGroupProps, 'radioPosition'>;

const RadioGroup: FC<RadioGroupProps> = (props) => (
  <Form.Radio.Group
    /**
     * the `radioPosition` is required here because
     * - `Form.Radio` renders a space between its label and the radio input
     * - since the label is handled by SunshineCard, `Form.Radio` will render an empty div
     * - to avoid having this extra space, we switch the empty label position to the left
     */
    radioPosition="right"
    {...props}
  />
);

export default RadioGroup;
