import { FC } from 'react';

import Text from 'components/_core/Text';

import { ButtonBadgeContainer, ButtonBadgeIcon } from './styles';
import { ButtonBadgeProps } from './types';

const ButtonBadge: FC<ButtonBadgeProps> = ({ children, icon, ...props }) => (
  <ButtonBadgeContainer hasText={!!children} {...props}>
    {icon !== undefined ? (
      <ButtonBadgeIcon color="inherit" hasText={!!children} name={icon} />
    ) : null}
    {children ? (
      <Text color="inherit" fontWeight="weight-500" variant="primary">
        {children}
      </Text>
    ) : null}
  </ButtonBadgeContainer>
);

export default ButtonBadge;
