// eslint-disable-next-line no-restricted-imports
import features from 'featureFlags';
import { Page } from 'features/Bento/types/Global';

import { CapDep, CompanyCreation, Standard, State } from '../machine';
import accountCreation from '../modules/AccountCreation';
import BeneficiaryInviteReceived from './BeneficiaryInviteReceived';
import CapitalDepositSelectStatutes from './CapitalDepositSelectStatutes';
import CompanyCreationLandbot from './CompanyCreationLandbot';
import CompanyCreationSelectLegalForm from './CompanyCreationSelectLegalForm';
import CompanyCreationSelectType from './CompanyCreationSelectType';
import CompanyCreationSelectTypeV2 from './CompanyCreationSelectTypeV2';
import Home from './Home';
import SasuCreationEligibility from './SasuCreationEligibility';
import SignupLoaderPage from './SignupLoaderPage';
import StandardSelectCompanyProfile from './StandardSelectCompanyProfile';
import Welcome from './Welcome';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyPage = Page<any, any, any>;

export const STATE_TO_PAGE: Record<
  State,
  | AnyPage
  | Record<Standard, AnyPage>
  | Record<CompanyCreation, AnyPage>
  | Record<CapDep, AnyPage>
> = {
  [State.CHECK_REFERRAL]: SignupLoaderPage,
  [State.HOME]: Home,
  [State.STANDARD]: {
    [Standard.INIT_STANDARD_FLOW]: () => null,
    [Standard.SELECT_COMPANY_PROFILE]: StandardSelectCompanyProfile,
    [Standard.ACCOUNT]: accountCreation.component,
    [Standard.WELCOME]: Welcome,
  },
  [State.CAP_DEP]: {
    [CapDep.SELECT_STATUTES]: CapitalDepositSelectStatutes,
    [CapDep.COMPANY_CREATION_LANDBOT]: CompanyCreationLandbot,
    [CapDep.ACCOUNT]: accountCreation.component,
    [CapDep.WELCOME]: Welcome,
  },
  [State.COMPANY_CREATION]: {
    [CompanyCreation.LANDBOT]: CompanyCreationLandbot,
    [CompanyCreation.ACCOUNT]: accountCreation.component,
    [CompanyCreation.WELCOME]: Welcome,
    [CompanyCreation.SASU_WELCOME]: Welcome,
    [CompanyCreation.SELECT_LEGAL_FORM]: CompanyCreationSelectLegalForm,
    [CompanyCreation.SELECT_TYPE]: features.enableNewLandbotFlow
      ? CompanyCreationSelectTypeV2
      : CompanyCreationSelectType,
    [CompanyCreation.ELIGIBILITY_CHECK]: SasuCreationEligibility,
  },
  [State.BENEFICIARY]: BeneficiaryInviteReceived,
  [State.DONE]: Home,
} as const;
