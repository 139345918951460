import { useState } from 'react';
import { Box, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react';

import { IdDocumentCase } from '__generated__/GQL';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import { OBCard } from 'features/Bento/components';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import IDCard from './assets/IDCard@2x.png';
import Selfie from './assets/Selfie@2x.png';
import Shield from './assets/Shield@2x.png';
import locales from './locales';

const HELP_PAGE_URL =
  'https://help.shine.fr/articles/8320272-verifier-son-identite';

const getItems = ({
  idDocumentCase,
  isAllowedToSkipOnfidoSelfie,
}: {
  isAllowedToSkipOnfidoSelfie: boolean;
  idDocumentCase: IdDocumentCase;
}) => {
  const items = [
    <Box key="step1">
      <OBCard.Illustration src={Shield} />

      <OBCard.Body minHeight="250px" textAlign="center">
        <Heading marginBottom="space-16" size="lg">
          {locales.step1.title}
        </Heading>

        <Text marginBottom="space-16">
          {locales.step1.body}
          <Link href={HELP_PAGE_URL} target="_blank" variant="inline">
            {locales.step1.moreInfoLink}
          </Link>
        </Text>
      </OBCard.Body>
    </Box>,

    <Box key="step2">
      <OBCard.Illustration src={IDCard} />

      <OBCard.Body minHeight="250px" textAlign="center">
        <Heading marginBottom="space-16" size="lg">
          {locales.step2[idDocumentCase].title}
        </Heading>

        <Text marginBottom="space-16">
          {locales.step2[idDocumentCase].body}
        </Text>
      </OBCard.Body>
    </Box>,
  ];

  if (!isAllowedToSkipOnfidoSelfie) {
    items.push(
      <Box key="step3">
        <OBCard.Illustration src={Selfie} />

        <OBCard.Body minHeight="250px" textAlign="center">
          <Heading marginBottom="space-16" size="lg">
            {locales.step3.title}
          </Heading>

          <Text marginBottom="space-16">{locales.step3.body}</Text>
        </OBCard.Body>
      </Box>,
    );
  }

  return items;
};

interface GuideProps {
  isAllowedToSkipOnfidoSelfie: boolean;
  idDocumentCase: IdDocumentCase;
  onContinue: () => void;
}

/**
 * A carousel presenting the ID verification of the user.
 */
const Guide = asBentoPage<GuideProps>(
  ({
    idDocumentCase,
    isAllowedToSkipOnfidoSelfie,
    Layout,
    onContinue,
    onPrev,
  }) => {
    const items = getItems({ idDocumentCase, isAllowedToSkipOnfidoSelfie });
    const [carouselIndex, setCarouselIndex] = useState(0);

    return (
      <Layout nav={<Navigation onPrev={onPrev} />}>
        <Flex direction="column" height={{ base: 'full', md: 'auto' }}>
          <OBCard flexGrow={1}>
            <OBCard.Carousel
              onChange={(index) => setCarouselIndex(index)}
              onDone={onContinue}
              value={carouselIndex}
            >
              {items}
            </OBCard.Carousel>

            <OBCard.Footer
              bottom="space-24"
              position={{ base: 'sticky', md: 'initial' }}
            >
              <Grid templateColumns="repeat(3, 1fr)" width="100%">
                <Flex alignItems="center" justifyContent="center">
                  <Button onClick={onContinue} variant="inline-primary">
                    {locales.cta.skip}
                  </Button>
                </Flex>

                <GridItem colSpan={2}>
                  <Button
                    onClick={
                      carouselIndex >= items.length - 1
                        ? onContinue
                        : () => setCarouselIndex(carouselIndex + 1)
                    }
                    width="100%"
                  >
                    {locales.cta.next}
                  </Button>
                </GridItem>
              </Grid>
            </OBCard.Footer>
          </OBCard>
        </Flex>
      </Layout>
    );
  },
);

export default Guide;
