import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    back: 'Back',
  },
  fr: {
    back: 'Précédent',
  },
});
