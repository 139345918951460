import { FC } from 'react';
import { chakra, useMultiStyleConfig } from '@chakra-ui/react';
import { HTMLChakraProps, ThemeTypings } from '@chakra-ui/system';
import { ColorName } from '@shinetools/sunshine';

import SunshineIcon, { IconName } from '../SunshineIcon';
import Text from '../Text';

type TagSize = NonNullable<ThemeTypings['components']['SunshineTag']['sizes']>;
type IconSize = NonNullable<
  ThemeTypings['components']['SunshineIcon']['sizes']
>;

const iconSizeMap: Record<TagSize, IconSize> = {
  md: 'icon-20',
  sm: 'icon-14',
};

export interface SunshineTagProps extends Omit<HTMLChakraProps<'div'>, 'size'> {
  size?: ThemeTypings['components']['SunshineTag']['sizes'];
  iconRight?: IconName;
  colorScheme?: ColorName;
  icon?: IconName;
}

const SunshineTag: FC<SunshineTagProps> = ({
  children,
  colorScheme = 'grey',
  icon,
  iconRight,
  noOfLines,
  size = 'md',
  ...props
}) => {
  const styles = useMultiStyleConfig('SunshineTag', {
    colorScheme,
    size,
  });

  return (
    <chakra.div __css={styles.container} {...props}>
      {icon ? (
        <chakra.svg
          __css={styles.icon}
          as={SunshineIcon}
          name={icon}
          size={iconSizeMap[size]}
        />
      ) : null}

      <chakra.p
        __css={styles.label}
        as={Text}
        noOfLines={noOfLines}
        size={size === 'md' ? 'sm' : 'xs'}
      >
        {children}
      </chakra.p>

      {iconRight ? (
        <chakra.svg
          __css={styles.iconRight}
          as={SunshineIcon}
          name={iconRight}
          size={iconSizeMap[size]}
        />
      ) : null}
    </chakra.div>
  );
};

export default SunshineTag;
