import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMachine } from '@xstate/react';

import useQueryParams from 'common/hooks/useQueryParams';
import useReplaceModuleLocation from 'features/Bento/hooks/useReplaceModuleLocation';
import stringifyState from 'features/Bento/libs/machines/stringifyState';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import machine, { model } from '../machine';
import { AFFILIATE_PARAM, REFERRAL_PARAM } from './params';

/**
 * This hook:
 * - initializes the signup flow machine,
 * - forwards the browser PREV events to it,
 * - sync the location of the local part of the URL with the machine state
 */
const useSignupMachine = () => {
  const history = useHistory();
  const device = useDevice();

  const queryParams = useQueryParams();

  const referralCode = queryParams.get(REFERRAL_PARAM);
  const affiliate = queryParams.get(AFFILIATE_PARAM);

  const [state, send] = useMachine(machine, {
    context: {
      affiliate,
      affiliationContext: affiliate
        ? {
            sourceUrl: affiliate,
          }
        : undefined,
      helpers: {
        getDevice: () => device,
        getHistory: () => history,
      },

      referral: referralCode
        ? { code: referralCode, referrerName: null }
        : null,
    },

    devTools: true,
  });

  const pushBufferHistoryEntry = useCallback(() => {
    const { pathname, search } = history.location;

    history.push(`${pathname}${search}`);
  }, [history]);

  /**
   * This original `history.push` is transparent - it won't re-route the user to anything.
   *
   * It add an entry to the history, effectively serving as a "buffer",
   * so that any `history.pop` action will NOT eject the user from the SPA, and we can react however we want to it.
   */
  useEffect(() => {
    logger.info('ℹ️ Buffer history entry created.');

    pushBufferHistoryEntry();
  }, [pushBufferHistoryEntry]);

  /**
   * When the user clicks the browser back button,
   * flow down this event to the current child machine.
   */
  useEffect(
    () =>
      history.listen((_, action) => {
        if (action === 'POP') {
          send(model.events.PREV());

          /**
           * The history.pop removed our buffer history entry.
           * We re-create it right away.
           */
          pushBufferHistoryEntry();
        }
      }),
    [pushBufferHistoryEntry, history, send],
  );

  useReplaceModuleLocation(stringifyState(state));

  return [state, send] as const;
};

export default useSignupMachine;
