import { FC } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';

import Logo from 'components/Logo';
import HelpButton from 'features/Help/components/HelpButton';

import OnboardingMenu from '../OnboardingMenu';
import {
  OnboardingSidePanelBottomItemsWrapper,
  OnboardingSidePanelBottomWrapper,
  OnboardingSidePanelWrapper,
} from './styles';
import { OnboardingSidePanelProps } from './types';

const OnboardingSidePanel: FC<OnboardingSidePanelProps> = ({
  children,
  user,
}) => {
  const isLarge = useBreakpointValue({ base: false, md: true });

  return (
    <OnboardingSidePanelWrapper>
      <Box
        backgroundColor="white"
        borderBottom={{ base: '1px solid', md: 'none' }}
        borderBottomColor="grey.3"
        paddingTop={{ base: 'space-24', md: '0' }}
        paddingX={{ base: 'space-24', md: '0' }}
        zIndex="banner"
      >
        <OnboardingMenu user={user} />
      </Box>

      {isLarge ? (
        <>
          {children}
          <OnboardingSidePanelBottomWrapper>
            <OnboardingSidePanelBottomItemsWrapper>
              <Logo />
              <HelpButton alignment="left" />
            </OnboardingSidePanelBottomItemsWrapper>
          </OnboardingSidePanelBottomWrapper>
        </>
      ) : null}
    </OnboardingSidePanelWrapper>
  );
};

export default OnboardingSidePanel;
