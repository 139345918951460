import { lazy, Suspense, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import type { LottieComponentProps } from 'lottie-react';

import ANIMATIONS, { Animation, AnimationData } from './animations';

const Lottie = lazy(() => import('lottie-react'));

interface LottieAnimationProps
  extends Omit<LottieComponentProps, 'animationData'> {
  animation: Animation;
}

/**
 * A component that will display a Lottie animation from a list of available animations.
 * Will lazy-load:
 * - the lottie-react library on first mount,
 * - and the actual animations on first usage.
 */
const LottieAnimation = ({
  animation,
  ref,
  ...props
}: LottieAnimationProps) => {
  const [animationData, setAnimationData] = useState<AnimationData | null>(
    null,
  );

  useEffect(() => {
    let mounted = true;

    const getAnimationFile = ANIMATIONS[animation];

    getAnimationFile().then((data) => {
      if (mounted) {
        setAnimationData(data.default);
      }
    });

    return () => {
      mounted = false;
      setAnimationData(null);
    };
  }, [animation]);

  return (
    <Suspense fallback={null}>
      <Box
        // Setting manually these values on the container is necessary on Safari
        height={props.height}
        width={props.width}
      >
        <Lottie {...props} animationData={animationData ?? null} />
      </Box>
    </Suspense>
  );
};

export default LottieAnimation;
