import { ReactNode } from 'react';
import {
  Box,
  Collapse,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Stack,
  StackProps,
} from '@chakra-ui/react';

import { formatText } from 'components/_core/Text/utils';

export const ERROR_MESSAGE_CLASSNAME = 'js-form-error-message';

export interface FieldProps extends Omit<FormControlProps, 'isInvalid'> {
  label?: string;
  /**
   * The id of the input element that the Field label will reference.
   */
  htmlFor?: string;
  hint?: string;
  error?: string;
  children: ReactNode;
  link?: ReactNode;
  spacing?: StackProps['spacing'];
}

/**
 * A form field wrapper showing a label and an optional hint message.
 * You can pass an error message that will take precedence over the hint.
 *
 * This component is supposed to be agnostic of the validation lib you use (eg. react-hook-form).
 */
const Field = forwardRef<FieldProps, 'div'>(
  (
    {
      children,
      error,
      hint,
      htmlFor,
      label,
      link,
      spacing = 'space-4',
      ...props
    },
    ref,
  ) => (
    <FormControl
      as={Stack}
      ref={ref}
      spacing={spacing}
      {...props}
      isInvalid={!!error}
    >
      {label ? (
        <FormLabel {...(htmlFor ? { htmlFor } : null)}>
          {formatText(label)}
        </FormLabel>
      ) : null}

      <Box>{children}</Box>

      {
        // the wrapping box is needed to avoid layout animation issue
        // https://linear.app/shine/issue/SUN-13/fix-formfield-animation-issue
      }
      <Box>
        <Collapse in={Boolean(error || hint)}>
          {error ? (
            <FormErrorMessage className={ERROR_MESSAGE_CLASSNAME}>
              {error}
            </FormErrorMessage>
          ) : null}
          {!error && hint ? <FormHelperText>{hint}</FormHelperText> : null}
          {link ? link : null}
        </Collapse>
      </Box>
    </FormControl>
  ),
);

export default Field;
