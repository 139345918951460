import {
  Document,
  getKycDocStatus,
  KYC_CATEGORIES,
  KYC_STATUSES,
  kycDocumentByCategories,
} from '@shinetools/kyc-validation';

import {
  KycReviewDocument,
  KycReviewDocumentStatus,
  KycReviewDocumentType,
} from '__generated__/GQL';

/**
 * A function which returns errors linked to a kyc category
 * based on the kyc documents query data
 */
export const getKycDocErrorsByCategory = (
  data: { kycDocuments: Pick<KycReviewDocument, 'type' | 'errorLocalized'>[] },
  kycCategory: keyof typeof KYC_CATEGORIES,
) => {
  const categoryDocTypes = kycDocumentByCategories[kycCategory];
  const categoryDocuments = data.kycDocuments?.filter(
    (doc) =>
      doc !== null &&
      categoryDocTypes.some((docTypes) => docTypes.includes(doc.type)),
  );

  return (
    (categoryDocuments
      ?.map((doc) => doc?.errorLocalized)
      .filter((error) => error) as string[]) || []
  );
};

/**
 * A function which returns the kyc status of a kyc category
 * based on the kyc documents query data
 */
export const getKycDocStatusByCategory = (
  queryData: { kycDocuments: Pick<KycReviewDocument, 'type' | 'status'>[] },
  kycCategory: keyof typeof KYC_CATEGORIES,
): keyof typeof KYC_STATUSES =>
  getKycDocStatus({
    category: kycCategory,
    documents: (queryData.kycDocuments as Document[]) || [],
  });

/**
 * A function which returns whether a kyc document
 * is reviewable (ie can be sent or not)
 */
export const isKycDocumentReviewable = (
  kycDocument: Pick<KycReviewDocument, 'status'>,
): boolean => {
  const { status } = kycDocument;

  return Boolean(
    status &&
      [
        KycReviewDocumentStatus.Pending,
        KycReviewDocumentStatus.Validated,
      ].includes(status),
  );
};

/**
 * A function which returns all the kyc documents
 * having a type matching the document types argument
 */
export const getKycDocumentsForDocTypes = <
  P extends Pick<KycReviewDocument, 'type'>,
>(
  kycDocuments: P[],
  documentTypes: KycReviewDocumentType[],
): P[] => kycDocuments.filter((doc) => documentTypes.includes(doc.type));

/**
 * A function which returns all document types which have no corresponding document
 * in the kyc documents argument
 */
export const getRemainingDocTypesToUpload = (
  kycDocuments: Pick<KycReviewDocument, 'type'>[],
  documentTypes: KycReviewDocumentType[],
): KycReviewDocumentType[] =>
  documentTypes.filter((docType) =>
    kycDocuments.every((doc) => doc.type !== docType),
  );
