import LocalizedStrings from 'localized-strings';

import { LocalePathKey, UserLockedReasons } from './types';

const locales = new LocalizedStrings({
  en: {
    description: {
      [UserLockedReasons.LockedByAdmin]: {
        [LocalePathKey.LockedFromMultipleCompanies]:
          'The account holders withdrew your accesses to their accounts. All your current payment cards (both physical and virtual) have been deactivated. You will no longer be able to log into your accounts.\n\nIf you think this is a mistake and would like to be invited back, please contact an admin.',
        [LocalePathKey.LockedFromSingleCompany]:
          'An admin withdrew your access to {companyName}’s Shine account. All your current payment cards (both physical and virtual) have been deactivated. You will no longer be able to log into your account\n\nIf you think this is a mistake and would like to be invited back, please contact an admin of the account.',
      },
      [UserLockedReasons.KycRefused]:
        'Unfortunately, we cannot validate your registration based on our Terms of Use.\n\nWe are very sorry that we are unable to welcome you to Shine at this time.',
    },
    title: {
      [LocalePathKey.LockedFromMultipleCompanies]:
        'You no longer have access to your Shine accounts',
      [LocalePathKey.LockedFromSingleCompany]:
        'You no longer have access to {companyName}’s Shine account',
    },
  },
  fr: {
    description: {
      [UserLockedReasons.LockedByAdmin]: {
        [LocalePathKey.LockedFromMultipleCompanies]:
          'Les titulaires des comptes ont supprimé vos différents accès aux comptes Shine. Cela signifie que toutes vos cartes actuelles (physiques et virtuelles) ont été désactivées. Vous ne pourrez plus vous connecter à aucun compte.\n\nSi vous pensez qu’il s’agit d’une erreur, vous pouvez contacter les titulaires de compte qui devront vous inviter à nouveau.',
        [LocalePathKey.LockedFromSingleCompany]:
          'Le titulaire du compte a supprimé vos accès au compte Shine de {companyName}. Cela signifie que toutes vos cartes actuelles (physiques et virtuelles) ont été désactivées. Vous ne pourrez plus vous connecter au compte.\n\nSi vous pensez qu’il s’agit d’une erreur, vous pouvez contacter le titulaire du compte qui devra vous inviter à nouveau.',
      },
      [UserLockedReasons.KycRefused]:
        'Nous ne pouvons malheureusement pas valider votre inscription à Shine, conformément à nos Conditions Générales d’Utilisation.\n\nNous sommes sincèrement désolés de ne pas pouvoir vous accueillir chez Shine',
    },
    title: {
      [LocalePathKey.LockedFromMultipleCompanies]:
        'Vous n’avez plus accès à vos comptes Shine',
      [LocalePathKey.LockedFromSingleCompany]:
        'Vous n’avez plus accès au compte Shine de {companyName}',
    },
  },
});

export default locales;
