import { useCallback, useEffect } from 'react';
import { DoneEvent } from 'xstate';

import useOnMounted from 'common/hooks/useOnMounted';

import { AnyModel } from '../types/Abstract';
import {
  BentoModuleDoneData,
  BentoModuleDoneStatus,
  OnBentoModuleDone,
} from '../types/BentoModule';
import { RunningMachine } from '../types/Helpers';
import stringifyState from './stringifyState';

/**
 * Given an object, this function asserts that the input is assignable to `ModuleDoneData`.
 * We use it to validate at runtime that the machine terminates with a valid data prop.
 */
const isModuleDoneData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): data is BentoModuleDoneData =>
  Boolean(data) && 'status' in data && data.status in BentoModuleDoneStatus;

/**
 * Given an interpreted machine and a callback, this hook will call the callback
 * when the corresponding machine reaches its `done` state with its final data.
 */
const useOnModuleMachineDone = (
  machine: RunningMachine<AnyModel>,
  onDone: OnBentoModuleDone,
) => {
  const [state, , service] = machine;

  /**
   * If the machine starts directly into its `done` state, fire the callback.
   * It can happen if there are `always` clauses that transition immediately to a final state.
   *
   * The below `service.onDone` listener would not catch it, because it would be triggered instantly.
   */
  useOnMounted(() => {
    if (state.done) {
      onDone(state.event);
    }
  });

  /**
   * Add a defensive layer to the callback
   * to catch machines that transition to a final state without a valid `data` prop.
   */
  const callback = useCallback(
    ({ data }: DoneEvent) => {
      if (!isModuleDoneData(data)) {
        throw Error(
          `The machine ${service.id} terminated
           with an invalid done data: ${JSON.stringify(data)}`,
        );
      }

      onDone({ ...data, finalState: stringifyState(service.state) });
    },

    [onDone, service],
  );

  /**
   * Register the `onDone` listener for the running service.
   */
  useEffect(() => {
    service.onDone(callback);

    return () => {
      service.off(callback);
    };
  }, [callback, service]);
};

export default useOnModuleMachineDone;
