import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const WIDTH = '3rem'; // 48px;
const HEIGHT = '1.75rem'; // 28px;

const baseStyleTrack: SystemStyleObject = {
  _checked: {
    _disabled: {
      backgroundColor: 'switch-track-bg-checked-disabled',
    },
    backgroundColor: 'switch-track-bg-checked',
  },
  _disabled: {
    backgroundColor: 'switch-track-bg-disabled',
    cursor: 'not-allowed',
  },
  _focus: {
    boxShadow: 'outline-rebranded',
  },
  backgroundColor: 'switch-track-bg',
  borderRadius: 'full',
  height: HEIGHT,
  padding: '2px',
  transitionDuration: 'duration-200',
  transitionProperty: 'common',
  width: WIDTH,
};

const baseStyleThumb: SystemStyleObject = {
  _checked: {
    _disabled: {
      backgroundColor: 'switch-thumb-bg-checked-disabled',
    },
    backgroundColor: 'switch-thumb-bg-checked',
    transform: `translateX(calc(${WIDTH} - ${HEIGHT}))`,
  },
  _disabled: {
    backgroundColor: 'switch-thumb-bg-disabled',
  },
  backgroundColor: 'switch-thumb-bg',
  borderRadius: 'inherit',
  height: HEIGHT,
  transitionDuration: 'duration-200',
  transitionProperty: 'transform',
  width: HEIGHT,
};

const baseStyle: PartsStyleObject<typeof parts> = {
  thumb: baseStyleThumb,
  track: baseStyleTrack,
};

const defaultProps = {
  size: 'md',
};

const SunshineSwitchTheme = {
  baseStyle,
  defaultProps,
  parts: parts.keys,
};

export default SunshineSwitchTheme;
