import { components, OptionProps } from 'react-select';
import { Flex, Image, Text } from '@chakra-ui/react';

import { OptionTypeBase } from '../..';

const LanguageOption = <TOptionType extends OptionTypeBase>(
  optionProps: OptionProps<TOptionType, false>,
) => (
  <components.Option {...optionProps}>
    <Flex alignItems="center" gap="space-16">
      <Image borderRadius="radius-4" src={optionProps.data.flag} width="20px" />
      <Text variant="primary">{optionProps.data.label}</Text>
    </Flex>
  </components.Option>
);

export default LanguageOption;
