import { ContextWithBootstrap } from './types';

/**
 * This helper function will generate an array of XState transitions for each provided `State`.
 *
 * Each of these transitions will test their own `State` against `moduleData.bootstrap`.
 * Compatible (at compile time) with any machine whose `moduleData` includes `bootstrap: string | null`
 *
 * If one of them is evaluated as equal, the transition will be taken to `State`.
 * Else, a transition to the `defaultState` will be taken, if it is provided.
 */
const getBootstrapGuards = <TState extends string>(
  states: TState[],
  defaultState?: TState,
): {
  cond?: ({ getModuleData }: ContextWithBootstrap) => boolean;
  target: TState;
}[] => {
  /**
   * An array of regular XState transitions.
   */
  const conditionalTransitions = states.map((state) => ({
    cond: (context: ContextWithBootstrap) =>
      context.getModuleData().bootstrap === state,
    target: state,
  }));

  if (!defaultState) {
    return conditionalTransitions;
  }

  /**
   * This transition has no `cond`: it's a fallback and will be taken unconditionally.
   */
  const fallbackTransition = {
    target: defaultState,
  };

  return [...conditionalTransitions, fallbackTransition];
};

export default getBootstrapGuards;
