/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8571%3A34657&t=Zo1x1tq5fDFhbo8L-1
 */

import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools';

export type Parts = typeof checkboxAnatomy;

const baseStyleContainer: SystemStyleObject = {
  _disabled: {
    cursor: 'not-allowed',
  },
  gap: 'space-12',
};

const baseStyleControl: SystemStyleObject = {
  _checked: {
    _disabled: {
      backgroundColor: 'checkbox-icon-disabled',
      borderColor: 'checkbox-border-disabled',
    },
    backgroundColor: 'checkbox-icon-active',
    borderColor: 'checkbox-border-active',
    color: 'white',
  },
  _disabled: {
    borderColor: 'checkbox-border-disabled',
  },
  backgroundColor: 'checkbox-icon',
  // needed to simulate a 24px box
  border: '2px solid',
  borderColor: 'checkbox-border',
  borderRadius: '5px',
  boxSize: '18px',
  color: 'white',
  fontSize: 'font-12',
  margin: '3px',
  // not in foundations, but needed by the design team
  padding: 'space-2',
  transitionDuration: 'duration-100',
  transitionProperty: 'common',
};

const baseStyleLabel: SystemStyleObject = {
  flex: 1,
  margin: 0,
};

const baseStyleIcon: SystemStyleObject = {
  fill: 'currentColor',
};

const baseStyle: PartsStyleObject<Parts> = {
  container: baseStyleContainer,
  control: baseStyleControl,
  icon: baseStyleIcon,
  label: baseStyleLabel,
};

export type Variant = 'default' | 'outline';

const variantOutline: PartsStyleObject<Parts> = {
  container: {
    _disabled: {
      _hover: {
        backgroundColor: 'white',
      },
    },
    _hover: {
      backgroundColor: 'grey.100',
    },
    _invalid: {
      _hover: {
        borderColor: 'error',
      },
      borderColor: 'error',
    },
    background: 'inherit',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: 'radius-6',
    paddingX: 'space-12',
    paddingY: 'space-8',
    transitionDuration: 'duration-200',
    transitionProperty: 'common',
  },
  label: {
    flex: 1,
  },
};

const variants: Record<Variant, PartsStyleObject<Parts>> = {
  default: {},
  outline: variantOutline,
};

const CheckboxTheme = {
  baseStyle,
  parts: checkboxAnatomy.keys,
  variants,
};

export default CheckboxTheme;
