import { FC } from 'react';

import PasscodeInput from 'components/PasscodeInput';

import locales from './locales';
import {
  BackButton,
  Container,
  Description,
  PasscodeInputContainer,
  Title,
} from './styles';
import { ActionRequestPasscodeProps } from './types';

const ActionRequestPasscode: FC<ActionRequestPasscodeProps> = ({
  goBack,
  onPasscodeSubmit,
}) => {
  return (
    <Container>
      <BackButton onClick={goBack}>{locales.back}</BackButton>
      <Title>{locales.title}</Title>
      <Description>{locales.description}</Description>
      <PasscodeInputContainer>
        <PasscodeInput hidden onSubmit={onPasscodeSubmit} />
      </PasscodeInputContainer>
    </Container>
  );
};

export default ActionRequestPasscode;
