import { useHistory } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import { RootLegalRegistrationNumberRequiredFragment } from 'Main/SecuredRoutes/graphql/rootCompany.gql';

const LOCAL_STORAGE_KEY = 'missingSiret.postponedAt';
const POSTPONE_DAYS = 3;

const useIsLegalRegistrationNumberRequired = (
  data: RootLegalRegistrationNumberRequiredFragment | undefined,
) => {
  const history = useHistory();

  const isRequired =
    data?.viewer?.company?.isLegalRegistrationNumberRequired ?? false;
  const canPostpone =
    data?.viewer?.company?.canPostponeLegalRegistrationNumberRequest ?? false;
  const storagePostponedAt = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  const postponedAt = storagePostponedAt && new Date(storagePostponedAt);
  const hasPostponed =
    canPostpone &&
    postponedAt &&
    differenceInDays(new Date(), postponedAt) < POSTPONE_DAYS;

  return {
    canPostpone,
    isRequired: !hasPostponed && isRequired,
    postpone: () => {
      if (!canPostpone) {
        throw new Error("Shouldn't try to postpone when it's not allowed");
      }

      const now = new Date();
      window.localStorage.setItem(LOCAL_STORAGE_KEY, `${now}`);
      history.push('/');
    },
  };
};

export default useIsLegalRegistrationNumberRequired;
