import { MachineServices } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';
import { CreateTeamMemberReviewDocument } from 'features/TeamOnboarding/Onboarding/graphql/createTeamMemberReview.gql';

import { Model } from './model';

export enum Service {
  CreateReview = 'createReview',
}

const services: MachineServices<Model, Service> = {
  createReview: ({ getModuleData }) => {
    const { companyUserId } = getModuleData().data.viewer.companyUser;

    return apolloClient.mutate({
      awaitRefetchQueries: true,
      mutation: CreateTeamMemberReviewDocument,
      variables: { input: { companyUserId } },
    });
  },
};

export default services;
