import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    authenticationDeviceRequestAlreadyHandled:
      'The request has already been handled.',
    authenticationDeviceRequestExpired: 'The request has expired.',
  },
  fr: {
    authenticationDeviceRequestAlreadyHandled: 'La requête a déjà été traitée.',
    authenticationDeviceRequestExpired: 'La requête a expiré.',
  },
});

export default locales;
