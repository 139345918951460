import { useContext } from 'react';

import { PageLayout } from 'features/Bento/components';
import { GuidanceShineStartSociety } from 'features/Bento/flows/signup/components/Guidance';
import { model } from 'features/Bento/flows/signup/machine';
import { Signup } from 'features/Bento/flows/signup/machine/machine';
import { SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import CompanyCreationLegalFormForm from '../../components/CompanyCreationLegalFormForm';

/**
 * A page asking the user what legal form they want to create
 */
const CompanyCreationSelectLegalForm: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      guidance={<GuidanceShineStartSociety />}
    >
      <CompanyCreationLegalFormForm
        onSubmit={(selectedLegalForm) =>
          send(
            model.events.SUBMIT_COMPANY_CREATION_LEGAL_FORM(selectedLegalForm),
          )
        }
      />
    </Layout>
  );
};

export default CompanyCreationSelectLegalForm;
