import LocalizedStrings from 'localized-strings';

import { OBErrorCode } from './OBErrorCode';

const en: Record<OBErrorCode, string> = {
  [OBErrorCode.WRONG_PASSCODE]: 'Oops! Wrong passcode',
  [OBErrorCode.UNRECOGNIZED]: 'Oops! There was an unknown error',
  [OBErrorCode.NETWORK]: 'Please check your connection and try again',
  [OBErrorCode.RATE_LIMIT]: 'Too many attempts. Try again in an hour.',
  [OBErrorCode.CODE_NOT_MATCH]:
    'Code does not match. Please check your code or generate a new one.',
  [OBErrorCode.CODE_EXPIRED]: 'Code has expired. Please generate a new one.',
  [OBErrorCode.PAYMENT_3DS_FAILURE]: '3DS confirmation failure',
};

const fr: Record<OBErrorCode, string> = {
  [OBErrorCode.WRONG_PASSCODE]:
    'Oups\u00A0! Votre code secret semble incorrect',
  [OBErrorCode.UNRECOGNIZED]: 'Oups\u00A0! Une erreur inconnue est survenue',
  [OBErrorCode.NETWORK]: 'Pouvez-vous vous reconnecter et réessayer\u00A0?',
  [OBErrorCode.RATE_LIMIT]:
    "Vous avez dépassé le nombre d'essais autorisés. Vous pourrez réessayer dans une heure.",
  [OBErrorCode.CODE_NOT_MATCH]:
    'Le code ne correspond pas. Pouvez-vous réessayer, ou en générer un nouveau ?',
  [OBErrorCode.CODE_EXPIRED]:
    'Le code a expiré. Pouvez-vous en générer un nouveau ?',
  [OBErrorCode.PAYMENT_3DS_FAILURE]: 'Echec de la confirmation 3DS',
};

const localizedErrors = new LocalizedStrings({
  en,
  fr,
});

export default localizedErrors;
