import { IconName } from '@shinetools/sunshine-icons';
import { EventFrom } from 'xstate';

import { State } from '../../machine';
import { Model, VerificationModuleData } from '../../machine/model';

export enum VerificationStatus {
  /**
   * This step is irrelevant for the current user and dossier.
   */
  Irrelevant = 'IRRELEVANT',
  /**
   * This step is waiting for an action from the current user.
   */
  ToDo = 'TO_DO',
  /**
   * This step has been invalidated by Shine,
   * and is waiting for an action from the user.
   */
  Invalid = 'INVALID',
  /**
   * This step has been completed,
   * but is not validated yet (it may be invalidated by Shine)
   */
  Completed = 'COMPLETED',
  /**
   * This step was completed and validated by Shine.
   */
  Validated = 'VALIDATED',
}

export enum VerificationStepCategory {
  PersonalInfo = 'PERSONAL_INFO',
  CompanyInfo = 'COMPANY_INFO',
  ShineOffer = 'SHINE_OFFER',
}

export interface VerificationStep {
  errors: string[];
  id: State;
  metadata: {
    // A unique key for steps that are displayed multiple times, like shine start document upload
    key?: string;
    category?: VerificationStepCategory;
    event: EventFrom<Model>;
    isDisabled?: boolean;
    isHidden?: boolean;
    iconName: IconName;
    label: {
      add: string;
      review: string;
    };
    description?: {
      add?: string;
      review?: string;
    };
  };
  status: VerificationStatus;
}

export type GetVerificationStep = (
  moduleData: VerificationModuleData,
) => VerificationStep;
