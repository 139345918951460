import hornImg from 'assets/brand/horn@2x.png';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Text from 'components/_core/Text';
import CardContainer from 'features/TeamOnboarding/Onboarding/components/CardContainer';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import { ModuleData } from '../../machine/model';
import locales from './locales';

interface WelcomeProps {
  moduleData: ModuleData;
  onNext: () => void;
}

const Welcome = asBentoPage<WelcomeProps>(
  ({ Layout, moduleData: { profile }, onNext }) => (
    <Layout nav={<Navigation />}>
      <CardContainer
        cta={
          <Button isExpanded onClick={onNext}>
            {locales.continue}
          </Button>
        }
        headerImg={hornImg}
        title={
          locales.formatString(locales.title, {
            firstName: profile.firstName,
            lastName: profile.lastName,
          }) as string
        }
      >
        <Text>{locales.content}</Text>
      </CardContainer>
    </Layout>
  ),
);

export default Welcome;
