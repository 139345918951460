import { FC } from 'react';

import hornImg from 'assets/brand/horn@2x.png';
import Button from 'components/_core/Button';
import SunshineModal from 'components/_core/SunshineModal';
import Text from 'components/_core/Text';

import locales from './locales';
import { Props } from './types';

const RefusedStep: FC<Props> = ({ onClose }) => (
  <>
    <SunshineModal.Body>
      <SunshineModal.Body.Image src={hornImg} />

      <SunshineModal.Title>{locales.title}</SunshineModal.Title>

      <Text marginTop="space-16">{locales.description}</Text>
      <Text fontWeight="weight-500" marginTop="space-8" variant="primary">
        {locales.changePasscode}
      </Text>
    </SunshineModal.Body>

    <SunshineModal.Footer>
      <Button icon="arrow-right" onClick={onClose} variant="primary">
        {locales.allGood}
      </Button>
    </SunshineModal.Footer>
  </>
);

export default RefusedStep;
