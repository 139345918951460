/* eslint-disable react/display-name */

import { createElement } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useActor } from '@xstate/react';

import { AbstractModel } from 'features/Bento/types/Abstract';

import SignupLoaderPage from '../pages/SignupLoaderPage';
import { SignupModule, SignupModuleWrapperProps } from '../types/SignupModule';

/**
 * Expose to the children a `page` param.
 */
const SignupWrapper = <TModel extends AbstractModel>({
  children,
  machineRef,
  ...props
}: {
  children: SignupModule<TModel>;
} & SignupModuleWrapperProps<TModel>) => {
  const { url } = useRouteMatch();

  const machine = useActor(machineRef);

  /**
   * An actor can take several renders to spawn entirely.
   */
  const isActorLoaded = Boolean(machine[0]);

  return isActorLoaded ? (
    <Route path={`${url}/:page?`}>
      {createElement(children, {
        machine,
        ...props,
      })}
    </Route>
  ) : (
    <SignupLoaderPage />
  );
};

/**
 * Wraps OBModule into a Route component with path <currentURL/:page?>
 * And only render it if its machineRef is defined (avoid the first frame crashing if machineRef is not defined already)
 */
const withSignupWrapper =
  <TModel extends AbstractModel>(Component: SignupModule<TModel>) =>
  ({ machineRef, ...props }: SignupModuleWrapperProps<TModel>) =>
    machineRef ? (
      <SignupWrapper {...props} machineRef={machineRef}>
        {Component}
      </SignupWrapper>
    ) : (
      <SignupLoaderPage />
    );

export default withSignupWrapper;
