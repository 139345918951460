import { FC, useCallback, useRef, useState } from 'react';

import { ClearAllDigitsFn } from 'components/PasscodeInput/types';
import logger from 'helpers/logger';

import Component from './component';
import locales from './locales';
import { PasscodeProps } from './types';

const isCodeValid = (type: string, code: string): boolean =>
  (type === 'passcode' && code.length === 4) || code.length === 6;

const Passcode: FC<PasscodeProps> = ({
  errorMessage,
  loading,
  loginType,
  onBack,
  onForgotPasscode,
  onSubmit,
  passcodeType = 'passcode',
}) => {
  const [code, setCode] = useState('');
  const [codeFromFirstEntry, setCodeFromFirstEntry] = useState<
    string | undefined
  >();

  const [didPreviousValuesMismatch, setDidPreviousValuesMismatch] =
    useState(false);

  const requiresEnteringTwice =
    passcodeType === 'passcode' && loginType !== 'sign_in';

  const isEnteringSecondTime =
    requiresEnteringTwice && codeFromFirstEntry !== undefined;

  const submitOrTriggerSecondEntry = useCallback(
    async (
      newCode: string,
      clearAllDigits: ClearAllDigitsFn,
    ): Promise<void> => {
      if (isCodeValid(passcodeType, newCode)) {
        try {
          setDidPreviousValuesMismatch(false);
          if (!requiresEnteringTwice) {
            if (!(await onSubmit(newCode))) {
              clearAllDigits({ andFocusFirstInput: true });
            }
          } else if (
            requiresEnteringTwice &&
            codeFromFirstEntry === undefined
          ) {
            setCodeFromFirstEntry(newCode);
            clearAllDigits({ andFocusFirstInput: true });
          } else if (isEnteringSecondTime && newCode === codeFromFirstEntry) {
            onSubmit(newCode);
          } else if (isEnteringSecondTime && newCode !== codeFromFirstEntry) {
            clearAllDigits({ andFocusFirstInput: false });
            setDidPreviousValuesMismatch(true);
            setCodeFromFirstEntry(undefined);
          }
        } catch (error) {
          logger.error(error);
          clearAllDigits({ andFocusFirstInput: true });
        }
      }
    },
    [
      passcodeType,
      requiresEnteringTwice,
      codeFromFirstEntry,
      isEnteringSecondTime,
      onSubmit,
    ],
  );

  // Empty function added before no-empty-function rule added
  const onPressNextCallbackRef = useRef(() => {});

  const setCodeWithAutoSubmit = useCallback(
    (newCode: string, clearAllDigits: ClearAllDigitsFn): void => {
      setCode(newCode);
      // Allow the user to repeat the effect of making this call again e.g. in the event
      // of a failed connection.
      // TODO(matt): Find a cleaner way of doing this that does not maintain a reference
      // to clearAllDigits in particular.
      onPressNextCallbackRef.current = (): void => {
        submitOrTriggerSecondEntry(newCode, clearAllDigits);
      };
      submitOrTriggerSecondEntry(newCode, clearAllDigits);
    },
    [onPressNextCallbackRef, submitOrTriggerSecondEntry],
  );

  const displayedErrorMessage = didPreviousValuesMismatch
    ? locales.didNotMatchError
    : errorMessage;

  return (
    <Component
      errorMessage={displayedErrorMessage}
      isEnteringSecondTime={isEnteringSecondTime}
      isValid={isCodeValid(passcodeType, code)}
      loading={loading}
      loginType={loginType}
      onBack={onBack}
      onForgotPasscode={onForgotPasscode}
      onPressNext={onPressNextCallbackRef.current}
      passcodeType={passcodeType}
      setCode={setCodeWithAutoSubmit}
    />
  );
};

export default Passcode;
