import { useQuery } from '@apollo/client';

import { Role } from '__generated__/GQL';

import {
  TeamOnboardingFlowDocument,
  TeamOnboardingFlowQueryVariables,
} from './teamOnboardingFlow.gql';

const useTeamOnboardingFlow = (
  companyProfileId:
    | TeamOnboardingFlowQueryVariables['companyProfileId']
    | undefined,
) => {
  const hook = useQuery(TeamOnboardingFlowDocument, {
    skip: companyProfileId === undefined,
    variables: { companyProfileId: companyProfileId as string },
  });

  const viewer = hook.data?.viewer;

  if (!viewer) {
    return { ...hook, data: undefined };
  }

  const accountHolder = viewer.companyUsers.find(({ roles }) =>
    roles.includes(Role.AccountHolder),
  );

  const data = {
    accountHolderName: `${accountHolder?.firstName} ${accountHolder?.lastName}`,
    companyName: viewer.company.profile.legalName,
    companyProfileId: companyProfileId as string,
    companyUserId: viewer.companyUser.companyUserId,
    // we are in an onboarding context, so invitation will be defined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    invitation: viewer.companyUser.invitation!,
    isOnboarded: viewer.companyUser.isOnboarded,
    kycIdStatus: viewer.companyUser.kycIdStatus,
    kycStatus: viewer.companyUser.kycStatus,
    profile: viewer.profile,
    reviewStatus: viewer.companyUser.reviewStatus,
    status: viewer.companyUser.status,
  };

  return { ...hook, data };
};

export type UseTeamOnboardingFlow = ReturnType<
  typeof useTeamOnboardingFlow
>['data'];

export default useTeamOnboardingFlow;
