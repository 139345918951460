/* eslint-disable @shinetools/shine/prefer-typographic-apostrophe */
/* eslint-disable @typescript-eslint/naming-convention */
import { enUS, fr } from 'date-fns/locale';
import LocalizedStrings from 'localized-strings';

// Examples: FR 12:00 | 12:00 AM
const p = 'p';

// [23/07/2021] [07/23/2021]
export const P = 'P';

// [5 juil. 2021] [Jul 5, 2021]
export const PP = 'PP';

// [5 juillet 2016] [July 5th, 2016]
export const PPP = 'PPP';

// [5 juillet 2016 à 14:52:30] [July 5th, 2016 at 2:52:30 PM]
export const PPPpp = 'PPPpp';

// [5 juillet 2016, 14:52] [Jul 5, 2016, 2:52 PM]
export const PPp = 'PP, p';

// [Lundi] [Monday]
export const eeee = 'eeee';

// [5]
export const d = 'd';

// [5th]
export const _do = 'do';

// [lundi 5 juil. 2016] [Monday Jul 5th, 2016]
export const eeee_PP = 'eeee PP';

// [17/05/2021 à 14:52] [05/17/2021 at 2:52 PM]
export const Patp = 'Patp';

// [16 févr. 2022 à 10:35] [Feb 16, 2022 at 10:35 AM]
export const PPatp = 'PPatp';

// [2021-05-17] [2021-05-17]
export const YYYY_MM_DD = 'yyyy-MM-dd';

// 2021
export const yyyy = 'yyyy';

// [octobre 2021] [October 2021]
export const LLLL_yyyy = 'LLLL yyyy';

// FR janv, ... déc | Jan, ..., Dec
export const LLL = 'LLL';

// FR janvier, ... décembre | January, ..., December
export const LLLL = 'LLLL';

// Sat. 12 Feb
export const EEddMMM = 'EE dd MMM';

// Sat. 12
export const EEdd = 'EE. dd';

// 01 February
export const ddMMM = 'dd MMM';

// 1 February
export const dMMM = 'd MMM';

// Juin | Juil. | Jun | Jul
export const MMM = 'MMM';

// Février | February
export const MMMM = 'MMMM';

// FR 1er trimestre, 2e trimestre ... | 1st quarter, 2nd quarter ....
export const QQQQ = 'QQQQ';

// don’t forget to add your format to this map
export const FORMATS = {
  d,
  ddMMM,
  dMMM,
  do: _do,
  EEdd,
  EEddMMM,
  eeee,
  eeee_PP,
  LLL,
  LLLL,
  [LLLL_yyyy]: LLLL_yyyy,
  MMM,
  MMMM,
  p,
  P,
  Patp,
  PP,
  PPatp,
  PPP,
  [PPp]: PPp,
  PPPpp,
  QQQQ,
  yyyy,
  [YYYY_MM_DD]: YYYY_MM_DD,
} as const;

/**
 * Custom format locales
 */
export const locales = new LocalizedStrings({
  en: {
    ...FORMATS,
    [Patp]: "P 'at' p",
    [PPatp]: "PP 'at' p",
  },
  fr: {
    ...FORMATS,
    [Patp]: "P 'à' p",
    [PPatp]: "PP 'à' p",
  },
});

/**
 * Date fns supported locales
 */
export const dateFnsLocales: Record<string, Locale> = {
  en: enUS,
  fr,
};
