import { SystemStyleObject } from '@chakra-ui/styled-system';

const baseStyle = {
  fill: 'currentColor',
  verticalAlign: '-0.17em',
};

type Size =
  | 'icon-10'
  | 'icon-13'
  | 'icon-14'
  | 'icon-15'
  | 'icon-16'
  | 'icon-18'
  | 'icon-20'
  | 'icon-22'
  | 'icon-24'
  | 'icon-26'
  | 'icon-31'
  | 'icon-56';

const sizes: Record<Size, SystemStyleObject> = {
  'icon-10': { height: '10px', width: '10px' },
  'icon-13': { height: '13px', width: '13px' },
  'icon-14': { height: '14px', width: '14px' },
  'icon-15': { height: '15px', width: '15px' },
  'icon-16': { height: '16px', width: '16px' },
  'icon-18': { height: '18px', width: '18px' },
  'icon-20': { height: '20px', width: '20px' },
  'icon-22': { height: '22px', width: '22px' },
  'icon-24': { height: '24px', width: '24px' },
  'icon-26': { height: '26px', width: '26px' },
  'icon-31': { height: '31px', width: '31px' },
  'icon-56': { height: '56px', width: '56px' },
};

const IconTheme = {
  baseStyle,
  sizes,
};

export default IconTheme;
