import { createToast } from 'features/Bento/atoms';
import logger from 'helpers/logger';

import { SharedAction } from '.';

/**
 * This function returns a XState action that, when executed with any event,
 * - logs an error to Sentry that an event was not handled,
 * - if in development, display a warning to the developer.
 */
const unhandled: SharedAction = () => ({
  exec: (context, event, meta) => {
    // Ignore NEXT and PREV generic events to avoid throwing logging errors
    // each time a previous or next button is clicked during a "loading"
    // state transition (service callers).
    if (event.type === 'PREV' || event.type === 'NEXT') {
      return;
    }

    logger.error(`An event of type ${event.type} was not handled.`, {
      context: {
        event,
        state: meta.state.value,
      },
    });

    if (import.meta.env.MODE === 'development') {
      createToast({
        status: 'error',
        title: `[DEV] This event was not handled : ${JSON.stringify(
          event,
        )} in state ${JSON.stringify(meta.state?.value)}`,
      });
    }
  },
  type: 'unhandled',
});

export default unhandled;
