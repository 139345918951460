import DevPage from 'common/bento/__dev__/DevPage';
import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import LoaderPage from 'common/bento/pages/LoaderPage';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import useCompanyContext from 'common/hooks/useCompanyContext';
import SuspenseQuery from 'components/SuspenseQuery';
import NavigationContext, {
  NavigationStepStatus,
} from 'features/TeamOnboarding/Onboarding/components/Navigation/NavigationContext';

import ErrorView from '../../../../../components/ErrorView';
import Identity from '../../modules/Identity';
import useTeamOnboardingFlow from './hooks/useTeamOnboardingFlow';
import locales from './locales';
import machineConfig, { model, State } from './machine';
import { Model } from './machine/model';
import AutoReviewApplication from './pages/AutoReviewApplication';
import ManualReviewApplication from './pages/ManualReviewApplication';
import PersonalInformation from './pages/PersonalInformation';
import Validated from './pages/Validated';
import Welcome from './pages/Welcome';

const NAVIGATION_STEPS = [
  {
    status: NavigationStepStatus.Done,
    title: locales.steps.accountCreation,
  },
  {
    status: NavigationStepStatus.Ongoing,
    title: locales.steps.identityCheck,
  },
];

const EmployeeOrAccountant = withStateMachine<Model>(
  ({ machine, moduleData }) => {
    const [state, send] = machine;
    const stringifiedState = stringifyState<State>(state);

    let pageContent;
    switch (stringifiedState) {
      case State.PERSONAL_INFORMATION_FORM:
      case State.PERSONAL_INFORMATION_SEND:
        pageContent = (
          <PersonalInformation
            isLoading={state.matches(State.PERSONAL_INFORMATION_SEND)}
            moduleData={moduleData}
            onNext={(values) =>
              send(model.events.SUBMIT_PERSONAL_INFORMATION(values))
            }
            onPrev={() => send(model.events.PREV())}
          />
        );
        break;

      case State.WELCOME:
        pageContent = (
          <Welcome
            moduleData={moduleData}
            onNext={() => send(model.events.NEXT())}
            onPrev={() => send(model.events.PREV())}
          />
        );
        break;

      case State.IDENTITY:
        pageContent = (
          <Identity
            companyProfileId={moduleData.companyProfileId}
            companyUserId={moduleData.companyUserId}
            onDone={(data) => {
              const isDone = data.status === BentoModuleDoneStatus.DONE;
              return send(isDone ? model.events.NEXT() : model.events.PREV());
            }}
          />
        );
        break;

      case State.CREATE_REVIEW:
      case State.UNDER_AUTO_REVIEW:
        pageContent = (
          <AutoReviewApplication
            moduleData={moduleData}
            onError={() => send(model.events.ON_ERROR())}
            onNext={() => send(model.events.NEXT())}
            onPrev={() => send(model.events.PREV())}
            onTimeout={() => send(model.events.VALIDATION_TIMEOUT())}
          />
        );
        break;

      case State.NEED_MANUAL_REVIEW as const:
        pageContent = (
          <ManualReviewApplication onPrev={() => send(model.events.PREV())} />
        );
        break;

      case State.VALIDATED:
        pageContent = (
          <Validated
            moduleData={moduleData}
            onNext={() => send(model.events.NEXT())}
            onPrev={() => send(model.events.PREV())}
          />
        );
        break;

      case State.DONE:
      case State.IDLE:
        // case State.CREATE_REVIEW:
        pageContent = <LoaderPage onPrev={() => send(model.events.PREV())} />;
        break;

      case State.ERROR:
        pageContent =
          import.meta.env.VITE_API_ENV !== 'production' ? (
            <DevPage
              moduleData={moduleData}
              onNext={() => send(model.events.NEXT())}
              onPrev={() => send(model.events.PREV())}
              send={send}
              state={state}
            />
          ) : (
            <ErrorView />
          );
        break;

      default:
        pageContent = assertNever(stringifiedState);
        break;
    }

    return (
      <NavigationContext.Provider
        value={{
          steps: NAVIGATION_STEPS,
        }}
      >
        {pageContent}
      </NavigationContext.Provider>
    );
  },
  {
    devTools: true,
    machineConfig,
  },
);

const EmployeeOrAccountantContainer = asBentoModule(({ onDone }) => {
  const { companyProfileId } = useCompanyContext();
  const query = useTeamOnboardingFlow(companyProfileId);

  if (!companyProfileId) {
    onDone({
      status: BentoModuleDoneStatus.ABORT,
    });
    return null;
  }

  return (
    <SuspenseQuery query={query}>
      {(moduleData) => (
        <EmployeeOrAccountant moduleData={moduleData} onDone={onDone} />
      )}
    </SuspenseQuery>
  );
});

export default EmployeeOrAccountantContainer;
