import { apolloClient } from 'common/graphql/clients';
import logger from 'helpers/logger';

import {
  AddressSchema,
  getDefaultAddress,
} from '../../../components/AddressForm/addressSchema';
import { TeamOnboardingVerificationAddressUpdatePersonalAddressDocument } from '../graphql/mutations/updatePersonalAddress.gql';
import { GetVerificationAddressInitialDataDocument } from '../graphql/queries/getInitialData.gql';
import { Context } from './model';

const services = {
  loadInitialData: async (): Promise<{ address: AddressSchema }> => {
    const { data } = await apolloClient.query({
      query: GetVerificationAddressInitialDataDocument,
    });
    const address = data.viewer.profile;

    try {
      return {
        address: address
          ? {
              city: address.city ?? '',
              street: address.street ?? '',
              streetAddition: address.streetAddition ?? '',
              zip: address.zip ?? '',
            }
          : getDefaultAddress(),
      };
    } catch (e) {
      logger.error(e);

      return { address: getDefaultAddress() };
    }
  },

  sendAddress: async (context: Context): Promise<AddressSchema> => {
    const res = await apolloClient.mutate({
      mutation: TeamOnboardingVerificationAddressUpdatePersonalAddressDocument,
      variables: { profile: context.address },
    });

    const address = res.data?.updateUserProfile?.profile;

    if (!address) {
      throw new Error();
    }

    return {
      city: address.city ?? '',
      street: address.street ?? '',
      streetAddition: address.streetAddition ?? '',
      zip: address.zip ?? '',
    };
  },
};

export default services;

export type Services = typeof services;
