import {
  chakra,
  createIcon,
  forwardRef,
  IconProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { IconName, icons, viewBox } from '@shinetools/sunshine-icons';

/**
 * build the icons set from the icons schema provided by sunshine-icons
 */
export const iconSet = Object.entries(icons).reduce(
  (iconsMap, [iconName, elements]) => {
    const childrens = elements.map((path, i) => (
      <path.component key={`iconName-${path.component}-${i}`} {...path.props} />
    ));

    return iconsMap.set(
      iconName as IconName,
      createIcon({ path: childrens, viewBox }),
    );
  },
  new Map<IconName, ReturnType<typeof createIcon>>(),
);

export interface SunshineIconProps
  extends ThemingProps<'SunshineIcon'>,
    Omit<IconProps, 'orientation'> {
  name: IconName;
}

/**
 * @deprecated Use Icon from `@shinetools/sunshine-universal` instead.
 * @see https://shinetools.github.io/shine/sunshine-universal-storybook/?path=/docs/icon--docs
 */
const SunshineIcon = forwardRef<SunshineIconProps, 'svg'>(
  ({ name, size = '1em', ...props }, ref) => {
    const {
      // use theme sizes or fallback to the size prop
      height = size,
      width = size,
      ...styles
    } = useStyleConfig('SunshineIcon', { size });

    return (
      <chakra.svg
        __css={{ height, width, ...styles }}
        as={iconSet.get(name)}
        ref={ref}
        {...props}
      />
    );
  },
);

export type { IconName };
export default SunshineIcon;
