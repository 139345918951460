/* eslint-disable react/display-name */

import { Box, Flex } from '@chakra-ui/react';

import * as PageLayout from 'features/Bento/components/PageLayout';
import { OBLayout } from 'features/Bento/types/OBLayout';

import SignupSidebar from './SignupSidebar';
import withLayoutFull from './withLayoutFull';

/**
 * A grid layout for the onboarding screens.
 */
const SignupLayoutMedium: OBLayout = ({
  actions,
  children,
  'data-testid': testId,
  footer,
  guidance,
  sidebar,
}) => (
  <PageLayout.Grid
    data-testid={testId}
    templateAreas={`
      "sidebar actions actions actions"
      "sidebar . body ."
    `}
    templateColumns="minmax(280px,320px) 1fr minmax(343px,462px) 1fr;"
    templateRows="82px auto"
  >
    {sidebar === false ? null : (
      <PageLayout.Area gridArea="sidebar">
        {sidebar || <SignupSidebar />}
      </PageLayout.Area>
    )}

    {actions ? (
      <PageLayout.Area gridArea="actions" padding="space-24">
        {actions}
      </PageLayout.Area>
    ) : null}

    <PageLayout.Body
      display="flex"
      flexDirection="column"
      gridArea="body"
      padding="space-16"
    >
      <Flex flexDirection="column" flexGrow={1}>
        {children}

        {guidance || null}

        <Box paddingRight="space-80">{footer}</Box>
      </Flex>
    </PageLayout.Body>
  </PageLayout.Grid>
);

export default withLayoutFull(SignupLayoutMedium);
