import { FC, PropsWithChildren } from 'react';

import { Transaction, VatAmount } from '__generated__/GQL';

export type FCWithChildren<P = Record<string, unknown>> = FC<
  PropsWithChildren<P>
>;

export interface GraphQLNode {
  __typename: string;
}

export interface Receipt extends GraphQLNode {
  receiptId: string;
  createdAt: number;
  updatedAt: number;
  category: string;
  currency: string;
  processing: boolean;
  sellerName: string;
  total: number;
  transactionAt: number;
  transactionId: string;
  url: string;
  deleted: boolean;
  vatAmount: VatAmount | null;
  __typename: 'Receipt';
}

interface PartialReceipt extends Partial<Receipt> {
  receiptId: Receipt['receiptId'];
  __typename: Receipt['__typename'];
}

export interface ReceiptPayload extends GraphQLNode {
  receipt: PartialReceipt;
  __typename: 'ReceiptPayload';
}

export type FeeType =
  | 'ACCOUNT_IRREGULAR_USE'
  | 'ADDITIONAL_TRANSFER'
  | 'CHECK'
  | 'CARD_ATM_EURO'
  | 'CARD_ATM_NON_EURO'
  | 'CARD_PAYOUT_NON_EURO'
  | 'CARD_PAYIN_EURO'
  | 'CARD_PAYIN_NON_EURO'
  | 'CARD_PAYIN_NON_EU'
  | 'CARD_PAYOUT_NON_EU'
  | 'CARD_PAYMENT_DISPUTE'
  | 'CARD_PAYMENT_IRREGULARITY'
  | 'CARD_REPLACEMENT_BASIC'
  | 'CARD_REPLACEMENT_PREMIUM'
  | 'CASH_DEPOSIT'
  | 'CHECK_INVALID'
  | 'CHECK_REFUSED'
  | 'DIRECT_DEBIT_CANCELLATION'
  | 'FREE'
  | 'SWIFT_TRANSFER_PAYIN';

interface PartialTransaction extends Partial<Transaction> {
  transactionId: Transaction['transactionId'];
  __typename: Transaction['__typename'];
}

export interface TransactionPayload extends GraphQLNode {
  transaction: PartialTransaction;
  __typename: 'TransactionPayload';
}

export interface CompanyUser extends GraphQLNode {
  companyUserId: string;
  companyProfileId: string;
  isAllowedToSkipPOA: boolean;
  isPrimaryApplicant: boolean;
  uid: string;
  __typename: 'CompanyUser';
  kycStatus: string;
  shouldAskForEpCguAgreement: boolean;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}
