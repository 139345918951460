import { FC } from 'react';
import { Grid, GridProps } from '@chakra-ui/react';

/**
 * A grid layout for the onboarding screens.
 * See the inline comments for details on each column.
 * Ugly overflow-x to prevent scrollbar to shift the grid
 */
const PageLayoutGrid: FC<GridProps> = ({
  children,
  templateRows = '1fr',
  ...props
}) => {
  return (
    <Grid
      gap="space-16"
      height="100vh"
      overflow="hidden"
      templateRows={templateRows}
      width="100vw"
      {...props}
    >
      {children}
    </Grid>
  );
};

export default PageLayoutGrid;
