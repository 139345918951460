import { createMachine } from 'xstate';

import shared from 'common/bento/shared';
import { AnyMachineEvent } from 'common/bento/types/Abstract';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';

import guards, { Guard } from './guards';
import model, { Context } from './model';
import services, { Service } from './services';

export enum State {
  IDLE = 'idle', // enter state
  DONE = 'done', // final state
  ERROR = 'error', // something went wrong

  PERSONAL_INFORMATION_FORM = 'personal-information-form', // ask user's personal information
  PERSONAL_INFORMATION_SEND = 'personal-information-send', // send user's personal information

  CREATE_REVIEW = 'create-review', // loader, waiting for the review to have been created
  IDENTITY = 'identity', // ask user for a proof of identity (PoID)
  UNDER_AUTO_REVIEW = 'under-auto-review', // application is being reviewed (gql subscription)
  NEED_MANUAL_REVIEW = 'need-manual-review', // fallback to the 'async' validation
  VALIDATED = 'validated', // the user account is now open
  WELCOME = 'welcome', // welcome the user, start of the flow-
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'employeeOrAccountant',
    initial: State.IDLE,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.IDLE]: {
        always: [
          {
            cond: Guard.IsWaitingForReview,
            target: State.NEED_MANUAL_REVIEW,
          },
          {
            cond: Guard.IsReviewInvalid,
            target: State.IDENTITY,
          },
          {
            cond: Guard.IsAwaitingReviewSubmission,
            target: State.CREATE_REVIEW,
          },
          {
            cond: Guard.IsProfileCompleted,
            target: State.WELCOME,
          },
          {
            target: State.PERSONAL_INFORMATION_FORM,
          },
        ],
      },

      /**
       * Ask the user's personal information
       */
      [State.PERSONAL_INFORMATION_FORM]: {
        on: {
          SUBMIT_PERSONAL_INFORMATION: State.PERSONAL_INFORMATION_SEND,
        },
      },

      /**
       * Send user's personal information
       */
      [State.PERSONAL_INFORMATION_SEND]: {
        invoke: {
          id: 'update-personal-information',
          onDone: [
            {
              cond: Guard.IsAccountant,
              target: State.VALIDATED,
            },
            {
              cond: Guard.IsEmployee,
              target: State.IDLE,
            },
          ],
          onError: {
            target: State.ERROR,
          },
          src: Service.UpdatePersonalInfos,
        },
      },

      [State.WELCOME]: {
        on: {
          NEXT: State.IDENTITY,
        },
      },

      /**
       * Proof of ID check
       */
      [State.IDENTITY]: {
        on: {
          NEXT: State.CREATE_REVIEW,
          PREV: State.WELCOME,
        },
      },

      [State.CREATE_REVIEW]: {
        invoke: {
          id: 'create-review',
          onDone: {
            target: State.UNDER_AUTO_REVIEW,
          },
          onError: {
            target: State.ERROR,
          },
          src: Service.CreateReview,
        },
      },

      /**
       * Review in progress (gql subscription)
       */
      [State.UNDER_AUTO_REVIEW]: {
        on: {
          NEXT: State.VALIDATED,
          ON_ERROR: State.NEED_MANUAL_REVIEW,
          VALIDATION_TIMEOUT: State.NEED_MANUAL_REVIEW,
        },
      },

      /**
       * Send to human review
       */
      [State.NEED_MANUAL_REVIEW]: {
        on: {
          NEXT: State.DONE,
        },
      },

      [State.VALIDATED]: {
        on: {
          NEXT: {
            target: State.DONE,
          },
        },
      },

      [State.ERROR]: {
        on: {
          NEXT: State.DONE,
        },
      },

      /*
       * Exit door
       */
      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },
    },
  },
  {
    guards,
    services,
  },
);

export default machine;
