/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8558%3A35124&t=Zo1x1tq5fDFhbo8L-1
 */

import { inputAnatomy } from '@chakra-ui/anatomy';
import { PartsStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';
import { getTokenValue } from '@shinetools/sunshine';

const parts = inputAnatomy.extend('group');
export type Parts = typeof parts;

const ICON_SIZE = '24px';
const ELEMENT_INTERNAL_PADDING = '12px';
const ICON_AND_TEXT_SPACING = '16px';
const INPUT_PADDING = `calc(${ELEMENT_INTERNAL_PADDING} + ${ICON_SIZE} + ${ICON_AND_TEXT_SPACING})`;

const disabledStyles: SystemStyleObject = {
  backgroundColor: 'input-backgroundColor-disabled',
  border: '1px solid',
  borderColor: 'input-borderColor-disabled',
  color: 'input-color-disabled',
  cursor: 'not-allowed',
  /**
   * Required for Webkit browsers that enforce a default color.
   */
  WebkitTextFillColor: getTokenValue('input-color-disabled'),
};

const baseStyle: PartsStyleFunction<Parts> = ({
  hasValue,
  leftIcon,
  rightIcon,
}) => ({
  element: {
    color: 'currentColor',
    paddingX: 'space-12',
    /**
     * to-do: improve pointer events
     * https://linear.app/shine/issue/SUN-25/rethink-pointerevents-for-inputelements
     */
    // pointerEvents: 'none',
    width: 'auto',
    zIndex: 0,
  },
  field: {
    _active: {
      borderColor: 'input-borderColor-active',
    },
    _disabled: {
      ...disabledStyles,
    },
    _focus: {
      borderColor: 'input-borderColor-focus',
    },
    _hover: {
      _disabled: { ...disabledStyles },
      backgroundColor: 'input-backgroundColor-hover',
    },
    _invalid: {
      _disabled: { ...disabledStyles },
      borderColor: 'input-borderColor-invalid',
    },
    _placeholder: {
      color: 'text-placeholder',
      fontWeight: 'weight-400',
    },
    _readOnly: {
      userSelect: 'all',
    },
    appearance: 'none',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'input-borderColor',
    borderRadius: 'radius-6',
    color: 'input-color',
    fontWeight: 'weight-500',
    height: '2.5rem', // 40px
    outline: 0,
    paddingX: 'space-12',
    position: 'relative',
    transitionDuration: 'duration-200',
    transitionProperty: 'common',
    width: '100%',
  },
  group: {
    '.chakra-input': {
      ...(leftIcon && { paddingLeft: INPUT_PADDING }),
      ...(rightIcon && { paddingRight: INPUT_PADDING }),
    },
    '.chakra-input__right-element': {
      ...(rightIcon && { color: 'text-placeholder' }),
    },
    _disabled: {
      color: 'input-color-disabled',
    },
    _focusWithin: {
      color: 'input-color',
    },
    color: hasValue ? 'input-color' : 'text-placeholder',
    display: 'flex',
    isolation: 'isolate',
    position: 'relative',
    width: '100%',
  },
});

const InputTheme = {
  baseStyle,
  parts: inputAnatomy.keys,
};

export default InputTheme;
