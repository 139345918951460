import { useMachine } from '@xstate/react';
import { ContextFrom, EventFrom } from 'xstate';

import { AnyMachine } from '../types/Abstract';
import { OnBentoModuleDone } from '../types/BentoModule';
import { AllStateMachineOptions } from '../types/Helpers';
import useOnModuleMachineDone from './useOnModuleMachineDone';
import useUpdatedRef from './useUpdatedRef';

/**
 * This hooks expands on `useMachine`.
 *
 * It will enrich it with
 *  - a getter for `moduleData`,
 *  - an `onDone` callback.
 */
const useModuleMachine = <TModuleData, TMachineConfig extends AnyMachine>({
  machineConfig,
  machineOptions,
  moduleData,
  onDone,
}: {
  moduleData: TModuleData;
  machineConfig: TMachineConfig;
  machineOptions?: AllStateMachineOptions<
    ContextFrom<TMachineConfig>,
    EventFrom<TMachineConfig>
  >;
  onDone: OnBentoModuleDone;
}) => {
  const moduleDataReference = useUpdatedRef(moduleData);

  const machine = useMachine<
    ContextFrom<TMachineConfig>,
    EventFrom<TMachineConfig>
  >(machineConfig, {
    ...machineOptions,

    context: {
      ...machineOptions?.context,

      getModuleData: () => moduleDataReference.current,
    } as ContextFrom<TMachineConfig>,
  });

  useOnModuleMachineDone(machine, onDone);

  return machine;
};

export default useModuleMachine;
