import { Box, Grid } from '@chakra-ui/react';

import { BentoLayout, BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';

// TODO(LED-2367): implement new layout
const OnboardingDesktop: BentoLayout = ({ children, nav, ...props }) => {
  const isNewLayout = !('data-onboarding-legacy' in props);

  const isLarge = useLayoutSizeQuery(BentoLayoutSize.lg);

  return isNewLayout ? (
    <Grid
      height="full"
      overflow="hidden"
      position="relative"
      templateColumns={[
        `minmax(min-content, ${isLarge ? '500px' : '300px'})`,
        `1fr`,
      ].join(' ')}
      {...props}
    >
      <Box paddingLeft="space-24" paddingY="space-32">
        {nav}
      </Box>

      <Box
        minHeight="full"
        overflowY="auto"
        paddingRight="space"
        paddingTop="space-128"
      >
        <Box maxWidth="420px" paddingLeft="space-2">
          {children}
        </Box>
      </Box>
    </Grid>
  ) : (
    <Grid
      gridGap="0"
      height="full"
      position="relative"
      templateColumns={[
        `minmax(min-content, ${isLarge ? '380px' : '260px'})`,
        `minmax(min-content, 1fr)`,
      ].join(' ')}
      width="100vw"
      {...props}
    >
      <Box paddingLeft="space-24" paddingY="space-32">
        {nav}
      </Box>

      <Grid
        gap="space-2"
        overflowY="auto"
        templateColumns={[
          `minmax(min-content, ${isLarge ? '120px' : '40px'})`, // The back button
          `minmax(min-content, 420px)`, // The rest
        ].join(' ')}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default OnboardingDesktop;
