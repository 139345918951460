import { FC } from 'react';

import SunshineIcon, { SunshineIconProps } from '../../../SunshineIcon';
import { useCard } from '../../hooks/useCard';

const CardSunshineIcon: FC<SunshineIconProps> = (props) => {
  const { isDisabled } = useCard();

  return (
    <SunshineIcon
      color="grey.600"
      size="icon-24"
      {...props}
      {...(isDisabled && { color: 'disabled' })}
    />
  );
};

export default CardSunshineIcon;
