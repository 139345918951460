import { Box } from '@chakra-ui/react';

import { FCWithChildren } from 'common/types';
import Text from 'components/_core/Text';

const Description: FCWithChildren = (props) => (
  <Box marginBottom="space-32">
    <Text {...props} />
  </Box>
);

export default Description;
