import { useEffect, useState } from 'react';

import { LoginStep } from './types';

const useAutoResettingErrorMessage = (
  currentStep: LoginStep,
): [string, (newMessage: string) => void] => {
  const [message, setMessage] = useState('');
  // Reset the error message each time the current login step changes
  useEffect((): void => {
    setMessage('');
  }, [currentStep]);
  return [message, setMessage];
};

export default useAutoResettingErrorMessage;
