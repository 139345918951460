import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'For security reasons, changing the passcode is not possible on a new device.\n\nIn order to change your code, go to a device to which you have already connected (computer or another mobile).',
    emailSubject: 'I don’t have access to my other device (change password)',
    noAccessToTrustedDeviceDescription:
      'If you are unable to access your device, please send us a photo of yourself holding your identity card to:',
    noAccessToTrustedDeviceTitle:
      'What if I no longer have access to a connected device?',
    title: 'This action is not possible from this device',
  },
  fr: {
    description:
      'Pour des raisons de sécurité, le changement de code secret n’est pas possible sur un nouvel appareil.\n\nAfin de changer votre code, rendez-vous sur un appareil auquel vous vous êtes déjà connecté·e (ordinateur ou autre mobile).',
    emailSubject:
      'Je n’ai plus accès à mon autre appareil (changement de mot de passe)',
    noAccessToTrustedDeviceDescription:
      'Si vous n’êtes pas en mesure d’accéder à votre appareil, merci de nous envoyer une photo de vous tenant votre carte d’identité à\xa0:',
    noAccessToTrustedDeviceTitle:
      'Comment faire si je n’ai plus accès à un appareil connecté\xa0?',
    title: 'Cette action est impossible depuis cet appareil',
  },
});
export default locales;
