import { FC, useCallback, useState } from 'react';
import { Divider, Image } from '@chakra-ui/react';

import crossRedFull from 'assets/images/crossRedFull.svg';
import successCheckConfettis from 'assets/images/successCheckConfettis.svg';
import Button from 'components/_core/Button';
import ErrorView from 'components/ErrorView';

import NewDeviceLoginLayout from '../NewDeviceLoginLayout';
import { sendEmailWithSecureLink as sendEmail } from './helpers';
import locales from './locales';
import { Description, Title } from './styles';

interface NoAccessToDeviceProps {
  authenticationDeviceId: string;
  authenticationDeviceRequestId: string;
  cancel: () => void;
  passcode: string;
  phone: string;
}

const NoAccessToDevice: FC<NoAccessToDeviceProps> = ({
  authenticationDeviceId,
  authenticationDeviceRequestId,
  cancel,
  passcode,
  phone,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const sendEmailWithSecureLink = useCallback(async () => {
    setIsLoading(true);
    try {
      await sendEmail({
        authenticationDeviceId,
        authenticationDeviceRequestId,
        passcode,
        phone,
      });

      setIsEmailSent(true);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    setIsEmailSent,
    authenticationDeviceId,
    authenticationDeviceRequestId,
    passcode,
    phone,
  ]);

  if (hasError) {
    return <ErrorView />;
  }

  if (isEmailSent) {
    return (
      <NewDeviceLoginLayout>
        <Image src={successCheckConfettis} width="180px" />
        <Title>{locales.successTitle}</Title>
        <Description>{locales.successDescription}</Description>
      </NewDeviceLoginLayout>
    );
  }

  return (
    <NewDeviceLoginLayout cancel={cancel}>
      <Image src={crossRedFull} width="180px" />
      <Title>{locales.title}</Title>
      <Description>{locales.description}</Description>
      <Divider marginTop="space-32" width="113%" />
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        marginTop="space-24"
        onClick={sendEmailWithSecureLink}
      >
        {locales.receiveAnEmail}
      </Button>
    </NewDeviceLoginLayout>
  );
};

export default NoAccessToDevice;
