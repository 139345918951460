import { verifyReferral } from '../libs/verifyReferral';
import { Context } from './model';

const services = {
  /**
   * This service check that the context's referral code, if present, is valid.
   *
   * If there is an invalid referral code, it throws the attempted code, used to inform the user.
   * In any other case it returns a resolved Promise.
   */
  verifyReferral: async ({ referral }: Context) => {
    if (!referral) {
      return null;
    }

    return verifyReferral(referral.code)
      .then((response) => response.data)
      .catch(() => {
        throw referral.code;
      });
  },
};

export default services;

export type Services = typeof services;
