import { useContext, useState } from 'react';

import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import {
  GuidanceShineStartAdvertising,
  GuidanceShineStartLandbot,
  GuidanceShineStartMicro,
  GuidanceShineStartSociety,
} from '../../components/Guidance';
import { model } from '../../machine';
import { Signup } from '../../machine/machine';
import { CompanyCreationType } from '../../machine/model';
import { SignupPage } from '../../types/SignupPage';
import locales from './locales';

/**
 * A page asking the user what type of company they want to create
 */
const CompanyCreationSelectType: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);
  const [option, setOption] = useState<CompanyCreationType | undefined>(
    undefined,
  );

  const renderGuidance = () => {
    if (option === undefined) {
      return <GuidanceShineStartAdvertising />;
    }

    return {
      independent: <GuidanceShineStartMicro />,
      society: <GuidanceShineStartSociety />,
      unknown: <GuidanceShineStartLandbot />,
    }[option];
  };

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      footer={
        <Button
          isDisabled={option === undefined}
          onClick={() => {
            if (option) {
              send(model.events.SUBMIT_COMPANY_CREATION_TYPE(option));
            }
          }}
          type="submit"
          width="100%"
        >
          {locales.submit}
        </Button>
      }
      guidance={renderGuidance()}
    >
      <PageLayout.Header marginBottom="space-24">
        <Heading size="lg">{locales.title}</Heading>
      </PageLayout.Header>

      <SunshineCard.RadioGroup
        marginBottom="space-24"
        onChange={(nextValue) => setOption(nextValue as CompanyCreationType)}
        value={option}
      >
        <SunshineCard.Group>
          <SunshineCard
            title={locales.societyTitle}
            value="society"
            variant="radio"
          >
            <SunshineCard.Content>
              <SunshineCard.Text>
                {locales.societyDescription}
              </SunshineCard.Text>
            </SunshineCard.Content>
          </SunshineCard>

          <SunshineCard
            title={locales.independentDescription}
            value="independent"
            variant="radio"
          >
            <SunshineCard.Content>
              <SunshineCard.Text>
                {locales.independentDescription}
              </SunshineCard.Text>
            </SunshineCard.Content>
          </SunshineCard>

          <SunshineCard
            title={locales.unknownTitle}
            value="unknown"
            variant="radio"
          >
            <SunshineCard.Content>
              <SunshineCard.Text>
                {locales.unknownDescription}
              </SunshineCard.Text>
            </SunshineCard.Content>
          </SunshineCard>
        </SunshineCard.Group>
      </SunshineCard.RadioGroup>
    </Layout>
  );
};

export default CompanyCreationSelectType;
