import { Box, Collapse, Flex, useDisclosure, VStack } from '@chakra-ui/react';

import { FCWithChildren } from 'common/types';
import Button from 'components/_core/Button';

import locales from '../locales';

/**
 * A component that displays the main content of the OBDocumentView in a foldable drawer.
 */
const OBDocumentViewDrawer: FCWithChildren<{ onClose: () => void }> = ({
  children,
  onClose,
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <Box
      backgroundColor="white"
      borderTopRadius="radius-24"
      bottom="space-0"
      boxShadow="shadow-medium-3"
      left="space-0"
      overflow="hidden"
      position="fixed"
      right="space-0"
      zIndex="modal"
    >
      <VStack
        align="stretch"
        background="white"
        paddingX="space-20"
        paddingY="space-16"
        spacing="space-0"
      >
        <Flex justify="space-between">
          <Button
            alignSelf="flex-end"
            icon="cross"
            iconPosition="left"
            onClick={onClose}
            size="sm"
            variant="inline-secondary"
          >
            {locales.cancel}
          </Button>

          <Button
            alignSelf="flex-end"
            icon={isOpen ? 'chevron-down' : 'chevron-right'}
            iconPosition="left"
            onClick={onToggle}
            size={isOpen ? 'sm' : 'md'}
            variant={isOpen ? 'secondary' : 'primary'}
          >
            {isOpen ? locales.collapse : locales.expand}
          </Button>
        </Flex>

        <Collapse
          in={isOpen}
          style={{ overflow: isOpen ? 'visible' : 'hidden' }}
          unmountOnExit={false}
        >
          <Box marginTop="space-16" maxHeight="85vh" overflow="scroll">
            {children}
          </Box>
        </Collapse>
      </VStack>
    </Box>
  );
};

export default OBDocumentViewDrawer;
