import { FC, Fragment, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Circle, Grid, HStack } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { CompanyUserStatus } from '__generated__/GQL';
import webView from 'common/webView';
import SunshineIcon from 'components/_core/SunshineIcon';
import Text from 'components/_core/Text';
import MotionBox from 'components/MotionBox';

import BackButton from '../BackButton';
import CloseButton from '../CloseButton';
import MenuMobile from './components/MenuMobile';
import { TeamOnboardingNavigationDocument } from './graphql/queries/navigation.gql';
import { NavigationProps } from './Navigation';
import NavigationContext, {
  Navigation,
  NavigationStepStatus,
} from './NavigationContext';

interface NavigationMobileProps extends NavigationProps {
  navigation: Navigation;
}

const getStepCircleStyles = (status: NavigationStepStatus) => {
  if (status === NavigationStepStatus.Done) {
    return {
      bg: 'grey.800',
      color: 'white',
      fontSize: '13px',
      size: 'space-24',
    } as const;
  }

  if (status === NavigationStepStatus.Ongoing) {
    return {
      borderColor: 'grey.800',
      borderStyle: 'solid',
      borderWidth: '1.5px',
      boxSizing: 'border-box',
      color: 'grey.800',
      fontWeight: 'weight-500',
    } as const;
  }

  return {
    bg: 'grey.300',
    color: 'grey.700',
    fontWeight: 'weight-500',
  } as const;
};

const getSeparatorBackground = (status: NavigationStepStatus) => {
  if (status === NavigationStepStatus.Done) {
    return 'yellow.800';
  }

  return 'grey.300';
};

const NavigationMobile: FC<NavigationMobileProps> = ({
  isCollapsed = false,
  navigation,
  onClose,
  onPrev,
  ...props
}) => {
  const activeStep = navigation.steps.find(
    (step) => step.status === NavigationStepStatus.Ongoing,
  );
  const { data } = useQuery(TeamOnboardingNavigationDocument);

  const navigationContext = useContext(NavigationContext);
  const shouldCollapseNavigation =
    navigationContext?.shouldCollapseNavigation || isCollapsed;

  const renderPrevOrCloseButton = () => {
    if (onClose) {
      return <CloseButton gridArea="prev" onClick={onClose} />;
    }

    if (onPrev) {
      return <BackButton gridArea="prev" onClick={onPrev} />;
    }

    return <Box gridArea="prev" />;
  };

  return (
    <Grid
      alignItems="center"
      backgroundColor="white"
      borderBottom="1px solid"
      borderBottomColor="white"
      {...(shouldCollapseNavigation && {
        borderBottomColor: 'grey.3',
      })}
      columnGap="space-8"
      left={0}
      paddingTop="space-16"
      paddingX="space-16"
      position="sticky"
      right={0}
      rowGap="space-12"
      templateAreas="'prev section settings' '. timeline .'"
      templateColumns="40px 1fr 40px"
      templateRows="40px"
      top={0}
      transitionDuration="duration-500"
      transitionProperty="all"
      transitionTimingFunction="ease-out"
      {...props}
    >
      {renderPrevOrCloseButton()}

      <AnimatePresence exitBeforeEnter initial={false}>
        {shouldCollapseNavigation ? null : (
          <MotionBox
            animate={{ opacity: 1, transition: { delay: 0.15 }, y: 0 }}
            exit={{ opacity: 0, y: -15 }}
            gridArea="section"
            initial={{ opacity: 0, y: -15 }}
          >
            <Text size="lg" textAlign="center" variant="primary">
              {activeStep?.title}
            </Text>
          </MotionBox>
        )}
      </AnimatePresence>

      {webView.config?.isEmbedInAppLayout || !data ? null : (
        <MenuMobile
          isMandatee={data?.viewer.metadata.isMandatee}
          isMultiAccount={
            (data?.viewer.companyUsers ?? []).filter(
              (cu) => cu.status !== CompanyUserStatus.Locked,
            ).length > 1
          }
        />
      )}

      <HStack
        align="center"
        as={motion.div}
        gridArea={shouldCollapseNavigation ? 'section' : 'timeline'}
        justify="space-between"
        layout
        marginX="auto"
        maxWidth="216px"
      >
        {navigation.steps.map((step, index) => {
          return (
            <Fragment key={step.title}>
              <Circle
                fontSize="13px"
                size="space-24"
                title={step.title}
                {...getStepCircleStyles(step.status)}
              >
                {step.status === NavigationStepStatus.Done ? (
                  <SunshineIcon name="tick" />
                ) : (
                  (index + 1).toString()
                )}
              </Circle>

              {index < navigation.steps.length - 1 ? (
                <Box
                  bg={getSeparatorBackground(step.status)}
                  borderRadius="full"
                  height="1.5px"
                  width={shouldCollapseNavigation ? '16px' : '24px'}
                />
              ) : null}
            </Fragment>
          );
        })}
      </HStack>
    </Grid>
  );
};

export default NavigationMobile;
