import { useIntercom } from 'react-use-intercom';
import { Heading, VStack } from '@chakra-ui/react';

import redFlag from 'assets/brand/red-flag@2x.png';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import Text from 'components/_core/Text';
import { getDeviceInformation } from 'helpers/device';

import CardInfo from '../../components/CardInfo';
import DeviceInfo from '../../components/DeviceInfo';
import ModalBody from '../../components/ModalBody';
import { Request } from '../../types';
import locales from './locales';

interface Props {
  onFinish: () => void;
  navigateToCards: () => void;
  cardPaymentStrongAuthenticationRequest: Request;
}

const CardPaymentStrongAuthenticationRequestRefused = ({
  cardPaymentStrongAuthenticationRequest,
  navigateToCards,
  onFinish,
}: Props) => {
  const { authenticationDevice, creditCard } =
    cardPaymentStrongAuthenticationRequest;
  const hasBeenRefusedElsewhere =
    authenticationDevice?.token !== getDeviceInformation().token;

  const { show } = useIntercom();

  const openSupport = () => {
    onFinish();
    show();
  };

  return (
    <>
      <ModalBody>
        <Modal.Image src={redFlag} width="144px" />
        <Heading marginBottom="space-12">{locales.title}</Heading>
        <Text marginBottom="space-32">
          {hasBeenRefusedElsewhere
            ? locales.descriptionAlreadyRefused
            : locales.description}
        </Text>
        {hasBeenRefusedElsewhere && authenticationDevice ? (
          <VStack
            alignItems="start"
            borderColor="border"
            borderRadius="radius-8"
            borderWidth="thin"
            marginBottom="space-32"
          >
            <DeviceInfo authenticationDevice={authenticationDevice} />
          </VStack>
        ) : (
          <>
            <Text marginBottom="space-10" variant="light">
              {locales.usedCreditCard}
            </Text>
            <VStack
              alignItems="start"
              borderColor="grey.2"
              borderRadius="radius-8"
              borderWidth="thin"
              marginBottom="space-32"
            >
              <CardInfo creditCard={creditCard} />
            </VStack>
          </>
        )}
      </ModalBody>
      <Modal.Footer justifyContent="space-between">
        {hasBeenRefusedElsewhere ? (
          <Button onClick={onFinish}>{locales.closeButton}</Button>
        ) : (
          <>
            <Button onClick={openSupport} variant="inline-primary">
              {locales.contactSupport}
            </Button>
            <Button onClick={navigateToCards}>
              {locales.goToPaymentCardsButton}
            </Button>
          </>
        )}
      </Modal.Footer>
    </>
  );
};

export default CardPaymentStrongAuthenticationRequestRefused;
