/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8500%3A33155&t=RlRyvbosnkJ5XVCD-1
 */

import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  color: 'text-secondary',
  fontFamily: 'mabry',
  fontFeatureSettings: `'salt' on, 'ss08' on`, // Modify Mabry Pro font characters (middle dot and question mark)
  fontVariant: 'no-common-ligatures tabular-nums', // Configure Mabry Pro font (no ligature)
  lineHeight: 'base',
  strong: { fontWeight: 'weight-500' },
  whiteSpace: 'pre-line',
};

type Size = 'lg' | 'md' | 'sm' | 'xs';

const sizes: Record<Size, SystemStyleObject> = {
  lg: {
    fontSize: 'font-18',
  },
  md: {
    fontSize: {
      base: 'font-16',
      md: 'font-15',
    },
  },
  sm: {
    fontSize: {
      base: 'font-15',
      md: 'font-13',
    },
  },
  xs: {
    fontSize: {
      base: 'font-13',
      md: 'font-12',
    },
    lineHeight: {
      base: 'base',
      md: 'short',
    },
  },
};

export type Variant = 'primary' | 'secondary' | 'light' | 'disabled' | 'danger';

const variants: Record<Variant, SystemStyleObject> = {
  danger: {
    color: 'error',
  },
  disabled: {
    color: 'disabled',
  },
  light: {
    color: 'text-discreet',
  },
  primary: {
    color: 'text-primary',
  },
  secondary: {
    color: 'text-secondary',
  },
};

const defaultProps = {
  size: 'md',
  variant: 'secondary',
};

const TextTheme = {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};

export default TextTheme;
