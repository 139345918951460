import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    back: 'Back',
    description: 'Important actions require your passcode.',
    title: 'Confirm with your passcode',
  },
  fr: {
    back: 'Retour',
    description: 'Les opérations importantes nécessitent votre code secret.',
    title: 'Confirmez avec votre code secret',
  },
});

export default locales;
