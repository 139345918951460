/* eslint-disable react/jsx-no-useless-fragment */
import { Route, useRouteMatch } from 'react-router-dom';

import { FCWithChildren } from 'common/types';

import useModuleLocation from '../../lib/useModuleLocation';

/**
 * We extract a component here just to call the hook inside the `RouteParameter` component.
 * Because the `navigate` call must be made in a child of the `RouteParameter`.
 */
const SyncLocationComponent: FCWithChildren<{ path: string }> = ({
  children,
  path,
}) => {
  useModuleLocation(path);

  return <>{children}</>;
};

/**
 * Given a `path`, this component will wrap its children in a Route,
 * and navigate to `path` locally (in the last, trailing part of the URL) each time it changes.
 */
const SyncLocation: FCWithChildren<{ path: string }> = ({ children, path }) => {
  const { url } = useRouteMatch();

  return (
    <Route path={`${url}/:page?`}>
      <SyncLocationComponent path={path}>{children}</SyncLocationComponent>
    </Route>
  );
};

export default SyncLocation;
