import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    agreement: 'I agree to',
    cta: 'Agree and continue',
    emailAlreadyExists: 'This email address is already used',
    inputLabel: 'Email Address',
    subtitle: 'Your email address is an additional security factor',
    termsAndConditions: 'the terms & conditions',
    title: 'Enter your email address',
  },
  fr: {
    agreement: 'J’accepte',
    cta: 'Accepter et continuer',
    emailAlreadyExists: 'Cette adresse email est déjà utilisée',
    inputLabel: 'Adresse email',
    subtitle: 'Votre adresse email est un facteur de sécurité supplémentaire',
    termsAndConditions: 'les conditions générales d’utilisation',
    title: 'Entrez votre adresse email',
  },
} as const);

export default locales;
