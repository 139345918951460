import { FCWithChildren } from 'common/types';

import { Slots } from '../../hooks/useSlot';

interface SlotProps {
  name: Slots;
}

/**
 * enable slots to be used
 * the main Card component will handle the rendering and the positioning of the slots
 */
const Slot: FCWithChildren<SlotProps> = () => null;

export default Slot;
