import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9eaf7;
  color: black;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  cursor: pointer;

  @media (max-width: 504px) {
    display: none;
  }
`;

interface Props {
  text: string;
}

const Tooltip: FC<Props> = () => (
  <Container>
    <div>?</div>
  </Container>
);

export default Tooltip;
