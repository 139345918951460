/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=4333%3A18223&t=L5brpIIutNGDqzZP-1
 */

import { accordionAnatomy } from '@chakra-ui/anatomy';
import {
  anatomy,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const parts = anatomy('Accordion').parts(
  'root',
  'container',
  'button',
  'panel',
  'iconLeft',
  'iconRight',
  'link',
);
export type Parts = typeof parts;

const baseStyleContainer: SystemStyleObject = {
  borderBottom: '1px solid',
  borderColor: 'grey.300',
  color: 'grey.0',
};

const baseStyleButton: SystemStyleObject = {
  _disabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  _expanded: {
    color: 'text-primary',
  },
  _focus: {
    boxShadow: 'outline',
  },
  _hover: {
    background: 'none',
    color: 'text-primary',
  },
  alignItems: 'center',
  color: 'grey.700',
  display: 'flex',
  fontSize: { base: 'font-16', md: 'font-15' },
  fontWeight: 'weight-400',
  justifyContent: 'space-between',
  paddingLeft: 'space-24',
  paddingRight: 0,
  paddingY: 'space-16',
  position: 'relative',
  textAlign: 'left',
  transition: 'color 200ms linear',
  transitionDuration: 'duration-200',
  transitionProperty: 'common',
};

const baseStylePanel: SystemStyleObject = {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  gap: 'space-12',
  paddingBottom: 'space-16',
  paddingTop: 'space-8',
  paddingX: 'space-24',
};

const baseStyleIconLeft: SystemStyleObject = {
  '[aria-expanded="true"] &': {
    color: 'text-primary',
  },
  color: 'grey.600',
  left: 0,
  position: 'absolute',
  size: 'icon-16',
  top: '50%',
  transform: 'translateY(-50%)',
  transition: 'color 200ms linear',
};

const baseStyleIconRight: SystemStyleObject = {
  '[aria-expanded="true"] &': {
    color: 'text-primary',
  },
  color: 'grey.600',
  marginLeft: 'space-8',
  transition: 'transform 200ms ease-out, color 200ms linear',
};

const baseStyleLink: SystemStyleObject = {
  _hover: {
    color: 'grey.700',
  },
  color: 'grey.700',
  fontSize: {
    base: 'font-16',
    md: 'font-13',
  },
};

const baseStyle: PartsStyleObject<Parts> = {
  button: baseStyleButton,
  container: baseStyleContainer,
  iconLeft: baseStyleIconLeft,
  iconRight: baseStyleIconRight,
  link: baseStyleLink,
  panel: baseStylePanel,
};

const AccordionTheme = {
  baseStyle,
  parts: accordionAnatomy.keys,
};

export default AccordionTheme;
