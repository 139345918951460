import { FC } from 'react';
import { useLocation } from 'react-router';
import { useDisclosure } from '@chakra-ui/react';

import restrictedAccessSvg from 'assets/images/restricted-access.svg';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';

import { SecuredRoutesState } from '../index';
import locales from './locales';

const InvitationRedemptionError: FC = () => {
  const { state } = useLocation<SecuredRoutesState>();
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: state?.invitationRedeemedByExistingTeamMember,
  });

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body marginBottom="space-32">
        <Modal.Image src={restrictedAccessSvg} />
        <Modal.Title>{locales.title}</Modal.Title>
        <Modal.Description>{locales.description}</Modal.Description>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>{locales.button}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvitationRedemptionError;
