import type { FC } from 'react';
import { AccountSelectionItemRebranded } from 'Main/SecuredRoutes/AccountSelection/components/AccountSelectionRebranded/AccountSelectionItemRebranded';

import useCompanyContext from 'common/hooks/useCompanyContext';
import { UserCompanyList } from 'common/hooks/useUserCompanyList';
import SunshineCard from 'components/_core/SunshineCard';
import { getCompanies } from 'components/CompanyList/utils';

interface Props {
  companies: UserCompanyList;
}

const AccountSelectionRebranded: FC<Props> = ({ companies }) => {
  const { companyProfileId, setCompanyContext } = useCompanyContext();
  const { companyInOnboarding, otherValidatedCompanies, selectedCompany } =
    getCompanies(companies, companyProfileId);

  return (
    <SunshineCard.Group>
      {selectedCompany ? (
        <AccountSelectionItemRebranded company={selectedCompany} />
      ) : null}
      {otherValidatedCompanies.map((company) => (
        <AccountSelectionItemRebranded
          company={company}
          key={company.companyProfileId}
          onClick={() => {
            setCompanyContext({
              companyProfileId: company.companyProfileId,
              companyUserId: company.companyUserId,
            });
          }}
        />
      ))}
      {companyInOnboarding ? (
        <AccountSelectionItemRebranded
          company={companyInOnboarding}
          isOnboarding
          key={companyInOnboarding.companyProfileId}
          onClick={() => {
            setCompanyContext({
              companyProfileId: companyInOnboarding.companyProfileId,
              companyUserId: companyInOnboarding.companyUserId,
            });
          }}
        />
      ) : null}
    </SunshineCard.Group>
  );
};

export default AccountSelectionRebranded;
