import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    genericError: {
      description:
        'This view couldn’t load correctly. It happens sometimes, and in this case you have to wait a few minutes before trying again. If the problem persists after 10 minutes, you can contact our support team.',
      title: 'An error occurred',
    },
    restrictedAccess: {
      description:
        'The link is no longer valid. Please reach out to the account holder for more information. He will have to generate a new link.',
      title: 'The link has expired',
    },
    tooManyRequests: {
      description:
        'The link you used is temporarily unavailable. We invite you to try again in a few hours or to connect to a secure private wifi. If the problem persists, you can contact the support.',
      title: 'Link temporarily unavailable',
    },
  },
  fr: {
    genericError: {
      description:
        'Cette vue n’a pas pu charger correctement. Cela arrive parfois, et il faut dans ce cas-là patienter quelques minutes avant de réessayer. Si le problème persiste après 10 minutes, vous pouvez prendre contact avec nos équipes.',
      title: 'Une erreur est survenue',
    },
    restrictedAccess: {
      description:
        'Le lien que vous avez utilisé n’est plus valable. Nous vous invitons à vous rapprocher du titulaire du compte pour plus d’informations afin qu’il puisse vous renvoyer un nouveau lien.',
      title: 'Le lien a expiré',
    },
    tooManyRequests: {
      description:
        'Le lien que vous avez utilisé est temporairement indisponible. Nous vons invitons à réessayer d’ici quelques heures ou de vous connecter sur un wifi privé sécurisé. Si le problème persiste, vous pouvez contacter le support.',
      title: 'Lien temporairement indisponible',
    },
  },
});
