import { createContext, ReactNode } from 'react';

export enum NavigationStepStatus {
  Done = 'DONE',
  Ongoing = 'ONGOING',
  Todo = 'TODO',
}

export interface NavigationStep {
  status: NavigationStepStatus;
  title: string;
  slot?: ReactNode;

  subSteps?: Array<{
    steps: {
      status: NavigationStepStatus;
      title: string;
    };
  }>;
}

export type Navigation = {
  /**
   * The list of all steps to display in the Navigation UI.
   */
  steps: NavigationStep[];
  shouldCollapseNavigation?: boolean;
};

const NavigationContext = createContext<Navigation>({
  steps: [],
});

export default NavigationContext;
