import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Link as ChakraLink, Text } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import Heading from 'components/_core/Heading';
import { SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import { Intro } from '../../machine/machine';
import locales from './locales';

const AccountAlreadyExists: SignupPage<Intro> = () => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout>
      <Heading marginBottom="space-24" size="lg">
        {locales.title}
      </Heading>

      <Callout marginBottom="space-24">
        <Callout.Content>
          {locales.description}
          <ChakraLink
            fontSize="inherit"
            href="https://help.shine.fr/fr/articles/1175607-est-il-possible-d-ouvrir-plusieurs-comptes-shine"
          >
            {locales.multiAccountLink}
          </ChakraLink>
        </Callout.Content>
      </Callout>

      <Text marginBottom="space-4">{locales.prompt}</Text>

      <Button alignSelf="flex-start" as={Link} to="/" variant="inline-primary">
        {locales.login}
      </Button>
    </Layout>
  );
};

export default AccountAlreadyExists;
