import { FC, ReactNode } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import { LoginStep } from 'components/Login/LoginForm/types';
import { InvitationInfo } from 'types/invitation';

import accountantImg from './assets/accountant.png';
import appImg from './assets/app.png';
import EmployeeFeatures from './components/EmployeeFeatures';
import LegalBeneficiaryPresentation from './components/LegalBeneficiaryPresentation';
import * as S from './styles';
import {
  getHeading,
  isAccountant,
  isAdmin,
  isEmployee,
  isLegalBeneficiary,
} from './utils';

interface AuthentificationLayoutProps {
  children: ReactNode;
  currentStep?: LoginStep;
  invitationInfo: Pick<InvitationInfo, 'role'> | null;
}

const AuthentificationLayout: FC<AuthentificationLayoutProps> = ({
  children,
  invitationInfo: invitation,
}) => {
  const heading = getHeading(invitation?.role);

  return (
    <Flex height="100vh" textAlign="center" width="100vw">
      <S.Inner>{children}</S.Inner>

      {isLegalBeneficiary(invitation?.role) || isAdmin(invitation?.role) ? (
        <LegalBeneficiaryPresentation flexGrow={1} marginLeft="auto" />
      ) : (
        <Box
          alignItems="center"
          backgroundColor="grey.200"
          display={{ base: 'none', md: 'flex' }}
          flex={1}
          flexDirection="column"
          gap="space-40"
          justifyContent="center"
        >
          <Heading marginX="auto" maxWidth="60%" size="xl">
            {heading}
          </Heading>

          <Flex
            justifyContent="center"
            marginX="auto"
            maxWidth="1024px"
            width="90%"
          >
            {isEmployee(invitation?.role) ? <EmployeeFeatures /> : null}

            {isAccountant(invitation?.role) ? (
              <Image alt="" role="presentation" src={accountantImg} />
            ) : null}

            {!isEmployee(invitation?.role) &&
            !isAccountant(invitation?.role) ? (
              <Image alt="" role="presentation" src={appImg} />
            ) : null}
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default AuthentificationLayout;
