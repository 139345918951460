import { FC, useState } from 'react';

import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import * as Form from 'components/_core/form';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import Column from 'components/Column';
import LegacyForm from 'components/Form';
import { checkEmail, startRegister } from 'helpers/auth/service';
import { InvitationInfo } from 'types/invitation';

import BackButton from '../components/BackButton';
import { LoginStep } from '../LoginForm/types';
import locales from './locales';

interface CguWithHiddenEmailProps {
  email: string;
  hasAgreedToTermsOfService: boolean;
  invitationInfo: InvitationInfo | null;
  onBack: () => void;
  setCurrentStep: (currentStep: LoginStep) => void;
  toggleHasAgreedToTermsOfService: () => void;
  phone: string;
}

const CguWithHiddenEmail: FC<CguWithHiddenEmailProps> = ({
  email,
  hasAgreedToTermsOfService,
  invitationInfo,
  onBack,
  phone,
  setCurrentStep,
  toggleHasAgreedToTermsOfService,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldDisplayAlreadyExistsError, setShouldDisplayAlreadyExistsError] =
    useState(false);

  const onSubmit = async () => {
    // TODO(Achille): This should be done with an async validator
    // on the input component itself
    try {
      setShouldDisplayAlreadyExistsError(false);
      setIsLoading(true);
      const { available } = await checkEmail(email);
      setIsLoading(false);

      if (!available) {
        setShouldDisplayAlreadyExistsError(true);
      }

      const isValid = available && hasAgreedToTermsOfService;

      if (isValid) {
        await startRegister({ phone });
        setCurrentStep('createVerificationCode');
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <LegacyForm onValidSubmit={onSubmit}>
      <BackButton onClick={onBack} />
      <Column>
        <Heading marginBottom="space-8" size="lg">
          {locales.title}
        </Heading>

        <Text marginBottom="space-32">
          {locales.formatString(locales.subtitle, {
            companyName: invitationInfo?.companyName as string,
          })}
        </Text>

        {shouldDisplayAlreadyExistsError ? (
          <Callout variant="error">
            <Callout.Content>{locales.error}</Callout.Content>
          </Callout>
        ) : (
          <>
            <Form.Checkbox
              id="terms-and-conditions"
              isChecked={hasAgreedToTermsOfService}
              onChange={toggleHasAgreedToTermsOfService}
            >
              <Form.Checkbox.Label>
                {locales.agreement}&nbsp;
                <a
                  href="https://www.shine.fr/lp/cgu-ep-en-clair/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Text
                    as="span"
                    color="blue.-1"
                    display="inline"
                    fontWeight="weight-500"
                  >
                    {locales.termsAndConditions}
                  </Text>
                </a>
              </Form.Checkbox.Label>
            </Form.Checkbox>

            <Button
              isDisabled={!hasAgreedToTermsOfService}
              isLoading={isLoading}
              marginTop="space-32"
              type="submit"
            >
              {locales.cta}
            </Button>
          </>
        )}
      </Column>
    </LegacyForm>
  );
};

export default CguWithHiddenEmail;
