import { useMemo } from 'react';
import { countries, CountryCode, countryCodes } from '@shinetools/geo-library';

import useCurrentLanguage from 'common/hooks/useCurrentLanguage';

import { TranslatedCountry, TranslatedCountrySortFn } from './types';

const sortByLabel: TranslatedCountrySortFn = (a, b) =>
  a.label.localeCompare(b.label);
/**
 * Returns a sorted list of countries in the given language.
 */
const useCountriesList = (options?: {
  excludedCountries?: CountryCode[];
  includeDrom?: boolean;
}) => {
  const { excludedCountries = [], includeDrom = true } = options ?? {};

  const language = useCurrentLanguage();
  return useMemo(() => {
    const filteredCountryList = Object.entries(countries).filter(
      ([country]) => {
        if (excludedCountries?.includes(country as CountryCode)) {
          return false;
        }

        if (!includeDrom && !countryCodes.includes(country as CountryCode)) {
          return false;
        }
        return true;
      },
    );

    const countriesList: TranslatedCountry[] = filteredCountryList.map(
      ([id, { callingCode, emoji, flag, name }]) => ({
        callingCode,
        emoji,
        flag,
        label: name[language],
        value: id,
      }),
    );

    return countriesList.sort(sortByLabel);
  }, [language, excludedCountries, includeDrom]);
};

export default useCountriesList;
