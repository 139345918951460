import {
  NavigationStep,
  NavigationStepStatus,
} from 'features/TeamOnboarding/Onboarding/components/Navigation/NavigationContext';

import { State } from '../machine';
import locales from './locales';
import { SECTIONS, STATE_TO_SECTION } from './sections';

/**
 * Takes the current State of the beneficiary flow machine
 * and returns the relevant navigation steps assignable to NavigationContext.
 */
const getNavigationSteps = (state: State): NavigationStep[] => {
  const currentSection = STATE_TO_SECTION[state];
  const indexOfCurrentSection =
    currentSection === null ? null : SECTIONS.indexOf(currentSection);

  const getSectionStatus = (
    sectionIndex: number,
    activeSectionIndex: number,
  ) => {
    if (sectionIndex === activeSectionIndex) {
      return NavigationStepStatus.Ongoing;
    }

    return sectionIndex > activeSectionIndex
      ? NavigationStepStatus.Todo
      : NavigationStepStatus.Done;
  };

  return indexOfCurrentSection === null
    ? []
    : SECTIONS.map((section, index) => ({
        status: getSectionStatus(index, indexOfCurrentSection),
        title: locales[section].label,
      }));
};

export default getNavigationSteps;
