import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Center, VStack } from '@chakra-ui/react';
import AccountSelectionRebranded from 'Main/SecuredRoutes/AccountSelection/components/AccountSelectionRebranded';
import { FullscreenContainer } from 'Main/styles';

import { UserCompany } from 'common/hooks/useUserCompanyList';
import SunshineLoader from 'components/_core/SunshineLoader';
import Text from 'components/_core/Text';

import { GetUserProfileAccountSelectionDocument } from './graphql/userProfile.gql';
import locales from './locales';

interface AccountSelectionProps {
  companies: UserCompany[];
}

const AccountSelection: FC<AccountSelectionProps> = ({ companies }) => {
  const { data, error, loading } = useQuery(
    GetUserProfileAccountSelectionDocument,
  );
  const companiesUserIsNotLockedFrom = useMemo(
    () => companies.filter((company) => !company.isCompanyUserLocked),
    [companies],
  );

  if (error) {
    // Have any errors at this loading up stage caught by the top level ErrorBoundary and message
    throw new Error(`${error.name}: ${error.message}`);
  }

  if (loading || !data) {
    return (
      <FullscreenContainer>
        <SunshineLoader />
      </FullscreenContainer>
    );
  }

  const name = data.viewer.profile.firstName ?? '';

  return (
    <Center
      height="full"
      marginX="auto"
      padding="space-24"
      width={{ base: 'full', md: '498px' }}
    >
      <VStack alignItems="stretch" spacing="space-16" width="full">
        <VStack alignItems="stretch" spacing="space-4">
          <Text fontSize="font-18" fontWeight="weight-500">
            {locales.formatString(locales.hello, { name })}
          </Text>
          <Text>{locales.selectYourAccount}</Text>
        </VStack>

        <AccountSelectionRebranded companies={companiesUserIsNotLockedFrom} />
      </VStack>
    </Center>
  );
};

export default AccountSelection;
