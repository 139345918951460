import { useCallback, useEffect, useRef, useState } from 'react';

import config from 'config';
import devConfig from 'config/development';
import { loadGoogleMapsScript } from 'features/GoogleMaps/utils';
import isPlaywrightTest from 'helpers/experiments/isPlaywrightTest';

import { getAddressFromPlace } from './helpers';
import {
  Address,
  Prediction,
  SearchOptions,
  UseAddressSearchOptions,
} from './types';

const useAddressSearch = ({
  debounceDelay = 300,
  language,
  region = 'fr',
}: UseAddressSearchOptions) => {
  const [predictions, setPredictions] = useState<Prediction[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const placesService = useRef<google.maps.places.PlacesService | undefined>();

  const autocompleteService = useRef<
    google.maps.places.AutocompleteService | undefined
  >();

  const sessionToken = useRef<
    google.maps.places.AutocompleteSessionToken | undefined
  >();

  const debounceTimeout = useRef<NodeJS.Timeout | undefined>();

  const getPlaceDetails = useCallback(
    async (placeId: string) =>
      new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        placesService.current?.getDetails(
          {
            fields: ['address_component'],
            language,
            placeId,
            region,
            sessionToken: sessionToken.current,
          },
          (result) => {
            if (result) {
              resolve(result);
            } else {
              reject(new Error(`Unable to get place details for ${placeId}`));
            }
          },
        );

        // A session in N calls to autocomplete API + 1 call to places API
        sessionToken.current =
          new google.maps.places.AutocompleteSessionToken();
      }),
    [language, region],
  );

  const fetchPredictions = useCallback(
    (input: string) => {
      autocompleteService.current?.getPlacePredictions(
        {
          componentRestrictions: {
            country: region,
          },
          input,
          region,
          sessionToken: sessionToken.current,
          types: ['address'],
        },
        (placePredictions) => {
          setPredictions(
            placePredictions?.map((placePrediction) => ({
              getAddress: async (): Promise<Address> => {
                const place = await getPlaceDetails(placePrediction.place_id);

                return getAddressFromPlace(place);
              },
              mainText: placePrediction.structured_formatting.main_text,
              placeId: placePrediction.place_id,
              secondaryText:
                placePrediction.structured_formatting.secondary_text,
            })) ?? [],
          );
        },
      );
      setIsLoading(false);
    },
    [getPlaceDetails, region],
  );

  const clear = useCallback(() => {
    setPredictions(null);
    setIsLoading(false);
  }, [setPredictions, setIsLoading]);

  const search = useCallback(
    async (input: string, options: SearchOptions = {}) => {
      if (debounceTimeout.current !== undefined) {
        clearTimeout(debounceTimeout.current);
      }

      if (!input) {
        clear();
        return;
      }

      setIsLoading(true);

      if (options.debounce === false) {
        fetchPredictions(input);
      } else {
        debounceTimeout.current = setTimeout(() => {
          fetchPredictions(input);
        }, debounceDelay);
      }
    },
    [clear, debounceDelay, fetchPredictions],
  );

  useEffect(() => {
    loadGoogleMapsScript({
      apiKey: isPlaywrightTest() ? devConfig.gcloudApiKey : config.gcloudApiKey,
      language,
    }).then(() => {
      if (!google?.maps?.places) {
        throw new Error('GoogleMaps has not been found.');
      }

      sessionToken.current = new google.maps.places.AutocompleteSessionToken();

      autocompleteService.current =
        new google.maps.places.AutocompleteService();

      placesService.current = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
    });
  }, [language]);

  return {
    clear,
    isLoading,
    predictions,
    search,
  };
};

export default useAddressSearch;
