/**
 * Not added yet in Figma, so this implementation is based on https://shinetools.slack.com/archives/C043U87DR9D/p1676391252844919?thread_ts=1676386746.921699&cid=C043U87DR9D
 */

import { anatomy, PartsStyleObject } from '@chakra-ui/theme-tools';

const parts = anatomy('ProgressBar').parts('container', 'bar');

export type Parts = typeof parts;

const baseStyle: PartsStyleObject<Parts> = {
  bar: {
    background: 'progressBar-color',
    borderRadius: 'radius-full',
    transition: 'all 0.2s ease-out',
  },
  container: {
    background: 'progressBar-backgroundColor',
    borderRadius: 'radius-full',
    display: 'flex',
    height: '3px',
    width: '100%',
  },
};

const sizes = {
  lg: {
    container: { height: '6px' },
  },
  md: {
    container: { height: '3px' },
  },
} as const;

const defaultProps = {
  size: 'lg',
};

const ProgressBarTheme = {
  baseStyle,
  defaultProps,
  part: parts.keys,
  sizes,
} as const;

export default ProgressBarTheme;
