import { LinkProps } from '@chakra-ui/react';
import { chakra, forwardRef, useStyleConfig } from '@chakra-ui/system';
import { mergeWith } from '@chakra-ui/utils';

import { useStyles } from '../../context';

type AccordionLinkProps = LinkProps;

const AccordionLink = forwardRef<AccordionLinkProps, 'a'>((props, ref) => {
  const linkStyles = useStyleConfig('Link');
  const styles = useStyles();

  // We want the style of the Link component, overridden by some properties defined in the Accordion theme
  // We also want to be able to use the as props with this component
  // -> merging computed styles from both components is the easiest way to do so, as they're plain JS object
  // Note that mergeWith with 2 arguments is just a deep merge
  const css = mergeWith(linkStyles, styles.link);

  return <chakra.a __css={css} ref={ref} {...props} />;
});

export default AccordionLink;
