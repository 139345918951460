import { CountryCode } from '@shinetools/geo-library';

import { KycStatus } from '__generated__/GQL';
import assertEventType from 'common/bento/lib/assertEventType';
import { MachineGuards } from 'common/bento/types/MachineConfig';
import canEditPersonalInformation from 'features/TeamOnboarding/Onboarding/lib/canEditPersonalInformation';

import { ACCEPTED_PASSPORT_AND_ID_CARD_COUNTRIES } from '../libs/acceptedPassportAndIdCardCountries';
import { Model } from './model';

export enum Guard {
  CanEditIdentityInformation = 'canEditIdentityInformation',
  HasNativeIdCheckSucceeded = 'hasNativeIdCheckAchieved',
  HasNativeIdCheckFailed = 'hasNativeIdCheckFailed',
  ShouldGoThroughResidencyDocumentQuestions = 'shouldGoThroughResidencyDocumentQuestions',
  IsIdentityReviewInvalid = 'isIdentityReviewInvalid',
}

const guards: MachineGuards<Model, Guard> = {
  canEditIdentityInformation: ({ getModuleData }) => {
    const { companyUser, isNewOnboardingAllowed } = getModuleData().viewer;

    return canEditPersonalInformation({
      companyUser,
      isNewOnboardingAllowed,
    });
  },

  hasNativeIdCheckAchieved: (context, event) => {
    assertEventType(event, 'done.invoke.completeNativeIdCheck');

    return event.data.status === 'done';
  },

  hasNativeIdCheckFailed: (context, event) => {
    assertEventType(event, 'done.invoke.completeNativeIdCheck');

    return event.data.status === 'error';
  },

  isIdentityReviewInvalid: (context) => {
    const { companyUser } = context.getModuleData().viewer;

    return companyUser.kycIdStatus === KycStatus.Refused;
  },

  shouldGoThroughResidencyDocumentQuestions: ({ getModuleData }) => {
    const { nationality } = getModuleData().viewer.profile;

    if (!nationality) {
      throw new Error(
        `nationality missing in shouldGoThroughResidencyCardQuestions`,
      );
    }

    return !ACCEPTED_PASSPORT_AND_ID_CARD_COUNTRIES.has(
      nationality as CountryCode,
    );
  },
};

export default guards;
