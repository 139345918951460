import {
  RefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

/**
 * Listen if an element is in or out of the viewport.
 *
 * @param options IntersectionObserver's options, see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * @returns The visibility state or null if the visibility hasn't been determined yet
 */
const useIsVisible = (
  elementRef: RefObject<Element>,
  options?: IntersectionObserverInit,
): boolean | null => {
  const previousVisiblity = useRef(false);
  const [isVisible, setIsVisible] = useState<boolean | null>(null);

  const handleObserve = useCallback(
    (entries: IntersectionObserverEntry[]): void => {
      const entry = entries.find(
        ({ target }: IntersectionObserverEntry): boolean =>
          target === elementRef.current,
      );

      if (!entry) {
        return;
      }

      const newVisibility = entry.intersectionRatio > 0.0;

      if (previousVisiblity.current !== newVisibility) {
        previousVisiblity.current = newVisibility;
        setIsVisible(newVisibility);
      }
    },
    [elementRef],
  );

  useLayoutEffect((): (() => void) | void => {
    const { current: el } = elementRef;

    if (!window.IntersectionObserver || !el) {
      return undefined;
    }

    const observer = new window.IntersectionObserver(handleObserve, {
      threshold: [0.0, 1.0],
      ...options,
    });

    observer.observe(el);

    return (): void => {
      observer.unobserve(el);
    };
  }, [elementRef, handleObserve, options]);

  return isVisible;
};

export default useIsVisible;
