import type { FC, ReactNode } from 'react';
import type { CenterProps } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';

interface ErrorLayoutProps extends CenterProps {
  children: ReactNode;
}

const ErrorLayout: FC<ErrorLayoutProps> = ({ children, ...props }) => {
  return (
    <Center height="100vh" {...props}>
      {children}
    </Center>
  );
};

export default ErrorLayout;
