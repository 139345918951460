import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

import useCompanyContext from 'common/hooks/useCompanyContext';
import webView from 'common/webView';
import IconButton from 'components/_core/IconButton';

import locales from '../OBMenu/locales';
import OBMenuMobileItem from './components/OBMenuMobileItem';

interface OBMenuMobileProps {
  isMandatee?: boolean;
  isMultiAccount: boolean;
}

const OBMenuMobile: FC<OBMenuMobileProps> = ({
  isMandatee,
  isMultiAccount,
}) => {
  const diclosure = useDisclosure();
  const { clearCompanyContext } = useCompanyContext();
  const intercom = useIntercom();

  return (
    <Fragment>
      <IconButton
        aria-label="Menu"
        boxSize="40px"
        fontSize="18px"
        gridArea="settings"
        icon="help"
        onClick={diclosure.onOpen}
        variant="tertiary"
      />

      <Modal {...diclosure} motionPreset="slideInBottom">
        <ModalOverlay />

        <ModalContent
          alignSelf="flex-end"
          borderBottomRadius="0"
          borderTopRadius="radius-24"
          marginBottom="0"
        >
          <ModalBody padding="space-12">
            <VStack
              alignItems="flex-start"
              divider={<Divider />}
              spacing="space-0"
            >
              <OBMenuMobileItem
                icon="help"
                label={locales.help}
                onClick={
                  webView.isInWebView
                    ? () =>
                        webView.postMessage({
                          action: 'OPEN_INTERCOM_REQUESTED',
                        })
                    : // As this specific button is shown dynamically in an overlay,
                      // Intercom doesn't register it correctly with the magic Intercom classname.
                      intercom.show
                }
              />

              {isMandatee || isMultiAccount ? (
                <OBMenuMobileItem
                  icon="company"
                  label={locales.switchCompany}
                  // Clearing the company context here will redirect the user to the
                  // appropriate company selection screen
                  onClick={clearCompanyContext}
                />
              ) : null}

              <OBMenuMobileItem
                as={Link}
                icon="logout-left"
                label={locales.logout}
                to="/logout"
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default OBMenuMobile;
