import { FC, ReactNode } from 'react';

import Accordion from 'components/_core/Accordion';

/**
 * Small and foldable version of the helper
 * - It is folded by default
 * - The title is mandatory
 */
interface GuidanceSmallProps {
  title: string;
  children: ReactNode;
}

const GuidanceSmall: FC<GuidanceSmallProps> = ({ children, title }) => (
  <Accordion>
    <Accordion.Item title={title}>
      <Accordion.Text>{children}</Accordion.Text>
    </Accordion.Item>
  </Accordion>
);

export default GuidanceSmall;
