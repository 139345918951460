import { tagAnatomy } from '@chakra-ui/anatomy';
import {
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

import Badge from './Badge';

type Parts = typeof tagAnatomy;

const baseStyleContainer: SystemStyleObject = {
  _focus: {
    // @to-check
    boxShadow: 'outline',
  },
  fontWeight: 'weight-500',
  lineHeight: 1.2,
  outline: 0,
};

const baseStyleLabel: SystemStyleObject = {
  lineHeight: 1.2,
  overflow: 'visible',
};

const baseStyleCloseButton: SystemStyleObject = {
  _active: { opacity: 1 },
  _disabled: {
    opacity: 0.4,
  },
  _focus: {
    background: 'rgba(0, 0, 0, 0.14)',
    boxShadow: 'outline',
  },

  _hover: { opacity: 0.8 },

  borderRadius: 'radius-full',

  fontSize: 'font-18',

  height: '1.25rem',

  // @to-check
  marginEnd: '-1',
  marginStart: 'space-4',
  opacity: 0.5,
  transitionDuration: 'duration-200',
  transitionProperty: 'common',
  width: '1.25rem',
};

const baseStyle: PartsStyleObject<Parts> = {
  closeButton: baseStyleCloseButton,
  container: baseStyleContainer,
  label: baseStyleLabel,
};

const sizes: Record<string, PartsStyleObject<Parts>> = {
  sm: {
    closeButton: {
      marginEnd: '-2px',
      marginStart: 'space-6',
    },
    container: {
      borderRadius: 'radius-6',
      fontSize: 'font-12',
      minHeight: '1.25rem',
      minWidth: '1.25rem',
      paddingX: 'space-8',
    },
  },
};

const variants: Record<string, PartsStyleInterpolation<Parts>> = {
  subtle: (props) => ({
    container: Badge.baseStyle(props),
  }),
};

const defaultProps = {
  colorScheme: 'gray',
  size: 'sm',
  variant: 'subtle',
};

const TagTheme = {
  baseStyle,
  defaultProps,
  parts: tagAnatomy.keys,
  sizes,
  variants,
};

export default TagTheme;
