import { Operation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ExecutionResult, GraphQLError } from 'graphql';

import redactSecrets from './redactSecrets';
import { getOperationType, setTraceIdInSentryTagsIfPresent } from './utils';

/**
 * Reports a GraphQL error to Sentry.
 */
const reportGraphQLErrorToSentry = (
  graphQLError: GraphQLError,
  operation: Operation,
  response: ExecutionResult | undefined,
) => {
  const { locations, message, path } = graphQLError;

  const { operationName, query } = operation;
  const operationType = getOperationType(operation);

  Sentry.withScope((scope) => {
    // Short term solution, all graphql errors are reported as "info" to avoid alert fatigue.
    // The long term goal is be able to distinguish business errors that are not foreseen by the frontend and report them as errors.
    scope.setLevel('info');

    setTraceIdInSentryTagsIfPresent(scope, operation);

    // Set tags, which can be used for filtering in Sentry UI
    scope.setTags({
      graphqlError: true,
      graphqlErrorMessage: message,
      operationName,
    });

    const readableQuery = query.loc?.source.body;

    // Set extra values, that cannot be used for filtering in Sentry UI
    scope.setExtras({
      locations: JSON.stringify(locations, null, 2),
      path: path?.join(' > '),
      query: readableQuery,

      response: JSON.stringify(response, null, 2),
      // ensure we do not send sensitive pieces of information such as PIN codes
      variables: JSON.stringify(redactSecrets(operation.variables), null, 2),
    });

    Sentry.captureMessage(
      `GraphQL ${operationType} ${operationName} failed : ${message}`,
    );
  });
};

export default reportGraphQLErrorToSentry;
