import { FC } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react';

import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import Icon from 'components/_core/Icon';
import SunshineIcon from 'components/_core/SunshineIcon';
import MotionBox from 'components/MotionBox';
import { logEvent } from 'features/Analytics/analytics';

import OBMenuItem from './components/OBMenuItem';
import locales from './locales';

interface OBMenuProps {
  firstName: string | null;
  lastName: string | null;
  isMandatee?: boolean;
  isMultiAccount?: boolean;
}

/**
 * The common menu of the onboarding.
 * Features a logout button directing to `/logout`.
 */
const OBMenu: FC<OBMenuProps> = ({
  firstName,
  isMandatee,
  isMultiAccount,
  lastName,
}) => {
  const { clearCompanyContext } = useCompanyContext();
  const history = useHistory();

  const switchCompany = () => {
    clearCompanyContext();

    /**
     * @note Necessary, since we removed the useEffect listening on the company context to decide whether we show the
     * account selection component or not. Navigating back to the Onboarding screen will show the account selection
     * component again.
     */
    history.push('/onboarding');
  };

  return (
    <Menu
      closeOnSelect={false}
      onClose={() => logEvent({ name: 'Close_Onboarding_Menu_Dropdown' })}
      onOpen={() => logEvent({ name: 'Open_Onboarding_Menu_Dropdown' })}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            _hover={{
              background: 'grey.200',
            }}
            as={Button}
            background="white"
            borderRadius="radius-6"
            height="auto"
            marginBottom="space-32"
            marginX="-space-8"
            padding="space-8"
          >
            <Flex align="center">
              <Avatar
                background="yellow.600"
                color="black"
                icon={<SunshineIcon name="profile" size="icon-20" />}
                marginRight="space-8"
                name={
                  firstName && lastName ? `${firstName} ${lastName}` : undefined
                }
                size="sm"
              />

              <Text>{firstName || locales.genericLabel}</Text>

              <MotionBox
                animate={{ rotate: isOpen ? 180 : 0 }}
                marginLeft="space-24"
              >
                <Icon color="grey.600" name="chevron-down" size="icon-10" />
              </MotionBox>
            </Flex>
          </MenuButton>

          <MenuList minWidth="240px" paddingY="0">
            {isMandatee || isMultiAccount ? (
              <OBMenuItem
                iconName="company"
                label={locales.switchCompany}
                // Clearing the company context here will redirect the user to the
                // appropriate company selection screen
                onClick={switchCompany}
              />
            ) : null}

            <OBMenuItem
              as={NavLink}
              iconName="logout"
              label={locales.logout}
              to="/logout"
            />
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default OBMenu;
