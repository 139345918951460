import { FC } from 'react';

import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import Button, { ButtonProps } from 'components/_core/Button';
import IconButton from 'components/_core/IconButton';

import locales from './locales';

/**
 * The back button for all onboarding pages.
 * It already has a left/right margin of space-32,
 * so it can be used out-of-the-box directly as a first child of a layout content.
 */
const BackButton: FC<Omit<ButtonProps, 'children'>> = (props) => {
  const isLarge = useLayoutSizeQuery(BentoLayoutSize.lg);

  return isLarge ? (
    <Button
      icon="arrow-left"
      iconPosition="left"
      size="sm"
      variant="tertiary"
      {...props}
    >
      {locales.back}
    </Button>
  ) : (
    <IconButton
      aria-label={locales.back}
      icon="arrow-left"
      variant="inline-secondary"
      {...props}
    />
  );
};

export default BackButton;
