import { Component, ElementType, ReactNode } from 'react';

import logger from 'helpers/logger';

interface ErrorBoundaryProps {
  children: ReactNode;
  errorView: ElementType;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public componentDidCatch(error: Error) {
    logger.error(error);
    this.setState({ hasError: true });
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    const { children, errorView: View } = this.props;

    if (hasError) {
      return <View />;
    }

    return children;
  }
}

export default ErrorBoundary;
