import { FC } from 'react';
import { Box, Divider, Flex, Link, Text } from '@chakra-ui/react';

import { OnboardingHotline } from '__generated__/GQL';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';

import { locales } from './locales';

interface HotlineProps {
  hotline: Partial<OnboardingHotline>;
}

const HotlineInfo: FC<HotlineProps> = ({ hotline }) => {
  const { availability, description, isAvailable, phoneNumber } = hotline;

  if (!isAvailable && !hotline.calendarLink) {
    return null;
  }

  return (
    <>
      {hotline.calendarLink ? (
        <Flex alignItems="start">
          <SquircleIcon colorScheme="green" name="calendar" />

          <Box marginLeft="space-16">
            <Text fontSize="font-13" variant="secondary">
              {description}
            </Text>
            <Text fontSize="font-13" variant="light">
              {availability}
            </Text>
            <Link href={hotline.calendarLink} isExternal>
              {locales.bookingLinkText}
            </Link>
          </Box>
        </Flex>
      ) : (
        <Flex alignItems="start">
          {isAvailable ? (
            <SquircleIcon colorScheme="green" name="phone" />
          ) : (
            <SquircleIcon colorScheme="gray" name="phone-offline" />
          )}

          <Box marginLeft="space-16">
            <Text variant="primary">{phoneNumber}</Text>
            <Text fontSize="font-13" variant="secondary">
              {description}
            </Text>
            <Text fontSize="font-13" variant="light">
              {availability}
            </Text>
          </Box>
        </Flex>
      )}

      <Divider marginY="space-24" />
    </>
  );
};

export default HotlineInfo;
