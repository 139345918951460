// eslint-disable-next-line no-restricted-imports
import { useTheme as ChakraUseTheme } from '@chakra-ui/react';

import { Theme } from '../';

const useTheme = () => {
  return ChakraUseTheme<Theme>();
};

export default useTheme;
