import { KycStatus } from '__generated__/GQL';
import { MachineActions } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';
import webView from 'common/webView';

import { TrackSegmentEventDocument } from '../../../../../Analytics/graphql/mutations/trackSegmentEvent.gql';
import { trackSkipVerificationHomeDecided } from './lib';
import { Context, Model } from './model';

export enum Action {
  TrackApplicantBeneficiaryStatusCompleted = 'trackApplicantBeneficiaryStatusCompleted',
  TrackVerificationHomeSkipped = 'trackVerificationHomeSkipped',
  TrackVerificationHomeShowed = 'trackVerificationHomeShowed',
  GoToApplicationSelection = 'goToApplicationSelection',
  TrackAvoidAppLayoutExperiment = 'trackAvoidAppLayoutExperiment',
  TrackVerificationHomeErrors = 'trackVerificationHomeErrors',
}

const actions: MachineActions<Model, Action> = {
  goToApplicationSelection: (context) => {
    context.getModuleData().companyContext.clearCompanyContext();
  },

  trackApplicantBeneficiaryStatusCompleted: (context: Context) => {
    const { companyProfileId } =
      context.getModuleData().verificationData.viewer.company;

    apolloClient.mutate({
      mutation: TrackSegmentEventDocument,
      variables: {
        input: {
          companyProfileId,
          event: 'Applicant Beneficiary Status Completed',
        },
      },
    });
  },

  trackAvoidAppLayoutExperiment: ({ getModuleData }) => {
    if (
      // This experiment only matters in the webView.
      !webView.isInWebView ||
      // The `appVersion` config property was rolled in 1.196.0, same release as the start of the experiment.
      !webView.config?.appVersion
    ) {
      return;
    }

    const { shouldSkipAppLayoutExperiment } =
      getModuleData().verificationData.viewer.webViewOnboarding;

    // This is a synchronous action : this mutation is a side-effect.
    apolloClient.mutate({
      mutation: TrackSegmentEventDocument,
      variables: {
        input: {
          event: 'Experiment AvoidAppLayout Started',
          properties: {
            isInExperiment: shouldSkipAppLayoutExperiment,
          },
        },
      },
    });
  },

  trackVerificationHomeErrors: ({ getModuleData }) => {
    const { kycIdStatus, lastKycReview } =
      getModuleData().verificationData.viewer.companyUser;

    if (kycIdStatus === KycStatus.Refused) {
      apolloClient.mutate({
        mutation: TrackSegmentEventDocument,
        variables: {
          input: {
            event: 'Onboarding Verification ID Issue Shown',
            properties: {
              isBeforeFirstSubmission: !lastKycReview,
            },
          },
        },
      });
    }
  },

  trackVerificationHomeShowed: ({ getModuleData }) => {
    const { companyProfileId } =
      getModuleData().verificationData.viewer.company;

    return trackSkipVerificationHomeDecided({
      companyProfileId,
      skippedVerificationHome: false,
    });
  },

  trackVerificationHomeSkipped: ({ getModuleData }) => {
    const { companyProfileId } =
      getModuleData().verificationData.viewer.company;

    return trackSkipVerificationHomeDecided({
      companyProfileId,
      skippedVerificationHome: true,
    });
  },
};

export default actions;
