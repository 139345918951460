import { FC, useEffect, useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { CompanyInvitationRole } from '__generated__/GQL';
import { useTrackLoadingState } from 'common/sentry/webAppOpeningTransaction';
import HelpAndTermsLinks from 'components/HelpAndTermsLinks';
import { authenticationEmitter, AuthenticationEvents } from 'helpers/auth';
import AuthentificationLayout from 'layouts/AuthentificationLayout';
import { InvitationInfo } from 'types/invitation';

import { LoginType } from '../types';
import Component from './component';
import ForgotPasscodeUnknownDevice from './components/ForgotPasscodeUnknownDevice';
import NewDeviceAuthorizationComponent from './components/NewDeviceAuthorization';
import { Footer } from './styles';
import { GetDefaultStepFn, LoginFormProps, LoginStep } from './types';
import useConfirmResetPasscode from './useConfirmResetPasscode';
import useCreatePasscodeSubmit from './useCreatePasscodeSubmit';
import useEmailSubmit from './useEmailSubmit';
import usePasscodeSubmit from './usePasscodeSubmit';
import usePhoneSubmit from './usePhoneSubmit';
import useVerificationCodeSubmit from './useVerificationCodeSubmit';

const getPreviousStep = ({
  currentStep,
  invitationInfo,
  isInvitation,
  isOnSignupPage,
  loginType,
}: {
  currentStep: LoginStep;
  loginType: LoginType;
  invitationInfo: InvitationInfo | null;
  isOnSignupPage: boolean;
  isInvitation: boolean;
}): LoginStep => {
  const isUmInvitee =
    invitationInfo?.role === CompanyInvitationRole.Accountant ||
    invitationInfo?.role === CompanyInvitationRole.Employee ||
    invitationInfo?.role === CompanyInvitationRole.Admin;

  if (loginType === 'sign_up' && currentStep === 'cguWithHiddenEmail') {
    return 'redeemInvite';
  }

  if (currentStep === 'verificationCode') {
    return 'passcode';
  }

  if (currentStep === 'createPasscode') {
    if (loginType === 'passcode_reset') {
      return 'confirmResetPasscode';
    }

    if (isUmInvitee) {
      return 'cguWithHiddenEmail';
    }

    return 'createEmail';
  }

  if (currentStep === 'createVerificationCode') {
    if (isUmInvitee) {
      return 'cguWithHiddenEmail';
    }

    return 'createEmail';
  }

  if (currentStep === 'confirmResetPasscode') {
    return 'passcode';
  }

  if (currentStep === 'createEmail') {
    if (isOnSignupPage || loginType === 'sign_up') {
      return 'createPhone';
    }
    if (isInvitation) {
      return 'redeemInvite';
    }
  }

  if (invitationInfo?.role === CompanyInvitationRole.LegalBeneficiary) {
    return 'redeemInvite';
  }

  return 'phone';
};

const getDefaultStep: GetDefaultStepFn = ({
  isInvitation,
  isInvitationValid,
  isOnSignupPage,
  user,
}) => {
  if (isInvitation) {
    return isInvitationValid ? 'redeemInvite' : 'wrongInviteSlug';
  }
  if (user && user.phone) {
    return 'passcode';
  }
  if (isOnSignupPage) {
    return 'createPhone';
  }
  return 'phone';
};

const LoginForm: FC<LoginFormProps> = ({
  invitationInfo,
  invitationSlug: invitationSlugProp,
  loginType,
  setCurrentUser,
  setLoginType,
  user,
}) => {
  const intercom = useIntercom();
  const isInitialStep = useRef(true);
  const isInvitation = invitationSlugProp !== null;
  const isOnSignupPage = window.location.pathname === '/signup';

  const [phoneNumberOwnershipToken, setPhoneNumberOwnershipToken] = useState<
    string | null
  >(null);

  const [currentStep, setCurrentStep] = useState<LoginStep>(
    getDefaultStep({
      isInvitation,
      isInvitationValid: isInvitation && invitationInfo !== null,
      isOnSignupPage,
      user,
    }),
  );

  useTrackLoadingState({ destination: 'login', isLoaded: true });

  useEffect(() => {
    intercom.boot();

    return () => {
      intercom.shutdown();
    };
  }, [intercom]);

  useEffect(() => {
    // logout users going back to phone step to avoid unwanted remains
    if (!isInitialStep.current) {
      if (currentStep === 'phone') {
        // If we currently are in an invitation redeeming flow, soft logout in
        // order to not lose the slug in the path.
        if (isInvitation) {
          authenticationEmitter.emit(AuthenticationEvents.OnSoftLogout);
        } else {
          authenticationEmitter.emit(AuthenticationEvents.OnLogout);
        }
      }
    } else {
      isInitialStep.current = false;
    }
  }, [currentStep, isInvitation]);

  const phoneSubmitProps = usePhoneSubmit(
    currentStep,
    user ? user.phone || '' : '',
    setCurrentStep,
    setLoginType,
    loginType,
    invitationInfo,
  );
  const { phone } = phoneSubmitProps;

  const createEmailProps = useEmailSubmit(invitationInfo?.inviteeEmail);
  const { email, hasAgreedToTermsOfService } = createEmailProps;

  // Uses a different hook for the passcode stage when registering/logging in
  // as the work carried out is quite different.
  const passcodeSubmitProps = usePasscodeSubmit(
    currentStep,
    phone,
    setCurrentStep,
    setCurrentUser,
    loginType,
  );
  const {
    authenticationDeviceId,
    authenticationDeviceRequestId,
    passcode: passcodeFromPasscodeSubmit,
  } = passcodeSubmitProps;

  const createPasscodeSubmitProps = useCreatePasscodeSubmit({
    currentStep,
    email,
    hasAgreedToTermsOfService,
    loginType,
    phone,
    phoneNumberOwnershipToken: phoneNumberOwnershipToken as string,
    setCurrentStep,
    setCurrentUser,
  });

  const { passcode: passcodeFromCreatePasscodeSubmit } =
    createPasscodeSubmitProps;

  // Verification goes back to using the same hook for both registering/logging in.
  const verificationCodeSubmitProps = useVerificationCodeSubmit({
    currentStep,
    loginType,
    passcode:
      loginType === 'sign_in'
        ? passcodeFromPasscodeSubmit
        : passcodeFromCreatePasscodeSubmit,
    phone,
    setCurrentStep,
    setCurrentUser,
    setPhoneNumberOwnershipToken,
  });

  const confirmResetPasscodeProps = useConfirmResetPasscode(
    setLoginType,
    setCurrentStep,
  );

  const onBack = (): void => {
    if (loginType !== 'sign_in' && currentStep === 'createPhone') {
      setLoginType('sign_in');
    }
    if (
      loginType === 'passcode_reset' &&
      currentStep === 'confirmResetPasscode'
    ) {
      setLoginType('sign_in');
    }
    setCurrentStep(
      getPreviousStep({
        currentStep,
        invitationInfo,
        isInvitation,
        isOnSignupPage,
        loginType,
      }),
    );
  };
  const onStartRegistering = (): void => {
    setLoginType('sign_up');
    setCurrentStep('createPhone');
  };
  const onLogin = (): void => {
    setLoginType('sign_in');
    setCurrentStep('phone');
  };

  if (currentStep === 'forgotPasscodeUnknownDevice') {
    return (
      <ForgotPasscodeUnknownDevice cancel={() => setCurrentStep('phone')} />
    );
  }

  if (currentStep === 'newDeviceAuthorization') {
    return (
      <NewDeviceAuthorizationComponent
        authenticationDeviceId={authenticationDeviceId}
        authenticationDeviceRequestId={authenticationDeviceRequestId}
        cancel={() => setCurrentStep('phone')}
        passcode={passcodeFromPasscodeSubmit}
        phone={phone}
        submitPasscode={passcodeSubmitProps.onSubmit}
        submitPasscodeErrorMessage={passcodeSubmitProps.errorMessage}
        submitPasscodeLoading={passcodeSubmitProps.loading}
      />
    );
  }

  return (
    <AuthentificationLayout
      currentStep={currentStep}
      invitationInfo={invitationInfo}
    >
      <Component
        confirmResetPasscodeProps={confirmResetPasscodeProps}
        createEmailSubmitProps={createEmailProps}
        createPasscodeSubmitProps={createPasscodeSubmitProps}
        currentStep={currentStep}
        invitationInfo={invitationInfo}
        isOnSignupPage={isOnSignupPage}
        loginType={loginType}
        onBack={onBack}
        onLogin={onLogin}
        onStartRegistering={onStartRegistering}
        passcodeSubmitProps={passcodeSubmitProps}
        phoneSubmitProps={phoneSubmitProps}
        setCurrentStep={setCurrentStep}
        verificationCodeSubmitProps={verificationCodeSubmitProps}
      />
      <Footer>
        <HelpAndTermsLinks />
      </Footer>
    </AuthentificationLayout>
  );
};

export default LoginForm;
